<template>
    <div>
        <pin-media class="w-full rounded-t-lg cursor-pointer" @click="$emit('view', pin)" :pin="pin" />
        <div class="p-3 bg-white rounded-b-lg">
            <h2 @click="$emit('view', pin)" class="text-md font-medium cursor-pointer">{{ pin.name }}</h2>
            <router-link :to="{name: 'user.show', params: {id: pin.user.publicId}}" class="flex space-x-2 items-center mt-2" >
                <user-avatar :user="pin.user" class="h-5 w-5 rounded-full" />
                <div  class="flex-grow">
                    <span>{{ [pin.user.firstName, pin.user.lastName].join(' ') }}</span>
                </div>
            </router-link>
            <div class="text-xs">
                <span class="text-gray-500">on </span>
                <router-link :to="{name: 'event.show', params: {slug: pin.event.slug}}" class="text-primary-900 text-sm">{{ pin.event.name }}</router-link>
            </div>
        </div>
        <!-- <div class="border-gray-300 border-t p-2 flex flex-wrap divide-x">
            <like-button :pin="pin" />
            <comment-button :pin="pin" />
        </div> -->
    </div>
</template>

<script>
// import CommentButton from './CommentButton.vue';
// import LikeButton from './LikeButton.vue';
import PinMedia from './PinMedia.vue';
import UserAvatar from '../../User/components/Avatar.vue';

export default {
    name: "PinFeed",
    components: {
        // LikeButton,
        // CommentButton,
        PinMedia,
        UserAvatar
    },
    props: {
        pin: Object
    },
}
</script>