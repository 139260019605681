<template>
    <app-layout>
        <div class="flex justify-center py-5">
            <div class="w-full lg:w-4/5 flex flex-wrap">
                <div class="w-full sm:w-1/3">
                    <template v-if="order">
                        <div class="space-y-3 bg-white">
                            <img :src="event.imageUrl" :alt="event.name" class="w-full shadow-lg">
                            <div class="p-2 space-y-2">
                                <div class="flex flex-wrap justify-between items-center">
                                    <h4 class="text-gray-500">Ticket Order: {{ order.orderNumber }}</h4>
                                    <app-drop-down v-if="hasAccess" align="right">
                                        <template #trigger>
                                            <button class="focus:outline-none"><i class="fe fe-more-vertical"></i></button>
                                        </template>
                                        <template #content>
                                            <div class="divide-y">
                                                <app-drop-down-link type="button" @click="deleteOrder">
                                                    <span class="text-red-500">
                                                        <i class="fe fe-trash"></i> Delete order
                                                    </span>
                                                </app-drop-down-link>
                                            </div>
                                        </template>
                                    </app-drop-down>
                                </div>
                                <router-link :to="{name: 'event.show', params: {slug: event.slug, _event: event}}">
                                    <h1 class="text-lg font-medium text-primary-900 truncate">{{ event.name }}</h1>
                                </router-link>
                                <p>{{ event.shortDescription }}</p>
                                <p class="text-gray-500">{{ startMoment.format('ddd, MMMM Do YYYY') }}</p>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="w-full sm:w-2/3">
                    <app-tabs-panel v-model="tab" :items="tabs" :vertical="false">
                        
                        <template #content="attrs">
                            <div class="bg-white sm:mx-2">
                                <data-container :loading="loading">
                                    <template v-if="order">
                                        <slot v-bind="{ tab: attrs, order, ticket, event, business, customer, payment }" />
                                    </template>
                                    <template v-else>
                                        <div class="text-center text-gray-500 py-10">
                                            Ticket not found
                                        </div>
                                    </template>
                                </data-container>
                            </div>
                        </template>
                    </app-tabs-panel>
                </div>

            </div>
        </div>
        <app-confirmation :show="confirmation.show" @close="confirmation.show = false" :onConfirm="confirmation.action">
            <template #title>Confirm</template>
            <template #content>{{ confirmation.content }}</template>
        </app-confirmation>
    </app-layout>
</template>

<script>
import AppLayout from '../../../layouts/App.vue';
import AppTabsPanel from '../../../components/TabsPanel.vue';
import DataContainer from '../../../components/DataContainer.vue';
import AppConfirmation from '../../../components/Confirmation.vue';
import AppDropDown from '../../../components/Dropdown.vue';
import AppDropDownLink from '../../../components/DropdownLink.vue';

import eventMixin from '../mixins/event';
import TicketService from '../../Ticket/services';

export default {
    name: "EventTicket",
    mixins: [eventMixin],
    components: {
        AppLayout, AppTabsPanel,
        DataContainer, AppConfirmation, AppDropDown, AppDropDownLink
    },
    props: {
        id: String
    },

    data() {
        return {
            tab: 0,
            tabs: [
                {
                    title: 'Order',
                    id: 'order',
                },
                {
                    title: 'Ticket',
                    id: 'ticket',
                },
                {
                    title: 'Payment',
                    id: 'payment',
                },
                {
                    title: 'Customer',
                    id: 'customer',
                }
            ],
            confirmation: {
                show: false,
                title: null,
                content: null,
                action: () => Promise.resolve()
            },
            loading: false,
            order: null,
        };
    },

    computed:{
        event(){
            return this.ticket.event ? this.ticket.event : {}
        },

        business(){
            return this.event.business ? this.event.business : {}
        },

        ticket(){
            return this.order && this.order.ticket ? this.order.ticket : {}
        },

        customer(){
            return this.order && this.order.customer ? this.order.customer : {}
        },

        payment(){
            return this.order && this.order.payment ? this.order.payment : {}
        },

        hasAccess(){
            return false;
            // return this.business.publicId == this.$store.getters.business.publicId;
        }
    },

    methods: {

        getOrder(){

            if(this.$route.params._order) {
                this.order = this.$route.params._order;
                return;
            } 

            this.loading = true;
            return TicketService.getEventTicketOrder(this.id).then(response => {
                this.order = response.data;
                this.$emit('order', response.data);
            })
            .finally(() => {
                this.loading = false
            })
        },

        deleteOrder(){
            this.confirmation = {
                show: true,
                title: 'Delete order',
                content: `Are you sure you want to delete the order?`,
                action: () => TicketService.deleteTicketOrder(this.order.publicId).then(() =>  {
                    this.$store.commit('APP_SNACKBAR', {
                        text: `Ticket order deleted`
                    });
                }),
            }
        },
    },


    // beforeRouteEnter (to, from, next) {
    //     next(vm => {
    //         if(!vm.isMyEvent) vm.$router.push({
    //             name: 'board'
    //         })
    //     });
    // },

    watch: {
        id: {
            immediate: true,
            handler() {
                this.getOrder();
            }
        },

        $route: {
            immediate: true,
            handler(route) {
                const target = route.params.tab ?? this.tabs[0].id;
                this.tab =  this.tabs.findIndex(tab => tab.id === target) ?? 0;
            }
        },
        tab: {
            handler(tab) {
                this.$router.push({
                    name: this.$route.name,
                    params: {
                        tab: this.tabs[tab].id
                    }
                })
            }
        }
    }


}
</script>