<template>
    <div v-if="!product.isService" class="flex flex-wrap items-center justify-center sm:space-x-2 space-y-2">
        <div class="flex space-x-3 items-center justify-center">
            <i class="fe fe-minus text-lg text-primary-900 cursor-pointer" @click="() => cart.quantity > 0 ? cart.quantity-- : 0"></i>
            <app-input class="w-16" type="number" min="0" v-model="cart.quantity" />
            <i class="fe fe-plus text-lg text-primary-900 cursor-pointer" @click="cart.quantity++"></i>
        </div>
        <div class="text-center">
            <app-button @click="addToCart"><i class="mdi mdi-cart-plus"></i> 
            <span v-if="!inCart">Add to cart</span> <span v-else>Update in cart</span>
            </app-button>
        </div>
    </div>
    <div class="flex flex-wrap items-center justify-center" v-else>
        <app-button :to="{name: 'shop.product.show', params: { id: product.publicId }}">More Info</app-button>
    </div>
</template>

<script>
import AppInput from '../../../../components/Input.vue';
import AppButton from '../../../../components/Button.vue';
import CartService from '../../services/cart';

export default {
    name: "AddToCart",
    components: {
        AppInput, AppButton
    },
    data(){
        return {
            cart: {
                quantity: 1
            },
            inCart: false,
        }
    },

    props: {
        product: Object
    },

    methods: {
        addToCart(){
            if(this.cart.quantity <= 0){
                this.cart.quantity = 1;
                this.$store.commit('APP_SNACKBAR', {
                    text: "Invalid quantity"
                });
                return;
            }
            this.$store.dispatch('addCartItem', {quantity: this.cart.quantity, product: this.product}).then(() => {
                this.inCart = true;
            })
        }
    },

    watch:{
        product: {
            immediate: true,
            handler(product){
                const inCart = CartService.findProductInCart(product)
                if(inCart){
                    this.inCart = true;
                    this.cart = inCart;
                }else{
                    this.cart = {
                        quantity: 1,
                    };
                }
            }
        }
    }

}
</script>

