<template>
<div>
    <modal :show="show" max-width="xl" @close="close">
       <form @submit.prevent="submit" class="divide-y">
            <div class="p-3 text-center">
                <h1 class="text-primary-900 text-md font-medium">{{category ? `Update Category: ${category.name}` : 'New Category'}}</h1>
            </div>

            <div class="px-3 py-5 sm:px-10 space-y-5 overflow-y-auto" style="max-height: 65vh">       
                <app-input label="Name" v-model="form.name" type="text" :error="error('name')" required />               
            </div>

            <div class="border-gray-200 py-5 text-center">
                <div class="px-5 md:px-28">
                    <app-button type="submit">{{category ? 'Update' : 'Create' }} Category</app-button>
                </div>
            </div>

        </form> 
    </modal>
</div>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import AppInput from '../../../components/Input.vue';
import AppButton from '../../../components/Button.vue';
import pageMixin from '../../../mixins/page';
import EventService from '../services';

export default {
    name: "EventCategoryForm",
    mixins: [pageMixin],
    components: {
        Modal, AppInput, AppButton,
    },
    data(){
        return {
            show: false,           
            form: {},
            category: null,
        }
    },
  
    computed: {
        
    },
    methods: {
        open(category = null){
            this.category = category;
            this.show = true;
            if(!category){
                this.form = { }
            }else{
                this.form = {
                    name: category.name,
                };
            }
        },
        close(){
            this.show = false;
            this.$emit('close')
        },
       
        submit(){
            this.errors = null
            if(this.category) this.updateCategory();
            else this.createCategory();
        },

        createCategory(){
            this.start()
             EventService.storeCategory({
                ...this.form,
            })
            .then(response => {
                this.$emit('created', response.data)
                this.snackbar({
                    text: `New category created`
                });
                this.category = response.data;
            })
            .catch(e => {
                this.errors = e.response.data;
                // this.snackbar({
                //     text: 'Failed to create category. check your input',
                //     type: 'error'
                // });
            })
            .finally(() => {
                this.complete();
            })
        },

        updateCategory(){
            this.start()
             EventService.updateCategory(this.category.publicId, {
                ...this.form,
            })
            .then(response => {
                this.$emit('updated', response.data)
                this.snackbar({
                    text: `Category updated.`
                });
                this.category = response.data;
            })
            .catch(e => {
                this.errors = e.response.data;
                this.snackbar({
                    text: 'Failed to update category. check your input',
                    type: 'error'
                });
            })
            .finally(() => {
                this.complete();
            })
        },
    },

}
</script>