import API from '../../../api';

export default class extends API {
    
    /**
     * Create a comment for a pin
     * @param {*} pin 
     * @param {*} text 
     */
    static createComment(userId, pin, text){
        return this.post(`comments/create`, {
            text,
            pin: pin.publicId,
            user: userId
        })
    }

    /**
     * Update a comment
     * @param {*} commentId 
     * @param {*} param1 
     */
    static updateComment(commentId, { text }){
        return this.post(`comments/${commentId}/edit`, {text})
    }

    /**
     * delete comment
     * @param {*} commentId 
     */
    static deleteComment(commentId){
        return this.delete(`comments/${commentId}`);
    }

}
