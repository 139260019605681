<template>
<div>
    <modal :show="show" max-width="3xl" @close="close">
        <div class="p-3">
            <h1 class="text-primary-900 text-md font-medium">{{event ? `Update Event: ${event.name}` : 'New Event'}}</h1>
        </div>
        <div class="px-3 py-5 sm:px-10 overflow-y-auto" style="height: 80vh">
            <event-form :event="event" v-on="$listeners" />
        </div>
    </modal>
</div>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import EventForm from './Form.vue';

export default {
    name: "EventFormModal",
    components: {
        Modal, EventForm
    },
    data() {
        return {
            show: false,
            event: null,
        }
    },
  
    methods: {
        open(event = null){
            this.event = event;
            this.show = true;
        },
        close(){
            this.show = false;
            this.$emit('close')
        },

    },
}
</script>