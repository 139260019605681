import CartService from '../services/cart';

const actions = {
    addCartItem: ({ commit }, { quantity, product }) => {
        return new Promise((resolve) => {
            CartService.addCartItem({quantity, product});
            commit('SET_CART', CartService.getCart())
            commit('APP_SNACKBAR', {
                text: `${product.name} added to cart`
            });
            resolve();
        }) 
    },

    updateCartItem: ({ commit }, item) => {
        return new Promise((resolve) => {
            CartService.updateCartItem(item);
            commit('SET_CART', CartService.getCart())
            commit('APP_SNACKBAR', {
                text: `${item.product.name} updated in cart`
            });
            resolve();
        }) 
       
    },

    removeCartItem: ({ commit }, item) => {
        return new Promise((resolve) => {
            CartService.removeCartItem(item);
            commit('SET_CART', CartService.getCart())
            commit('APP_SNACKBAR', {
                text: `${item.product.name} removed from cart`
            });
            resolve();
        }) 
    },

    clearCartItem: ({ commit }) => {
        return new Promise((resolve) => {
            CartService.clearCart();
            commit('SET_CART', CartService.getCart())
            commit('APP_SNACKBAR', {
                text: `Cart cleared`
            });
            resolve();
        }) 
    },

    setCheckoutData: ({ commit }, data) => {
        return new Promise((resolve) => {
            CartService.setCheckoutData(data);
            commit('SET_CHECKOUT', CartService.getCheckout());
            resolve();
        })
    }
}

export default actions;