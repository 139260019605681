import API from '../../../api';

export default class extends API {

    static getUsers(query = {})
    {
        return this.post(`users/find`, query);
    }

    /**
     * Get a user
     * @param {*} userId 
     */
    static getUser(userId)
    {
        return this.get(`users/${userId}`);
    }
    /**
     * Update user password
     * @param {*}  
     */
    static updatePassword({ old_password, password }) {
        return this.put('password/update', { old_password, password })
    }

    /**
     * update user profile
     * @param {*} userId 
     * @param {*} data 
     */
    static update(userId, data)
    {
        return this.put(`users/${userId}/edit`, data)
    }

    /**
     * Get a users followings
     * @param {*} userId 
     */
    static getUserFollowings(userId, query = {})
    {
        return this.post(`users/followings/${userId}/find`, query);
    }

    /**
     * Get a users followers
     * @param {*} userId 
    */
    static getUserFollowers(userId,  query = {})
    {
        return this.post(`users/followers/${userId}/find`, query);
    }

    /**
     * Following another user
     * @param {*} followerId 
     * @param {*} followingId 
     */
    static follow(followerId, followingId)
    {
        return this.post(`users/follower/create`, {
            follower: followerId,
            following: followingId
        });
    }

    /**
     * Unfollowing another user
     * @param {*} followerId 
     * @param {*} followingId 
     */
    static unfollow(followerId, followingId)
    {
        return this.delete(`users/follower/${followerId}/following/${followingId}`);
    }

    /**
     * create user bank details
     * @param {*} id 
     * @param {*} data 
     */

    static createBank(data){
        return this.post('users/banks/create', data);
    }
    
    /**
     * update business bank details
     * @param {*} id 
     * @param {*} data 
     */

    static updateBank(bankId, data){
        return this.put(`users/banks/${bankId}/edit`, data)
    }
    
}
