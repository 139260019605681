<template>
    <div v-if="$store.getters.profileSet" class="p-5 space-y-5">
        <div>
            <h1 class="text-primary-900 text-lg font-medium">{{ $route.params.getStarted ? 'Choose your interests' : 'Update Interests'}}</h1>
            <h4 class="text-gray-500 text-md">Select atleast 5</h4>
        </div>

        <interest-tag-select display="grid" v-model="tags" />
        
        <div class="text-center">
            <p class="text-gray-500 mb-3">{{ tags.length }} interest<span v-show="tags.length > 1">s</span> selected</p>
            <div>
                <app-button type="submit" :disabled="tags.length < 5" @click="submit">{{$route.params.getStarted ? 'START PINNING' : 'Update Interests'}}</app-button>
            </div>
        </div>

    </div>
    <div v-else class="text-center p-5 text-gray-500">
        <h2 class="text-lg">Complete your profile before setting your interest tags</h2>
    </div>
        
</template>

<script>
import AppButton from '../../../components/Button';
import InterestTagSelect from '../../Event/components/InterestTagSelect.vue';
import UserService from '../services';
import pageMixin from '../../../mixins/page';

export default {
    name: "UserInterestUpdate",
    mixins: [pageMixin],

    data(){
        return {
            modal: false,
            tags: [],
        }
    },
    components: {
        AppButton, InterestTagSelect
    },
    computed: {
       
    },
    methods: {
        
        submit(){
            if(this.tags.length >= 5){
                this.start();
                this.errors = null;
                const form = {
                    ...this.$store.getters.auth,
                    interests: this.tags.map(tag => tag.publicId)
                }
                console.log(form);

                UserService.update(form.publicId, form)
                 Promise.resolve()
                .then(() => {
                    this.$store.commit('SET_AUTH_USER', {
                        ...this.$store.getters.auth,
                        interests: this.tags
                    });

                    this.$emit('updated', this.tags);
                })
                .catch(e => {
                    this.errors = e.response.data;
                })
                .finally(() => {
                    this.complete();
                })
            }
        }
    },
    mounted(){
        this.tags = this.$store.getters.auth.interests;
    }
}
</script>