<template>
    <router-link :to="{name: 'user.show', params: {id: user.publicId}}">
        <div class="flex flex-wrap px-1 py-2 items-center border-gray-100 border md:border-none rounded-md m-1 md:m-0">
            <div class="w-full sm:w-1/4">
                <user-avatar :user="user" class="h-10 w-10 rounded-full mx-auto" />
            </div>
            <div class="w-full sm:w-3/4 text-center sm:text-left">
                <div class="text-primary-900">{{ [user.firstName, user.lastName].join(' ') }}</div>
                <div class="text-sm">
                    <div>{{ user.city }}</div>
                    <div class="text-gray-500">{{ user.country }}</div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import UserAvatar from './Avatar.vue'
export default {
    name: "UserTile",
    components: {
        UserAvatar
    },
    props: {
        user: Object
    }
}
</script>