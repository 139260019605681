<template>
    <app-data-container :loading="loading">
        <div class="space-y-5">
            <app-empty-items v-if="!pin.pins.totalCount" message="No pin yet here" />
            <div v-else class="flex flex-wrap space-y-3 md:space-y-0">
                <div v-for="(col, c) in cols" :key="c" :class="`w-full md:w-1/${cols.length} space-y-3`">
                    <div v-for="(row, r) in rows" :key="r" class="md:mx-1" >
                        <pin-feed v-if="row[col]" :pin="row[col]" @view="viewPin" />
                    </div>
                </div>
            </div>
        </div>

        <pin-modal :pin="view" @close="view = null" @edit="editPin" @deleted="pinDeleted" />
        <pin-form @updated="pinUpdated" ref="pinForm" :event="action" />

    </app-data-container>
</template>

<script>
import PinFeed from '../components/PinFeed.vue';
import AppEmptyItems from '../../../components/EmptyItems.vue';
import AppDataContainer from '../../../components/DataContainer.vue';
import PinModal from '../components/PinModal.vue';
import PinForm from '../components/PinForm.vue';

export default {
    name: "PinFeeds",
    components: {
        PinFeed, AppEmptyItems, AppDataContainer, PinModal, PinForm
    },
    props: {
        src: Promise 
    },
    data(){
        return {
            loading: true,
            cols: [0,1,2,3],
            view: null,
            action: null,
            pin: {
                pins: [],
                query: {}
            }
        }
    },
    computed: {
        rows(){
            let feeds = this.pin.pins.items;
            let rows = [];
            let totalRows = feeds.length/this.cols.length;
            let start = 0;
            let limit = this.cols.length;
            for(let r = 1; r<=totalRows; r++){
                rows.push(feeds.slice(start, limit*r));
                start = limit*r;
            }
            if(feeds.length%this.cols.length){
                rows.push(feeds.slice(start, start + (feeds.length%this.cols.length)))
            }
            return rows;
        }
    },

    methods: {
        viewPin(pin){
            this.view = pin;
        },

        editPin(pin){
            this.view = null;
            this.action = pin;
            this.$refs.pinForm.open(pin);
        },

        pinUpdated(updatedPin){            
            this.pin.pins.items = this.pin.pins.items.map(pin => {
                if(pin.publicId == updatedPin.publicId) return {
                    ...pin,
                    name: updatedPin.name,
                    description: updatedPin.description,
                    mediaUrl: updatedPin.mediaUrl,
                };

                else return pin;
            })
        },

        pinDeleted(deletedPin){
            const index = this.pin.pins.items.findIndex(p => p.publicId === deletedPin.publicId);
            if(index >= 0) {
                this.pin.pins.items.splice(index, 1);
                this.view = null;
            }
        },

    },

    watch: {
        src: {
            immediate: true,
            handler(src){
                if(src){
                    this.loading = true;
                    src.then(response => {
                        this.pin.pins = response.data
                    })
                    .catch(() => {
                        // this.$store.commit('APP_SNACKBAR', {
                        //     type: 'error',
                        //     text: `Couldn't get pins. ${e.message}`
                        // })
                    })
                    .finally(() => {
                        this.loading = false
                    })
                }
               
            }
        }
    }

}
</script>