import BusinessService from '../services';

const actions = {

    BusinessCreate: ({ getters, commit }, data) => {

        return new Promise((resolve, reject) => {
            BusinessService.store({
                owner: getters.auth.publicId,
                ...data
            })
            .then(response => {
                if(response && response.data){
                    commit('SET_AUTH_BUSINESS', response.data)
                }
                resolve(response);
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    BusinessUpdate: ({getters, commit }, data) => {
        return new Promise((resolve, reject) => {
            BusinessService.update( getters.business.publicId, data )
            .then(response => {
                commit('SET_AUTH_BUSINESS', response.data)
                resolve(response);
            })
            .catch(err => {
                reject(err)
            })
        })
        
    },

    BusinessBankCreate: ({getters, commit }, data) => {
        return new Promise((resolve, reject) => {
            BusinessService.createBank(data )
            .then(response => {
                
                commit('SET_AUTH_BUSINESS', {
                    ...getters.business,
                    bankAccount: response.data
                })

                resolve(response);
            })
            .catch(err => {
                commit('APP_ERROR', {
                    text: `Failed to create bank details for ${getters.business.name}`
                })
                reject(err)
            })
        })
    },

    BusinessBankUpdate: ({getters, commit }, data) => {
        return new Promise((resolve, reject) => {
            BusinessService.updateBank(getters.business.bankAccount.publicId, data )
            .then(response => {
                
                commit('SET_AUTH_BUSINESS', {
                    ...getters.business,
                    bankAccount: response.data
                })

                resolve(response);
            })
            .catch(err => {
                commit('APP_ERROR', {
                    text: `Failed to update bank details for ${getters.business.name}`
                })
                reject(err)
            })
        })
        
    },

    



    

}

export default actions;