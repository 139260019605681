<template>
    <shop>
        <app-data-container :loading="loading">
            <div class="bg-white p-3 my-2">
                <h4 class="text-gray-500">Category</h4>
                <h1 class="text-xl">{{ category.name }}</h1>
            </div>
            <products-widget :src="products" />
        </app-data-container>
    </shop>
</template>

<script>
import Shop from '../../layout';
import AppDataContainer from '../../../../components/DataContainer.vue';
import ProductsWidget from '../widgets/Products.vue';
import ProductService from '../services';

export default {
    name: "ProductCategory",
    metaInfo() {
        return {
            title: `Products ${this.category.name ? ` - ${this.category.name}` : ``}`
        }
    },
    components: {
        Shop, AppDataContainer, ProductsWidget
    },

    data(){
        return {
            loading: false,
            category: {},
            categories: [],
            products: null
        }
    },

    computed: {
        id(){
            return this.$route.params.category
        }
    },

    watch:{
        id: {
            immediate: true,
            handler(id){
              this.loading = true;
               this.$store.dispatch('getProductCategories').then(categories => {
                    this.categories = categories;
                    this.category = categories.find(c => c.publicId == id)
                    this.products = ProductService.getProductsInCategory(this.id)
                })
                .finally(() => {
                    this.loading = false;
                })
            }
        }
    } 
}
</script>