<template>
    <shop :include-categories="false">
        <data-container :loading="loading">
            <div v-if="!order" class="text-center">
                <h1>Order not found</h1>
            </div>
            <div v-else class="flex justify-center">
                <div class="w-full md:w-2/3">
                    <div class="p-3 flex justify-between items-center">
                        <h1 class="text-lg text-primary-900">Order {{ order.orderNumber }}</h1>
                        <app-drop-down v-if="hasAccess" align="right">
                            <template #trigger>
                                <button class="text-gray-400 bg-white px-2 py-1 focus:outline-none border-gray-400 border rounded-md"><i class="fe fe-more-vertical"></i></button>
                            </template>
                            <template #content>
                                <div class="divide-y">
                                    <app-drop-down-link type="button" @click="deleteOrder">
                                        <!-- <span class="text-red-500">
                                            <i class="fe fe-trash"></i> Delete order
                                        </span> -->
                                    </app-drop-down-link>
                                </div>
                            </template>
                        </app-drop-down>
                    </div>
                    <div class="flex flex-wrap space-y-5 sm:space-y-0">
                        <div class="w-full">
                            <div class="m-2 p-5 bg-white rounded-md">
                                <h1 class="text-primary-900 text-lg">Product</h1>
                                <router-link :to="{name: 'shop.product.show', params: {id: product.publicId}}">
                                    <h3 class="">{{ product.name }}</h3>
                                    <h1 class="text-2xl">{{ product.price }}</h1>
                                </router-link>
                            </div>
                        </div>
                        <div class="w-full">
                            <div class="m-2 p-5 bg-white rounded-md">
                                <h1 class="text-primary-900 text-lg">Order</h1>
                                <div class="divide-y">
                                    <div class="p-2">
                                        Order Num: {{ order.orderNumber }}
                                    </div>
                                    <div class="p-2">
                                        Quantity: {{ order.quantity }}
                                    </div>
                                    <div class="p-2">
                                        Status: {{ order.status }}
                                    </div>
                                    <div class="p-2">
                                        Total: {{ order.total }}
                                    </div>
                                    <div class="p-2">
                                        Note: {{ order.notes }}
                                    </div>
                                    <div class="p-2">
                                        Date: {{ order.createdAt | datetime }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full">
                            <div class="m-2 p-5 bg-white rounded-md">
                                <h1 class="text-primary-900 text-lg">Payment</h1>
                                <div class="divide-y">
                                    <div class="p-2">
                                        Ref: {{ payment.reference }}
                                    </div>
                                    <div class="p-2">
                                        Amount: {{ payment.amount }}
                                    </div>
                                    <div class="p-2">
                                        Currency: {{ payment.currency }}
                                    </div>
                                    <div class="p-2">
                                        Payment Type: {{ payment.paymentType }}
                                    </div>
                                    <div class="p-2">
                                        Status: {{ payment.status}}
                                    </div>
                                    <div class="p-2">
                                        Date: {{ payment.createdAt | datetime }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full">
                            <div class="m-2 p-5 bg-white rounded-md">
                                <h1 class="text-primary-900 text-lg">Customer</h1>
                                <div class="text-center my-5">
                                    <user-avatar :user="customer" class="w-20 h-20 rounded-full" />
                                </div>
                                <div class="divide-y">
                                    <div class="p-2">
                                        Name: {{ [customer.firstName, customer.lastName].join(' ') }}
                                    </div>
                                    <div class="p-2">
                                        Email: {{ customer.email }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </data-container>
        <app-confirmation :show="confirmation.show" @close="confirmation.show = false" :onConfirm="confirmation.action">
            <template #title>Confirm</template>
            <template #content>{{ confirmation.content }}</template>
        </app-confirmation>
    </shop>
</template>

<script>
import Shop from '../../layout.vue';
import DataContainer from '../../../../components/DataContainer';
import UserAvatar from '../../../User/components/Avatar';
import AppConfirmation from '../../../../components/Confirmation';
import AppDropDown from '../../../../components/Dropdown.vue';
import AppDropDownLink from '../../../../components/DropdownLink.vue';

import OrderService from '../services';

export default {
    name: "CustomerOrder",
    metaInfo() {
        return {
            title: `Order ${this.id}`
        }
    },
    components: {
        Shop, DataContainer, UserAvatar,
        AppConfirmation, AppDropDown, AppDropDownLink
    },
    data(){
        return {
            loading: false,
            order: null,
            confirmation: {
                show: false,
                title: null,
                content: null,
                action: () => Promise.resolve()
            },

        }
    },

    computed: {
        id(){
            return this.$route.params.id
        },
        product(){
            return this.order ? this.order.product : {}
        },

        customer(){
            return this.order && this.order.customer ? this.order.customer : {}
        },

        payment(){
            return this.order && this.order.payment ? this.order.payment : {}
        },

        hasAccess(){
            return false
        }
    },
    methods: {
        getOrder(){
            if(this.$route.params._order) {
                this.order = this.$route.params._order;
                return;
            } 

            this.loading = true;
            return OrderService.getOrder(this.id).then(response => {
                this.order = response.data
            })
            .finally(() => {
                this.loading = false
            })
        },

        deleteOrder(){
            this.confirmation = {
                show: true,
                title: 'Delete order',
                content: `Are you sure you want to delete the order?`,
                action: () => OrderService.deleteOrder(this.order.publicId).then(() =>  {
                    this.$store.commit('APP_SNACKBAR', {
                        text: `Order deleted`
                    });
                }),
            }
        },
    },

    watch: {
        $route: {
            immediate: true,
            handler(){
                this.getOrder()
            }
        }
    }
}
</script>