<template>
    <shop>
        <div class="flex flex-wrap">
            <div class="w-full sm:w-1/2">
                <img :src="image" :alt="product.name" class="w-full" />
            </div>
            <div class="w-full sm:w-1/2">
                <div class="m-2">
                    <div class="bg-white p-2">
                        <div class="text-center space-y-5">
                            <h1 class="text-lg font-medium">{{ product.name }}</h1>
                            <h1 class="text-2xl">{{ product.price }}</h1>
                            <add-to-cart v-if="!product.isService" :product="product" />
                            <app-button 
                            v-else-if="business" 
                            class="block"
                            :to="{name: 'shop.business.show', params: { username: business.username, _business: business }}"> Contact Business </app-button>
                        </div>
                    </div>

                    <div class="bg-white p-2 mt-2 space-y-5">
                        <h4 class="font-medium text-lg">Product details</h4>
                        <p class="text-sm">Category: <router-link class="text-primary-900 hover:underline" :to="{name: 'shop.products', params: {category: category.publicId }}">{{ category.name }}</router-link></p>
                        <p>Status: <span class="capitalize">{{ product.status }}</span></p>
                        <div>{{ product.description }}</div>
                    </div>
                </div>
            </div>
        </div>

    </shop>
</template>

<script>
import Shop from '../../layout';
import AddToCart from '../components/AddToCart.vue';
import AppButton from '../../../../components/Button';
import pageMixin from '../../../../mixins/page';

import ProductService from '../services';
// import CartService from '../../services/cart';
import BusinessService from '../../../Business/services';

export default {
    name: "ProductShow",
    metaInfo() {
        return {
            title: this.product.name ? this.product.name : 'Product'
        }
    },
    mixins: [pageMixin],
    components: {
       AppButton, Shop, AddToCart
    },
    data(){
        return {
            product: {},
            business: null,
            cart: {
                quantity: 1
            },
            inCart: false,
        }
    },

    computed: {
        slug(){
            return this.$route.params.slug
        },
        
        id(){
            return this.$route.params.id
        },

        image(){
            return this.product.imageUrl ?? 'https://picsum.photos/200'
        },

        category(){
            return this.product.category ?? {}
        }
    },

    methods: {
         getProduct(){

            this.start({
                opacity: 1
            });
            (this.$route.params._product ? Promise.resolve({data: this.$route.params._product}) : ProductService.getProductByID(this.id))
            .then(response => {
                this.product = response.data;
                return BusinessService.getBusinessById(this.product.business)
            })
            .then(response => {
                // const inCart = CartService.findProductInCart(this.product)
                // if(inCart){
                //     this.inCart = true;
                //     this.cart = inCart;
                // }else{
                //     this.cart = {
                //         quantity: 1,
                //     };
                // }
                this.business = response.data
            })
            .catch(e => {
                this.$store.commit('APP_SNACKBAR', {
                    type: 'error',
                    text: `Couldn't get product. ${e.message}`
                })
            })
            .finally(() => {
                this.complete()
            });

        },

        addToCart(){
            if(this.cart.quantity <= 0){
                this.cart.quantity = 1;
                this.$store.commit('APP_SNACKBAR', {
                    text: "Invalid quantity"
                });
                return;
            }
            this.$store.dispatch('addCartItem', {quantity: this.cart.quantity, product: this.product}).then(() => {
                this.inCart = true;
            })
        }

    },

    watch: {
        $route: {
            immediate: true,
            handler(){
                this.getProduct();
            }
        },
    }
}
</script>