var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white flex flex-wrap justify-between items-center py-5 px-3"},[_c('div',{staticClass:"w-full md:w-auto flex flex-wrap items-center"},[_c('h1',{staticClass:"w-full md:w-auto font-bold mr-5"},[_vm._v("FILTER BY")]),_c('div',{staticClass:"w-full md:w-64 flex flex-wrap mt-5 md:mt-0"},[_vm._m(0),_c('app-select',{staticClass:"w-full",attrs:{"options":_vm.cities.map(function (c) { return ({label: c, value: c}); }),"placeholder":"Enter city...","bg":"white"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_c('i',{staticClass:"mdi mdi-map-marker text-primary-900"}),_vm._v(" "+_vm._s(label)+" ")]}},{key:"selected",fn:function(ref){
var label = ref.label;
return [_c('i',{staticClass:"mdi mdi-map-marker text-primary-900"}),_vm._v(" "+_vm._s(label)+" ")]}}]),model:{value:(_vm.filter.city),callback:function ($$v) {_vm.$set(_vm.filter, "city", $$v)},expression:"filter.city"}})],1)]),_c('div',{staticClass:"w-full md:w-72 flex space-x-2 items-center mt-5 md:mt-0"},[_c('i',{staticClass:"mdi mdi-view-grid text-3xl"}),_c('app-select',{staticClass:"w-full",attrs:{"options":_vm.categories.map(function (c) { return (Object.assign({}, c, {label: c.name, value: c.name})); }),"placeholder":"Search category...","bg":"white"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
var icon = ref.icon;
return [_c('i',{staticClass:"text-primary-900",class:icon}),_vm._v(" "+_vm._s(label)+" ")]}},{key:"selected",fn:function(ref){
var label = ref.label;
var icon = ref.icon;
return [_c('i',{staticClass:"text-primary-900",class:icon}),_vm._v(" "+_vm._s(label)+" ")]}}]),model:{value:(_vm.filter.category),callback:function ($$v) {_vm.$set(_vm.filter, "category", $$v)},expression:"filter.category"}}),_c('app-button',{attrs:{"title":"Apply filter","size":"md"},on:{"click":function($event){return _vm.$router.replace({name: _vm.$route.name, query: _vm.filter})}}},[_c('i',{staticClass:"mdi mdi-arrow-right"})])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('i',{staticClass:"mdi mdi-map-marker"}),_vm._v(" City")])}]

export { render, staticRenderFns }