<template>
    <div v-if="snackbar.show" class="fixed w-full bottom-2 flex flex-wrap justify-center" style="z-index: 999">
        <div class="flex-shrink">
            <div class="mx-5 sm:mx-0">
                <div :class="`flex justify-between items-center  p-2 px-5  bg-${bg} opacity-90 rounded-md shadow-lg`">
                    <div>
                        <div class="text-white">{{ snackbar.text }}</div>
                        <div  v-if="snackbar.actions && snackbar.actions.length" class="flex flex-wrap mt-2">
                            <div v-for="(action, i) in snackbar.actions" :key="i">
                                <button @click="doSnackbarAction(action.action)" class="px-4 py-1 mx-1 bg-transparent border-gray-300 border rounded-md focus:outline-none disabled:opacity-25 transition ease-in-out duration-150 text-white text-center">{{ action.text }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap items-center justify-between ml-2">
                        <div class="text-lg text-white ml-2">
                            <i @click="close" class="fe fe-x cursor-pointer"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    name: "AppSnackbar",
    
    data(){
        return {
            
        }
    },
    computed: {
        ...mapGetters([
            'snackbar'
        ]),
        bg(){
            if(this.snackbar.type == 'error') return 'red-500';
            return 'primary-900';
        }
    },
    methods: {
        ...mapMutations([
            'APP_SNACKBAR'
        ]),

        doSnackbarAction(action){
            Promise.resolve(action()).then(() => {
                if(this.snackbar.closeAfterAction) this.close();
            })
        },

        close(){
            this.APP_SNACKBAR({
                show: false,
                text: null,
            })
        }
    }
}
</script>