<template>
    <div>
        <app-select v-bind="$attrs" v-on="$listeners" :options="options" :loading="loading" />
        <div v-if="error" class="text-sm text-right mt-1">
            Failed to load types <a href="#" class="text-primary-900 underline" @click.prevent="getTypes()">Retry</a>
        </div>
    </div>
</template>

<script>
import AppSelect from '../../../components/Select';

export default {
    name: "AdvertTypeSelect",
    data(){
        return {
            loading: false,
            error: false,
            types: []
        }
    },
    computed: {
        options(){
            return this.types.map(type => ({
                label: `${type.name} - ${type.price}`,
                value: type.publicId
            }))
        }
    },
    components: {
        AppSelect
    },

    methods: {
        getTypes(){
            this.loading = true;
            this.error = false;
            
            this.$store.dispatch('getAdvertTypes').then(types => {
                this.types = types
            })
            
            .catch(() => {
                this.error = true;
            })
            .finally(() => {
                this.loading = false;
            })
        }
    },

    mounted(){
        this.getTypes();
    }
}
</script>