<template>
    <div>
        <header class="fixed bg-white left-0 right-0 py-3"
         style="z-index: 20">
            <div class="flex justify-between items-center mx-2 md: mx-5">
                <div class="flex gap-x-3 items-center">
                    <button @click="mobileNavExpand = !mobileNavExpand" class="sm:hidden text-primary-900 text-2xl focus:outline-none transition duration-150 ease-in-out">
                       <i class="mdi mdi-menu"></i>
                    </button>
                    <router-link :to="{name: 'home'}">
                        <img src="@/assets/logo.png" alt="PinEventz" class="h-6">
                    </router-link>
                </div>
               
                <div v-if="auth" class="flex items-center gap-x-2">
                    <h4 class="hidden lg:block text-gray-500">{{ auth.firstName }}</h4>
                    <user-avatar :user="auth" class="h-8 w-8 rounded-full object-cover" />
                </div>
            </div>
        </header>
        <div class="flex pt-14">
            
            <div class="w-2/12 sm:w-3/12 lg:w-2/12">
                <div class=" h-full overflow-x-hidden fixed left-0 bg-white" 
                    :class="{'w-2/12 sm:w-3/12 lg:w-2/12': !mobileNavExpand, 'w-5/12 sm:w-3/12 lg:w-2/12': mobileNavExpand}"
                    style="z-index: 20"
                    >
                    <ul class="overflow-y-auto" style="max-height: 80vh">
                        <li v-for="(nav, n) in navs" :key="n">
                            <admin-nav-menu  :nav="nav" :mobileNavExpand="mobileNavExpand" />
                        </li>
                    </ul>

                    <ul>
                        <li>
                            <a href="#" @click.prevent="logout">
                                <div class="px-1 sm:pl-5 py-3 sm:text-left text-red-500" 
                                :class="{'text-center': !mobileNavExpand, 'text-left pl-5': mobileNavExpand} ">
                                    <span class="mr-2 text-xl sm:text-md ">
                                        <i class="mdi mdi-logout"></i>
                                    </span>
                                    <span class="sm:inline sm:ml-3" :class="{'hidden': !mobileNavExpand, 'inline': mobileNavExpand}">Logout</span>           
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="w-10/12 sm:w-9/12 lg:w-10/12">

                <div class="fixed w-10/12 sm:w-9/12 lg:w-10/12 right-0" :class="bg"
                style="z-index: 10">
                    <div class="px-2 sm:px-6">
                        <slot name="header">Admin</slot>
                    </div>
                </div>
                
                <div class="min-h-screen pt-12 px-2 sm:px-6" :class="bg">
                    <slot />
                </div>
                    
            </div>    
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserAvatar from '../domain/User/components/Avatar.vue';

export default {
    components: {
        UserAvatar
    },

    props:{
        user: Object,
        bg: {
            type: String,
            default: () => 'bg-gray-100'
        }
    },

    data() {
        return {
            mobileNavExpand: false,
            navs: [
                {
                    title: 'Overview',
                    icon: 'mdi mdi-chart-pie',
                    route:{
                        name: 'admin',
                        params: {}
                    }
                },

                {
                    title: 'Users',
                    icon: 'mdi mdi-account-multiple',
                    route:{
                        name: 'admin.users',
                        params: {}
                    }
                },

                {
                    title: 'Businesses',
                    icon: 'mdi mdi-briefcase',
                    route:{
                        name: 'admin.businesses',
                        params: {}
                    }
                },

                {
                    title: 'Events',
                    icon: 'mdi mdi-calendar',
                    route:{
                        name: 'admin.events',
                        params: {}
                    }
                },
                
                {
                    title: 'Products',
                    icon: 'mdi mdi-store',
                    route:{
                        name: 'admin.products',
                        params: {}
                    }
                },
                {
                    title: 'Adverts',
                    icon: 'mdi mdi-bell',
                    route:{
                        name: 'admin.adverts',
                        params: {}
                    }
                },

            ]
        }
    },

    computed: {
        ...mapGetters(['auth']),
        path() {
            return window.location.pathname
        }
    },

    methods: {
        logout() {
            this.$store.dispatch('UserLogout')
            .then(() => {
                this.$eventHub.$emit('signed-out');

                if(this.$router.currentRoute.name !== 'home'){
                    this.$router.push({
                        name: 'home',
                    });
                }
            })
        },
    },

    beforeRouteEnter (to, from, next) {
        next(vm => vm.$store.getteres.auth.isStaff);
    }
}
</script>
