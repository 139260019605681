<template>
    <data-container :loading="loadingEvent">
        <div v-if="event" class="flex flex-wrap">
            <div class="w-full sm:w-8/12 bg-white">
                <div class="p-3 lg:p-5 space-y-5">
                    <div class="text-center">
                        <h4 class="text-center text-xl font-bold">{{ event.name }}</h4>
                        <p class="text-gray-500">{{ startMoment.format('dddd MMMM Do, YYYY') }}</p>
                    </div>
                    <div v-if="!tickets.length" class="p-5 text-gray-500 text-center">
                        No ticket available
                    </div>
                    <div v-else class="space-y-5">

                        <template v-if="!paymentIntent">
                            <!-- <app-select label="Select ticket"
                            :options="tickets.map(ticket => ({...ticket, label: ticket.name, value: ticket.publicId}))" 
                            v-model="order.ticket"
                            :returnObject="true" /> -->
                            <div v-for="(order, i) in orders" :key="order.ticket.publicId"
                            class="flex justify-between">
                                <div>
                                    <h4 class="font-medium text-lg">{{ order.ticket.name }}</h4>
                                    <p class="text-gray-500">{{ order.ticket.currency }}{{ order.ticket.price | number }}</p>
                                </div>
                                <div v-if="(order.ticket.ifActive && order.ticket.slotsRemaining > 0)" class="flex items-center gap-x-2">
                                    <button class="focus:outline-none hover:text-primary-900"
                                    @click="() => orders[i].quantity > 0 ? orders[i].quantity-- : null ">
                                        <i class="fe fe-minus"></i>
                                    </button>
                                    <input type="number" 
                                    class="h-8 w-16 py-2 border border-gray-200 focus:outline-none px-3 text-center" 
                                    min="0" :max="order.ticket.slotsRemaining"
                                    v-model="orders[i].quantity">
                                    <button class="focus:outline-none hover:text-primary-900"
                                    @click="() => orders[i].quantity < orders[i].ticket.slotsRemaining ? orders[i].quantity++ : null ">
                                    <i class="fe fe-plus"></i>
                                    </button>
                                </div> 
                                <div v-else>
                                    <app-alert
                                    color="red-500"
                                    bg="red-100" 
                                    class="text-center my-2">
                                        <p v-if="!order.ticket.ifActive">
                                          <i class="fe fe-slash"></i>  Not active
                                        </p>
                                        <p v-else-if="order.ticket.slotsRemaining <= 0">
                                           <i class="fe fe-slash"></i> Out of stock
                                        </p>
                                    </app-alert>
                                </div>                           
                            </div>

                            <div class="mt-20 text-center">
                                <app-button
                                @click="getPaymentIntent"
                                :loading="loadingPaymentIntent"
                                :disabled="!ticketSelected"
                                >CONTINUE</app-button>
                            </div>
                        </template>
                        <template v-else>
                            <h2 class="font-medium text-lg">General Information</h2>
                            <div class="space-y-1">
                                <div class="flex flex-wrap space-y-1 md:space-y-0">
                                    <div class="w-full md:w-1/2">
                                        <div class="md:pr-5">
                                            <app-input label="First name" :value="auth.firstName" type="text" prepend-icon="user" readonly />
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/2">
                                    <div class="md:pl-5">
                                            <app-input label="Last name" :value="auth.lastName" type="text" prepend-icon="user" readonly />
                                        </div>
                                    </div>
                                </div>
                                <app-input label="E-mail" :value="auth.email" type="email" prepend-icon="mail" readonly />

                                <div class="flex flex-wrap space-y-1 md:space-y-0">
                                    <div class="w-full md:w-1/2">
                                        <div class="md:pr-5">
                                            <app-input label="Phone Number" :value="auth.phone" type="text" prepend-icon="phone" readonly />
                                        </div>
                                    </div>
                                    <div class="w-full md:w-1/2">
                                    <div class="md:pl-5">
                                            <app-input label="Location" :value="[auth.city, auth.country].join(', ')" type="text" prepend-icon="map-pin" readonly />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 class="font-medium text-lg">Card Details</h2>
                            <stripe-payment 
                            ref="stripePayment"
                            :amount="totalOrderAmount" 
                            :currency="currency" 
                            :publishable-key="stripePublishableKey" 
                            :email="auth.email"
                            :payment-intent="paymentIntent"
                            tag="div"
                            @error="stripeError"
                            @success="stripeSuccess"
                            @aborted="stripeAborted" 
                            >
                            REGISTER
                            </stripe-payment>
                        </template>
                    </div>
                </div>
            </div>
            <div class="w-full sm:w-4/12 bg-gray-100 space-y-5">
                <img :src="image" :alt="event.name" class="w-full">
                <div class="py-5 px-3 lg:px-5">
                    <h4 class="font-bold text-lg text-center">Order Summary</h4>
                    <div v-if="validTicketOrders.length" class="mt-5 divide-y">
                        <div v-for="order in validTicketOrders" :key="order.ticket.publicId"
                        class="flex justify-between items-center py-3">
                            <div class="text-gray-500">{{ order.ticket.name }} <span class="ml-3">x{{ order.quantity | number }}</span></div>
                            <div class="text-gray-700 font-bold"> {{ order.ticket.currency }} {{ order.ticket.price * order.quantity  | number  }} </div>
                        </div>
                        <div
                        class="flex justify-between items-center py-3">
                            <div class="text-gray-500">VAT</div>
                            <div class="text-gray-700 font-bold">{{ currency }} {{ vat }} </div>
                        </div>
                    </div>
                    <div v-else class="text-center text-gray-500 py-5">
                        No ticket added yet
                    </div>
                    <div
                    class="flex justify-between items-center border-t-2 border-b-2 border-gray-500 py-5">
                        <div class="text-gray-500">Total</div>
                        <div class="text-gray-700 font-bold">{{ currency }} {{ totalOrderAmount }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center text-gray-500 p-5">
            Invalid event
        </div>
    </data-container>
</template>

<script>
import DataContainer from '../../../components/DataContainer.vue';
import AppButton from '../../../components/Button.vue';
import AppInput from '../../../components/Input.vue';
import AppAlert from '../../../components/Alert.vue'
import StripePayment from '../../Payment/components/StripePayment.vue';

import event from '../../Event/mixins/event';
import EventService from '../../Event/services';
import TicketService from '../services';
import { mapGetters } from 'vuex';

export default {
    name: "TicketOrderForm",
    mixins: [event],
    components: {
        AppButton, AppInput, AppAlert, DataContainer, 
        StripePayment,

    },
    data() {
        return {
            show: false,
            event: null,
            loadingEvent: false,
            makePayment: false,
            orders: [],
            loadingPaymentIntent: false,
            paymentIntent: null,
        }
    },

    props: {
        theEvent: Object,
    },

    computed: {
        ...mapGetters([
            'auth'
        ]),
        tickets() {
            return this.event && this.event.tickets ? this.event.tickets : [];
        },
        validTicketOrders() {
            return this.orders.filter(order => order.quantity > 0);
        },
        totalOrderAmount() {
            return this.orders.map(order => order.quantity * order.ticket.price)
                    .reduce((x,y) => x+y, 0);
        },

        vat() {
            return 0;
        },

        currency() {
            return this.orders.length ? this.orders[0].ticket.currency : null;
        },

        stripePublishableKey(){
            return process.env.VUE_APP_STRIPE_PUBLIC_KEY
        },

        processable(){
            return this.stripePublishableKey ? true : false;
        },

        ticketSelected() {
           return this.orders.some(order => order.quantity > 0);
        }
    },
    
    methods: {
        getEvent() {
            this.loadingEvent = true;
            EventService.getEventBySlug(this.theEvent.slug)
            .then(response => {
                this.event = response.data;
            })
            .catch(e => {
                this.$store.commit('APP_SNACKBAR', {
                    type: 'error',
                    text: `Couldn't get events properly. ${e.message}`
                })
            })
            .finally(() => {
                this.loadingEvent = false;
            });
        },

        initiateOrder(){
            this.success = false;
            this.orders = this.tickets.map(ticket => {
                return {
                    ticket,
                    quantity: 0
                }
            });
        },

        stripeError(error){
            this.$store.commit('APP_ERROR', error)
        },

        stripeSuccess(){
            this.success = true;
            this.$store.commit('APP_SNACKBAR', {
                text: "Payment successful"
            })
            this.paymentIntent = null;
            this.$emit('success', this.validTicketOrders);
        },

        stripeAborted(){
            this.$store.commit('APP_SNACKBAR', {
                text: "Payment aborted"
            });
            this.paymentIntent = null;
        },

        getPaymentIntent(){

            if(!this.ticketSelected){
                this.$store.commit('APP_SNACKBAR', {
                    text: "Select a ticket to order"
                })
                return;
            }
            if(!this.currency || !this.processable){
                this.$store.commit('APP_SNACKBAR', {
                    text: "Sorry, your ticket order cannot be placed. The host cannot process the payment at the moment"
                })
                return;
            }

            const orders = this.validTicketOrders.map(order => {
                return {
                    quantity: order.quantity,
                    total: order.quantity * order.ticket.price,
                    customer: this.$store.getters.auth.publicId,
                    ticket: order.ticket.publicId,
                    ownerName: [this.$store.getters.auth.firstName, this.$store.getters.auth.lastName].join(' ')
                }
            });
            
            this.loadingPaymentIntent = true;
            TicketService.createTicketOrder(orders)
            .then(response => {
                this.paymentIntent = response.data;                
            })
            .catch(e => {
                this.$store.commit('APP_SNACKBAR', {
                    type: 'error',
                    text: `Couldn't start payment. ${e.message}`
                })
            })
            .finally(() => {
                this.loadingPaymentIntent = false;
            })
        }
    },

    watch: {
        theEvent: {
            immediate: true,
            handler(event) {
                if(event && event.tickets) {
                    this.event = event; // no need to load the event again
                    return; 
                }
                if (event) this.getEvent(); //Load the event so we can have the tickets
            }
        },

        event: {
            immediate: true,
            handler() {
                this.initiateOrder();
            }
        }
    }
}
</script>