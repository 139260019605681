<template>
    <router-link :to="{name: 'shop.business.show', params: { username: business.username }}">
        <div class="bg-white shadow-lg bg-white hadow-lg  rounded-md">
            <business-avatar :business="business" class="w-full rounded-t-md" />
            <div class="p-2 rounded-b-md">
                <h2 class="font-bold text-primary-900 truncate">{{ business.name }}</h2>
                <div class="flex justify-between space-x-2 text-gray-500 ">
                    <div class="truncate">
                        <i class="mdi mdi-flower-poppy"></i> {{ business.businessType }}
                    </div>
                    <div class="truncate truncate">
                        <i class="mdi mdi-map-marker"></i> {{ business.city }}
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import BusinessAvatar from '../../../Business/components/Avatar.vue';

export default {
    name: "BusinessCard",
    components: {
        BusinessAvatar
    },
    props: {
        business: {
            type: Object,
            default: function() {
                return {
                    name: 'Jason Floral Decors',
                    city: 'Lagos',
                    logoUrl: `https://picsum.photos/id/${this._uid%100}/200`,
                    businessType:  'Organization',
                    username: 'pineventz'
                }
            }
        }
    }
}
</script>