<template>
    <shop :include-categories="false">
        <h1 class="text-2xl font-medium">My Orders({{ orders.length }})</h1>
        <h4 class="text-lg font-medium text-gray-600">Your orders in one place</h4>
        <p class="text-gray-400">If you have any questions, please feel free to contact us, our customer service center is working for you 24/7.</p>            
        <div class="overflow-x-auto">
            <data-container :loading="loading">
                <app-empty-items v-if="!orders.length" />
                <orders-table v-else :orders="orders" />
            </data-container>
        </div>
    </shop>
</template>

<script>
import Shop from '../../layout.vue';
import AppEmptyItems from '../../../../components/EmptyItems';
import DataContainer from '../../../../components/DataContainer';
import  OrdersTable from '../widgets/OrdersTable.vue';

import OrderService from '../services';

export default {
    name: "CustomerOrders",
    metaInfo: {
        title: `My orders`
    },

    components: {
        Shop, DataContainer, AppEmptyItems,
        OrdersTable
    },

    data(){
        return {
            loading: false,
            orders: []
        }
    },

    methods: {
        getOrders(){
            this.loading = true;
            OrderService.getCustomerOrders().then(response => {
                this.orders = response.data
            })
            .finally(() => {
                this.loading = false;
            })
        }
    },

    mounted(){
        this.getOrders();
    },
}
</script>