<template>
    <auth-layout :order="['side', 'main']">

        <template #main>
            <signup-form  v-if="!$route.params.step" @success="registered" />
            <email-verification v-if="$route.params.step == 'verification'" @verified="verified" />
        </template>
        <template #side>
            <div class="space-y-5 text-white">
                <h1 class="text-3xl font-bold">Hello there</h1>
                <p>Check out your upcoming eventz</p>
                <p>Concerts, Fashion, Awards, Parties, Celebrity, Weddings or Album launches </p>
                <app-button type="submit" bg="white" color="primary-900" :to="{name: 'signin'}" large block >SIGNIN</app-button>
            </div>
        </template>
        
    </auth-layout>
</template>

<script>
import AuthLayout from './Layout.vue';
import SignupForm from '../components/SignupForm';
import EmailVerification from '../components/EmailVerification.vue';
import AppButton from '../../../components/Button.vue';

export default {
    name: "Signup",
    metaInfo: {
        title: `Signup`
    },
    data(){
        return {
            
        }
    },
    components: {
        AuthLayout,
        SignupForm,
        EmailVerification,
        AppButton
    },
    methods: {
        goToStep(step){
            this.$router.push({
                name: 'signup',
                params: { step }
            }); 
        },

        registered(){
            this.goToStep('verification')
        },

        verified(){
            this.$router.push({
                name: 'signin'
            })
        },

    },
}
</script>