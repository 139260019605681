<template>
    <div>
        <app-select v-bind="$attrs" v-on="$listeners" :options="options" :loading="loading" />
        <div v-if="error" class="text-sm text-right">
            Failed to load categories <a href="#" class="text-primary-900 underline" @click.prevent="getCategories()">Retry</a>
        </div>
    </div>
</template>

<script>
import AppSelect from '../../../../components/Select';

export default {
    name: "ProductCategorySelect",
    data(){
        return {
            loading: false,
            error: false,
            categories: []
        }
    },
    computed: {
        options(){
            return this.categories.map(category => ({
                label: category.name,
                value: category.publicId
            }))
        }
    },
    components: {
        AppSelect
    },

    methods: {
        getCategories(){
            this.loading = true;
            this.error = false;
            
            this.$store.dispatch('getProductCategories').then(categories => {
                this.categories = categories
            })
            
            .catch(() => {
                this.error = true;
            })
            .finally(() => {
                this.loading = false;
            })
        }
    },

    mounted(){
        this.getCategories();
    }
}
</script>