export default {
    data(){
        return {
            errors: null,
            loader: null
        }
    },
    methods: {
        start(loader = {}) {
            this.loader = this.$loading.show(loader);
        },

        complete(){
            if(!this.loader) return;
            this.loader.hide();
        },

        snackbar(options){
            this.$store.commit('APP_SNACKBAR', options)
        },

        error(key) {
            if(!this.errors) return null;
            const error = this.errors[key];
            if(!error) return null;
            return error.constructor === Array ? error[0] : error;
        }
    },
};
