<template>
    <div>  
        <component :is="isAuthenticated ? 'user-nav' : 'guest-nav' "></component> 
        <main :class="`pt-12 min-h-screen bg-${bg}`" v-bind="$attrs">
            <slot />
        </main>
        <!-- Footer area -->
        <div class="bg-white p-3">
        <div class="flex flex-wrap">
            <div class="w-full lg:w-2/3">
            <div class="flex flex-wrap justify-center">
                <router-link :to="{name: 'home'}" class="m-2 text-sm text-gray-500 hover:text-primary-900">Home</router-link>
                <router-link :to="{name: 'about'}" class="m-2 text-sm text-gray-500 hover:text-primary-900">About</router-link>
                <router-link :to="{name: 'privacy'}" class="m-2 text-sm text-gray-500 hover:text-primary-900">Privacy Policy</router-link>
                <router-link :to="{name: 'terms'}" class="m-2 text-sm text-gray-500 hover:text-primary-900">Terms Of Use</router-link>
                <router-link :to="{name: 'home'}" class="m-2 text-sm text-gray-500 hover:text-primary-900">Contact Us</router-link>
            </div>
            </div>
            <div class="w-full mt-2 lg:mt-0 lg:w-1/3">
            <div class="flex flex-wrap justify-center lg:justify-end">
                <a href="#">
                <img src="@/assets/images/google-play-download.png" alt="Google Play Download" class="w-24 h-8 mx-2">
                </a>
                <a href="#">
                <img src="@/assets/images/apple-store-download.png" alt="Google Play Download" class="w-24 h-8 mx-2">
                </a>
            </div>
            </div>
        </div>
        </div>

    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import GuestNav from './components/GuestNav.vue';
import UserNav from './components/UserNav.vue';

export default {
    name: "AppLayout",
    components: {
        GuestNav, UserNav
    },

    props: {
        bg: {
            type: String,
            default: () => 'gray-100'
        }
    },

    computed: {
        ...mapGetters([
            'isAuthenticated',
        ]),
    },

    methods: {
        ...mapMutations([
            'APP_SNACKBAR'
        ]),
    },

    created(){
        
    },
    mounted(){
        
    },

    beforeDestroy(){
        this.$eventHub.$off('logged-in');
    },
    
}
</script>
