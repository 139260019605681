<template>
<div>
    <modal :show="show" max-width="xl" @close="close">
       <form @submit.prevent="submit" class="divide-y">
            <div class="p-3 text-center">
                <h1 class="text-primary-900 text-md font-medium">{{type ? `Update Type: ${type.name}` : 'New Type'}}</h1>
            </div>

            <div class="px-3 py-5 sm:px-10 space-y-5 overflow-y-auto" style="max-height: 65vh">       
                <app-input label="Name" v-model="form.name" type="text" :error="error('name')" required />               
                <app-input label="Price" v-model="form.price" type="number" :error="error('price')" required />               
            </div>

            <div class="border-gray-200 py-5 text-center">
                <div class="px-5 md:px-28">
                    <app-button type="submit">{{type ? 'Update' : 'Create' }} Type</app-button>
                </div>
            </div>

        </form> 
    </modal>
</div>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import AppInput from '../../../components/Input.vue';
import AppButton from '../../../components/Button.vue';
import pageMixin from '../../../mixins/page';
import AdvertService from '../services';

export default {
    name: "AdvertTypeForm",
    mixins: [pageMixin],
    components: {
        Modal, AppInput, AppButton,
    },
    data(){
        return {
            show: false,           
            form: {},
            type: null,
        }
    },
  
    computed: {
        
    },
    methods: {
        open(type = null){
            this.type = type;
            this.show = true;
            if(!type){
                this.form = { }
            }else{
                this.form = {
                    name: type.name,
                    price: type.price
                };
            }
        },
        close(){
            this.show = false;
            this.$emit('close')
        },
       
        submit(){
            this.errors = null
            if(this.type) this.updateType();
            else this.createType();
        },

        createType(){
            this.start()
             AdvertService.storeType({
                ...this.form,
            })
            .then(response => {
                this.$emit('created', response.data)
                this.snackbar({
                    text: `New type created`
                });
                this.type = response.data;
            })
            .catch(e => {
                this.errors = e.response.data;
                // this.snackbar({
                //     text: 'Failed to create type. check your input',
                //     type: 'error'
                // });
            })
            .finally(() => {
                this.complete();
            })
        },

        updateType(){
            this.start()
             AdvertService.updateType(this.type.publicId, {
                ...this.form,
            })
            .then(response => {
                this.$emit('updated', response.data)
                this.snackbar({
                    text: `Type updated.`
                });
                this.type = response.data;
            })
            .catch(e => {
                this.errors = e.response.data;
                this.snackbar({
                    text: 'Failed to update type. check your input',
                    type: 'error'
                });
            })
            .finally(() => {
                this.complete();
            })
        },
    },

}
</script>