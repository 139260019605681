<template>
    <table class="min-w-max w-full table-auto mt-3">
        <thead>
            <tr class="bg-white text-gray-600 uppercase leading-normal">
                <th class="py-3 px-6 text-left">Order</th>
                <th class="py-3 px-6 text-left">Date</th>
                <th class="py-3 px-6 text-center">Total</th>
                <th class="py-3 px-6 text-center">Status</th>
                <th class="py-3 px-6 text-center">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(order, i) in items" :key="order.publicId"
                :class="{'bg-gray-100': i%2 === 0, 'bg-white': i%2 === 1 }" 
                >
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <div class="flex items-center">
                        <span class="font-medium">{{order.orderNumber}}</span>
                    </div>
                </td>
                <td class="py-3 px-6 text-left">
                    <div class="flex items-center">
                        <span>{{ order.createdAt | datetime }}</span>
                    </div>
                </td>
                <td class="py-3 px-6 text-center">
                    <span>{{ order.total }}</span>
                </td>
                <td class="py-3 px-6 text-center">
                    <span>{{ order.status }}</span>
                </td>
                <td class="py-3 px-6 text-center">
                    <div class="flex item-center justify-center">
                        <router-link
                        class="text-primary-900"
                        :to="{name: 'shop.customer.product.order', params: {id: order.publicId, _order: order}}"
                        >View</router-link>
                    </div>
                </td>
            </tr>
        </tbody>

    </table>
</template>

<script>
export default {
    name: "ProductOrdersTable",
    props: {
        orders: [Object, Array]
    },
    computed: {
        items() {
            if(!this.orders) return []
            return this.orders instanceof Array ? this.orders : this.orders.items
        }
    }
}
</script>