<template>
    <data-container :loading="loading">
        <div v-if="event">

            <app-empty-items v-if="!orders.totalCount" />
            <ticket-orders-table v-else :orders="orders" />
            
        </div>
        <div v-else class="text-center text-gray-500">
            <h4>Event not found</h4>
        </div>
    </data-container>
</template>
<script>
import DataContainer from '../../../components/DataContainer.vue';
import AppEmptyItems from '../../../components/EmptyItems.vue';
import TicketOrdersTable from '../../Ticket/widgets/OrdersTable.vue';

import TicketService from '../../Ticket/services';
import eventMixin from '../mixins/event';

export default {
    name: "EventTicketOrders",
    mixins: [eventMixin],
    components: {
        DataContainer,
        AppEmptyItems,
        TicketOrdersTable
    },
    props: { 
        event: Object,
    },
    data(){
        return {
            orders: {},
            loading: false
        }
    },
    methods: {
        getEventTicketOrders(){

            this.loading = true;

            TicketService.getEventTicketOrders(this.event.publicId)
            .then(response => {
                this.orders = response.data
            })
            .catch(e => {
                this.$store.commit('APP_SNACKBAR', {
                    type: 'error',
                    text: `Couldn't get ticket orders. ${e.message}`
                })
            })
            .finally(() => {
                this.loading = false;
            });

        },
    },

    watch: {
        event: {
            immediate: true,
            handler(event) { 
                if(event) this.getEventTicketOrders();
            }
        }
    }
}
</script>