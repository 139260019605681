<template>
    <div class="px-2 sm:px-5 lg:px-20 py-7">
      <div class="flex flex-wrap">
          <div class="w-full sm:w-3/12 lg:w-2/12">
            <ads-widget heading="Ads" />
          </div>
          
          <div class="w-full sm:w-9/12 lg:w-10/12">
            <div class="sm:px-5">
              <pin-feeds :src="feeds"  />
            </div>
          </div>
      </div>
    </div>
</template>

<script>
import AdsWidget from '../../domain/Advert/widgets/Ads.vue';
import PinFeeds from '../../domain/Pin/widgets/PinFeeds.vue';

import PinService from '../../domain/Pin/services';

export default {
    name: "UserHome",
    components: {
        AdsWidget, PinFeeds
    },
    data(){
        return {
        feeds: Promise.resolve(),
        }
    },
    methods: {
        getFeeds() {
            this.feeds = PinService.getUserSuggestedPins(this.$store.getters.auth.publicId)
        }
    },

    mounted(){
        this.getFeeds();
    }
}
</script>