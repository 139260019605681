<template>
    <nav class="border-b  fixed z-20 w-full max-h-screen overflow-y-auto sm:overflow-visible"
        :class="{'bg-white border-gray-100': $store.getters.mode == 'personal', 'bg-primary-100 ': $store.getters.mode == 'business'}"
    >
        <!-- Primary Navigation Menu -->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16">
                <div class="flex items-center overflow-x-auto overflow-y-hidden">

                    <!-- Logo -->
                    <div class="flex-shrink-0 flex items-center">
                        <router-link :to="{name: 'home'}">
                            <img src="@/assets/logo.png" alt="PinEventz" class="h-6 mx-auto">
                        </router-link>
                    </div>

                    <div class="ml-1 sm:ml-10">
                        <nav-search />
                    </div>
                </div>

                <!-- Account Dropdown -->
                <div class="flex items-center">

                    <!-- Navigation Links -->
                    <div class="hidden sm:flex  space-x-5 h-16 overflow-auto sm:ml-6" >
                        <nav-link :to="{name: 'home'}" :active="$router.currentRoute.name == 'home'">
                            Home
                        </nav-link>
                        <nav-link :to="{name: 'events'}" :active="$router.currentRoute.name == 'events'">
                            Events
                        </nav-link>
                        <nav-link :to="{name: 'board'}" :active="$router.currentRoute.name == 'board'">
                            Boards
                        </nav-link>
                        <nav-link :to="{name: 'user.connections'}" :active="$router.currentRoute.name == 'user.connections'">
                            Connections
                        </nav-link>
                        <nav-link :to="{name: 'shop'}" :active="$router.currentRoute.name.startsWith('shop')">
                            Shop
                        </nav-link>
                    </div>
                    
                    <!-- <div class="border-gray-200 sm:border-l sm:border-r flex ml-0 sm:ml-5 sm:px-3">
                        <a href="#" class="mx-3 relative" @click.prevent>
                            <span class="absolute text-sm h-5 w-5 rounded-full text-center -top-3 -right-3"
                            :class="{'bg-primary-900  text-white': $store.getters.mode == 'personal', 'bg-white  text-primary-900':  $store.getters.mode == 'business'}"
                            >5</span>
                            <i class="fe fe-bell text-xl"
                            :class="{'text-gray-300': $store.getters.mode == 'personal', 'text-white':  $store.getters.mode == 'business'}"
                            ></i>
                        </a>
                        <a href="#" class="mx-3 relative" @click.prevent>
                            <span class="absolute text-sm h-5 w-5 rounded-full text-center -top-3 -right-3"
                            :class="{'bg-primary-900  text-white': $store.getters.mode == 'personal', 'bg-white  text-primary-900':  $store.getters.mode == 'business'}">2</span>
                            <i class="fe fe-mail text-xl" 
                            :class="{'text-gray-300': $store.getters.mode == 'personal', 'text-white':  $store.getters.mode == 'business'}"></i>
                        </a>
                    </div> -->

                    <div class="hidden sm:block ml-3 relative">
                        <dropdown align="right" width="48">

                            <template #trigger>
                                <button class="flex items-center text-sm border-transparent focus:outline-none transition duration-150 ease-in-out">
                                    <user-avatar :user="auth" class="h-8 w-8 rounded-full object-cover" />
                                    <h4  v-if="auth" class="hidden lg:block  ml-2" :class="{'text-gray-400': $store.getters.mode == 'personal', 'text-white': $store.getters.mode == 'business' }">{{ auth.firstName }}</h4>
                                </button>
                            </template>

                            <template #content>
                                <!-- Account Management -->
                                <div class="block px-4 py-2 text-xs text-gray-400">
                                    Manage Account
                                </div>

                                <div class="divide-y">
                                    <dropdown-link :to="{name: 'user.profile.edit'}">
                                        Edit Profile
                                    </dropdown-link>

                                    <dropdown-link :to="{name: 'shop.customer.product.orders'}">
                                        Product orders
                                    </dropdown-link>

                                    <!-- <dropdown-link :to="{name: 'home'}">
                                        Report a problem
                                    </dropdown-link>

                                    <dropdown-link type="button" v-if="$store.getters.business && $store.getters.mode == 'business'" @click="switchMode('personal')">
                                        Switch to personal
                                    </dropdown-link>

                                    <dropdown-link type="button" v-if="$store.getters.business && $store.getters.mode == 'personal'"  @click="switchMode('business')">
                                        Switch to business
                                    </dropdown-link> -->

                                    <dropdown-link v-if="!$store.getters.business" :to="{name: 'business.create'}">
                                        Create business profile
                                    </dropdown-link>

                                    <dropdown-link v-else :to="{name: 'business.edit'}">
                                        Edit business profile
                                    </dropdown-link>

                                    <dropdown-link v-if="$store.getters.business" :to="{name: 'shop.business.show', params: { username: $store.getters.business.username }}">
                                        Visit store
                                    </dropdown-link>

                                    <dropdown-link v-if="auth.isStaff" :to="{name: 'admin'}">
                                        Admin
                                    </dropdown-link>

                                    <!-- Authentication -->
                                    <form @submit.prevent="logout">
                                        <dropdown-link type="button">
                                           <span class="text-red-500">Logout</span>
                                        </dropdown-link>
                                    </form>
                                </div>
                                
                                
                            </template>
                        </dropdown>
                    </div>
                </div>

                <!-- Hamburger -->
                <div class="-mr-2 flex items-center sm:hidden">
                    <button @click="showingNavigationDropdown = ! showingNavigationDropdown" class="inline-flex items-center justify-center p-2 rounded-md focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    :class="{'text-gray-400 hover:text-gray-500 hover:bg-gray-100': $store.getters.mode == 'personal', 'text-white hover:text-primary-900 hover:bg-white':  $store.getters.mode == 'business'}"
                    >
                        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path :class="{'hidden': showingNavigationDropdown, 'inline-flex': ! showingNavigationDropdown }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                            <path :class="{'hidden': ! showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- Responsive Navigation Menu -->
        <div :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}" class="bg-white sm:hidden">
            <div class="pt-2 pb-3 space-y-1">
                <responsive-nav-link :to="{name: 'home'}" :active="$router.currentRoute.name == 'home'">
                    Home
                </responsive-nav-link>
                <responsive-nav-link :to="{name: 'events'}" :active="$router.currentRoute.name == 'events'">
                    Events
                </responsive-nav-link>
                <responsive-nav-link :to="{name: 'board'}" :active="$router.currentRoute.name == 'board'">
                    Boards
                </responsive-nav-link>
                <responsive-nav-link :to="{name: 'user.connections'}" :active="$router.currentRoute.name == 'user.connections'">
                    Connections
                </responsive-nav-link>
                <responsive-nav-link :to="{name: 'shop'}" :active="$router.currentRoute.name == 'shop'">
                    Shop
                </responsive-nav-link>
            </div>

            <!-- Responsive Settings Options -->
            <div class="pt-4 pb-1 border-t border-gray-200">
                <div class="flex items-center px-4">
                    <div class="flex-shrink-0">
                        <user-avatar :user="auth" class="h-10 w-10 rounded-full" />
                    </div>
                    <div v-if="auth" class="ml-3">
                        <div class="font-medium text-base text-gray-800">{{ auth.firstName }} {{ auth.lastName }}</div>
                        <div class="font-medium text-sm text-gray-500">{{ auth.email }}</div>
                    </div>
                </div>

                <div class="mt-3 space-y-1">
                    <responsive-nav-link :to="{name: 'user.profile.edit'}">
                        Edit Profile
                    </responsive-nav-link>

                    <responsive-nav-link :to="{name: 'shop.customer.product.orders'}">
                        Product Orders
                    </responsive-nav-link>

                    <!-- <responsive-nav-link :to="{name: 'home'}">
                        Report a problem
                    </responsive-nav-link>

                     <responsive-nav-link type="button" v-if="$store.getters.business && $store.getters.mode == 'business'" @click="switchMode('personal')">
                        Switch to personal
                    </responsive-nav-link>

                    <responsive-nav-link type="button" v-if="$store.getters.business && $store.getters.mode == 'personal'"  @click="switchMode('business')">
                        Switch to business
                    </responsive-nav-link> -->

                    <responsive-nav-link v-if="!$store.getters.business" :to="{name: 'business.create'}">
                        Create business profile
                    </responsive-nav-link>

                    <responsive-nav-link v-else :to="{name: 'business.edit'}">
                        Edit business profile
                    </responsive-nav-link>

                    <responsive-nav-link v-if="$store.getters.business" :to="{name: 'shop.business.show', params: { username: $store.getters.business.username }}">
                        Visit store
                    </responsive-nav-link>

                    <responsive-nav-link v-if="auth.isStaff" :to="{name: 'admin'}">
                       Admin
                    </responsive-nav-link>

                    
                    <!-- Authentication -->
                    <form method="POST" @submit.prevent="logout">
                        <responsive-nav-link type="button">
                            <span class="text-red-500">Logout</span>
                        </responsive-nav-link>
                    </form>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';

    import NavLink from '../../components/NavLink.vue';
    import ResponsiveNavLink from '../../components/ResponsiveNavLink.vue';
    import Dropdown from '../../components/Dropdown.vue';
    import DropdownLink from '../../components/DropdownLink.vue';
    import NavSearch from './Search.vue';
    import UserAvatar from '../../domain/User/components/Avatar.vue';

    export default {
        name: "UserNavigation",
        components: {
            NavLink,
            ResponsiveNavLink,
            Dropdown,
            DropdownLink,
            NavSearch,
            UserAvatar
        },
        data() {
            return {
                showingNavigationDropdown: false,
            }
        },

        computed: {
            ...mapGetters([
                'auth'
            ])
        },  

        methods: {
            ...mapMutations([
                'SET_AUTH_USER',
                'SWITCH_MODE',
                'APP_SNACKBAR',
            ]),

            switchMode(mode){
                this.SWITCH_MODE(mode);
                this.$eventHub.$emit('mode-switched', mode);
                this.APP_SNACKBAR({
                    text: `Switched to ${mode}`
                });
            },

            logout() {
                this.$store.dispatch('UserLogout')
                .then(() => {
                    this.$eventHub.$emit('signed-out');

                    if(this.$router.currentRoute.name !== 'home'){
                        this.$router.push({
                            name: 'home',
                        });
                    }
                })
            },
        }
    }
</script>