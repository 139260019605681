<template>
    <div>
        <label v-if="label" class="block font-medium text-sm"  :class="{ 'text-transparent' : !focused, 'text-gray-500' : focused }">
            {{ label }}
        </label>

        <div>
            <textarea 
            ref="input"
            v-bind="$attrs"
            @input="$emit('input', $event.target.value)"
            @focus="focused = true"
            @blur="focused = false"
            :placeholder="label"
            class="block w-full py-2 bg-gray-200 focus:outline-none px-3"
            ></textarea>
        </div>
        <input-error v-if="error" :message="error" />
    </div>
</template>

<script>
    import InputError from './InputError';
    export default {
        name: 'AppTextArea',
        components: {
            InputError
        },
        data() {
            return {
                focused: false,
            }
        },
        props:{
            label: String,
            error: String,
        },

        methods: {
            focus() {
                this.$refs.input.focus()
            }
        },
    }
</script>

