const mutations = {

    SET_AUTH_USER: (state, user) => {
        state.auth = user
    },

    SET_OAUTH: (state, oauth) => {
        state.oauth = oauth
    },

};
export default mutations;