<template>
    <div class="px-1 sm:px-5 lg:px-12 py-5">
        <div class="flex flex-wrap">
            <div class="w-10/12 sm:w-4/12 lg:w-3/12 space-y-5
            fixed sm:relative
            shadow-lg sm:shadow-none
            bg-white sm:bg-transparent
            -mt-2 sm:mt-0
            h-full sm:h-auto
            overflow-y-auto"
            :class="{ 
                'hidden sm:block': !sideProfile,
                'block': sideProfile
            }"
            >

                <div class="flex flex-wrap content-center space-y-5">
                    <!-- Profile -->
                    <div class="w-full">
                        <div class="">
                            
                            <div class="bg-white p-3 space-y-2">
                                <div class="flex justify-between sm:justify-end">
                                   <button class="focus:outline-none bg-transparent text-primary-900 text-lg sm:hidden"
                                   @click="sideProfile = false">
                                    <i class="fe fe-arrow-left"></i>
                                   </button>
                                    <app-drop-down v-show="$store.getters.isAdmin" align="right">
                                        <template #trigger>
                                            <button class="bg-transparent focus:outline-none"><i class="mdi mdi-cog"></i></button>
                                        </template>
                                        <template #content>
                                            <div class="divide-y">
                                                <app-drop-down-link type="button" @click="$refs.userAdminEdit.open()">
                                                    Edit User
                                                </app-drop-down-link>
                                            </div>
                                        </template>
                                    </app-drop-down>
                                </div>
                                <div>
                                    <user-avatar :user="user" class="h-36 w-36 rounded-full mx-auto" />   
                                     <div class="text-center">
                                        <h4 class="font-bold text-lg text-primary-900 truncate">{{[user.firstName,  user.lastName ].join(' ') }}</h4>
                                        <p class="text-gray-500 text-sm">{{[user.city,  user.country ].join(', ') }}</p>
                                    </div>
                                </div>
                                <div v-if="isMyBoard" class="flex justify-between items-center">
                                    <div class="text-sm">
                                        Events joined
                                    </div>
                                    <div class="font-medium">
                                        {{ user.numOfEvents }}
                                    </div>
                                </div>
                                <div v-if="isMyBoard" class="flex justify-between items-center">
                                    <div  class="text-sm">
                                        Pins created
                                    </div>
                                    <div class="font-medium">
                                        {{ user.numOfPins }}
                                    </div>
                                </div>
                                <div v-if="!isMyBoard" class="flex justify-between">
                                    <user-follow-button :user="user" />
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div  class="w-full">
                        <!-- Interests -->
                        <div v-if="isMyBoard" >
                            <div class="bg-white p-2 space-y-5">
                                <div class="flex justify-between">
                                    <h4 class="font-medium">Interests ({{user.interests.length}})</h4>
                                    <router-link v-if="isMyBoard" :to="{name: 'user.profile.edit', params: {tab: 'interests'}}"><i class="fe fe-edit"></i></router-link>
                                </div>
                                <div v-if="user.interests && user.interests.length" class="flex flex-wrap overflow-y-auto" style="max-height: 400px">
                                    <div v-for="(interest, i) in user.interests" :key="i" >
                                        <div class="px-2 py-1 m-1 rounded-md bg-primary-900 text-white text-center truncate">
                                            {{ interest.name }} 
                                        </div>
                                    </div>
                                </div>

                                <div v-else class="text-center text-gray-500 text-sm py-10">
                                    No interest yet
                                </div>
                            </div>
                        </div>

                        <!-- Ads -->
                        <ads-widget v-else heading="Ads" />                            

                    </div>
                </div>
            </div>

            <div class="w-full sm:w-8/12 lg:w-9/12 mt-2 sm:mt-0">
                <div :class="`sm:mx-2 lg:mx-4 bg-${bg}`">

                    <div class="flex flex-wrap justify-between items-center">
                        <div class="flex-grow flex">
                            <user-avatar :user="user" class="sm:hidden h-10 w-10 rounded-full mr-2" @click="sideProfile = true" />
                            <slot name="title" />
                        </div>
                        <slot name="action" />
                        <div v-if="isMyBoard" class="flex-shrink sm:w-auto flex justify-wrap justify-end items-center space-x-2 mr-2">
                            <app-drop-down align="right">
                                <template #trigger>
                                    <button class="bg-transparent focus:outline-none"><i class="fe fe-more-vertical"></i></button>
                                </template>
                                <template #content>
                                    <div class="divide-y">
                                        <app-drop-down-link  v-if="$store.getters.business" :to="{name: 'board', params: {account: 'business'}}">
                                            <span>Business board</span>
                                        </app-drop-down-link>
                                        <app-drop-down-link :to="{name: 'user.events'}">
                                            <span>My events</span>
                                        </app-drop-down-link>
                                        <app-drop-down-link :to="{name: 'user.ticket.orders'}">
                                            <span>My ticket orders</span>
                                        </app-drop-down-link>
                                        <app-drop-down-link :to="{name: 'user.adverts'}">
                                            <span>My adverts</span>
                                        </app-drop-down-link>
                                    </div>
                                </template>
                            </app-drop-down>
                        </div>

                    </div>

                    <!-- Event list -->
                    <slot />

                </div>
            </div>
        </div>
        <user-admin-edit ref="userAdminEdit" :user="user" v-on="$listeners" />
    </div>
</template>

<script>
import UserFollowButton from '../../User/components/FollowButton.vue';
import AppDropDown from '../../../components/Dropdown.vue';
import AppDropDownLink from '../../../components/DropdownLink.vue';
import UserAvatar from '../components/Avatar.vue';
import UserAdminEdit from '../components/AdminEdit.vue';
import AdsWidget from '../../Advert/widgets/Ads.vue';

export default {
    name: "UserBoard",

    components: {
        UserFollowButton, UserAvatar,
        AppDropDown, AppDropDownLink, UserAdminEdit, AdsWidget
    },
    props: {
        user: Object,
        bg: {
            type: String,
            default: () => 'white',
        }
    },

    data(){
        return {
            sideProfile: false,
        }
    },

    computed: {
        isMyBoard(){
            return this.user.publicId == this.$store.getters.auth.publicId;
        },
    }, 

    methods: {

    },

    watch:{
       
    }
}
</script>