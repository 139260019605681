<template>
    <div>

        <app-select v-if="display == 'select'" ref="select" v-bind="$attrs" v-on="$listeners" :options="options" :loading="loading" :return-object="true"/>

        <div v-else class="flex flex-wrap justify-center">
            <a  v-for="tag in tags" :key="tag.publicId" :class="gridClasses" @click.prevent="select(tag)" href="#"> 
                <slot v-bind="tag">
                    <div class="p-3 m-1 hover:text-white hover:shadow-md flex items-center break-words text-center" :class="{'bg-primary-900 text-white': selected.find(t => t.publicId == tag.publicId), 'bg-gray-100  hover:bg-gray-500': !selected.find(t => t.publicId == tag.publicId)}">
                        <!-- <div class="mr-3 text-2xl">
                            <i class="fe fe-tag"></i>
                        </div> -->
                        <div class="truncate" :title="tag.name">
                            {{ tag.name }} 
                        </div>
                    </div>
                </slot>
            </a>
        </div>

        <div v-if="error" class="text-sm text-right">
            Failed to load tags <a href="#" class="text-primary-900 underline" @click.prevent="getTags()">Retry</a>
        </div>
    </div>
</template>

<script>
import AppSelect from '../../../components/Select.vue';

export default {
    name: "InterestTagSelect",
    data(){
        return {
            loading: false,
            tags: [],
            selected: [],
            error: false
        }
    },
    props: {
        display: {
            type: String,
            default: () => 'select'
        },
        gridClasses: {
            type: String,
            default: () => ''
        },
        value: Array
    },
    computed: {
        options(){
            return this.tags.map(tag => ({
                label: tag.name,
                value: tag.publicId
            }))
        },
    },
    components: {
        AppSelect
    },

    methods: {
        getTags(){
            this.loading = true;
            this.error = false;
            this.$store.dispatch('getInterestTags').then(tags => {
                this.tags = tags
            })
            .catch(() => {
                this.error = true
            })
            .finally(() => {
                this.loading = true;
            })
        }, 
        
        select(tag){
            const index = this.selected.findIndex(t => t.publicId === tag.publicId);
            if(index < 0){
                this.selected.push(tag);
            }else{
                this.selected.splice(index, 1);
            }

            this.$emit('input', this.selected);
        },
    },

    mounted(){
        this.getTags();
    },

    watch: {
        value: {
            immediate: true,
            handler(v){
                if(v){
                    this.selected = v.map(tag => {
                        if(tag instanceof Object) return tag;
                        return this.tags.find(option => option.publicId == tag);
                    }).filter(selected => selected !== null && selected !== undefined);
                }
            }
        }
    }
}
</script>