<template>
    <div class="divide-y bg-white">
        <h5 class="text-primary-900 p-2 font-medium truncate">{{ ad.title }}</h5>
        <div class="flex flex-wrap items-center">
            <div class="w-full">
                <div class="w-full bg-center bg-no-repeat bg-cover" :style="`height: 150px; background-image: url(${ad.imageUrl})`"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppAd",
        props: {
            ad: {
                type: Object,
                default: () => ({
                    imageUrl: 'https://picsum.photos/100',
                    title:  'Ad Title',
                })
            }
        }
    }
</script>