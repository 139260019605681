<template>
    <div>
        <form class="mt-5" @submit.prevent="submit">
            <div class="px-10 py-5 space-y-5">
                <h1 class="text-2xl text-center text-primary-900">Sign in to your account</h1>
                <app-input label="Verification code" v-model="code" type="text" :error="error('code')" prepend-icon="lock" required />
                <app-button type="submit" large block>Verify</app-button>
            </div>
        </form>

    </div>
</template>

<script>
import AppInput from '../../../components/Input';
import AppButton from '../../../components/Button';
import pageMixin from '../../../mixins/page';
export default {
    name: "EmailVerification",
    mixins: [pageMixin],
    components: {
        AppInput,
        AppButton,
    },
    data(){
        return {
            modal: false,
            code: ''
        }
    },

    methods: {

        submit(){
            this.start();
            this.errors = null;

             this.$store.dispatch('UserVerification', this.code)
            .then(() => {
                this.$emit('verified')
            })
            .catch(error => {
                this.errors = error.response ? error.response.data : null;
            })
            .finally(() => {
                this.complete();
            })
        }
    },
}
</script>