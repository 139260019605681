<template>
    <div>
        <form @submit.prevent="submit" class="space-y-5">
            <h4 class="text-lg">Items</h4>
            <div class="overflow-auto">
                <table class="min-w-max w-full table-auto">
                    <thead>
                        <tr class="bg-white">
                            <th class="py-3 px-6 text-left">Product</th>
                            <th class="py-3 px-6 text-center">Quantity</th>
                            <th class="py-3 px-6 text-center">Unit price</th>
                            <th class="py-3 px-6 text-center">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, i) in cart" :key="item.id"
                        :class="{'bg-gray-100': i%2 === 0, 'bg-white': i%2 === 1}">
                            <td class="py-3 px-6 text-left whitespace-nowrap">
                                <div class="flex items-center">
                                    <img :src="item.product.imageUrl" class="w-10 h-10 mr-2">
                                    <h4>{{item.product.name}}</h4>
                                </div>
                            </td>
                            <td class="py-3 px-6 text-center">
                                <span>{{ item.quantity }}</span>
                            </td>
                            <td class="py-3 px-6 text-center">
                                <span>{{ item.product.price }}</span>
                            </td>
                            <td class="py-3 px-6 text-center">
                                <span>{{ item.quantity * item.product.price }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h4 class="text-lg">Payment Method</h4>
            <p>{{ checkout.paymentMethod }}</p>

            <app-button type="submit" :loading="creatingOrder">Confirm Order</app-button>

        </form>

        <stripe-payment 
        ref="stripePayment"
        :amount="cartTotal" 
        :currency="currency" 
        :publishable-key="stripePublishableKey" 
        :email="$store.getters.auth.email"
        @error="stripeError"
        @success="stripeSuccess"
        @aborted="stripeAborted"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppButton from '../../../components/Button.vue';
import ProductService from '../Product/services';
import StripePayment from '../../Payment/components/StripePayment';

export default {
    name: "CheckoutReview",
    components: {
        AppButton, StripePayment
    },
    data(){
        return {
            creatingOrder: false
        }
    },

    computed: {

        ...mapGetters([
            'cart', 'cartTotal', 'checkout'
        ]),

        currency(){
            return 'NGN';
        },

        stripePublishableKey(){
            return process.env.VUE_APP_STRIPE_PUBLIC_KEY
        },

    },

    methods: {

        stripeError(error){
            this.$store.commit('APP_ERROR', error)
        },

        stripeSuccess(){
            this.success = true;
            this.$store.commit('APP_SNACKBAR', {
                text: "Payment successful"
            })
            this.$refs.stripePayment.close();
            this.$store.dispatch('clearCartItem').then(() => {
                this.$store.commit('APP_SNACKBAR', {
                    text: "Order placed successful"
                })
                this.$router.push({
                    name: 'customer.orders'
                })
            })
        },

        stripeAborted(){
            this.$store.commit('APP_SNACKBAR', {
                text: "Payment aborted"
            })
        },

        submit(){
            this.creatingOrder = true;
            ProductService.createProductOrder(
                this.cart.map(item => {
                    return {
                        quantity: item.quantity,
                        total: item.quantity * item.product.price,
                        customer: this.$store.getters.auth.publicId,
                        product: item.product.publicId,
                        ownerName: [this.$store.getters.auth.firstName, this.$store.getters.auth.lastName].join(' ')
                    }
                })
            ).then(response => {
                /**
                 * Get the stripe payment intent
                 */
                this.$refs.stripePayment.open(response.data);
            })
            .finally(() => {
                this.creatingOrder = false;
            })
        }
    }
}
</script>