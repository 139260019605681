<template>
    <div>
        <section class="s1">
            <div class="flex flex-wrap justify-center items-center">
            <div class="w-full md:w-1/3 absolute md:relative">
                <div class="px-10 lg:px-20 space-y-5">
                    <h2 class="text-3xl uppercase text-primary-900 font-bold">Looking for an event? </h2>
                    <p class="font-medium my-2 text-white md:text-black">Join a group of pinners, share ideas and explore your interests. Also join popular events around you either virtually or physically.</p>
                    <div>
                        <app-button :to="{name: 'events'}"> BROWSE EVENTS <i class="fe fe-arrow-right ml-3" block></i></app-button>
                    </div>
                </div>
            </div>
            <div class="w-full md:w-2/3 bg-center bg-cover bg-no-repeat event-banner">
            </div>
            </div>
        </section>
       
        <section class="s2 bg-center bg-cover bg-no-repeat">
            <div class="py-5 px-0 sm:px-20 space-y-10">
                <p class="text-white text-center text-xl">Joining your favorite events is as simple as just three steps.</p>
                <div class="flex flex-wrap justify-center gap-y-6">
                    <div class="w-full sm:w-1/3 space-y-2 text-center">
                        <img src="@/assets/images/icons/handshake.png" alt="" class="mx-auto">
                        <h2 class="w-full text-white ">Sign up or Sign in</h2>
                    </div>
                    <div class="w-full sm:w-1/3 text-center">
                        <img src="@/assets/images/icons/search.png" alt="" class="mx-auto">
                        <h2 class="w-full text-white ">Find your event</h2>
                    </div>
                    <div class="w-full sm:w-1/3 text-center">
                        <img src="@/assets/images/icons/join-or-pin.png" alt="" class="mx-auto">
                        <h2 class="w-full text-white ">Join or pin event</h2>
                    </div>
                </div>
                <div class="text-center">
                    <app-button :to="{name: 'signup'}">JOIN PINEVENTZ</app-button>
                </div>
            </div>
        </section>

        <section class="s3 py-5 px-5 sm:px-20 md:px-32 bg-white space-y-5">
            <h1 class="text-2xl font-medium">Trending Events</h1>
            <data-container :loading="loading">
                <app-empty-items v-if="!trending.length" message="Nothing here for now" />
                <div v-else class="flex flex-wrap">
                    <div class="w-full sm:w-1/2 lg:w-1/4" v-for="(event, e) in trending" :key="e" >
                        <div class="m-2">
                            <event-card :event="event"   />
                        </div>
                    </div>
                </div>
            </data-container>
        </section>

        <section class="s4 py-5 px-5 sm:px-20 md:px-32 bg-white space-y-5">
            <h1 class="text-2xl font-medium">Upcoming Events</h1>
            <data-container :loading="loading">
                <app-empty-items v-if="!upcoming.length" message="Nothing here for now" />
                <div v-else class="flex flex-wrap">
                    <div class="w-full sm:w-1/2 lg:w-1/4" v-for="(event, e) in upcoming" :key="e" >
                        <div class="m-4">
                            <event-card :event="event"  />
                        </div>
                    </div>
                </div>
            </data-container>
        </section>

        <section class="s5 bg-primary-900 py-10 text-center space-y-10">
            <h4 class="text-lg text-white">Do you want your events to reach a larger audience as a business?</h4>
            <div>
                <app-button bg="white" color="primary-900" :to="{name: 'signup'}">JOIN PINEVENTZ </app-button>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AppButton from '../../components/Button.vue';
import DataContainer from '../../components/DataContainer.vue';
import AppEmptyItems from '../../components/EmptyItems.vue';
import EventCard from '../../domain/Event/components/EventCard.vue';

import EventService from '../../domain/Event/services';

export default {
  name: 'Home',
  components: {
    AppButton, EventCard,
    DataContainer, AppEmptyItems
  },
  data(){
    return {
        loading: false,
        events: []
    };
  },
  computed:{
    ...mapGetters([]),
    trending() {
        return this.events.slice(0,3);
    }, 
    upcoming() {
        return this.events.slice(0,3);
    }
  },

  methods: {
      getEvents() {
        EventService.getLatestEvents()
        .then(response => {
            this.events = response.data;
        })
         .catch(e => {
            this.$store.commit('APP_SNACKBAR', {
                type: 'error',
                text: `Couldn't get events. ${e.message}`
            })
        })
        .finally(() => {
            this.loading = false;
        })
      }
  },

  mounted() {
      this.getEvents();
  }
}
</script>

<style scoped>
    .event-banner{
        background-image: url('../../assets/images/event-banner.jpg');
        height: 500px
    }
    .s2{
        background-image: url('../../assets/svg/background.svg');
    }
</style>