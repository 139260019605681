<template>
    <div>
        <template v-if="!forgotPassword">

            <form @submit.prevent="submit">
                
                <div class="px-10 py-5 space-y-5">
                    <h1 class="text-2xl text-center text-primary-900">Sign in to your account</h1>
                    <div class="space-y-1">
                        <app-input label="E-mail" v-model="form.email" type="email" :error="error('email')" prepend-icon="mail" required />
                        <app-input label="Password" v-model="form.password" type="password" :error="error('password')" prepend-icon="lock" required />
                    </div>
                    <div class="flex justify-between">
                        <div class="text-gray-500">
                            <label for="remeberMe">
                            <input type="checkbox" id="remeberMe" > <span class="ml-2">Remember Me</span>
                            </label>
                        </div>
                        <div>
                            <a href="#" class="text-gray-500" @click.prevent="forgotPassword = true">Forgot password ?</a>
                        </div>
                    </div>
                    <app-button type="submit" large block >SIGNIN</app-button>
                    <div class="flex align-center justify-center">
                        <hr class="bg-primary-900"/><h1 class="text-lg text-primary-900">OR</h1><hr/>
                    </div>
                    <div class="flex justify-center">
                        <div class="w-full flex align-center justify-evenly border border-1 border-primary-900 divide-x">
                            <div class="border-primary-900 border-1 py-2 px-5">
                                <facebook-svg />
                            </div>
                            <div class="border-primary-900 border-1 py-2 px-5">
                                <google-svg />
                            </div>
                            <div class="border-primary-900 border-1 py-2 px-5">
                                <twitter-svg />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </template>
        <template v-else>
            <forgot-password-form :onHomePage="onHomePage" @signin="forgotPassword = false" />
        </template>
    </div>
</template>

<script>
import AppInput from '../../../components/Input.vue';
import AppButton from '../../../components/Button.vue';
import ForgotPasswordForm from './ForgotPasswordForm.vue';
import FacebookSvg from '../../../svg/Facebook.vue';
import TwitterSvg from '../../../svg/Twitter.vue';
import GoogleSvg from '../../../svg/Google.vue';

import pageMixin from '../../../mixins/page';

export default {
    name: "SigninForm",
    mixins: [pageMixin],
    components: {
        AppInput,
        AppButton,
        ForgotPasswordForm,
        FacebookSvg, GoogleSvg, TwitterSvg
    },

    data(){
        return {
            forgotPassword: false,
            form: {
                email: null,
                password: null
            },
        }
    },
    props:{
        onHomePage: {
            type: Boolean,
            default: () => false
        }
    },

    methods: {

        submit(){
            this.start();
            this.errors = null;
            
            this.$store.dispatch('UserLogin', {...this.form})
            .then(() => {
                this.$eventHub.$emit('signed-in');

                this.$emit('success');

                if(!this.$store.getters.profileSet){
                    this.$router.push({
                        name: 'user.profile.edit',
                        params: {
                            getStarted: true
                        }
                    });
                }else if(this.$route.query.redirect){
                    this.$router.push(this.$route.query.redirect);
                }else if(this.$router.currentRoute.name !== 'home'){
                    this.$router.push({
                        name: 'home'
                    });
                }
            })
            .catch(error => {
                this.errors = error.response.data;
            })
            .finally(() => {
                this.complete();
            })

        }
    },


}
</script>