<template>
    <data-container :loading="loading">
        <app-empty-items v-if="!businesses || !businesses.length" />
        <div v-else class="flex flex-wrap">
            <div v-for="business in businesses" :key="business.publicId"
            class="w-full sm:w-1/2 md:w-1/4">
                <div class="m-1">
                    <business-card :business="business" />
                </div>
            </div>
        </div>
    </data-container>
</template>

<script>
import DataContainer from '../../../../components/DataContainer.vue';
import AppEmptyItems from '../../../../components/EmptyItems.vue';
import BusinessCard from '../components/Card.vue';
import BusinessService from '../../../Business/services';

export default {
    name: "TopBusinesses",
    components: {
        DataContainer, AppEmptyItems, BusinessCard
    },
    data(){
        return {
            loading: false,
            businesses: []
        } 
    },
    mounted() {
        this.loading = true
        BusinessService.getBusinesses({
            pageSize: 4
        })
        .then(response => {
            this.businesses = response.data.items;
        })
        .finally(() => {
            this.loading = false;
        })

    }
}
</script>