import moment from 'moment';

export default {
    computed: {
        image() {
            return this.event.imageUrl ? this.event.imageUrl : 'https://picsum.photos/200'
        },
        
        startMoment() {
            return moment(this.event.startAt);
        },

        endMoment() {
            return moment(this.event.endAt);
        },

        categoryName() {
            if(this.event.category) return this.event.category.name;
            return 'Uncategorized';
        },
        
        author() {
            if(!this.event.business) return null;
            return {
                ...this.event.business,
                route: { name: 'business.show', params: {id: this.event.business.publicId}},
            }
        },
        isMyEvent(){
            if(!this.author) return false;
            return this.$store.getters.business && this.$store.getters.business.publicId == this.event.business.publicId
        },

    }
}