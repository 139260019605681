import multiguard  from 'vue-router-multiguard';
import middleware from '../../router/middleware';

import BusinessEdit from './views/BusinessEdit.vue';
import BusinessCreate from './views/BusinessCreate.vue';
import BusinessShow from './views/BusinessShow.vue';
export default [
    {
        path: '/business/create',
        name: 'business.create',
        component: BusinessCreate,
        beforeEnter: multiguard([middleware.auth, middleware.hasNoBusiness])
    },
    {
        path: '/business/edit',
        name: 'business.edit',
        component: BusinessEdit,
        beforeEnter: multiguard([middleware.auth, middleware.hasBusiness] )
    },
    {
        path: '/business/:id/:category?',
        name: 'business.show',
        component: BusinessShow,
        beforeEnter: middleware.auth
    },
];
