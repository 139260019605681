<template>
<div>
    <div v-if="success" class="space-y-5 text-center mt-5 sm:mt-20">
        <app-alert  bg="green-100" color="green-500">
            <h4>Ticket order for {{form.ticket.name }} was successful</h4> 
        </app-alert>
        <app-button @click="initiateOrder">Order another</app-button>
    </div>
    <form v-else @submit.prevent="submit" >
        <div class="space-y-5">       
            <h4 class="text-center text-lg">Select Ticket</h4>
            <div v-if="!tickets.length" class="p-5 text-gray-500 text-center">
                No ticket yet
            </div>
            <div v-else class="space-y-2">
                <label v-for="ticket in tickets" :key="ticket.publicId" :for="`ticket-${ticket.publicId}`" class="flex items-center">
                    <input type="radio" :value="ticket" :id="`ticket-${ticket.publicId}`" v-model="form.ticket" class="mr-2" :disabled="!ticket.ifActive || ticket.slotsRemaining <= 0 ">
                    <div class="flex-grow cursor-pointer">
                        <ticket :ticket="ticket" :show-actions="false" />
                    </div>
                </label>
            </div>
        </div>
        <div v-if="!processable" class="mt-5">
            <app-alert  bg="red-100" color="red-500">The host can not process ticket order at the moment. Please check back</app-alert>
        </div>
        <div v-else class="py-5 text-center">
            <app-button type="submit" :disabled="form.ticket == null">Order Ticket</app-button>
        </div>
    </form> 
    <stripe-payment 
        v-if="processable"
        ref="stripePayment"
        :amount="amountToPay" 
        :currency="currency" 
        :publishable-key="stripePublishableKey" 
        :email="$store.getters.auth.email"
        @error="stripeError"
        @success="stripeSuccess"
        @aborted="stripeAborted"
        />
</div>

</template>

<script>
import AppAlert from '../../../components/Alert.vue';
import AppButton from '../../../components/Button.vue';
import Ticket from './Ticket';
import StripePayment from '../../Payment/components/StripePayment.vue';
import TicketService from '../services';

export default {
    name: 'EventTicketOrder',
    components: {
        AppAlert, AppButton, Ticket, StripePayment
    },
    data(){
        return {
            show: false,
            form: {
                ticket: null,
            },
            tickets: [],
            success: false ,
        }
    },

    props: {
        event: Object
    },

    computed: {
        amountToPay(){
            return this.form.ticket ? this.form.ticket.price : 0
        },

        currency(){
            return this.form.ticket ? this.form.ticket.currency : null;
        },

        stripePublishableKey(){
            return process.env.VUE_APP_STRIPE_PUBLIC_KEY
        },

        processable(){
            return this.stripePublishableKey ? true : false;
        },

    },

    methods: {

        initiateOrder(){
            this.success = false;
            this.form = {
                ticket: null
            }
        },

        stripeError(error){
            this.$store.commit('APP_ERROR', error)
        },

        stripeSuccess(){
            this.success = true;
            this.$store.commit('APP_SNACKBAR', {
                text: "Payment successful"
            })
            this.$refs.stripePayment.close();
            // this.$router.push({
            //     name: 'boards'
            // })
        },

        stripeAborted(){
            this.$store.commit('APP_SNACKBAR', {
                text: "Payment aborted"
            })
        },

        submit(){

            if(!this.form.ticket){
                this.$store.commit('APP_SNACKBAR', {
                    text: "Select a ticket to order"
                })
                return;
            }
            if(!this.currency){
                this.$store.commit('APP_SNACKBAR', {
                    text: "Sorry, your ticket order cannot be placed. The host cannot process the payment at the moment"
                })
                return;
            }

            TicketService.createTicketOrder([{
                quantity: 1,
                total: this.form.ticket.price,
                customer: this.$store.getters.auth.publicId,
                ticket: this.form.ticket.publicId,
                ownerName: [this.$store.getters.auth.firstName, this.$store.getters.auth.lastName].join(' ')

            }]).then(response => {
                /**
                 * Get the stripe payment intent
                 */
                console.log(response);
                this.$refs.stripePayment.open(response.data);
                
            })

        }
    },
    watch: {
        event: {
            immediate: true, 
            handler(event){
                if(event) this.tickets = event.tickets;
                this.initiateOrder();
            }
        }
    }
}
</script>