<template>
    <div>
        <div class="p-2 py-2 text-center">
            <h2 class="text-3xl text-primary-900">Help others find you.</h2>
            <h2 class="text-gray-500">Tell us about your business</h2>
        </div>
        <form @submit.prevent="submit">
            <div class="flex flex-wrap items-center justify-center">
                <div class="w-1/3 lg:w-1/5" v-for="(category, c) in categories" :key="c">
                    <div class="text-center p-5 cursor-pointer" :title="category.name" 
                        :class="{'text-primary-900': form.businessType == category.name, 'text-gray-500 hover:text-gray-900': form.businessType != category.name}"
                        @click="form.businessType = category.name"
                        >
                        <div class="text-5xl mb-3">
                            <i :class="category.icon"></i>
                        </div>
                        <h3 class="font-bold">{{ category.name }}</h3>
                    </div>
                </div>
            </div>

            <div v-if="error('businessType')" class="text-center text-red-500">
                Pick a category your business belongs to
            </div>

            <div class="my-3 space-y-1">
                <app-input label="Name" v-model="form.name" type="text" :error="error('name')" prepend-icon="briefcase" />
                <div class="flex flex-wrap space-y-1 md:space-y-0">
                    <div class="w-full md:w-1/2">
                        <div class="md:pr-5">
                             <app-input label="Username" v-model="form.username" type="text" :error="error('username')" prepend-icon="user" />
                        </div>
                    </div>
                    <div class="w-full md:w-1/2">
                        <div class="md:pl-5">
                            <app-input label="Website" v-model="form.website" type="url" :error="error('website')" prepend-icon="globe"  />
                        </div>
                    </div>
                </div>

                <app-text-area label="Description" v-model="form.description" :error="error('description')" />
                
                <app-input label="Registration Number" v-model="form.registrationNumber" type="text" :error="error('registrationNumber')" prepend-icon="hash" />

                <div class="flex flex-wrap">

                    <div class="w-1/2">
                        <div class="my-2 pr-2 md:pr-5">
                            <app-input label="Email" v-model="form.businessEmail" type="email" :error="error('businessEmail')" prepend-icon="mail"  />
                        </div>
                    </div>

                    <div class="w-1/2">
                        <div class="my-2 pl-2 md:pl-5">
                            <app-input label="Phone" v-model="form.businessPhone" type="tel" :error="error('businessPhone')" prepend-icon="phone" />
                        </div>
                    </div>                   

                </div>

                <div class="flex flex-wrap space-y-1">
                    <div class="w-full">
                        <app-input label="Address" v-model="form.address" type="text" :error="error('address')" prepend-icon="map-pin" />
                    </div>

                    <div class="w-1/2">
                        <div class="pr-2 md:pr-5">
                            <business-city-select label="City" v-model="form.city" :error="error('city')" prepend-icon="map-pin" />
                        </div>
                    </div>

                    <div class="w-1/2">
                        <div class="pl-2 md:pl-5">
                            <app-input label="Country" v-model="form.country" type="text" :error="error('country')" prepend-icon="map-pin" />
                        </div>
                    </div>                   

                </div>


                <!-- <div class="flex flex-wrap">
                    <div class="w-full md:w-1/2">
                        <div class="my-2 md:pr-5">
                            <app-input label="Phone" v-model="form.business_phone" type="tel" required />
                        </div>
                    </div>
                    <div class="w-full md:w-1/2">
                        <div class="my-2 md:pl-5">
                            <app-input label="Date founded" v-model="form.date_founded" type="date" required />
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="py-5 text-center">
                <div class="px-5 md:px-28">
                    <app-button type="submit" large block> {{  $route.params.getStarted ? 'FINISH' : 'CREATE'}} </app-button>
                </div>
                <div v-if="$route.params.getStarted" class="my-2">
                     <app-button type="button" @click="$router.push({name: 'home'})" invert block>Skip</app-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AppInput from '../../../components/Input.vue';
import AppTextArea from '../../../components/TextArea.vue';
import AppButton from '../../../components/Button.vue';
import BusinessCitySelect from './BusinessCitySelect.vue';

import BusinessService from '../services';
import pageMixin from '../../../mixins/page';

export default {
    name: "BusinessCreateForm",
    mixins: [pageMixin],
    components: {
        AppInput, AppButton, AppTextArea, BusinessCitySelect
    },
    data(){
        return {
            form: {
                businessType: null,
                name: null,
                website: null,
                address: null,
                city: null,
                country: null,
                registrationNumber: null,
                businessEmail: null,
                businessPhone: null,
            }
        }
    },
    computed: {
        ...mapGetters([
            'auth', 'business'
        ]),
        categories(){
            return  BusinessService.categories();
        }
    },
    methods: {
        submit(){
            this.start();
            this.errors = null;

           this.$store.dispatch('BusinessCreate', {...this.form})
           .then(() => {
                this.snackbar({
                    text: 'Business created successfully'
                })
                this.$router.push({
                    name: 'board',
                    params: {
                        account: 'business'
                    }
                })
           })
           .catch(e => {
                this.errors = e.response.data;
            })
            .finally(() => {
                this.complete();
            })
        }
    }

}
</script>