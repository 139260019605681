<template>
    <app-layout>
        <div class="flex justify-center py-10">
            <div class="w-full sm:w-2/3">
                <div class="px-2">
                    <business-create-form />
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '../../../layouts/App.vue';
import BusinessCreateForm from '../components/BusinessCreateForm.vue';

export default {
    name: "BusinessCreate",
    components: {
        AppLayout,
        BusinessCreateForm
    },
}
</script>