<template>
    <div>
        <modal :show="modal" max-width="md" @close="modal = false">
             <div class="p-3 space-y-5">
                <slot name="heading"><h4 class="font-medium">Avatar</h4></slot>
                <image-input ref="imageInput" :value="avatar" @input="avatarUpdated"  />
             </div>
        </modal>
        <div @click="modal = true">
            <slot />
        </div>
    </div>
</template>

<script>
import Modal from './Modal.vue';
import ImageInput from './ImageInput.vue';

export default {
    name: "AppAvatarInput",
    components: {
       Modal, ImageInput
    },
    data(){
        return {
           modal: false,
           avatar: null,
        }
    },
    props: {
        value: String
    },
    methods: {
        avatarUpdated(url){
            this.avatar = url;
            this.modal = false;
            this.$emit('input', url);
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(url){
                this.avatar = url;
            }
        }
    }
}
</script>