<template>
    <form @submit.prevent="submit" class="space-y-5">
        <div class="flex flex-wrap justify-center">
            <div v-for="(method, i) in paymentMethods" :key="i" class="w-1/2 lg:w-1/4" >
                <div class="bg-gray-100 p-5 rounded-md text-center cursor-pointer m-2" 
                    :class="{ 'border-primary-900 border-2' : paymentMethod === method.label }"
                    @click="() => method.active ? paymentMethod = method.label : null">
                    <div>
                        <i :class="`${method.icon} text-7xl ${method.active ? 'text-primary-900' : 'text-gray-500'}`"></i>
                    </div>
                   <h1>{{ method.label }}</h1>
                </div>
            </div>            
        </div>
        <div class="text-right">
            <app-button type="submit" :disabled="paymentMethod === null">Continue</app-button>
        </div>
    </form>
</template>

<script>
import AppButton from '../../../components/Button';
export default {
    name: "CheckoutPaymentMethod",
    components: {
        AppButton
    },
    data(){
        return {
            paymentMethod: null,
            paymentMethods: [
                {
                    icon: 'mdi mdi-credit-card',
                    label: 'Debit/Credit Card',
                    active: true,
                },

                {
                    icon: 'mdi mdi-cash',
                    label: 'Cash on Delivery',
                    active: false,
                }
            ]
        }
    },
    methods: {
        submit(){
            this.$store.dispatch('setCheckoutData', {paymentMethod: this.paymentMethod}).then(() => {
                 this.$router.push({
                    name: 'shop.checkout',
                        params: {
                            step: 'review'
                        }
                })
            })
        }
    },

    mounted(){
        this.paymentMethod = this.$store.getters.checkout.paymentMethod ? this.$store.getters.checkout.paymentMethod : this.paymentMethods[0]
    },

}
</script>