<template>
    <modal :show="show" max-width="3xl" @close="close">
        <div v-if="success"
        class="flex justify-center items-center py-20 space-y-5"
        >
            <div class="text-center space-y-3">
                <h1 class="text-green-500"><i class="text-9xl fe fe-check-circle"></i></h1>
                <h2 class="font-bold text-xl">Success</h2>
                <h4>{{ success }}</h4>
                <p class="text-gray-500">Kindly check your email to view ticket</p>
                <div class="text-center">
                    <app-button :to="{name: 'user.ticket.orders'}">View Tickets</app-button>
                </div>
            </div>
        </div>
        <ticket-order-form v-else :the-event="event" @success="ticketOrdered" />
    </modal>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import AppButton from '../../../components/Button.vue';
import TicketOrderForm from './TicketOrderForm.vue';

export default {
    name: "TicketOrderModal",
    components: {
        Modal, AppButton, TicketOrderForm
    },
    data() {
        return {
            show: false,
            success: null,
        }
    },

    props: {
        event: Object,
    },

    methods: {
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
            this.$emit('close')
        },

        ticketOrdered(order = []) {
            const tickets = order.map(order => `${order.ticket.name} x ${order.quantity}`).join(', ');
            this.success = `${tickets} for ${this.event.name} sent to ${this.$store.getters.auth.email}`
        }
    },
}
</script>