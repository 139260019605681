<template>
   <router-link
    :to="{name: 'shop.businesses', query: { category: category.name }}"
    >
        <div class="m-2 bg-white hover:shadow-lg text-center rounded-md py-5">
            <div class="text-primary-900 text-5xl">
                <i :class="category.icon"></i>
            </div>
            <div class="mt-3">
                <h2 class="font-bold text-primary-900 truncate"> {{ category.name }}</h2>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "BusinessCategoryCard",
    props: {
        category: {
            type: Object,
            default: function() {
                return {
                    name: 'Category',
                    icon: 'mdi mdi-view-grid',
                    image: `https://picsum.photos/id/${100+this._uid}/200`,
                }
            }
        }
    }
}
</script>