<template>
    <div :class="`p-2 rounded-md bg-${bg} text-${color}`" >
        <slot />
    </div>
</template>

<script>
export default {
    name: "AppAlert",
    props: {
        color: {
            type: String,
            default: () => 'white'
        },
        bg: {
            type: String,
            default: () => 'primary-100'  
        }
    }
}
</script>