<template>
    <div>
       <form @submit.prevent="submit">

            <div class="p-3 text-center">
                <h1 class="text-primary-900 text-lg font-medium">Change Password</h1>
            </div>
           
            <div class="mt-3 px-3 sm:px-10 space-y-5 py-5"> 
                <div class="space-y-1">
                    <app-input label="Old password" v-model="form.old_password" type="password" :error="error('old_password')" prepend-icon="lock" required />
                    <app-input label="New password" v-model="form.password" type="password" :error="error('password')" prepend-icon="lock" required />
                    <app-input label="Repeat new password" v-model="form.password_confirmation" type="password" prepend-icon="lock" required />
                </div>              
                <div class="px-5 md:px-28">
                    <app-button type="submit" block>CHANGE</app-button>
                </div>
            </div>
        </form> 
    </div>
</template>

<script>
import AppInput from '../../../components/Input.vue';
import AppButton from '../../../components/Button.vue';
import UserService from '../services';

import pageMixin from '../../../mixins/page';

export default {
    name: "UserPasswordUpdateForm",
    mixins: [pageMixin],
    components: {
        AppInput, AppButton
    },
    data(){
        return {
            loader: null,
            form: {}
        }
    },
    methods: {
        
        submit(){
            this.start()
            this.errors = null

            UserService.updatePassword({...this.form})
            .then(response => {
                this.$emit('success');
                this.snackbar({
                    text: response.data.message
                });
            })
            .catch(e => {
                this.errors = e.response.data;
            })
            .finally(() => {
                this.complete();
            })
        }
    },

}
</script>