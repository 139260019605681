import AdvertService from '../domain/Advert/services';

const actions = {
    /**
     * Lazy load some data in the background
     * @param {*} param0 
     */
        LoadCache: ({dispatch}) => {
            Promise.all([
                dispatch('getEventCategories'),
                dispatch('getInterestTags')
            ])
            .catch(e => {
                console.log('Could not load cache-->', e.response)
            })
        },

        loadAds: ({ getters, commit }) => {
            const cache = '_ads';
            return getters.cache(cache) 
            ? Promise.resolve(getters.cache(cache)) 
            : new Promise((resolve, reject) => {
                AdvertService.getActiveAdverts().then(response => {
                    commit('CACHE', {key: cache, data: response.data })
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e);
                })
            })
        },
};
export default actions;