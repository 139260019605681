import middleware from '../../router/middleware';

import UserEdit from './views/UserEdit.vue';
import UserShow from './views/UserShow.vue';
import Connections from './views/Connections.vue';
import Boards from './views/Boards.vue';
import UserEvents from './views/UserEvents.vue';
import UserTicketOrders from './views/UserTicketOrders.vue';
import UserAdverts from './views/UserAdverts.vue';

export default [
    {
        path: '/profile/edit/:tab?',
        name: 'user.profile.edit',
        component: UserEdit,
        beforeEnter: middleware.auth
    },

    {
        path: '/board/:account?',
        name: 'board',
        component: Boards,
        beforeEnter: middleware.auth
    },

    {
        path: '/board/user/tickets-orders',
        name: 'user.ticket.orders',
        component: UserTicketOrders,
        beforeEnter: middleware.auth
    },

    {
        path: '/board/user/events/:period?',
        name: 'user.events',
        component: UserEvents,
        beforeEnter: middleware.auth
    },

    {
        path: '/board/user/adverts',
        name: 'user.adverts',
        component: UserAdverts,
        beforeEnter: middleware.auth
    },

    {
        path: '/user/:id/:period?',
        name: 'user.show',
        component: UserShow,
        beforeEnter: middleware.auth
    },

    {
        path: '/connections/:connections?/:profile?',
        name: 'user.connections',
        component: Connections,
        beforeEnter: middleware.auth
    },

];