<template>
    <div class="flex-grow flex items-center space-x-1 justify-center cursor-pointer" :class="{'text-red-500': like !== null, 'text-gray-500': liked == null }" @click="likePin">
        <i class="fe fe-thumbs-up"></i> <span>{{ likes.length }}</span>
    </div>
</template>

<script>
export default {
    name: "PinLikeButton",
    props: {
        pin: Object
    },
    data(){
        return {
            like: null,
            likes: []
        }
    },
    methods: {

        likePin(){
            if(this.like) this.deletelike();
            else this.createlike();
        },

        createlike(){
            this.$store.dispatch('likePin', this.pin).then(response => {
                this.like = response.data;
                this.likes.push(this.like);
                this.$emit('liked', this.like);
            })
            .catch(e => {
                console.log(e);
            })
        },
        deletelike(){
            this.like = null;
            this.$store.dispatch('unlikePin', this.like).then(response => {
                console.log('pin unlike--->', response);
                this.$emit('unliked', this.like);
            })
            .catch(e => {
                console.log(e);
            })
        }
    },
    watch: {
        pin: {
            immediate: true,
            handler(){
                this.liked = null
            }
        }
    }
    
}
</script>