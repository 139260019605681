import API from '../../../../api';

export default class extends API {

    /**
     * Create new order
     * @param {*} data 
     */
     static store(data){
        return this.post(`product_orders/create`, data);
    }

    /**
     * Update order
     * @param {*} id 
     * @param {*} data 
     */
    static update(id, data){
        return this.put(`product_orders/${id}/edit`, data);
    }

    static getBusinessOrders(businessId, query = {}){
        return this.post(`product_orders/businesss/${businessId}/find`, query);
    }

    static getCustomerOrders(){
        return this.get(`product_orders`);
    }

    static getOrder(id){
        return this.get(`product_orders/${id}`)
    }

    static deleteOrder(id){
        return this.delete(`product_orders/${id}/delete`)
    }

}
