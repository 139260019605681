import Pin from '../services';

const actions = {

    createPin: ({ getters, commit }, { name, description, mediaUrl, event }) => {
        return new Promise((resolve, reject) => {
            Pin.createPin({
                name,
                description,
                mediaUrl,
                user: getters.auth.publicId,
                event: event.publicId,
            }).then(response => {
                const cache = '_myPins';
                commit('CACHE', {key: cache, data: getters.cache(cache) ? getters.cache(cache).concat([response.data]) : [response.data]})
                commit('APP_SNACKBAR', {
                    show: true,
                    text: `Pin created for event ${event.name}`,
                })
                resolve(response)
            })
            .catch(e => {
                reject(e)
            })
        })
    },

    updatePin: ({ commit, getters }, { pin, data }) => {
        return new Promise((resolve, reject) => {
            Pin.updatePin(pin.publicId, { 
                ...data, 
                event: pin.event.publicId, 
                user: getters.auth.publicId
            }).then(response => {
                // const cache = '_myPins';
                // commit('CACHE', {key: cache, data: getters.cache(cache) ? getters.cache(cache).concat([response.data]) : [response.data]})
                commit('APP_SNACKBAR', {
                    show: true,
                    text: `Pin updated`,
                })
                resolve(response)
            })
            .catch(e => {
                reject(e)
            })
        })
    },

    deletePin: ({ commit }, pin) => {
        return new Promise((resolve, reject) => {
            Pin.deletePin(pin.publicId).then(response => {
                // const cache = '_myPins';
                // commit('CACHE', {key: cache, data: getters.cache(cache) ? getters.cache(cache).concat([response.data]) : [response.data]})
                commit('APP_SNACKBAR', {
                    show: true,
                    text: `Pin deleted`,
                })
                resolve(response)
            })
            .catch(e => {
                reject(e)
            })
        })
    },


    likePin: ({commit, getters}, pin) => {
        return new Promise((resolve, reject) => {
            Pin.createLike(getters.auth.publicId, pin.publicId)
            .then(response => {
                commit('APP_SNACKBAR', {
                    text: `Liked pin ${pin.name}`
                })
                resolve(response);
            })
            .catch(e => {
                reject(e);
            })
        })
    },

    unlikePin: ({commit}, like) => {
        return new Promise((resolve, reject) => {
            Pin.deleteLike(like.publicId)
            .then(response => {
                commit('APP_SNACKBAR', {
                    text: `Pin unliked`
                })
                resolve(response);
            })
            .catch(e => {
                reject(e);
            })
        })
    }
};
export default actions;