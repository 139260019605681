<template>
    <auth-layout bg="themed">
        <template  #main>
            <signin-form  />
        </template>
        <template #side>
             <div class="space-y-5 text-white">
                <h1 class="text-3xl font-bold">Hello there</h1>
                <p>Get update on upcoming eventz</p>
                <p>Concerts, Fashion, Awards, Parties, Celebrity, Weddings or Album launches and create boards for your own events.</p>
                <app-button type="submit" bg="white" color="primary-900" :to="{name: 'signup'}" large block >SIGNUP</app-button>
            </div>
        </template>
    </auth-layout>
</template>

<script>

import AuthLayout from './Layout.vue';
import SigninForm from '../components/SigninForm';
import AppButton from '../../../components/Button.vue';
export default {
    name: "Signin",
    metaInfo: {
        title: `Signin`
    },
    components: {
        AuthLayout,
        SigninForm,
        AppButton
    },

    methods: {
        
    }
}
</script>