<template>
    <shop>            
        <div class="flex flex-wrap">
            <div class="w-full sm:w-2/3">
                <slot>
                    <h1 class="text-2xl font-medium">Shopping Cart</h1>
                    <p class="text-gray-400">You currently have {{$store.getters.cartItemsTotal}} item<span v-show="$store.getters.cartItemsTotal > 1">s</span> in your cart.</p> 
                    <div v-if="!cart.length" class="text-center py-5">
                        <h2 class="text-gray-500">No item in your cart yet</h2>
                    </div>
                    <div  v-else class="overflow-auto">
                        <table class="min-w-max w-full table-auto mt-3">
                            <thead>
                                <tr class="bg-white text-gray-600 uppercase leading-normal">
                                    <th class="py-3 px-6 text-left">Product</th>
                                    <th class="py-3 px-6 text-center">Quantity</th>
                                    <th class="py-3 px-6 text-center">Unit price</th>
                                    <th class="py-3 px-6 text-center">Total</th>
                                    <th class="py-3 px-6 text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in cart" :key="item.id"
                                :class="{'bg-gray-100': i%2 === 0, 'bg-white': i%2 === 1 }" 
                                >
                                    <td class="py-3 px-6 text-left whitespace-nowrap">
                                        <div class="flex items-center">
                                            <img :src="item.product.imageUrl" class="w-10 h-10 mr-2">
                                            <h4><router-link :to="{name: 'shop.product.show', params:{id: item.product.publicId, _product: item.product}}">{{item.product.name}}</router-link></h4>
                                        </div>
                                    </td>
                                    <td class="py-3 px-6 text-center">
                                        <div class="flex space-x-3 items-center justify-center w-full">

                                            <button class="focus:outline-none hover:text-primary-900"
                                            @click="() => item.quantity > 1 ? updateItemQuantity(item, item.quantity-1) : null"
                                            >
                                                <i class="fe fe-minus"></i>
                                            </button>
                                            
                                            <input type="number" 
                                            class="h-8 w-16 py-2 border border-gray-200 focus:outline-none px-3 text-center" 
                                            min="1"
                                            :value="item.quantity"
                                            @input="(e) => e.target.value ? updateItemQuantity(item, e.target.value) : null"
                                            >

                                            <button class="focus:outline-none hover:text-primary-900"
                                            @click="updateItemQuantity(item, item.quantity+1)">
                                            <i class="fe fe-plus"></i>
                                            </button>

                                        </div>
                                    </td>
                                    <td class="py-3 px-6 text-center">
                                        <span>{{ item.product.price }}</span>
                                    </td>
                                    <td class="py-3 px-6 text-center">
                                        <span>{{ item.quantity * item.product.price }}</span>
                                    </td>
                                    <td class="py-3 px-6 text-center">
                                        <div class="flex item-center justify-center">
                                            <i class="mdi mdi-delete text-primary-900 text-xl cursor-pointer" @click="$store.dispatch('removeCartItem', item)"></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </slot>
            </div>
            <div class="w-full sm:w-1/3 mt-3 sm:mt-0">
                <div class="bg-white sm:ml-3 lg:ml-5">
                    <div class="bg-white p-3">
                        <h1 class="text-2xl font-medium">Order Summary</h1>
                        <p class="text-gray-400">Shipping and additional costs are calculated based on the values you have entered.</p>
                        <div class="space-y-3 mt-5">
                            <div class="flex justify-between">
                                <div class="text-gray-500">Order subtotal</div>
                                <div class="font-bold">{{ cartTotal }}</div>
                            </div>
                            <div class="flex justify-between">
                                <div class="text-gray-500">VAT</div>
                                <div class="font-bold">{{ tax }}</div>
                            </div>

                            <div class="flex border-gray-700 border-t border-b justify-between text-xl py-3">
                                <div class="text-gray-500">Total</div>
                                <div class="font-bold">{{ total }}</div>
                            </div>
                        </div>
                        <div class="text-center mt-10" v-if="$router.currentRoute.name === 'shop.cart'">
                            <app-button
                            block
                            :disabled="!$store.getters.cartItemsTotal"
                            @click="$router.push({name: 'shop.checkout'})">Checkout</app-button>
                            <a 
                            v-if="cart.length"
                            href="#"
                            @click.prevent="clearCart"
                            class="block mt-2 text-red-500">
                                <i class="mdi mdi-delete mr-3"></i> clear cart
                            </a>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </shop>
</template>

<script>
import { mapGetters } from 'vuex';

import Shop from './layout';
import AppButton from '../../components/Button';

export default {
    name: "CustomerCart",
     metaInfo: {
        title: `Cart`
    },
    components: {
        Shop, AppButton
    },
    computed: {
        ...mapGetters([
            'cart', 'cartTotal'
        ]),

        tax(){
            return 0;
        },

        total(){
            return this.cartTotal + this.tax
        }
    },
    methods: {
        clearCart(){
            this.$store.dispatch('clearCartItem').then(() => {
                this.$store.commit('APP_SNACKBAR', {
                    text: "Cart cleared"
                })
                this.$router.push({
                    name: 'shop'
                })
            })
        },

        updateItemQuantity(item, quantity) {
            this.$store.dispatch('updateCartItem', {...item, quantity});
        }
    }
}

</script>