<template>
    <div class="flex flex-wrap justify-between items-center bg-gray-100 p-2 rounded-md">
        <div>
            <h4 class="font-medium">{{ t.name }}</h4>
             <div>Remaining: {{ t.slotsRemaining }}</div>
             <div class="text-sm" :class="{'text-green-500': t.ifActive,  'text-red-500': !t.ifActive}">{{ t.ifActive ? 'Active' : 'Inactive' }} <span v-show="t.slotsRemaining <= 0" class="text-gray-500"> | sold out</span></div>
        </div>
        <div>
            <div class="text-xs text-gray-500">{{ t.currency }}</div>
            <h1 class="text-2xl">{{ t.price | number }}</h1>
        </div>
        <div v-if="showActions" class="w-full flex justify-end">
            <a href="#" @click.prevent="deleteTicket" class="text-red-500"> <i class="fe fe-trash"></i> </a>
        </div>
        <app-confirmation :show="confirmation.show" @close="confirmation.show = false" :onConfirm="confirmation.action">
            <template #title>Confirm</template>
            <template #content>{{ confirmation.content }}</template>
        </app-confirmation>
    </div>
</template>
<script>
import AppConfirmation from '../../../components/Confirmation.vue';
import TicketService from '../services';

export default {
    name: "Ticket",
    components: {
        AppConfirmation
    },
    data(){
        return {
            confirmation: {
                show: false,
                title: null,
                content: null,
                action: () => Promise.resolve()
            },
        }
    },
    computed: {
        
    },

    props: {
        ticket: Object,
        showActions: {
            type: Boolean,
            default: () => false
        }
    },

    methods: {
        deleteTicket(){
            this.confirmation = {
                show: true,
                title: 'Delete ticket',
                content: `Are you sure you want to delete the ticket?`,
                action: () => TicketService.deleteTicket(this.ticket.publicId).then(() =>  {
                    this.$store.commit('APP_SNACKBAR', {
                        text: `Ticket deleted`
                    });
                    this.$emit('deleted', this.ticket)
                }),
            }
        },
    },

    watch: {
        ticket: {
            immediate: true,
            handler(ticket){
                if(ticket){
                    this.t = ticket
                }
            }
        }
    }
}
</script>