<template>
     <div class="mt-1 relative">
        <div class="absolute inset-y-0 left-0 px-3 flex items-center pointer-events-none">
            <span class="text-lg text-gray-500 font-bold">
                <i class="fe fe-search"></i>
            </span>
        </div>
        <input 
            v-bind="$attrs"
            @input="$emit('input', $event.target.value)" ref="input"
            class="block w-full py-2 bg-gray-200 focus:outline-none px-3 pl-9"
            placeholder="Search...">

    </div>
</template>

<script>
export default {
    name: "NavigationSearch",
}
</script>