<template>
    <router-link v-on="$listeners" :to="to" :class="classes">
        <slot></slot>
    </router-link>
</template>

<script>
    export default {
        props: {
            to: Object,
            active: Boolean,
            
        },
        computed: {
            classes() {
                return this.active
                            ? `inline-flex items-center px-1 pt-1 border-b-2 ${this.$store.getters.mode == 'business' ? 'border-white text-white' : 'border-primary-900 text-gray-900'}  text-sm font-medium leading-5  focus:outline-none focus:border-primary-900 transition duration-150 ease-in-out`
                            : `inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 ${this.$store.getters.mode == 'business' ? 'text-white hover:border-white focus:border-white' : 'text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300'}  focus:outline-none transition duration-150 ease-in-out`
            }
        }
    }
</script>
