<template>
    <app-layout>
        <div class="py-5 px-3 sm:px-24 lg:px-36">
            <data-container :loading="loading">
                <div v-if="event" class="flex flex-wrap bg-white p-5 rounded-md">
                    <div class="w-full sm:w-1/2 lg:w-2/3">
                        <div class="p-3">
                            <h1 class="text-2xl font-medium mb-2">Order Event Ticket</h1>
                            <router-link :to="{name: 'event.show', params: {slug: event.slug, _event: event}}">
                                <h1 class="text-lg font-medium text-primary-900">{{ event.name }}</h1>
                            </router-link>
                            <img :src="event.imageUrl" :alt="event.name" class="w-full rounded-md shadow-lg">
                            
                            <p>{{ event.shortDescription }}</p>
                            
                        </div>
                    </div>
                    <div class="w-full sm:w-1/2 lg:w-1/3">
                        <div class="p-3">
                            <ticket-order :event="event" />
                        </div>
                    </div>
                </div>
                <div v-else class="text-center text-gray-500">
                    <h4>Oops! The event was not found</h4>
                </div>
            </data-container>
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '../../../layouts/App.vue';
import DataContainer from '../../../components/DataContainer.vue';
import TicketOrder from '../../Ticket/components/TicketOrder.vue';
import EventService from '../services';

export default {
    name: "EventRegister",
    metaInfo() {
        return {
            title: `Register ${this.event ? this.event.name : '' }`,
        }
    },
    components: {
        AppLayout,
        DataContainer,
        TicketOrder
    },
    data(){
        return {
            event: null,
            loading: true
        }
    },

    computed: {
        slug(){
            return this.$route.params.slug
        },
        isMyEvent(){
            return this.$store.getters.business && this.$store.getters.business.publicId == this.event.business.publicId
        },
    },

    methods: {
        getEvent(){

            this.loading = true;

            EventService.getEventBySlug(this.slug)
            .then(response => {
                this.event = response.data;
            })
            .catch(e => {
                this.$store.commit('APP_SNACKBAR', {
                    type: 'error',
                    text: `Couldn't get events. ${e.message}`
                })
            })
            .finally(() => {
                this.loading = false;
            });

        },
    },

    watch: {
        $route: {
            immediate: true,
            handler(route){
                if(route.params._event && route.params._event.tickets){
                    this.event = route.params._event;
                    this.loading = false;
                }else{
                    this.getEvent();
                }
            }
        }
    }
}
</script>