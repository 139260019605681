import ProductService from '../services';

const actions = {

    getProductCategories: ({ getters, commit }) => {
        const cache = '_productCategories';
        return getters.cache(cache) 
                ? Promise.resolve(getters.cache(cache)) 
                : new Promise((resolve, reject) => {
                    ProductService.getCategories().then(response => {
                        commit('CACHE', {key: cache, data: response.data })
                        resolve(response.data)
                    })
                    .catch(e => {
                        reject(e);
                    })
                })
    },   
}

export default actions;