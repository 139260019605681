<template>
    <div>
        <file-input :value="imageUrl" @change="imageChanged" accept="image/*">
            <div class="text-center text-xl uppercase mb-5">
                <i class="fe fe-camera"></i>
            </div>
        </file-input>

        <div v-if="uploading" class="text-gray-500">uploading file...</div>
        <div v-else-if="uploadError" class="text-red-500">Something went wrong while uploading file</div>
    </div>
</template>

<script>
import file from '../mixins/file';
import FileInput from './FileInput';

export default {
    name: "AppImageInput",
    mixins: [file],
    components: {
        FileInput
    },
    props: {
        value: String
    },
    data(){
        return {
            uploading: false,
            uploadError: null,
            imageUrl: null,
        }
    },
    methods: {
        imageChanged(file) {
           if(!file) {
              this.imageUrl = null;
              this.$emit('input', this.imageUrl);
              return;
            }

            this.uploading = true;
            this.uploadFileToCloudinary(file)
            .then(response => {
                this.imageUrl = response.secure_url;
                this.$emit('input', response.secure_url)
            })
            .catch(e => {
                this.uploadError = e;
            })
            .finally(() => {
                this.uploading = false;
            })
        },

    },

    watch: {
        value: {
            immediate: true,
            handler(url){
                this.imageUrl = url;
            }
        }
    }
}
</script>