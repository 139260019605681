<template>
    <div class="my-1 rounded-sm ">
        <div :class="`flex flex-wrap bg-${bg}`">
            
            <div class="w-1/2 lg:w-2/12">
                <div class="p-5 text-center">
                    <h1 class="text-5xl md:text-7xl font-bold">{{  event.startAt | datetime('day') }}</h1>
                    <h4 class="text-md">{{ event.startAt | datetime('mName year') }}</h4>
                </div>
            </div>

            <div class="w-1/2 lg:w-2/12" >
                <div class="h-full bg-cover bg-center bg-no-repeat" :style="`background-image: url(${image})`"></div>
            </div>

            <div class="w-full lg:w-4/12">
                <div class="px-3 py-1 ">
                    <router-link :to="{name: 'events', params:{category: event.category.slug}}" class="text-primary-900 uppercase">{{ event.category.name }}</router-link>
                    <router-link :to="{name: 'event.show', params: {slug: event.slug}}">
                        <h1 class="text-xl md:text-lg">{{ event.name }}</h1>
                    </router-link>
                    <div class="">
                        <p class="text-sm md:truncate">{{ event.shortDescription }}{{ event.shortDescription }} {{ event.shortDescription }}  {{ event.shortDescription }}</p>
                        <p class="text-sm">By <router-link :to="{name: 'business.show', params: {id: event.business.publicId}}" class="text-primary-900">{{ event.business.name }}</router-link></p>
                    </div>
                </div>                   
            </div>

            <div class="w-full lg:w-4/12">
                <div class="h-full bg-primary-900 flex lg:justify-center py-3 items-center px-2">
                    <!-- <p>0 joined</p> -->
                    <div class="w-full lg:w-auto lg:mx-auto space-y-5">
                        <div class="flex justify-between">
                            <div class="text-white space-y-3">
                                <p>{{ event.address }}</p>
                                <p v-if="author.website"><a :href="author.website">{{ author.website }}</a></p>
                            </div>
                            <app-drop-down v-if="isMyEvent" align="right" >
                                <template #trigger>
                                    <button class="text-white focus:outline-none"><i class="fe fe-more-vertical"></i></button>
                                </template>
                                <template #content>
                                    <div class="divide-y">
                                        <app-drop-down-link :to="{name: 'event.manager', params: {slug: event.slug }}">
                                            <span>Manage event</span>
                                        </app-drop-down-link>
                                    </div>
                                </template>
                            </app-drop-down>
                        </div>
                        
                        <div class="flex flex-wrap lg:justify-center items-center gap-2">
                            <join-button bg="white" color="primary-900" :event="event" />
                            <pin-button bg="yellow-500" color="white" :event="event" @created="pinCreated" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import JoinButton from './JoinButton.vue';
import PinButton from '../../Pin/components/PinButton.vue';
import AppDropDown from '../../../components/Dropdown.vue';
import AppDropDownLink from '../../../components/DropdownLink.vue';

import eventMixin from '../mixins/event';

export default {
    name: "UserBoardEvent",
    mixins: [eventMixin],
    components: {
        JoinButton, PinButton, AppDropDown, AppDropDownLink,
    },
    props: {
        event: Object,
        joined: {
            type: Boolean,
            default: () => false
        },
        bg: {
            type: String,
            default: () => 'white'
        }
    },

    methods: {
        pinCreated() {

        }
    }
}
</script>