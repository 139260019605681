<template>
<div>
    <modal :show="show" max-width="md" @close="close">
       <form @submit.prevent="submit" class="divide-y">

            <div class="p-3 text-center">
                <h1 class="text-primary-900 text-md font-medium">{{advert ? `Update Advert` : 'New Advert'}}</h1>
            </div>

            <div class="px-3 py-5 sm:px-10 space-y-5 overflow-y-auto" style="max-height: 65vh">       
                <app-input label="Title" v-model="form.title" type="text" :error="error('title')" required />

                <advert-type-select label="Advert Type" v-model="form.advertType" :error="error('advertType')" required />
                
                <div>
                    <app-image-input v-model="form.imageUrl" />
                    <app-input-error :message="error('imageUrl')" />
                </div>

                <!-- Dates -->
                <div class="flex flex-wrap">
                    <div class="w-1/2">
                        <div class="pr-2">
                            <app-input label="Start date" v-model="form.startAt" type="datetime-local" :error="error('startAt')" required />
                        </div>
                    </div>
                    <div class="w-1/2">
                        <div class="pl-2">
                            <app-input label="End date" v-model="form.endAt" type="datetime-local" :error="error('endAt')" required />
                        </div>
                    </div>
                </div> 
                <template v-if="$store.getters.isAdmin">
                    <app-select label="Status" v-model="form.status" :options="statuses.map(status=>({label:status, value:status.toLowerCase()}))"  :error="error('status')" required />
                    <app-switch  label="Active" v-model="form.isActive" />
                </template>
            </div>

            <div class="border-gray-200 py-5 text-center">
                <div class="px-5 md:px-28">
                    <app-button type="submit">{{advert ? 'Update' : 'Create' }} Advert</app-button>
                </div>
            </div>

        </form> 
    </modal>
</div>
</template>

<script>
import moment from 'moment';
import Modal from '../../../components/Modal.vue';
import AppInput from '../../../components/Input.vue';
import AppButton from '../../../components/Button.vue';
import AppSelect from '../../../components/Select.vue';
import AppSwitch from '../../../components/Switch.vue';
import AppImageInput from '../../../components/ImageInput';
import AppInputError from '../../../components/InputError.vue';
import AdvertTypeSelect from './TypeSelect.vue';

import pageMixin from '../../../mixins/page';
import AdvertService from '../services';

export default {
    name: "AdvertForm",
    mixins: [pageMixin],
    components: {
        Modal, AppInput, AppButton, AppSelect, AppSwitch, AdvertTypeSelect,
        AppImageInput, AppInputError
    },
    data(){
        return {
            show: false,           
            form: {},
            advert: null,
            statuses: [
                'Pending', 'Approved'
            ]
        }
    },
  
    computed: {
        startMoment() {
            return moment(this.advert.startAt);
        },

        endMoment() {
            return moment(this.advert.endAt);
        },
    },
    methods: {
        open(advert){
            this.advert = advert;
            this.show = true;
            if(advert){
                this.form = { 
                    title: advert.title,
                    imageUrl: advert.imageUrl,
                    isActive: advert.isActive,
                    startAt: this.startMoment.format('yyyy-MM-DDThh:mm'),
                    endAt: this.endMoment.format('yyyy-MM-DDThh:mm'),
                    status: advert.status,
                    owner: advert.owner.publicId,
                    advertType: advert.advertType
                 }
            }else{
                this.form = { 
                    owner: this.$store.getters.auth.publicId
                };
            }
        },
        close(){
            this.show = false;
            this.form = {};
            this.$emit('close')
        },
       
        submit(){
            this.errors = null
            if(this.advert) this.updateAdvert();
            else this.createAdvert();
        },

        createAdvert(){
            this.start()
             AdvertService.store({
                ...this.form,
            })
            .then(response => {
                this.snackbar({
                    text: `New advert created`
                });
                this.advert = response.data;
                this.$emit('created', response.data);
            })
            .catch(e => {
                this.errors = e.response.data;
                this.snackbar({
                    text: 'Failed to create advert. check your input',
                    type: 'error'
                });
            })
            .finally(() => {
                this.complete();
            })
        },

        updateAdvert(){
            this.start()
             AdvertService.update(this.advert.publicId, {
                ...this.form,
            })
            .then(response => {
                this.$emit('updated', response.data)
                this.snackbar({
                    text: `Advert updated.`
                });
                this.advert = response.data;
                this.$emit('upated', response.data);
            })
            .catch(e => {
                this.errors = e.response.data;
                this.snackbar({
                    text: 'Failed to update advert. check your input',
                    type: 'error'
                });
            })
            .finally(() => {
                this.complete();
            })
        },
    },

}
</script>