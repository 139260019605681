<template>
    <app-select 
        :options="cities.map(c => ({label: c, value: c}))"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #option="{ label }">
            <i class="mdi mdi-map-marker text-primary-900"></i> {{ label }}
        </template>
        <template #selected="{ label }">
            <i class="mdi mdi-map-marker text-primary-900"></i> {{ label }}
        </template>
    </app-select>
</template>

<script>
import AppSelect from '../../../components/Select.vue';
import BusinessService from '../services';
export default {
    name: "BusinessCitySelect",
    data(){
        return {
            cities: []
        }
    },
    components: {
        AppSelect
    },
    mounted() {
        this.cities = BusinessService.cities();
    }
}
</script>