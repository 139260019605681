<template>
    <router-link v-on="$listeners" :to="to" :class="classes">
        <slot></slot>
    </router-link>
</template>

<script>
    export default {
        props: {
            to: Object,
            active: Boolean,
            
        },
        computed: {
            classes() {
                return this.active
                            ? `inline-flex items-center px-1 py-3 border-b-2 border-primary-900 text-gray-900  text-sm font-medium leading-5  focus:outline-none focus:border-primary-900 transition duration-150 ease-in-out`
                            : `inline-flex items-center px-1 py-3 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300  focus:outline-none transition duration-150 ease-in-out`
            }
        }
    }
</script>
