     
import API from '../../../api';

export default class extends API {
      
  /**
  * Create an event ticket
  * @param {*} data 
  */
  static createEventTicket(data)
  {
      return this.post(`tickets/create`, data);
  }

  /**
   * 
   * @param {*} data 
   */
  static createTicketOrder(data){
    return this.post( data instanceof Array ? `ticket_orders/multiple/create` : `ticket_orders/create`, data);
  }

  /**
  * Get ticket orders for an event
  */

  static getEventTicketOrders(eventId, query = {}){
      return this.post(`ticket_orders/events/${eventId}/find`, query)
  }

  /**
  * Get ticket orders by a user
  */

  static getUserTicketOrders(userId, query = {}){
    return this.post(`ticket_orders/customers/${userId}/find`, query)
}

  
  /**
  * Get a ticket order
  */
  static getEventTicketOrder(id){
      return this.get(`ticket_orders/${id}`)
  }

  static deleteTicket(id){
    return this.delete(`tickets/${id}/delete`);
  }

  static deleteTicketOrder(id){
    return this.delete(`ticket_orders/${id}/delete`);
  }

}
  

