export default class{
    
    static storageKey(){
        return 'cart';
    }

    static getCart(){
        const cart = window.localStorage.getItem(this.storageKey()) ? JSON.parse(window.localStorage.getItem(this.storageKey())) : [];
        return cart.map(item => {
            return {
                ...item,
                quantity: parseInt(item.quantity)
            }
        });
    }

    static setCart(cart){
        return window.localStorage.setItem(this.storageKey(), JSON.stringify(cart))
    }

    static clearCart(){
        return this.setCart([]);
    }

    static addCartItem(item){
        const cart = this.getCart();
        let existingItem = cart.find(i => i.product.publicId == item.product.publicId);
        if(!existingItem){
            if(item.quantity >= 1){
                cart.push({
                    id: cart.length+1,
                    ...item
                })
                this.setCart(cart);
            }
        }else{
            existingItem = {...item,id: existingItem.id};
            if(existingItem.quantity <= 0) this.removeCartItem(existingItem)
            else this.updateCartItem(existingItem);
        }
        
    }

    static updateCartItem(item){
        const cart = this.getCart();
        const index = cart.findIndex(i => i.id == item.id)
        cart[index] = item;
        this.setCart(cart);
    }

    static removeCartItem(item){
        const cart = this.getCart();
        const index = cart.findIndex(i => i.id == item.id)
        if(index < 0) return;
        cart.splice(index, 1)
        this.setCart(cart);
    }

    static findProductInCart(product){
        const cart = this.getCart();
        return cart.find(item => item.product.publicId == product.publicId);
    }

    static checkoutKey(){
        return 'checkout';
    }

    static getCheckout(){
        return window.localStorage.getItem(this.checkoutKey()) ? JSON.parse(window.localStorage.getItem(this.checkoutKey())) : {};
    }

    static setCheckout(checkout){
        return window.localStorage.setItem(this.checkoutKey(), JSON.stringify(checkout))
    }

    static setCheckoutData(data){
        return this.setCheckout({
            ...this.getCheckout(),
            ...data
        })
    }

}