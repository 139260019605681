const mutations = {

    APP_SNACKBAR: (state, snackbar) => {
        state.snackbar = {
            show: true,
            persist: false,
            ...snackbar
        };
        setTimeout(()=>{
            // if not closed after 5 seconds
            if(state.snackbar.show && !state.snackbar.persist){
                state.snackbar = {
                    show: false
                }
            }
        }, 5000);
    },

    APP_ERROR: (state, error) => {
        state.snackbar = {
            show: true,
            type: 'error',
            text: error.message,
        };

        setTimeout(()=>{
            if(state.snackbar.show){
                state.snackbar = {
                    show: false
                }
            }
        }, 5000);
    },

    APP_READY: (state, ready) => {
        if(!ready){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'auto';
        }
        
        state.appReady = ready;
    },

    SWITCH_MODE: (state, mode) => {
        state.mode = mode
    },

    CACHE: (state, {key, data}) => {
        state.cache[key] = data
    }

};
export default mutations