<template>
    <app-layout>
        <div class="flex flex-wrap mt-5  sm:mx-5 lg:mx-10 space-y-3 sm:space-y-0">

             <div class="w-full sm:w-4/12 md:w-3/12 lg:w-2/12 space-y-3">
                <div class="px-2">
                    <h2 class="font-bold text-lg">Connections</h2>
                </div>
                <!-- Connections tabs -->
                <div class="flex flex-wrap divide-x sm:divide-x-0 sm:divide-y bg-white">
                    <router-link :to="{name: 'user.connections', params: {connections: 'followings'}}" 
                        class="p-2 block w-1/2 sm:w-full text-center sm:text-left" 
                        :class="{'font-bold bg-primary-900 text-white': tab == 'followings'}"
                    > 
                        Following
                    </router-link>
                
                    <router-link :to="{name: 'user.connections', params: {connections: 'followers'}}"
                    class="p-2 block w-1/2 sm:w-full text-center sm:text-left" 
                    :class="{'font-bold bg-primary-900 text-white': tab == 'followers'}">
                        Followers
                    </router-link>
                </div>
                
                <!-- Suggested Pinners-->
                <div class="px-2">
                    <suggested-pinners />
                </div>

                <div class="px-2">
                    <ads-widget heading="Ads" />
                </div>

                
             </div>

             <div class="w-full sm:w-8/12 md:w-9/12 lg:w-10/12">
                <div class="sm:ml-5 lg:ml-10">
                    <component v-if="user" :is="tab" v-bind="connectionsProps"></component>
                </div>
             </div>
        </div>
    </app-layout>
</template>

<script>

import AppLayout from '../../../layouts/App.vue';
import Followings from '../widgets/Followings';
import Followers from '../widgets/Followers';
import SuggestedPinners from '../widgets/SuggestedPinners';
import AdsWidget from '../../Advert/widgets/Ads.vue';

import UserService from '../services'; 
import pageMixin from '../../../mixins/page';

export default {
    name: "Connections",
    metaInfo() {
        return {
            title: `Connections (${this.tab})`,
        }
    },
    mixins: [pageMixin],
    components: {
        AppLayout, Followings, Followers, SuggestedPinners,
        AdsWidget
    },
    data(){
        return {
            user: null
        }
    },
    computed: {
        tab(){
            return this.$route.params.connections ?? 'followings'; 
        },
        connectionsProps(){
            return {
                user: this.user
            }
        }
    },

    methods: {
        getUser(id){
           
            this.start();

            UserService.getUser(id).then(response => {
                this.user = response.data;
            })
            .catch(e => {
                if(e.response.status == 404){
                    this.$store.commit('APP_SNACKBAR', {
                        type:'error',
                        text: 'User not found'
                    });
                }
            })
            .finally(() => {
                this.complete()
            })
        }
    },

    watch:{
        $route: {
            immediate: true,
            handler(route){
                if(route.params.user){
                    if(!this.user || this.user.publicId !== route.params.user) this.getUser(route.params.user);
                }else{
                    this.user = this.$store.getters.auth
                }
            }
        } 
    } 
}
</script>