<template>
    <div class="p-5 space-y-5">
        <h1 v-if="!starting" class="text-primary-900 text-lg font-medium">Basic Information</h1>
        <form @submit.prevent="submit">
            <div class="flex flex-wrap items-center space-x-2" :class="{'justify-center': starting}">
                <div class="text-center relative">
                    <div 
                        class="absolute right-0 top-0 h-6 w-6 rounded-full bg-primary-900
                        flex items-center justify-center shadow-lg cursor-pointer
                        rotate-180 border border-white" 
                        title="Update profile photo" 
                        >
                        <i class="mdi mdi-pencil text-white text-lg rotate-180"></i>
                    </div>
                    <app-avatar-input v-model="form.imageUrl">
                        <user-avatar :user="form" class="h-20 w-20 rounded-full cursor-pointer"  />
                    </app-avatar-input>
                </div>
                <h1 class="text-xl lg:text-2xl text-primary-900 font-medium">{{ [auth.firstName, auth.lastName].join(' ') }}</h1>
            </div>

            <!-- If first just completing the profile -->
            <template v-if="starting" >
                <div class="space-y-5">
                    <div class="space-y-1">
                        <app-input label="City" v-model="form.city" type="text" :error="error('city')" prepend-icon="map-pin"  />
                        <app-input label="Country" v-model="form.country" type="text" :error="error('country')" prepend-icon="map-pin" />
                        <app-input label="Date of birth" v-model="form.dob" type="date" :error="error('dob')" prepend-icon="calendar" required />
                        <app-select label="Gender" :options="genderOptions" v-model="form.gender" :error="error('gender')" />
                    </div>
                    <app-button type="submit" large block>Finish</app-button>
                </div>
            </template>  
            <!-- else... -->
            <template  v-else>
                <div class="space-y-1">
                    <div class="flex flex-wrap items-center space-y-1 md:space-y-0">
                        <div class="w-full md:w-1/2">
                            <div class="md:pr-5">
                                <app-input label="E-mail" v-model="form.email" type="text" :error="error('email')" prepend-icon="mail" required />
                            </div>
                        </div>
                        <div class="w-full md:w-1/2">
                            <div class="md:mt-5 md:pl-5">
                                <app-button type="button" block @click="updatePassword = true">Change Password</app-button>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap space-y-1 md:space-y-0">
                        <div class="w-full md:w-1/2">
                            <div class="md:pr-5">
                                <app-input label="First name" v-model="form.firstName" type="text" :error="error('firstName')" prepend-icon="user" />
                            </div>
                        </div>
                        <div class="w-full md:w-1/2">
                        <div class="md:pl-5">
                                <app-input label="Last name" v-model="form.lastName" type="text" :error="error('lastName')" prepend-icon="user" />
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap space-y-1 md:space-y-0">
                        <div class="w-full md:w-1/2">
                            <div class="md:pr-5">
                                <app-input label="City" v-model="form.city" type="text" :error="error('city')" prepend-icon="map-pin"  />
                            </div>
                        </div>
                        <div class="w-full md:w-1/2">
                            <div class="md:pl-5">
                                <app-input label="Country" v-model="form.country" type="text" :error="error('country')" prepend-icon="map-pin" />
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap space-y-1 md:space-y-0">
                        <div class="w-full md:w-1/2">
                            <div class="md:pr-5">
                                <app-input label="Date of birth" v-model="form.dob" type="date" :error="error('dob')" prepend-icon="calendar" required />
                            </div>
                        </div>

                        <div class="w-full md:w-1/2">
                            <div class="md:pl-5">
                                <app-select label="Gender" :options="genderOptions" v-model="form.gender" :error="error('gender')" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="py-5 text-center">
                    <div class="px-5 md:px-28">
                        <app-button type="submit" block>SAVE</app-button>
                    </div>
                </div>
            </template>

        </form>

        <modal :show="updatePassword" max-width="md" @close="updatePassword = false">
            <password-update-form @success="updatePassword = false"  />
        </modal>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import AppInput from '../../../components/Input.vue';
import AppSelect from '../../../components/Select.vue';
import AppButton from '../../../components/Button.vue';
import Modal from '../../../components/Modal.vue';
import PasswordUpdateForm from '../components/PasswordUpdateForm.vue';
import UserAvatar from './Avatar.vue';
import AppAvatarInput from '../../../components/AvatarInput.vue';

import pageMixin from '../../../mixins/page';
import UserService from '../services';

export default {
    name: "UserProfileForm",
    mixins: [pageMixin],
    components: {
        AppInput, AppButton, Modal, PasswordUpdateForm,
        UserAvatar, AppAvatarInput, AppSelect
    },
    data(){
        return {
            updatePassword: false,
            form: {},
            genders: [
                'Male', 'Female'
            ]
        }
    },

    props: {
        starting: Boolean
    },

    computed: {
        ...mapGetters([
            'auth'
        ]),
        genderOptions() {
            return  this.genders.map(gender => ({label: gender, value: gender.toLowerCase()}));
        }
    },

    methods: {
        ...mapMutations([
            'SET_AUTH_USER'
        ]),

       submit(){
        this.start();
        this.errors = null;

        this.form.interests = this.auth.interests.map(i => i.publicId)
        UserService.update(this.auth.publicId, {...this.form, isStaff: true})
            .then(() => {
                this.$store.commit('SET_AUTH_USER', {...this.form, interests: this.auth.interests});
                this.$emit('updated');
            })
            .catch(e => {
                this.errors = e.response.data;
            })
            .finally(() => {
                this.complete();
            })
       }
    },

    mounted(){
        this.form = {...this.auth};
        delete this.form.bankAccount;
    }

}
</script>