<template>
    <svg width="32" height="21" viewBox="0 0 32 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.4407 9.1925C19.5362 9.69729 19.6044 10.2021 19.6044 10.8569C19.6044 16.5732 15.7708 20.625 10 20.625C4.47479 20.625 0 16.1503 0 10.625C0 5.09974 4.47479 0.625 10 0.625C12.7013 0.625 14.9522 1.60724 16.6985 3.24437L13.9836 5.8501C13.2469 5.14068 11.9509 4.30849 10 4.30849C6.58938 4.30849 3.80625 7.1325 3.80625 10.625C3.80625 14.1175 6.58938 16.9415 10 16.9415C13.9563 16.9415 15.4434 14.0902 15.6753 12.6304H10V9.19245H19.4407V9.1925ZM29.0972 9.52776V6.625H26.1805V9.52776H23.2778V12.4445H26.1805V15.3472H29.0972V12.4445H32V9.52776H29.0972Z" fill="#1FB2B9"/>
    </svg>
</template>

<script>
export default {
    name: "GoogleSVG"
}
</script>