

import Vue from 'vue';
import Vuelidate from 'vuelidate'
import PortalVue from 'portal-vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/css/tailwind.css';
import './assets/fonts/feather/feather.css';
import '@mdi/font/css/materialdesignicons.min.css';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import filters from './plugins/filters';
import API from './api';

import AdminNavMenu from './layouts/components/AdminNavMenu';


Vue.use(PortalVue);
Vue.use(Loading, {
  loader: 'bars',
  color: '#1FB2B9',
  lockScroll: true,
  width: 60,
  height: 60
});
Vue.use(Vuelidate);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

Vue.use(filters);

Vue.component('admin-nav-menu', AdminNavMenu);

Vue.config.productionTip = false

Vue.prototype.$eventHub = new Vue(); // Global event bus

API.setAxiosHeaders();



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
