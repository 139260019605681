import Comment from '../services';

const actions = {

    createComment: ({ getters, commit }, { pin, text }) => {
        return new Promise((resolve, reject) => {
            Comment.createComment(getters.auth.publicId, pin, text).then(response => {
                commit('APP_SNACKBAR', {
                    show: true,
                    text: `Comment added to pin ${pin.name}`,
                })
                resolve(response)
            })
            .catch(e => {
                reject(e)
            })
        })
    },

};
export default actions;