<template>
    <div>  
        <plain-header />
        <main :class="`pt-12 min-h-screen bg-${bg}`">
            <slot />
        </main>
    </div>
</template>

<script>
import PlainHeader from './components/PlainHeader.vue'
export default {
    name: "PlainLayout",
    components: {
        PlainHeader
    },
    props:{
        bg: {
            type: String,
            default: () => 'gray-100'
        }
    }
}
</script>