<template>
    <app-layout bg="gray-100">
        <component :is="isAuthenticated ? 'user-home' : 'guest-home'"></component>
    </app-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import AppLayout from '../layouts/App.vue';
import GuestHome from './widgets/GuestHome.vue';
import UserHome from './widgets/UserHome.vue';

export default {
  name: 'Home',
  metaInfo: {
    title: 'Home'
  },
  components: {
    AppLayout, GuestHome, UserHome
  },
  data(){
    return {
      events: []
    };
  },
  computed:{
    ...mapGetters([
      'isAuthenticated'
    ]),
  },
}
</script>
