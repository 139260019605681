<template>
<div>
    <component :is="`router-link`" :to="nav.route"  @click="showSubMenu = !showSubMenu">
        <div class="px-1 sm:pl-5 py-3 hover:bg-gray-100 sm:text-left" 
        :class="{'font-bold border-primary-900 bg-primary-100 text-white' : active, 'text-gray-500' : !active, 'text-center': !mobileNavExpand, 'text-left pl-5': mobileNavExpand} ">
            <span class="mr-2 text-xl sm:text-md" :class="{'text-white': active, 'text-primary-900': !active}">
                <i :class="nav.icon"></i>
            </span>
            <span class="sm:inline sm:ml-3" :class="{'hidden': !mobileNavExpand, 'inline': mobileNavExpand}">{{ nav.title }}</span>           
        </div>
    </component>
    <ul v-if="showSubMenu && nav.submenu && nav.submenu.length" class="sm:ml-3 lg:ml-5">
        <li v-for="(menu, m) in nav.submenu" :key="(m)">
            <admin-nav-menu  :nav="menu" :mobileNavExpand="mobileNavExpand" />
        </li>
    </ul>
</div>
    
</template>

<script>

export default {
    name: "AdminNavMenu",
    data(){
        return {
            showSubMenu: false,
        }
    },
    props: {
        nav: Object,
        group: String,
        mobileNavExpand: Boolean,
    },
    computed: {
        active(){
            if(this.nav.group) return this.$router.currentRoute.name.startsWith(this.nav.group);
            return this.$router.currentRoute.name == this.nav.route.name || this.anySubmenuActive
        },

        anySubmenuActive(){
            return this.nav.submenu && this.nav.submenu.length && this.nav.submenu.some(m=> this.$router.currentRoute.name.startsWith(m.group));
        }
    },

    mounted(){
        this.showSubMenu = this.anySubmenuActive;
    }

}
</script>