import Vue from 'vue'
import VueRouter from 'vue-router';
import Home from '../views/Home2.vue';
import Terms from '../views/Terms.vue';
import About from '../views/About.vue';
import Privacy from '../views/Privacy.vue';

import AuthRoutes from '../domain/Auth/router';
import UserRoutes from '../domain/User/router';
import BusinessRoutes from '../domain/Business/router';
import EventRoutes from '../domain/Event/router';
import ShopRoutes from '../domain/Shop/router';
import AdminRoutes from '../domain/Admin/router';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
].concat(
  AuthRoutes, UserRoutes, BusinessRoutes, EventRoutes, ShopRoutes, AdminRoutes
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

export default router
