import axios from "axios";

export default class {

    static setOAuth(data) {
      window.localStorage.setItem('oauth', JSON.stringify(data));
      axios.defaults.headers.common['Authorization'] = this.authorization();
    }
  
    static getOAuth() {
      return JSON.parse(localStorage.getItem('oauth'));
    }

    static removeOauth() {
      localStorage.removeItem('oauth');
      //remove default authorization for axios
      delete axios.defaults.headers.common['Authorization'];
  }

  
    static get(key) {
      const oauth = this.getOAuth();
  
      return oauth && key in oauth ? oauth[key] : null;
    }
  
    static authorization() {
      const accessToken = this.get('access');
  
      if (!accessToken) return '';
      return `Bearer ${accessToken}`;
    }

    static isAuthenticated(){
      return this.get('access') ? true : false;
    }
    
  }
  