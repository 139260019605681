<template>
    <form @submit.prevent="submit" class="space-y-1">
            <div class="flex flex-wrap space-y-1 sm:space-y-0">
                <div class="w-full sm:w-1/2">
                    <div class="sm:px-1">
                        <app-input label="First name" v-model="address.firstName" type="text" :error="error('firstName')" required />
                    </div>
                </div>
                <div class="w-full sm:w-1/2">
                    <div class="sm:px-1">
                        <app-input label="Last name" v-model="address.lastName" type="text" :error="error('lastName')" required />
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap space-y-1 sm:space-y-0">
                <div class="w-full sm:w-1/3">
                    <div class="sm:px-1">
                        <app-input label="Street" v-model="address.street" type="text" :error="error('street')" required />
                    </div>
                </div>
                <div class="w-full sm:w-1/3">
                    <div class="sm:px-1">
                        <app-input label="City" v-model="address.city" type="text" :error="error('city')" required />
                    </div>
                </div>
                <div class="w-full sm:w-1/3">
                    <div class="sm:px-1">
                        <app-input label="State" v-model="address.state" type="text" :error="error('state')" required />
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap space-y-1 sm:space-y-0">
                <div class="w-full sm:w-1/2">
                    <div class="sm:px-1">
                        <app-input label="ZIP code" v-model="address.zipCode" type="text" :error="error('zipCode')" required />
                    </div>
                </div>
                <div class="w-full sm:w-1/2">
                    <div class="sm:px-1">
                        <app-input label="Country" v-model="address.country" type="text" :error="error('country')" required />
                    </div>
                </div>
            </div>

            <div class="text-right mt-5">
                <app-button type="submit">Continue</app-button>
            </div>

        </form>
</template>

<script>
import AppInput from '../../../components/Input';
import AppButton from '../../../components/Button';
import pageMixins from '../../../mixins/page';

export default {
    name: "CheckoutAddress",
    mixins: [pageMixins],
    components: {
        AppInput, AppButton
    },

    data(){
        return {
            address: {}
        }
    },

    mounted(){
        this.address = this.$store.getters.checkout.address ? this.$store.getters.checkout.address : {}
    },

    methods: {
       submit(){
           this.$store.dispatch('setCheckoutData', {address: this.address}).then(() => {
               this.$router.push({
                name: 'shop.checkout',
                    params: {
                        step: 'payment-method'
                    }
                })
           })
            
       }
    }
}
</script>