import middleware from '../../router/middleware';
import ProductRoutes from './Product/router';
import OrderRoutes from './Order/router';
import BusinesRoutes from './Business/router';

import Shop from './Index.vue';
import WishList from './WishList.vue';
import Cart from './Cart.vue';
import Checkout from './Checkout.vue';

const routes = [
    {
        path: '/shop',
        name: 'shop',
        component: Shop,
        // beforeEnter: middleware.auth
    },
    
    {
        path: '/shop/customer-wishlist',
        name: 'shop.customer.wishlist',
        component: WishList,
        beforeEnter: middleware.auth
    },
    {
        path: '/shop/cart',
        name: 'shop.cart',
        component: Cart,
        beforeEnter: middleware.auth
    },

    {
        path: '/shop/checkout/:step?',
        name: 'shop.checkout',
        component: Checkout,
        beforeEnter: middleware.auth
    },

]

export default routes.concat(
    ProductRoutes, OrderRoutes, BusinesRoutes
);