<template>
    <cart>
        <div class="bg-white">
            <app-tabs-panel v-model="tab" :items="tabs" :vertical="false">
                <template #content="{ active }">
                    <div class="p-3">
                        <component :is="`checkout-${active.id}`"></component>
                    </div>
                </template>
            </app-tabs-panel>
            <!-- <app-tab>
                <app-tab-router :active="step== 'address'" :to="{name: 'shop.checkout', params: {step: 'address'}}" :first="true"><i class="fe fe-map-pin"></i>  Address</app-tab-router>
                <app-tab-router :active="step== 'payment-method'" :to="{name: 'shop.checkout', params: {step: 'payment-method'}}"><i class="fe fe-credit-card"></i>  Payment Method</app-tab-router>
                <app-tab-router :active="step== 'review'" :to="{name: 'shop.checkout', params: {step: 'review'}}"><i class="fe fe-eye"></i>  Review</app-tab-router>
            </app-tab>
            <div class="mt-3">
                <component :is="component"></component>
            </div> -->
        </div>
    </cart>

</template>

<script>
import Cart from './Cart';

import AppTabsPanel from '../../components/TabsPanel.vue';

import CheckoutAddress from './components/CheckoutAddress.vue';
import CheckoutPaymentMethod from './components/CheckoutPaymentMethod.vue';
import CheckoutReview from './components/CheckoutReview.vue';

import { mapGetters } from 'vuex';

export default {
    name: "Checkout",
    metaInfo: {
        title: `Checkout`
    },
    components: {
        Cart, CheckoutAddress, CheckoutPaymentMethod, CheckoutReview,
        AppTabsPanel
    },

    data() {
        return {
            tab: 0,
            tabs: [
                {
                    title: 'Address',
                    id: 'address',
                },
                {
                    title: 'Payment method',
                    id: 'payment-method',
                },
                {
                    title: 'Review',
                    id: 'review',
                },
            ],
        }
    },

    computed: {
        ...mapGetters([
            'cart', 'checkout'
        ]),
    },

    beforeRouteEnter (to, from, next) {
        next(vm => {
            if(!vm.cart.length) next({name: 'shop'})
        })
    },

    watch: {
        $route: {
            immediate: true,
            handler(route) {
                const target = route.params.step ?? this.tabs[0].id;
                this.tab =  this.tabs.findIndex(tab => tab.id === target) ?? 0;
            }
        },
        tab: {
            handler(tab) {
                this.$router.push({
                    name: this.$route.name,
                    params: {
                        step: this.tabs[tab].id
                    }
                })
            }
        }
    }
   
}

</script>