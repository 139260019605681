<template>
    <plain-layout bg="themed">
        <div class="flex justify-center mt-10">
            <div class="w-full mx-3 lg:w-2/3 flex flex-wrap justify-center align-center shadow-lg">
                <div class="w-full md:w-2/3 lg:w-1/2 bg-white" :class="{'md:order-first' : order[0] === 'main', 'md:order-last' : order[0] === 'side'}">
                    <div class="p-2 lg:px-10">
                        <slot name="main" />
                    </div>
                </div>
                <div v-if="$slots['custom-side']" class="w-full md:w-1/3 lg:w-1/2">
                    <slot name="custom-side" />
                </div>
                <div v-else class="w-full md:w-1/3 lg:w-1/2 bg-primary-900">
                    <div class="flex align-center justify-center p-10">
                        <div class="w-full mx-5 lg:w-2/3">
                            <slot name="side" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </plain-layout>
</template>

<script>
import PlainLayout from '../../../layouts/Plain.vue';
export default {
    name: "AuthLayout",
    components: {
        PlainLayout,
    },
    props: {
        order: {
            type: Array,
            default: () => ['main', 'side']
        }
    }

}
</script>