<template>
  <app-layout>
      <user-board v-if="account == 'user'" :user="auth" /> 
      <business-board v-if="account == 'business'" :business="business" />
  </app-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import AppLayout from '../../../layouts/App.vue';
import UserBoard from '../widgets/Events.vue';
import BusinessBoard from '../../Business/widgets/Board.vue';

export default {
  name: 'Boards',
  metaInfo() {
    return {
      title: `${this.account == 'business' ? 'Business' : 'User' } Board`,
    }
  },
  components: {
    AppLayout, UserBoard, BusinessBoard
  },

  data(){
    return {
      boardProps: {}
    }
  },

  computed:{
    ...mapGetters([
      'auth',
      'business'
    ]),

    account(){
      return this.$route.params.account ?  this.$route.params.account : 'user'
    },

  },

  methods: {
      setBoard(mode){
        if(mode == 'personal'){
            this.$router.push({
              name: 'board',
              params: {
                account: 'user'
              }
            })
        }
        else if(mode == 'business'){
            this.$router.push({
              name: 'board',
              params: {
                account: 'business'
              }
            })
        }
      },

  },

  created(){
        // this.setBoard(this.$store.getters.mode);
        this.$eventHub.$on('mode-switched', (mode) => {
           this.setBoard(mode);
           this.$store.commit('APP_SNACKBAR', {
                text: `Switched to ${mode} board`
            })
        });
  },

  beforeDestroy(){
      this.$eventHub.$off('mode-switched')
    }
}
</script>
