<template>
    <admin>
        <template #header>
            <div class="py-2">
                <h2 class="text-xl">Events</h2>
            </div>
        </template>

        <app-tabs-panel v-model="tab" :items="tabs" >
            <template #content="{ active }">
                <data-container :loading="loading">
                    <!-- Events -->
                    <template v-if="active.route === 'admin.events'">
                        <app-empty-items v-if="!events.totalCount" />
                        <div v-else class="overflow-auto">
                            <table class="min-w-max w-full table-auto">
                                <thead>
                                    <tr class="bg-white text-gray-600 uppercase">
                                        <th class="py-3 px-6 text-left">Event</th>
                                        <th class="py-3 px-6 text-left">Start</th>
                                        <th class="py-3 px-6 text-left">End</th>
                                        <th class="py-3 px-6 text-center"></th>
                                    </tr>
                                </thead>
                                <tbody class="">
                                    <tr v-for="(event, i) in events.items" :key="event.publicId" 
                                    :class="{'bg-gray-100': i%2 === 0, 'bg-white': i%2 === 1 }" 
                                    >
                                        <td class="py-3 px-6 text-left whitespace-nowrap">
                                            <div class="flex items-center">
                                                <img :src="event.imageUrl" class="w-10 h-10 mr-2">
                                                <h4>{{event.name}}</h4>
                                            </div>
                                        </td>

                                        <td class="py-3 px-6 text-left whitespace-nowrap">
                                            {{ event.startAt | datetime }}
                                        </td>

                                        <td class="py-3 px-6 text-left whitespace-nowrap">
                                            {{ event.endAt | datetime }}
                                        </td>
                                        
                                        <td class="py-3 px-6 text-center">
                                            <div class="flex item-center space-x-2 justify-center">
                                                <router-link :to="{name: 'event.show', params: {slug: event.slug, _event: event} }"><i class="fe fe -eye"></i></router-link>
                                                <a href="#" @click.prevent="$refs.eventForm.open(event)" ><i class="fe fe-edit"></i></a>
                                                <a href="#" @click.prevent="deleteEvent(event)" ><i class="fe fe-trash text-red-500"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <admin-event-edit ref="eventForm" @updated="eventUpdated" />
                    </template>

                    <!-- Categories -->
                    <template v-if="active.route === 'admin.event.categories'" >
                        <div class="flex justify-between items-center py-2">
                            <h4 class="font-bold">Event categories ({{ categories.length }})</h4>
                            <app-button @click="$refs.categoryForm.open()" ><i class="fe fe-plus"></i> <span class="hidden sm:inline">Add Category</span></app-button>
                        </div>
                        <event-category-form ref="categoryForm" @created="categoryCreated" @updated="categoryUpdated" />
                        <app-empty-items v-if="!categories.length" />
                        <div v-else class="overflow-auto">
                            <table class="min-w-max w-full table-auto">
                                <thead>
                                    <tr class="bg-white text-gray-600 uppercase">
                                        <th class="py-3 px-6 text-left">Name</th>
                                        <th class="py-3 px-6 text-left">Created</th>
                                        <th class="py-3 px-6 text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="">
                                    <tr v-for="(category, i) in categories" :key="category.publicId"
                                    :class="{'bg-gray-100': i%2 === 0, 'bg-white': i%2 === 1 }" >
                                        <td class="py-3 px-6 text-left whitespace-nowrap">
                                            <div class="flex items-center">
                                                <router-link :to="{name: 'events', params: {category: category.publicId }}">
                                                    {{ category.name }}
                                                </router-link>
                                            </div>
                                        </td>
                                        <td class="py-3 px-6 text-left whitespace-nowrap">
                                            {{ category.createdAt | datetime('day/month/year hour:minute') }}
                                        </td>
                                        <td class="py-3 px-6 text-center">
                                            <div class="flex item-center space-x-2 justify-center">
                                                <i class="mdi mdi-pencil cursor-pointer" @click="$refs.categoryForm.open(category)"></i>
                                                <i class="mdi mdi-delete text-red-500 cursor-pointer" @click="deleteCategory(category)"></i>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>

            </data-container>
            </template>
        </app-tabs-panel>
            
        <app-confirmation :show="confirmation.show" @close="confirmation.show = false" :onConfirm="confirmation.action">
            <template #title>Confirm</template>
            <template #content>{{ confirmation.content }}</template>
        </app-confirmation>
        
    </admin>
</template>

<script>
import Admin from '../../../layouts/Admin.vue';
import AppEmptyItems from '../../../components/EmptyItems';
import AppButton from '../../../components/Button';
import DataContainer from '../../../components/DataContainer';
import AppTabsPanel from '../../../components/TabsPanel.vue';
import EventCategoryForm from '../../Event/components/CategoryForm';
import AppConfirmation from '../../../components/Confirmation';
import AdminEventEdit from '../../Event/components/FormModal';
import EventService from '../../Event/services';

export default {
    name: "AdminEvents",
    metaInfo() {
        let title = 'Events';
        if(this.$route.name === 'admin.events') {
            title = `Events (${this.events.length})`;
        }
        if(this.$route.name === 'admin.event.categories') {
            title = `Event Categories (${this.categories.length})`;
        }

        return {
            title
        };
    },
    components: {
        Admin,
        AppEmptyItems,
        DataContainer,
        AppTabsPanel,
        AppButton,
        EventCategoryForm,
        AppConfirmation,
        AdminEventEdit
    },
    data(){
        return {
            loading: false,
            events: [],
            categories: [],
            confirmation: {
                show: false,
                title: null,
                content: null,
                action: () => Promise.resolve()
            },
            tab: 0,
            tabs: [
                {
                    title: 'Events',
                    route: 'admin.events',
                },
                {
                    title: 'Categories',
                    route: 'admin.event.categories',
                },
            ]
        }
    },

    methods:{
        getEvents(){
            this.loading = true;
            EventService.getEvents()
            .then(response => {
                this.events = response.data
            })
            .catch(() => {
                // this.$store.commit('APP_SNACKBAR', {
                //     type: 'error',
                //     text: `Couldn't get events. ${e.message}`
                // })
            })
            .finally(() => {
                this.loading = false
            })
        },

        getEventCategories(){
            this.loading = true;
            this.$store.dispatch('getEventCategories').then(categories => {
                this.categories = categories;
            })
            .finally(() => {
                this.loading = false;
            })
        },

        categoryCreated(category){
            this.$refs.categoryForm.close()
            this.categories.push(category);
        },

        categoryUpdated(updatedCategory){
            this.$refs.categoryForm.close()
            this.categories = this.categories.map(category => {
                if(category.publicId == updatedCategory.publicId)  return updatedCategory;
                else return category;
            })
        },

        deleteCategory(category){
            this.confirmation = {
                show: true,
                title: 'Delete category',
                content: `Are you sure you want to delete ${category.name}?`,
                action: () => EventService.deleteCategory(category.publicId).then(() => {
                    this.categories.splice(this.categories.findIndex(c => c.publicId == category.publicId), 1)
                    this.$store.commit('APP_SNACKBAR', {
                        text: `${category.name} deleted`
                    });
                })
            }
        },

        eventUpdated(){
            this.$refs.eventForm.close();
            // this.events.items = this.events.items.map(event => {
            //     if(event.publicId == updatedEvent.publicId)  return updatedEvent;
            //     else return event;
            // })
        },

        deleteEvent(event){
            this.confirmation = {
                show: true,
                title: 'Delete Event',
                content: `Are you sure you want to delete event ${event.name}?`,
                action: () => EventService.deleteEvent(event.publicId).then(() => {
                    this.events.items.splice(this.events.items.findIndex(e => e.publicId === event.publicId), 1)
                    this.$store.commit('APP_SNACKBAR', {
                        text: `${event.name} deleted`
                    });
                }),
            }
        }

    },

    watch: {
        $route: {
            immediate: true,
            handler(route) {
                this.tab =  this.tabs.findIndex(tab => tab.route === route.name) ?? 0;
                if(route.name == 'admin.events') this.getEvents();
                if(route.name == 'admin.event.categories') this.getEventCategories();
            }
        },
        tab: {
            handler(tab) {
                this.$router.push({
                    name: this.tabs[tab].route
                })
            }
        }
    },
}
</script>