<template>
    <ticket :id="id" #default="{ tab, ticket, order, payment, customer  }">

        <div  v-if="tab.active.id === 'order'">
            <div class="m-2 p-5 bg-white rounded-md">
                <h1 class="text-primary-900 font-bold text-xl">Order</h1>
                <div class="divide-y">
                    <div class="p-2">
                        Order Num: {{ order.orderNumber }}
                    </div>
                    <div class="p-2">
                        Quantity: {{ order.quantity }}
                    </div>
                    <div class="p-2">
                        Status: {{ order.status }}
                    </div>
                    <div class="p-2">
                        Total: {{ order.total }}
                    </div>
                    <div class="p-2">
                        Note: {{ order.notes }}
                    </div>
                    <div class="p-2">
                        Date: {{ order.createdAt | datetime }}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="tab.active.id === 'ticket'">
            <div class="m-2 p-5 bg-white rounded-md">
                <h1 class="text-primary-900  font-bold text-xl">Ticket</h1>
                <div class="divide-y">
                    <div class="p-2">
                        Name: {{ ticket.name }}
                    </div>
                    <div class="p-2">
                        Price: {{ [ticket.currency, ticket.price].join('') }}
                    </div>
                        <div class="p-2">
                        Created: {{ ticket.createdAt | datetime }}
                    </div>
                </div>
            </div>
        </div>

        
        <div v-if="tab.active.id === 'payment'">
            <div class="m-2 p-5 bg-white rounded-md">
                <h1 class="text-primary-900 font-bold text-xl">Payment</h1>
                <div class="divide-y">
                    <div class="p-2">
                        Ref: {{ payment.reference }}
                    </div>
                    <div class="p-2">
                        Amount: {{ payment.amount }}
                    </div>
                    <div class="p-2">
                        Currency: {{ payment.currency }}
                    </div>
                    <div class="p-2">
                        Payment Type: {{ payment.paymentType }}
                    </div>
                    <div class="p-2">
                        Status: {{ payment.status}}
                    </div>
                    <div class="p-2">
                        Date: {{ payment.createdAt | datetime }}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="tab.active.id === 'customer'">
            <div class="m-2 p-5 bg-white rounded-md">
                <h1 class="text-primary-900 font-bold text-xl">Customer</h1>
                <div class="text-center my-5">
                    <user-avatar :user="customer" class="w-20 h-20 rounded-full" />
                </div>
                <div class="divide-y">
                    <div class="p-2">
                        Name: {{ [customer.firstName, customer.lastName].join(' ') }}
                    </div>
                    <div class="p-2">
                        Email: {{ customer.email }}
                    </div>
                </div>
            </div>
        </div>

    </ticket>
</template>

<script>
import Ticket from '../layouts/Ticket.vue';
import UserAvatar from '../../User/components/Avatar';


export default {
    name: "CustomerTicketOrder",
    metaInfo() {
        return {
            title: 'Ticket Order',
        }
    },
    components: {
        Ticket, UserAvatar,
    },
    data(){
        return {
            loading: false,
            order: null,
            confirmation: {
                show: false,
                title: null,
                content: null,
                action: () => Promise.resolve()
            },
        }
    },
    computed: {
        id() {
            return this.$route.params.id
        }
    }
}
</script>