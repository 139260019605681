<template>
    <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9808 16.875L16.814 11.4457H11.6045V7.92246C11.6045 6.43711 12.3322 4.98926 14.6654 4.98926H17.0338V0.366797C17.0338 0.366797 14.8845 0 12.8297 0C8.53943 0 5.73513 2.60039 5.73513 7.30781V11.4457H0.966187V16.875H5.73513V30H11.6045V16.875H15.9808Z" fill="#1FB2B9"/>
    </svg>
</template>

<script>
export default {
    name: "FacebookSVG"
}
</script>