<template>
    <div class="event-card rounded-b-md hover:shadow-lg">
        <router-link :to="{name: 'event.show', params: {slug: event.slug} }" :title="event.name">
            <div class="event-image rounded-t-md bg-center bg-cover bg-no-repeat" :style="`background-image: url(${image})`"></div>
            <div class="p-3">
                <p class="text-primary-900">{{ startMoment.format('ddd, MMMM Do YYYY') }}</p>
                <p class="uppercase text-gray-500 text-sm">{{ categoryName }}</p>
                <h2 class="text-lg font-medium truncate" >{{ event.name }}</h2>
                <p v-if="author">By
                    <router-link :to="author.route" class="text-primary-900">
                        {{author.name }}
                    </router-link>
                </p>
            </div>
        </router-link>
    </div>
</template>

<script>
import eventMixin from '../mixins/event';

export default {
    name: "EventCard",
    mixins: [eventMixin],
    props: {
        event: {
            type: Object,
            default: ()  => ({
                name: "Sample Event",
                slug: 'sample-event',
                business: {
                    name: 'PinEventz',
                    publicId: '123'
                }
            })
        }
    },
}
</script>

<style scoped>
    .event-image {
        height: 200px
    }
</style>