
<template>
    <data-container :loading="loading">
        <app-empty-items v-if="!items.length" />
        <div v-else class="flex flex-wrap">
            <div v-for="product in items" :key="product.publicId" class="w-1/2 sm:w-1/3 md:w-1/4">
                <div class="m-1">
                    <product :product="product"  />
                </div>
            </div>
        </div>
    </data-container>
</template>

<script>
import DataContainer from '../../../../components/DataContainer.vue';
import AppEmptyItems from '../../../../components/EmptyItems.vue';
import Product from '../components/Product.vue';
export default {
    name:"Products",
    components: {
        Product,
        DataContainer,
        AppEmptyItems
    },
    data(){
       return {
            loading: false,
            products: []
       }
    },
    computed: {
        items(){
            return this.products.items ? this.products.items : this.products
        }
    },
    props: {
        src: Promise,
        length: {
            type: Number,
            default: () => 3
         }
    },
    watch: {
        src: {
            immediate: true,
            handler(src){
                if(src){
                    this.loading = true;
                    src.then(response => {
                        this.products = response.data
                    })
                    .catch(() => {
                        // this.$store.commit('APP_SNACKBAR', {
                        //     type: 'error',
                        //     text: `Couldn't get products. ${e.message}`
                        // })
                    })
                    .finally(() => {
                        this.loading = false
                    })
                }
            }
        }
    }
}
</script>