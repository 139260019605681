<template>
    <div>
        
        <form class="mt-5" @submit.prevent="submit">
           <div class="px-10 py-5 space-y-5">
                <h1 class="text-2xl text-center text-primary-900">Sign in to your account</h1>
                <div class="space-y-1">
                    <app-input label="First name" v-model="form.firstName" type="text" :error="error('firstName')" prepend-icon="user" required />
                    <app-input label="Last name" v-model="form.lastName" type="text" :error="error('lastName')" prepend-icon="user" required />
                    <app-input label="E-mail" v-model="form.email" type="email" :error="error('email')" prepend-icon="mail" />
                    <app-input label="Password" v-model="form.password" type="password" :error="error('password')" prepend-icon="lock" required />
                    <app-input label="Confirm password" v-model="form.passwordConfirmation" type="password" prepend-icon="lock" required />
                </div>
                <app-button type="submit" large block>Signup</app-button>
                <div class="text-center">
                    <span class="text-gray-500">By clicking "Signup" you accept the </span><a href="#" class="text-primary-900">Terms of user</a>
                </div>
                <div class="flex align-center justify-center">
                    <hr class="bg-primary-900"/><h1 class="text-lg text-primary-900">OR</h1><hr/>
                </div>
                <div class="flex justify-center">
                    <div class="w-full flex align-center justify-evenly border border-1 border-primary-900 divide-x">
                        <div class="border-primary-900 border-1 py-2 px-5">
                            <facebook-svg />
                        </div>
                        <div class="border-primary-900 border-1 py-2 px-5">
                            <google-svg />
                        </div>
                        <div class="border-primary-900 border-1 py-2 px-5">
                            <twitter-svg />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';

import AppInput from '../../../components/Input';
import AppButton from '../../../components/Button';
import FacebookSvg from '../../../svg/Facebook.vue';
import TwitterSvg from '../../../svg/Twitter.vue';
import GoogleSvg from '../../../svg/Google.vue';

import pageMixin from '../../../mixins/page';

export default {
    name: "SigninForm",
    mixins: [pageMixin],
    components: {
        AppInput,
        AppButton,
        FacebookSvg, GoogleSvg, TwitterSvg

    },
    data(){
        return {
            modal: false,
            form: {
                email: '',
                firstName: '',
                lastName: '',
                password: '',
                passwordConfirmation: '',
            }
        }
    },

    methods: {
        ...mapMutations([
            'SET_AUTH_USER'
        ]),

        submit(){
            this.start();
            this.errors = null;

             this.$store.dispatch('UserRegister', {...this.form})
            .then(() => {
                this.$emit('success');
            })
            .catch(error => {
                this.errors = error.response ? error.response.data : null;
            })
            .finally(() => {
                this.complete();
            })
        }
    },
}
</script>