<template>
    <app-layout>
        <section class="s1 bg-center bg-cover bg-no-repeat businesses-banner">
            <div class="flex">
                <div class="w-full lg:w-3/5 py-32 px-5 sm:px-10">
                    <div class="text-white space-y-2">
                        <div class="flex space-x-2 items-center">
                            <router-link :to="{name: 'shop'}">Home</router-link>
                            <i class="mdi mdi-arrow-right"></i>
                            <template v-if="currentCategory">
                                <router-link :to="{name: 'shop.businesses'}">All categories</router-link>
                                <i class="mdi mdi-arrow-right"></i>
                                <p>{{ currentCategory.name }}</p>
                            </template>
                            <template v-else>
                                <p>All Suppliers & Vendors</p>
                            </template>
                        </div>

                        <div v-if="currentCategory">
                            <h1 class="text-white text-3xl lg:text-5xl">
                                {{ currentCategory.name }}
                            </h1>
                        </div>
                        <div v-else>
                            <h1 class="text-white text-3xl lg:text-5xl">
                                All Suppliers & Vendors
                            </h1>
                            <p class="text-xl">Check for your favourite supplier now and book a date with them.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="s2 -mt-20 sm:-mt-10 px-2 sm:px-5 lg:px-20">
            <business-filter v-model="filter" @categories="(c) => categories = c" />
        </section>

        <section class="s3 my-5 px-2 sm:px-5 lg:px-20">
            <data-container :loading="loading">
                <app-empty-items v-if="!businesses || !businesses.totalCount" />
                <div v-else class="flex flex-wrap">
                    <div v-for="business in businesses.items" :key="business.publicId"
                    class="w-full sm:w-1/2 md:w-1/4">
                    <div class="m-1">
                        <business-card :business="business" />
                    </div>
                       
                    </div>
                </div> 
            </data-container>           
        </section>

    </app-layout>
</template>

<script>

import AppLayout from '../../../../layouts/App.vue';
import DataContainer from '../../../../components/DataContainer.vue';
import AppEmptyItems from '../../../../components/EmptyItems.vue';
import BusinessCard from '../components/Card.vue';
import BusinessFilter from '../components/Filter.vue';

import BussinessService from '../../../Business/services';

export default {
    name: "ShopBusinesses",
    metaInfo: {
        title: `Businesses`
    },
    components: {
        AppLayout, DataContainer, AppEmptyItems,
        BusinessCard, BusinessFilter
    },

    data(){
        return {
            filter: {},
            categories: [],
            businesses: null,
            loading: false,
        }
    },

    computed: {
         currentCategory() {
            if(!this.filter.category) return null;
            return this.categories.find(c => c.name == this.filter.category);
        }
    },

    methods: {
        getBusinesses() {
            const source = this.filter.category
            ? BussinessService.getBusinessesByCategory(this.filter.category, 
            {
                filter:{
                    businessType: this.filter.category
                } 
            }) 
            : BussinessService.getBusinesses({
                filter:{
                    ...this.filter,
                    businessType: this.filter.category
                } 
            });

            this.loading = true;
            source.then(response => {
                this.businesses = response.data
            })
            .finally(() => {
                this.loading = false;
            })

        }
    },

    mounted(){
        
    },

    watch: {
        $route: {
            immediate: true,
            handler(route) {
                this.filter = route.query;
                this.getBusinesses();
            }
        }
    }
}
</script>

<style scoped>
    .businesses-banner{
        background-image: url('../../../../assets/images/businesses-banner.jpg');
    }
</style>>
