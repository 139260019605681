<template>
    <div v-click-outside="close" class="relative">
         <label v-if="label" class="block font-medium text-sm text-gray-500">
            {{ label }}
        </label>
        <div :class="`mt-1 form-input shadow-sm flex flex-wrap w-full cursor-text py-2 px-3 bg-${bg} ${opened ? 'rounded-b-none' : ''}`" @click="toggle">
            <div class="w-full flex justify-between">
                <div class="w-full flex flex-wrap items-center align-center">
                    <template v-if="multiple">
                        <div v-for="(option, i) in selected" :key="i" class="w-full">
                            <div class="flex-grow border rounded-full py-1 px-2 border-gray-200 m-1">
                                <div class="flex items-center justify-between">
                                    <div>
                                        <slot name="selected" v-bind="option">
                                            {{ option.label }}
                                        </slot>
                                    </div>
                                    <div v-if="unselectable" class="ml-2">
                                        <i class="fe fe-x-circle text-red-700 cursor-pointer" @click="unselect(i)"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="selected.length">
                        <div class="w-full">
                            <slot name="selected" v-bind="selected[0]">
                                <span>{{ selected[0].label }}</span>
                            </slot>
                        </div>
                    </template>
                    <div class="flex-auto" v-if="multiple || !selected.length">
                        <input :placeholder="$attrs.placeholder" ref="input" v-model="query" @input="searchQuery" class="outline-none w-full bg-transparent" autocomplete="off" />
                    </div>
                </div>
                <div class="">
                    <div class="flex-auto flex cursor-pointer">
                       <div v-if="unselectable && selected.length && !multiple" class="ml-2">
                            <i class="fe fe-x-circle text-red-700 cursor-pointer" @click="unselect(0)"></i>
                        </div>
                        <spinner v-else-if="processing"/>
                        <div  v-else class="w-6 h-6">
                            <svg v-if="!opened" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
        <div ref="dropdown" v-if="opened && !processing" class="absolute block dropdown-top border w-full rounded-b-md shadow-lg z-50 overflow-y-auto dropdown-max-h">
            <div class="bg-white shadow-xs py-1 cursor-pointer">
                <div v-if="!selectableOptions.length" class="py-3 text-center text-gray-500">No match</div>
                <ul v-else role="menu" :class="$attrs['dropdown-class']" 
                    aria-orientation="vertical" aria-labelledby="options-menu">
                    <li v-for="(option, i) in selectableOptions" @click="select(option)" :key="i"
                        class="py-2 px-2" 
                        :class="{'border-t border-gray-100' : i > 0, 'bg-primary-100': selected.find(o=>o.value==option.value), 'hover:bg-gray-100': !selected.find(o=>o.value==option.value) }"
                        >
                        <slot name="option" v-bind="option"><div>{{ option.label }}</div></slot>
                    </li>
                </ul>
                
            </div>
        </div>
        <input-error v-if="error" :message="error" />
    </div>
</template>

<script>
import Spinner from "./Spinner";
import ClickOutside from 'vue-click-outside';
import InputError from './InputError';


export default {
    name: 'AppSelect',
    components: {Spinner, InputError},
    props: {
        label: String,
        options: Array,
        multiple: Boolean,
        valueGetter: Function,
        filterable: {},
        value: [String, Object, Array],
        unselectable: {
            type: Boolean,
            default: () => true
        },
        returnObject: {
            type: Boolean,
            default: () => false
        },
        bg: {
            type: String,
            default: () => 'gray-200'
        },
        error: String
    },
    data() {
        return {
            processing: false,
            opened: false,
            selected: [],
            query: null,
            selectableOptions: [],
        };
    },
    computed: {

    },
    methods: {
        open() {
            this.opened = true;
            if(this.$refs.input)this.$refs.input.focus();
            
        },

        close() {
            if(this.$refs.input)this.$refs.input.blur();
            this.opened = false;
        },

        toggle(){
            if(!this.opened) this.open();
            else this.close();
        },


        select(option) {
            if (this.multiple) {
                if(!this.selected.find(o=>o['value'] === option.value)) this.selected.push(option);
                this.$refs.input.focus();
            }
            else{
                this.selected[0] = option;
                this.close();
            } 

            this.emitValue();
            this.query = null;
        },

        unselect(index){
            this.selected.splice(index,1);
            this.emitValue();
        },

        emitValue(){
            let value = null;
            if (this.multiple) {
                if(this.valueGetter) value = this.selected.map(option => this.valueGetter(option))
                else if(this.returnObject) value = this.selected;
                else value = this.selected.map(option => option.value);
            }
            else{
                let option = this.selected[0];
                if(this.valueGetter) value = option ? this.valueGetter(option) : {}
                else if(this.returnObject) value = option ? option : null
                else value = option ? option.value : null;
            } 
            this.$emit('input', value);
        },

        loading(status) {
            this.processing = status;
        },

        searchQuery() {
            let search = this.query;
            this.$emit('search', {
                query: search,
                loading: this.loading 
            });
            //Local search
            this.selectableOptions = this.options.filter((option) => {
                if (!option) return false;
                return Object.keys(option).filter((key) => {
                    let element = option[key];
                    if(!element) return false;

                    if (typeof element === 'number') element = element.toString();

                    return search ? RegExp(search.toLowerCase(), 'g').test(element.toString().toLowerCase()) : true
                }).length;
            });
        },

        findValueInOptions(value) {
            return value instanceof Object ? value : this.options.find(o => o.value == value)
        },

        setSelected(value) {
            if(value){
                if(value instanceof Array){
                    this.selected = value.map(v => this.findValueInOptions(v));
                    return;
                }
                this.selected = this.findValueInOptions(value) ? [this.findValueInOptions(value)] : [];
            }
        }
    },
    watch: {
        
        options: {
            immediate: true,
            handler(options){
                this.selectableOptions = options;
                this.setSelected(this.value);
            }
        },

        value: {
            immediate: true,
            handler(value){
                this.setSelected(value);
            }
        }
    },
    
    directives: {
        ClickOutside,
    }
}
</script>

<style>
.dropdown-top {
    top: calc(100% - 1px);
}
.dropdown-max-h {
    max-height: 350px;
}
</style>
