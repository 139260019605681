import axios from 'axios';
import Auth from '../classes/Auth';
export default class {

  static setAxiosHeaders(){
    axios.defaults.headers.common['X-Client'] = process.env.VUE_APP_API_CLIENT;
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Authorization'] = Auth.authorization();
  }
  static get(uri) {
    return axios.get(this.setEndpoint(uri));
  }

  static post(uri, data) {
    return axios.post(this.setEndpoint(uri), data);
  }

  static put(uri, data) {
    return axios.put(this.setEndpoint(uri), data);
  }

  static patch(uri, data) {
    return axios.patch(this.setEndpoint(uri), data);
  }
  
  static delete(uri) {
    return axios.delete(this.setEndpoint(uri));
  }

  static getUrl(url) {
    return axios.get(url);
  }

  static setEndpoint(uri) {
    return `${process.env.VUE_APP_API_URL}${uri.charAt(0) === '/' ? '' : '/'}${uri}`;
  }
  
}
