<template>
    <div class="bg-white pb-2">
        <router-link :to="{name: 'shop.product.show', params: {id: product.publicId, _product: product}}" >
            <div class="relative">
                <div class="absolute bg-primary-900 py-1 px-3 text-white right-0 top-0 capitalize">{{ product.status }}</div>
                <img :src="image" :alt="product.name" class="w-full">
                <div class="m-2 text-center">
                    <h4 class="text-md font-medium">{{ product.name }}</h4>
                    <h4 class="text-sm"><router-link class="text-primary-900 hover:underline" :to="{name: 'shop.products', params: {category: category.publicId }}">{{ category.name }}</router-link></h4>
                    <h1>{{ product.price }}</h1>
                </div>
            </div>
        </router-link>
        <div class="opacity-20 hover:opacity-100">
            <add-to-cart :product="product" />
        </div>
    </div>
</template>

<script>
import AddToCart from './AddToCart.vue';

export default {
    name: "Product",
    components: {
        AddToCart
    },
    props: {
        id: {
            type: Number,
            default: () => 1
        },

        product: {
            type: Object,
            default: function() {
                return {
                    name: "White Blouse Armani",
                    imageUrl: `https://picsum.photos/id/${this._uid%100}/200`,
                    slug: "white-blouse-armani",
                    description: "",
                    price: 300,
                    publicId: this.id,
                    status: 'Available'
                }
            } 
        }
    },
    computed: {
        image(){
            return this.product.imageUrl ?? 'https://picsum.photos/200'
        },
        category(){
            return this.product.category ?? {}
        }
    }
}
</script>