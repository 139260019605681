<template>
    <component :is="tag" 
        v-bind="$attrs" 
        v-on="$listeners"
        :class="classes" 
        :disabled="loading || disabled"> 
         <slot></slot>
         <span class="ml-3" v-if="loading">
            <spinner :color="color" />
        </span>
    </component>
</template>

<script>
    import Spinner from "./Spinner";
    export default {
        name: 'AppButton',
        components: {Spinner},
        props: {
            loading: Boolean,
            block: Boolean,
            disabled: Boolean,
            color: {
                type: String,
                default: () => 'white'
            },
            bg: {
                type: String,
                default: () => 'primary-900'
            },
            size: {
                type: String,
                default: () => 'md'
            }
        },
        computed: {
            tag(){
                if(this.$attrs.href) return 'a';
                else if(this.$attrs.to) return 'router-link';
                else return 'button';
            },

            btnSize() {
                if(this.block) return 'py-3 block w-full'
                return {
                    'sm': 'px-2 py-1 text-sm',
                    'md': 'px-4 py-2 text-md',
                    'lg': 'px-5 py-3 text-lg'
                }[this.size]
            },

            classes(){
                let classNames = `tracking-widest focus:outline-none disabled:opacity-25 transition ease-in-out duration-150 text-center text-${this.color} bg-${this.bg} ${this.btnSize} `;
                
                if(this.disabled){
                    classNames += "opacity-25"
                }
               
                return classNames;
            }
        }
    }
</script>
