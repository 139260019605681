<template>
    <shop>
        <h1 class="text-2xl font-medium">My Wishlist</h1>
        <h4 class="text-lg font-medium text-gray-600">Your product wishlist</h4>
        <p class="text-gray-400">If you have any questions, please feel free to contact us, our customer service center is working for you 24/7.</p>            
        <products />
    </shop>
</template>

<script>

import Shop from './layout';
import Products from './Product/widgets/Products.vue';

export default {
    name: "CustomerWishlist",
    metaInfo: {
        title: `My Wishlist`
    },
    components: {
        Shop, Products
    }
}

</script>