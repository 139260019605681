<template>
    <div class="px-1 sm:px-2 lg:px-8 py-5">
        <div class="flex flex-wrap">
            <div class="w-full sm:w-4/12 lg:w-2/12">
                <event-categories />
                <!-- Ads -->
                <div  class="sm:mt-5">
                    <ads-widget heading="Ads" />
                </div>
            </div>

            <div class="w-full sm:w-8/12 lg:w-10/12">
                <div class="sm:px-2 lg:px-4">
                    <div class="flex flex-wrap justify-between items-center space-y-2">
                        <div class="flex-grow flex items-center space-x-2">
                            <business-avatar :business="event.business" class="w-10 h-10 rounded-full" />
                            <h1 v-if="business" class="text-lg font-bold">{{ event.business.name }}</h1>
                        </div>
                        <div class="flex flex-wrap justify-end space-x-2 items-center">
                            <div v-if="isMyBoard && $route.name == 'board'" class="flex justify-end items-center space-x-2">
                                <app-drop-down align="right">
                                    <template #trigger>
                                        <button class="bg-transparent focus:outline-none"><i class="fe fe-more-vertical"></i></button>
                                    </template>
                                    <template #content>
                                        <div class="divide-y">
                                            <app-drop-down-link :to="{name: 'board', params: {account: 'user'}}">
                                                <span>Personal board</span>
                                            </app-drop-down-link>
                                            <app-drop-down-link :to="{name: 'event.create'}">
                                                <span>Create Event</span>
                                            </app-drop-down-link>
                                            <app-drop-down-link :to="{name: 'business.edit'}">
                                                <span>Edit Business</span>
                                            </app-drop-down-link>
                                            <app-drop-down-link :to="{name: 'shop.business.show', params: { username: event.business.username }}">
                                                <span>View in shop</span>
                                            </app-drop-down-link>
                                        </div>
                                    </template>
                                </app-drop-down>
                            </div>
                            <app-drop-down v-if="$store.getters.isAdmin" align="right">
                                <template #trigger>
                                    <button class="focus:outline-none"><i class="mdi mdi-cog"></i></button>
                                </template>
                                <template #content>
                                    <div class="divide-y">
                                        <app-drop-down-link type="button" @click="$refs.adminBusinessEdit.open(event.business)">
                                            <span>Edit business</span>
                                        </app-drop-down-link>
                                    </div>
                                </template>
                            </app-drop-down>
                        </div>
                    </div>
                    
                    <!-- Event list -->
                    <div ref="eventsContainer">
                        <app-data-container :loading="loading">
                            <app-empty-items v-if="!event.events.totalCount" />
                            <user-board-event v-else v-for="event in event.events.items" :key="event.publicId" :event="event" :joined="true" @edit="$refs.eventForm.open(event)" />
                        </app-data-container>
                    </div>
                </div>
            </div>
        </div>

        <event-form ref="eventForm" @finished="eventFormFinished" />
        <admin-business-edit ref="adminBusinessEdit" @updated="businessUpdated" />
    </div>
</template>

<script>
import AdsWidget from '../../Advert/widgets/Ads.vue';
import UserBoardEvent from '../../Event/components/UserBoardEvent.vue';
import AppEmptyItems from '../../../components/EmptyItems.vue';
import AppDataContainer from '../../../components/DataContainer.vue';
import EventForm from '../../Event/components/FormModal.vue';
import EventCategories from '../../Event/widgets/Categories.vue';
import BusinessAvatar from '../components/Avatar.vue';
import AppDropDown from '../../../components/Dropdown.vue';
import AppDropDownLink from '../../../components/DropdownLink.vue';
import AdminBusinessEdit from '../components/AdminEdit';
import EventService from '../../Event/services';

export default {
    name: "BusinessBoard",

    components: {
        AdsWidget, AppEmptyItems, UserBoardEvent, EventForm,
        EventCategories, AppDataContainer, BusinessAvatar,
        AppDropDown, AppDropDownLink, AdminBusinessEdit
    },
    
    data(){
        return {
            loading: false,
            event: {
                business: null,
                events: []
            },
        }
    },

    computed: {
        isMyBoard(){
            return this.$store.getters.business && this.event.business && this.event.business.publicId == this.$store.getters.business.publicId;
        },
    },

    props: {
        business: Object
    },

    methods: {
       
        getEvents(){
            this.event.loading = true;

            this.loading = true;

            EventService.getBusinessEvents(this.event.business.publicId).then(response => {
                this.event.events = response.data
            })
            .catch(() => {
                // this.$store.commit('APP_SNACKBAR', {
                //     type: 'error',
                //     text: `Couldn't get events. ${e.message}`
                // })
            })
            .finally(() => {
                this.loading = false;
            })
        },

        eventFormFinished(event){
            this.$refs.eventForm.close();
            if(event){
                this.$router.push({name: 'event.show', params: {slug: event.slug}});
            }
        },

        businessUpdated(business){
            this.event.business = business;
        }
    },

    watch:{
        business: {
            immediate: true,
            handler(business){
                if(business)
                {
                    this.event.business = business;
                    this.getEvents();
                }
            }
        },
    } 
}
</script>