<template>
    <div class="flex flex-wrap py-2">
        <user-avatar :user="user" class="h-10 w-10 rounded-full" />
        <div>
            <div>
                <h4>{{ [user.firstName, user.lastName].join(' ')  }}</h4>
                <div class="text-gray-500 text-xs">
                    {{ comment.createdAt | datetime }}
                </div>
            </div>
            <div class="mt-2">
                {{ comment.text }}
            </div>
        </div>
    </div>
</template>

<script>
import UserAvatar from '../../User/components/Avatar.vue';
export default {
    name: "Comment",
    components: {
        UserAvatar
    },
    props: {
        comment: Object
    },
    computed: {
        user(){
            return this.comment.user
        }
    }
}
</script>