<template>
    <app-layout>
        <section class="s1 bg-center bg-cover bg-no-repeat businesses-banner">
            <div class="flex">
                <div class="w-full lg:w-3/5 py-32 px-5 sm:px-10">
                <div class="text-white space-y-2">
                    <div class="flex space-x-2 items-center">
                        <router-link :to="{name: 'shop'}">Home</router-link>
                        <i class="mdi mdi-arrow-right"></i>
                        <p>All Categories</p>
                    </div>
                    <h1 class="text-white text-3xl lg:text-5xl">
                        All Categories
                    </h1>
                    <p class="text-xl">Click on the category of business you need and get started.</p>
                </div>
                </div>
            </div>
        </section>

        <section class="s2 my-5 px-2 sm:px-5 lg:px-20">
            <div class="flex flex-wrap justify-center">
                <div v-for="(category, i) in categories" :key="i"
                class="w-full sm:w-1/3 md:w-1/5">
                    <business-category-card :category="category" />
                </div>
            </div>
        </section>

    </app-layout>
</template>

<script>

import AppLayout from '../../../../layouts/App.vue';
import BusinessCategoryCard from '../components/CategoryCard.vue';

import BusinessService from '../../../Business/services';

export default {
    name: "ShopBusinesses",
    metaInfo: {
        title: `Businesses`
    },
    components: {
        AppLayout, BusinessCategoryCard
    },

    data(){
        return {
            categories: []
        }
    },

    computed: {
    },

    methods: {
        
    },

    mounted(){
        this.categories = BusinessService.categories();
    },

}
</script>

<style scoped>
    .businesses-banner{
        background-image: url('../../../../assets/images/businesses-banner.jpg');
    }
</style>>
