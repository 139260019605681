import multiguard  from 'vue-router-multiguard';
import middleware from '../../../router/middleware';
import CustomerOrders from './views/CustomerOrders.vue';
import CustomerOrder from './views/CustomerOrder.vue';
import BusinessOrders from './views/BusinessOrders.vue';
import BusinessOrder from './views/BusinessOrder.vue';

export default [
    {
        path: '/shop/customer-orders',
        name: 'shop.customer.product.orders',
        component: CustomerOrders,
        beforeEnter: middleware.auth
    },

    {
        path: '/shop/customer-orders/:id',
        name: 'shop.customer.product.order',
        component: CustomerOrder,
        beforeEnter: middleware.auth
    },


    {
        path: '/shop/business-orders',
        name: 'shop.business.product.orders',
        component: BusinessOrders,
        beforeEnter: multiguard([middleware.auth, middleware.hasBusiness] )
    },

    {
        path: '/shop/business-orders/:id',
        name: 'shop.business.product.order',
        component: BusinessOrder,
        beforeEnter: multiguard([middleware.auth, middleware.hasBusiness] )
    },

];