<template>
    <div>
        <h4 class="font-bold text-center">Pinners you might know</h4>
        <app-data-container :loading="loading">
            <div v-if="pinners.length" class="overflow-x-auto sm:overflow-y-auto pb-1 bg-white">
                <div class="inline-flex sm:flex sm:flex-wrap divide-x sm:divide-x-0 sm:divide-y" style="max-height: 300px">
                    <div v-for="user in pinners" :key="user.publicId" class="w-44 sm:w-full">
                        <pinner :user="user" />
                    </div>
                </div>
            </div>
            <div v-else class="py-10 px-2 text-center text-gray-500">
                No suggestion for now
            </div>
        </app-data-container>
    </div>
</template>

<script>
// import UserService from '../services';
import AppDataContainer from '../../../components/DataContainer.vue';
import Pinner from '../components/Tile';

export default {
    name: "SuggestedPinners",
    components: {
        AppDataContainer, Pinner
    },
    data(){
        return {
            loading: false,
            pinners: []
        }
    },

    mounted(){
        // this.loading = true;
        // UserService.getUsers().then(response => {
        //     this.pinners = response.data.items
        // })
        // .finally(() => {
        //     this.loading = false;
        // })
    }
}
</script>