<template>
    <div class="p-5 space-y-5">
        <h1 class="text-primary-900 text-lg font-medium">Update Business Profile</h1>
        <form  @submit.prevent="submit">
            <div class="flex flex-wrap items-center space-x-2">
                <div class="text-center relative">
                    <div 
                        class="absolute right-0 top-0 h-6 w-6 rounded-full bg-primary-900
                        flex items-center justify-center shadow-lg cursor-pointer
                        rotate-180 border border-white" 
                        title="Update profile photo" 
                        >
                        <i class="mdi mdi-pencil text-white text-lg rotate-180"></i>
                    </div>
                    <app-avatar-input v-model="form.logoUrl">
                        <business-avatar :business="form" class="h-20 w-20 rounded-full cursor-pointer"  />
                    </app-avatar-input>
                </div>
                <h1 class="text-xl lg:text-2xl text-primary-900 font-medium">{{ business.name }}</h1>
            </div>
           
            <div class="space-y-1">

                <app-input label="Name" v-model="form.name" type="text" :error="error('name')" prepend-icon="briefcase" />
                
                <div class="flex flex-wrap items-center space-y-1 md:space-y-0">
                    <div class="w-full md:w-1/2">
                        <div class="md:pr-5">
                            <app-input label="Username" v-model="form.username" type="text" :error="error('username')" prepend-icon="user" />
                        </div>
                    </div>
                    <div class="w-full md:w-1/2">
                       <div class="md:pl-5">
                            <app-input label="Website" v-model="form.website" type="url" :error="error('website')" prepend-icon="globe" />
                        </div>
                    </div>
                </div>

                <app-text-area label="Description" v-model="form.description" :error="error('description')" />

                <div class="flex flex-wrap justify-between items-center space-y-1 sm:space-y-0">
               
                    <div class="w-full">
                        <div class="">
                            <app-select label="Category" v-model="form.businessType" :options="categories" :error="error('businessType')" prepend-icon="briefcase" />
                        </div>
                    </div>

                    <div class="w-full sm:w-1/2">
                        <!-- <div class="sm:pl-2 md:pl-5">
                            <app-select label="Currency" v-model="form.currency" :options="currencies" :error="error('currency')" />
                        </div> -->

                        <!-- <div class="flex flex-wrap items-center pl-2 pt-5">
                            <div class="w-full sm:w-auto mr-2 text-gray-500">
                                Status
                            </div>
                            <div class="w-full sm:w-auto">
                                <label for="status-public">
                                    <input type="radio" name="status" id="status-public" value="public" v-model="form.status" />
                                    <span class="ml-1">Public</span>
                                </label>

                                <label for="status-hidden" class="ml-5">
                                    <input type="radio" name="status" id="status-hidden" value="hidden" v-model="form.status" />
                                    <span class="ml-1">Hidden</span>
                                </label>
                            </div>
                        </div> -->
                    </div>
                </div>

                <app-input label="Registration Number" v-model="form.registrationNumber" type="text" :error="error('registrationNumber')" prepend-icon="hash" />

                <div class="flex flex-wrap">

                    <div class="w-1/2">
                        <div class="pr-2 md:pr-5">
                            <app-input label="Email" v-model="form.businessEmail" type="text" :error="error('businessEmail')" prepend-icon="mail" />
                        </div>
                    </div>

                    <div class="w-1/2">
                        <div class="pl-2 md:pl-5">
                            <app-input label="Phone" v-model="form.businessPhone" type="tel" :error="error('businessPhone')" prepend-icon="phone" />
                        </div>
                    </div>                   

                </div>


                <div class="flex flex-wrap space-y-1">
                    <div class="w-full">
                        <app-input label="Address" v-model="form.address" type="text" :error="error('address')" prepend-icon="map-pin" />
                    </div>

                    <div class="w-1/2">
                        <div class="pr-2 md:pr-5">
                            <business-city-select label="City" v-model="form.city" :error="error('city')" prepend-icon="map-pin" />
                        </div>
                    </div>

                    <div class="w-1/2">
                        <div class="pl-2 md:pl-5">
                            <app-input label="Country" v-model="form.country" type="text" :error="error('country')" prepend-icon="map-pin" />
                        </div>
                    </div>                   
                </div>

            </div>


            <div class="border-gray-200 py-5 text-center">
                <div class="px-5 md:px-28">
                    <app-button type="submit" block>SAVE</app-button>
                </div>

            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AppInput from '../../../components/Input.vue';
import AppButton from '../../../components/Button.vue';
import AppSelect from '../../../components/Select.vue';
import AppAvatarInput from '../../../components/AvatarInput.vue';
import AppTextArea from '../../../components/TextArea.vue';
import BusinessAvatar from './Avatar.vue';
import BusinessCitySelect from './BusinessCitySelect.vue'

import BusinessService from '../services';
import pageMixin from '../../../mixins/page';

export default {
    name: "BusinessProfileForm",
    mixins: [pageMixin],
    components: {
        AppInput, AppButton, AppSelect, AppAvatarInput, BusinessAvatar, 
        AppTextArea, BusinessCitySelect
    },
    data(){
        return {
            form: {}
        }
    },
    computed: {
        ...mapGetters([
            'auth',
            'business'
        ]),

        categories(){
            return  BusinessService.categories().map(category => ({label: category.name, value: category.name}));
        },

        currencies(){
            return  BusinessService.currencies().map(currency => ({label: currency, value: currency}));
        }
    },
    methods: {
       submit(){
            this.start();
            this.errors = null;
           this.$store.dispatch('BusinessUpdate', {...this.form, owner: this.auth.publicId})
           .then(() => {
                this.snackbar({
                    text: 'Business update successful',
                    // actions: [
                    //     {
                    //         text: 'Visit your board',
                    //         action: () => {
                    //             this.$router.push({name: 'board', params: {account: 'business'}})
                    //         }
                    //     }
                    // ]
                })
           })
           .catch(e => {
                this.errors = e.response.data;
            })
            .finally(() => {
                this.complete();
            })
        }
    },

    mounted(){
        this.form = {...this.business}
        delete this.form.bankAccount;
    }

}
</script>