<template>
    <app-layout>
        <user-events-widget :user="user" :period="period" />
    </app-layout>
</template>
<script>
import AppLayout from '../../../layouts/App';
import UserEventsWidget from '../widgets/Events.vue';

export default {
    name: "UserEvents",
    metaInfo() {
        return {
        title: `My Events (${this.period})`,
        }
    },
    components: {
        AppLayout,
        UserEventsWidget
    },

    data(){
        return {
            period: null,
        }
    },
    computed: {
        user(){
            return this.$store.getters.auth
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(route){
                this.period = route.params.period ? route.params.period  : 'all'
            }
        }
    }
}
</script>