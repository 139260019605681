<template>
    <img v-if="business && business.logoUrl" :src="business.logoUrl" :alt="alt" v-bind="$attrs" v-on="$listeners">
    <img v-else src="@/assets/images/business-avatar.png" :alt="alt" v-bind="$attrs" v-on="$listeners">
</template>

<script>
export default {
    name: "BusinessAvatar",
    props: {
        business: Object
    },
    computed: {
        alt(){
            return this.business ? this.business.name : ''
        }
    }
}
</script>