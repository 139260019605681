<template>
  <nav class="bg-white border-b border-gray-100 fixed z-10 w-full max-h-screen overflow-y-auto sm:overflow-visible">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex justify-between h-16">
              <div class="flex overflow-x-auto overflow-y-hidden">
                  <!-- Logo -->
                  <div class="flex-shrink-0 flex items-center">
                      <router-link :to="{name: 'home'}">
                          <img src="@/assets/logo.png" alt="PinEventz" class="h-6 mx-auto">
                      </router-link>
                  </div>
              </div>

              <div class="flex items-center sm:ml-6">
                  <div class="ml-3 relative">
                    <div class="flex">
                        <app-button bg="primary-100" :to="{name: 'signup'}">SIGNUP</app-button>
                        <app-button :to="{name: 'signin'}">SIGNIN</app-button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </nav>
</template>

<script>
  import AppButton from '../../components/Button.vue';

export default {
    name: "GuestNavigation",
    components: {
      AppButton,
    },
}
</script>