import AdvertService from '../services';

const actions = {

    getAdvertTypes: ({ getters, commit }) => {
        const cache = '_advertTypes';
        return getters.cache(cache) 
                ? Promise.resolve(getters.cache(cache)) 
                : new Promise((resolve, reject) => {
                    AdvertService.getTypes().then(response => {
                        commit('CACHE', {key: cache, data: response.data })
                        resolve(response.data)
                    })
                    .catch(e => {
                        reject(e);
                    })
                })
    },
       
}

export default actions;