import EventService from '../services';

const actions = {

    getEventCategories: ({ getters, commit }) => {
        const cache = '_eventCategories';
        return getters.cache(cache) 
                ? Promise.resolve(getters.cache(cache)) 
                : new Promise((resolve, reject) => {
                    EventService.getCategories().then(response => {
                        commit('CACHE', {key: cache, data: response.data })
                        resolve(response.data)
                    })
                    .catch(e => {
                        reject(e);
                    })
                })
    },
    
    getInterestTags: ({ getters, commit }) => {
        const cache = '_interestTags';
        return getters.cache(cache) 
                ? Promise.resolve(getters.cache(cache)) 
                : new Promise((resolve, reject) => {
                    EventService.getInterestTags().then(response => {
                        commit('CACHE', {key: cache, data: response.data })
                        resolve(response.data)
                    })
                    .catch(e => {
                        reject(e);
                    })
                })
    },

   
}

export default actions;