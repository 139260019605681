<template>
    <div>
        <label v-if="label" class="block font-medium text-sm" :class="{ 'text-transparent' : !focused, 'text-gray-500' : focused || $attrs.value }">
            {{ label }}
        </label>

        <div class="mt-1 relative">
            <div v-if="prependIcon" class="absolute inset-y-0 left-0 px-3 flex items-center pointer-events-none">
                <span class="sm:text-sm" :class="{ 'text-primary-900': focused, 'text-gray-500': !focused }">
                    <i :class="`fe fe-${prependIcon}`"></i>
                </span>
            </div>
   
            <input 
                v-bind="$attrs"
                @input="$emit('input', $event.target.value)" ref="input"
                @focus="focused = true"
                @blur="focused = false"
                :placeholder="label"
                class="block w-full py-2 bg-gray-200 focus:outline-none px-3" :class="{'pl-8' : prependIcon !== undefined, 'pr-8': appendIcon !== undefined}">
        
            <div v-if="appendIcon" class="absolute inset-y-0 right-0 flex px-3 items-center">
                <span class="sm:text-sm">
                    <i :class="`fe fe-${appendIcon}`"></i>
                </span>
            </div>
    
        </div>
        <input-error v-if="error" :message="error" />
    </div>
</template>

<script>
    import InputError from './InputError';
    export default {
        name: 'AppInput',
        components: {
            InputError
        },
        data() {
            return {
                focused: false,
            }
        },
        props:{
            label: String,
            prependIcon: String,
            appendIcon: String,
            error: String,
        },

        methods: {
            focus() {
                this.$refs.input.focus()
            },
        },
    }
</script>

