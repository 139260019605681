<template>
    <div>
        <app-button v-bind="$attrs" v-if="joined"> <i class="fe fe-user-plus mr-2"></i> Joined</app-button>
        <app-button v-bind="$attrs" v-else @click="joinEvent"> <i class="fe fe-user-plus mr-2"></i> Join</app-button>
        <ticket-order-modal ref="ticketOrder" :event="event" v-on="$listeners"  />
    </div>
</template>

<script>
import AppButton from '../../../components/Button.vue';
import TicketOrderModal from '../../Ticket/components/TicketOrderModal.vue';

export default {
    name: "EventJoinButton",
    components: {
        AppButton, TicketOrderModal,
    },
    data(){
        return {
            joined: false
        }
    },
    props: {
        event: {
            type: Object,
            required: true,
        }
    },

    methods: {

        joinEvent(){

            this.$refs.ticketOrder.open();
            
            // this.$router.push({
            //     name: 'event.join',
            //     params: {
            //         slug: this.event.slug,
            //         _event: this.event
            //     }
            // })
        },
    },

    watch: {
        event: {
            immediate: true,
            handler(){
                this.joined = false;
            }
        }
    }
}
</script>