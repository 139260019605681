import Vue from 'vue'
import Vuex from 'vuex'

import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

// modules
import userStore from '../domain/User/store';
import businessStore from '../domain/Business/store';
import eventStore from '../domain/Event/store';
import pinStore from '../domain/Pin/store';
import commentStore from '../domain/Comment/store';
import productStore from '../domain/Shop/Product/store';
import ShopStore from '../domain/Shop/store';
import AdvertStore from '../domain/Advert/store';

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  modules: {
    userStore,
    businessStore,
    eventStore, 
    pinStore, 
    commentStore,
    productStore,
    ShopStore,
    AdvertStore
  },
})
