<template>
    <label class="flex space-x-2 cursor-pointer items-center" @click="$emit('input', !on)">
        <i class="fe text-3xl" :class="{'fe-toggle-left text-gray-500': !on, 'fe-toggle-right text-primary-900': on }"></i>
        <span v-if="label"> {{label}}</span>
    </label>
</template>

<script>
export default {
  name: 'AppSwitch',
  props: {
    label: String,
    value: {
      type: Boolean,
      default: () => false
    }
  },

  data(){
    return {
      on: false
    }
  },

  watch:{
    value: {
      immediate: true,
      handler(v){
        this.on = v;
      }
    }
  },
};
</script>
