import store from '../store'
import Auth from '../classes/Auth';
export default {

  auth: async (to, from, next) => {
    if (Auth.isAuthenticated()) {

      if(!store.getters.auth){
        try {
          await store.dispatch('getLoggedInUser')
        } catch (error) {
          store.commit('SET_AUTH_USER', null)
        }
      }

      if(store.getters.auth && !store.getters.profileSet && to.name != 'user.profile.edit' ){
        store.commit('APP_SNACKBAR', {
          text: "Complete your profile before continue",
        })
        next({
          name: 'user.profile.edit',
          params: {
            fromSignup: true
          }
        })
        return;
      }

      return next()
    }
    
    store.commit('APP_SNACKBAR', {
      text: 'Login first'
    })
    next({
      name: 'signin',
      query: {
        redirect: to.fullPath
      }
    })
  },
  
  guest: (to, from, next) => {
    
    if (Auth.isAuthenticated()) {
      next({
        name: 'home'
      })
      return
    }
    next()
  },

  hasBusiness: (to, from, next) => {
    
    if (!store.getters.business) {
      store.commit('APP_SNACKBAR', {
        text: 'You need to create a business first'
      })
      next({
        name: 'business.create'
      })
      return;
    }
    next()

  },

  hasNoBusiness: (to, from, next) => {
    console.log(store.getters.auth)
    if(store.getters.business) {
      store.commit('APP_SNACKBAR', {
        text: 'Not allowed'
      })
      next({
        name: 'home'
      })
      return;
    }
    next()
  },

  admin: async (to, from, next) => {
    if(!store.getters.auth.isStaff) {
      store.commit('APP_SNACKBAR', {
        text: 'Only an admin is allowed'
      })
      next({
        name: 'home'
      })
      return;
    }
    next()
  },
}