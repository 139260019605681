<template>
    <div class="p-5 space-y-5">
        <h1 class="text-primary-900 text-lg font-medium">Update Bank Information</h1>
        <form @submit.prevent="submit">
            <div class="space-y-5">
                <div class="space-y-1">
                    <app-select label="Bank" :options="bankOptions" v-model="form.bankName" :error="error('bankName')" />
                    <app-input label="Account owner name" v-model="form.accountOwnerName" type="text" :error="error('accountOwnerName')" />
                    <app-input label="Account number" v-model="form.accountNumber" type="text" :error="error('accountNumber')" />
                </div>
                <div class="text-center">
                    <div class="px-5 md:px-28">
                        <app-button type="submit" block>SAVE</app-button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AppInput from '../../../components/Input.vue';
import AppButton from '../../../components/Button.vue';
import AppSelect from '../../../components/Select.vue';
import pageMixin from '../../../mixins/page';

export default {
    name: "UserBankForm",
    mixins: [pageMixin],
    components: {
        AppInput, AppButton, AppSelect
    },
    data(){
        return {
            banks: [
                'Access bank',
                'GTBank',
            ],
            form: {}
        }
    },
    computed: {
        bankOptions(){
            return  this.banks.map(bank => ({label: bank, value: bank}));
        },
        ...mapGetters([
            'auth'
        ])
    },
    methods: {
       submit(){
            this.start();
            this.errors = null;

           this.$store.dispatch(this.auth.bankAccount ? 'UserBankUpdate' : 'UserBankCreate', {...this.form, user: this.auth.publicId})
           .then(() => {
                this.snackbar({
                    text: 'Bank account info updated',                   
                })
           })
           .catch(e => {
                this.errors = e.response.data;
            })
            .finally(() => {
                this.complete();
            })
        }
    },

    mounted(){
        this.form = {...this.auth.bankAccount}
    }

}
</script>