<template>
    <app-layout>
        <board :user="$store.getters.auth" bg="transparent">
             <template #title>
                 <div class="flex flex-wrap justify-between items-center pr-5">
                    <h1 class="text-2xl font-medium mb-2">Adverts</h1>
                 </div>
            </template>
            <template #action>
                <app-button @click="$refs.advertForm.open()"> <i class="fe fe-plus"></i> Create Advert</app-button>
            </template>
            <data-container :loading="loading">
                <app-empty-items v-if="!adverts.totalCount" />
                <div v-else class="overflow-auto">
                    <table class="min-w-max w-full table-auto mt-3 bg-white">
                        <thead>
                            <tr class="bg-white text-gray-600 uppercase leading-normal">
                                <th class="py-3 px-6 text-left">Title</th>
                                <th class="py-3 px-6 text-left">Starts</th>
                                <th class="py-3 px-6 text-left">Ends</th>
                                <th class="py-3 px-6 text-left">Status</th>
                                <th class="py-3 px-6 text-left">Active</th>
                                <th class="py-3 px-6 text-left">Created</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-600 ">
                            <tr v-for="(advert, i) in adverts.items" :key="advert.publicId" 
                            :class="{'bg-gray-100': i%2 === 0, 'bg-white': i%2 === 1 }" 
                            >
                                <td class="py-3 px-6 text-left whitespace-nowrap">
                                    <div class="flex items-center">
                                        <span class="font-medium">{{ advert.title }}</span>
                                    </div>
                                </td>
                                <td class="py-3 px-6 text-left">
                                    <div class="flex items-center">
                                        <span>{{ advert.startAt | datetime }}</span>
                                    </div>
                                </td>
                                <td class="py-3 px-6 text-left">
                                    <div class="flex items-center">
                                        <span>{{ advert.endAt | datetime }}</span>
                                    </div>
                                </td>
                                <td class="py-3 px-6 text-left">
                                    <span class="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs">{{ advert.status }}</span>
                                </td>
                                <td class="py-3 px-6 text-left">
                                    <div class="flex items-center">
                                        <span class="">{{ advert.isActive ? 'ACTIVE' : 'INACTIVE'}}</span>
                                    </div>
                                </td>
                                <td class="py-3 px-6 text-left">
                                    <div class="flex items-center">
                                        <span>{{ advert.createdAt | datetime }}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </data-container>
        </board>
        <advert-form ref="advertForm" @created="advertCreated" @updated="advertUpdated" />
    </app-layout>
</template>
<script>
import AppLayout from '../../../layouts/App';
import DataContainer from '../../../components/DataContainer.vue';
import AppEmptyItems from '../../../components/EmptyItems.vue';
import AppButton from '../../../components/Button.vue';
import Board from '../widgets/Board.vue';
import AdvertForm from '../../Advert/components/AdvertForm.vue';
import AdvertService from '../../Advert/services';

export default {
    name: "UserAdverts",
    metaInfo: {
        title: `My Advert`,
    },
    components: {
        AppLayout,
        DataContainer,
        AppEmptyItems,
        AppButton,
        Board,
        AdvertForm
    },
    data(){
        return {
            adverts: {},
            loading: false
        }
    },

    computed: {
       
    },

    methods: {
        getAdverts(){

            this.loading = true;

            AdvertService.getUserAdverts(this.$store.getters.auth.publicId)
            .then(response => {
                this.adverts = response.data
            })
            .catch(e => {
                this.$store.commit('APP_SNACKBAR', {
                    type: 'error',
                    text: `Couldn't get adverts. ${e.message}`
                })
            })
            .finally(() => {
                this.loading = false;
            });
        },

        advertCreated(advert){
            this.adverts.items.push(advert);
            this.adverts.totalCount++;
            this.$refs.advertForm.close();
        },

        advertUpdated(updatedAdvert){
            this.adverts.items = this.adverts.items.map(advert => {
                if(advert.publicId == updatedAdvert.publicId) return updatedAdvert;
                else return advert;
            })
            this.$refs.advertForm.close()
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(){
                this.getAdverts()
            }
        }
    }
}
</script>