<template>
    <div class="bg-white flex flex-wrap justify-between items-center py-5 px-3">
        <div class="w-full md:w-auto flex flex-wrap items-center">
            <h1 class="w-full md:w-auto font-bold mr-5">FILTER BY</h1>
            <div class="w-full md:w-64 flex flex-wrap mt-5 md:mt-0">
                <!-- <app-drop-down class="w-full" align="center" >
                    <template #trigger>
                        <div class="flex justify-between items-center">
                            <a href="#" @click.prevent class="cursor-pointer"><i class="mdi mdi-map-marker"></i> City</a>
                            <i class="mdi mdi-chevron-down ml-3"></i>
                        </div>
                        <div class="text-xs text-gray-500 ml-5">{{ filter.city }}</div>
                    </template>
                    <template #content>
                        <div class="divide-y">
                            <app-drop-down-link 
                            v-for="city in cities" :key="city"
                            type="button"
                            @click="filter.city = city"
                            >
                                <span class="capitalize">{{ city }}</span>
                            </app-drop-down-link>
                        </div>
                    </template>
                </app-drop-down> -->
                <div class="w-full"><i class="mdi mdi-map-marker"></i> City</div>
                <app-select 
                    :options="cities.map(c => ({label: c, value: c}))"
                    v-model="filter.city"
                    placeholder="Enter city..."
                    class="w-full"
                    bg="white"
                >
                    <template #option="{ label }">
                        <i class="mdi mdi-map-marker text-primary-900"></i> {{ label }}
                    </template>
                    <template #selected="{ label }">
                        <i class="mdi mdi-map-marker text-primary-900"></i> {{ label }}
                    </template>
                </app-select>

                <!-- <app-drop-down class="w-1/3 md:w-auto" align="center" >
                    <template #trigger>
                        <a href="#" @click.prevent class="cursor-pointer">Rating <i class="mdi mdi-chevron-down ml-3"></i></a>
                        <div class="text-xs text-gray-500">{{ filter.rating }} star<span v-show="filter.rating>1">s</span> </div>
                    </template>
                    <template #content>
                        <div class="divide-y">
                            <app-drop-down-link v-for="i in 5" :key="i"
                            type="button"
                            @click="filter.rating = i">
                                <div class="flex justify-between">
                                    <div>
                                        <span v-for="j in 5" :key="j" class="mx-1">
                                            <i class="mdi " :class="{'mdi-star text-primary-900': j <= i, 'mdi-star-outline text-primary-900': j > i }"></i>
                                        </span>
                                    </div>
                                    <div class="text-sm text-gray-500">
                                        {{i}} star<span v-show="i>1">s</span>
                                    </div>
                                </div>
                            </app-drop-down-link>
                        </div>
                    </template>
                </app-drop-down>

                <app-drop-down class="w-1/3 md:w-auto" align="center" >
                    <template #trigger>
                        <a href="#" @click.prevent class="cursor-pointer">Pricing <i class="mdi mdi-chevron-down ml-3"></i></a>
                        <div class="text-xs text-gray-500">{{ filter.pricing }}</div>
                    </template>
                    <template #content>
                        <div class="divide-y">
                        <app-drop-down-link v-for="pricing in pricings" :key="pricing"
                        type="button" 
                        @click="filter.pricing = pricing">
                                <span class="capitalize">{{ pricing }}</span>
                            </app-drop-down-link>
                        </div>
                    </template>
                </app-drop-down> -->
            </div>
        </div>

        <div class="w-full md:w-72 flex space-x-2 items-center mt-5 md:mt-0">
            <i class="mdi mdi-view-grid text-3xl"></i>
            <app-select 
                :options="categories.map(c => ({...c, label: c.name, value: c.name}))"
                v-model="filter.category"
                placeholder="Search category..."
                class="w-full"
                bg="white"
            >
                <template #option="{ label, icon }">
                    <i class="text-primary-900" :class="icon"></i> {{ label }}
                </template>
                <template #selected="{ label, icon }">
                    <i class="text-primary-900" :class="icon"></i> {{ label }}
                </template>
            </app-select>

            <!-- <app-drop-down class="flex-grow" align="right" >
                <template #trigger>
                    <div class="divide-x flex border border-gray-500">
                        <button class="flex-grow border-gray-500 focus:outline-none px-2 py-1">
                           <span v-if="currentCategory"><i :class="currentCategory.icon"></i> {{ currentCategory.name }}</span>
                           <span v-else>All Categories</span>
                        </button>
                        <button class="flex-shrink border-gray-500 px-2 py-1 focus:outline-none"><i class="mdi mdi-chevron-down"></i></button>
                    </div>
                </template>
                <template #content>
                    <div class="divide-y">
                        <app-drop-down-link
                        v-for="category in categories" :key="category.name"
                        type="button"
                        :class="{'bg-primary-900 text-white': currentCategory && category.name === currentCategory.name}"
                        @click="filter.category = category.name == 'All Categories'">
                            <i :class="category.icon"></i><span class="ml-2">{{ category.name }}</span>
                        </app-drop-down-link>
                    </div>
                </template>
            </app-drop-down> -->
            <app-button
            title="Apply filter"
            size="md"
            @click="$router.replace({name: $route.name, query: filter})"
            >
                <i class="mdi mdi-arrow-right"></i>
            </app-button>
        </div>
    </div>
</template>

<script>
// import AppDropDown from '../../../../components/Dropdown.vue';
// import AppDropDownLink from '../../../../components/DropdownLink.vue';
import AppButton from '../../../../components/Button.vue';
import AppSelect from '../../../../components/Select.vue';

import BusinessService from '../../../Business/services';

export default {
    name: "BusinessFilter",
    components: {
        // AppDropDown, AppDropDownLink,
        AppButton, AppSelect
    },
    data() {
        return {
            cities: BusinessService.cities(),
            pricings: ['low', 'medium', 'high'],
            categories: [],
            filter: {}
        }
    },

    computed: {
        currentCategory() {
            if(!this.filter.category) return null;
            return this.categories.find(c => c.name == this.filter.category);
        }
    },

    props: {
        value: Object
    },

    mounted() {
        this.categories = BusinessService.categories();
        this.$emit('categories', this.categories);
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if(value) {
                    this.filter = {
                        city: value.city ? value.city : null,
                        // rating: value.rating ? value.rating : 1,
                        // pricing: value.pricing ? value.pricing : this.pricings[0],
                        category: value.category ? value.category : null,
                    }
                }else {
                    this.filter = {
                        city: this.cities[0],
                        // rating: 1,
                        // pricing: this.pricings[0],
                        category: null,
                    }
                }
            }
        },
    }
}
</script>