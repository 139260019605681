<template>
    <div v-if="user">
        <div class="flex justify-between p-2 items-center">
            <h4 class="font-bold">Total: {{ total }}</h4>
            <!-- <app-button>Invite users</app-button> -->
        </div>
        <div class="bg-white">
            <!-- <app-tabs-panel v-model="tab" :items="tabs">
                <template #content>
                    <component :is="`${profile}-followings`" v-bind="followingsProps" @total="getTotal"></component>
                </template>
            </app-tabs-panel> -->
            <personal-followings v-bind="followingsProps" @total="getTotal" />
        </div>
    </div>
</template>

<script>

// import AppButton from '../../../components/Button';
// import AppTabsPanel from '../../../components/TabsPanel.vue';
// import BusinessFollowings from '../../Business/widgets/BusinessFollowings';
import PersonalFollowings from './PersonalFollowings';

export default {
    name: "UserFollowings",
    components: {
        // AppButton, AppTabsPanel,
        // BusinessFollowings,
        PersonalFollowings,
    },
    data(){
        return {
            total: 0,
            tab: 0,
            // tabs: [
            //     {
            //         title: 'Personal',
            //         id: 'personal',
            //     },
            //     {
            //         title: 'Business',
            //         id: 'business',
            //     }
            // ]
        }
    },
    props: {
        user: Object,
        business: Object
    },
    computed: {
        // profile(){
        //     return this.tabs[this.tab].id;
        // },
        followingsProps(){
            return {
                user: this.user,
                business: this.business
            }
        }   
    },
    methods: {
        getTotal(total){
            this.total = total
        }
    },
    watch: {
        // $route: {
        //     immediate: true,
        //     handler(route) {
        //         const target = route.params.profile ?? this.tabs[0].id;
        //         this.tab =  this.tabs.findIndex(tab => tab.id === target) ?? 0;
        //     }
        // },
        // tab: {
        //     handler(tab) {
        //         this.$router.push({...this.$route, params: {
        //             ...this.$route.params,
        //             profile: this.tabs[tab].id
        //         }})
        //     }
        // }
    }
}
</script>