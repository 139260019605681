<template>
    <div>
        <modal :show="show" max-width="3xl" @close="close">
            <template v-if="pin">
                <div class="flex flex-wrap overflow-y-auto divide-x" style="max-height: 80vh">
                    <div class="w-full sm:w-2/3 space-y-5">

                        <pin-media :pin="pin" class="w-full" />

                        <div class="space-y-2 p-3">
                            <div class="text-xs text-gray-500">Event</div>
                            <router-link :to="{name: 'event.show', params: {slug: pin.event.slug}}" class="text-primary-900 text-sm">{{ pin.event.name }}</router-link>
                            <div>
                                <p>{{ pin.description }}</p>
                            </div>
                            <div class="flex flex-wrap justify-between items-center border-gray-200 border-b py-3">
                                <router-link :to="{name: 'user.show', params: {id: pin.user.publicId}}" class="flex space-x-2 items-center mt-2" >
                                    <user-avatar :user="pin.user" class="h-10 w-10 rounded-full" />
                                    <div  class="flex-grow">
                                        <span>{{ [pin.user.firstName, pin.user.lastName].join(' ') }}</span>
                                    </div>
                                </router-link>
                                <app-drop-down v-if="isMyPin" align="right">
                                    <template #trigger>
                                        <button class="text-gray-400 focus:outline-none"><i class="fe fe-more-vertical"></i></button>
                                    </template>
                                    <template #content>
                                        <div class="divide-y">
                                            <app-drop-down-link type="button" @click="$emit('edit', pin)">
                                                <span><i class="fe fe-edit"></i> Edit pin</span>
                                            </app-drop-down-link>
                                            <app-drop-down-link type="button" @click="deletePin">
                                                <span class="text-red-500">
                                                    <i class="fe fe-trash"></i> Delete pin
                                                </span>
                                            </app-drop-down-link>
                                        </div>
                                    </template>
                                </app-drop-down>
                                <user-follow-button v-else :user="pin.user" />
                            </div>
                            <h2 class="text-gray-500">Comments ({{pin.comments.length}})</h2>
                            <div v-if="!pin.comments.length" class="text-gray-500 py-5 text-center">
                                No comment yet
                            </div>
                            <div v-else class="divide-y overflow-y-auto space-y-2" >
                                <comment v-for="comment in pin.comments" :key="comment.publicId" :comment="comment" />
                            </div>

                        </div>
                    </div>
                    <div class="w-full sm:w-1/3 divide-y">
                        <div class="p-3 flex justify-start">
                            <div class="flex-shrink">
                                <like-button :pin="pin" />
                            </div>
                        </div>
                        <div class="p-3">
                            <h4 class="font-bold">They Liked this..</h4>
                        </div>
                        <div v-if="!likes.length" class="text-gray-500 py-5 text-center">
                            No one likes this yet
                        </div>
                        <div v-else class="overflow-y-auto divide-y" style="max-height: 400px" >
                            <router-link v-for="like in likes" :key="like.publicId" :to="{name: 'user.show', params: {id: like.user.publicId}}" class="flex space-x-2 items-center mt-2 py-3" >
                                <user-avatar :user="like.user" class="h-10 w-10 rounded-full" />
                                <div  class="flex-grow">
                                    <span>{{ [like.user.firstName, like.user.lastName].join(' ') }}</span>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="p-3">
                    <comment-form :pin="pin" @created="commentAdded" />
                </div>
            </template>
        </modal>

        <app-confirmation
        :show="confirmation.show"
        :onConfirm="confirmation.action"
        @close="confirmation.show = false" >
            <template #title>Confirm</template>
            <template #content>{{ confirmation.message }}</template>
        </app-confirmation>
    </div>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import AppDropDown from '../../../components/Dropdown.vue';
import AppDropDownLink from '../../../components/DropdownLink.vue';
import PinMedia from './PinMedia.vue';
import LikeButton from './LikeButton.vue';
import UserFollowButton from '../../User/components/FollowButton.vue';
import Comment from '../../Comment/components/Comment.vue';
import CommentForm from '../../Comment/components/CommentForm.vue';
import UserAvatar from '../../User/components/Avatar.vue';
import AppConfirmation from '../../../components/Confirmation.vue';

export default {
    name: "PinModal",
    components: {
        Modal, PinMedia, Comment, LikeButton, UserFollowButton,
        CommentForm, AppDropDown, AppDropDownLink, UserAvatar,
        AppConfirmation,                                       
    },
    data(){
        return {
            show: false,
            likes: [],
            confirmation: {
                show: false,
                message: '',
                action: () => Promise.resolve()
            }
        }
    },
    props: {
        pin: Object
    },
    computed: {
        isMyPin(){
            if(!this.pin) return false;
            return this.pin.user.publicId == this.$store.getters.auth.publicId
        }
    },
    methods: {
        close(){
            this.show = false;
            this.$emit('close')
        },

        deletePin(){
            this.confirmation = {
                show: true,
                message: `Are you sure you want to delete the pin ?`,
                action: () => this.$store.dispatch('deletePin', this.pin)
                        .then(() => this.$emit('deleted', this.pin))
                        .catch(e => {
                            this.$store.commit('APP_SNACKBAR', {
                                type: 'error',
                                text: `Couldn't delete pin. ${e.message}`
                            })
                        })
            }
            
        },

        commentAdded(comment){
            this.pin.comments.push({
                ...comment,
                user: this.$store.getters.auth
            });
        }
    },
    watch: {
        pin: {
            immediate: true,
            handler(pin){
                if(pin){
                    this.show = true;
                }else{
                    this.show = false;
                }
            }
        }
    }
}
</script>