<template>
    <app-layout>
        <business-board :business="business" />
    </app-layout>
</template>
<script>
import AppLayout from '../../../layouts/App.vue';
import BusinessBoard from '../widgets/Board.vue';
import BusinessService from '../services';
import pageMixin from '../../../mixins/page';

export default {
    name: 'BusinessShow',
    mixins: [pageMixin],
    components: {
        AppLayout, BusinessBoard
    },

    data(){
        return {
            business: null
        }
    },

    computed: {
        id(){
            return this.$route.params.id
        }
    },

    methods:{
        getBusiness(){
            this.start();

            if(this.$route.name == 'boards'){
                this.business = this.$store.getters.business;
                this.complete();
                return;
            }

            BusinessService.getBusiness(this.id).then(response => {
                this.business = response.data;
            })
            .catch(e => {
                this.$store.commit('APP_ERROR', {text: 'Could not get business: '+e.message});
            })
            .finally(() => {
                this.complete()
            })
        }
    },
    watch: {
        id: {
            immediate: true,
            handler(){
                this.getBusiness();
            }
        }
    }
}
</script>