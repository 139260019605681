import multiguard  from 'vue-router-multiguard';
import middleware from '../../router/middleware';

import EventCreate from './views/Create.vue';
import EventShow from './views/Show.vue';
import EventRegister from './views/Register.vue';
import EventCategory from './views/Category.vue';
import EventManager from './views/Manager.vue'
import EventTicketOrder from './views/TicketOrder.vue'

export default [
    {
        path: '/create-event',
        name: 'event.create',
        component: EventCreate,
        beforeEnter:  multiguard([middleware.auth, middleware.hasBusiness] )
    },
    {
        path: '/event/:slug',
        name: 'event.show',
        component: EventShow,
        beforeEnter: middleware.auth,
    },
    {
        path: '/event/:slug/manage/:tab?',
        name: 'event.manager',
        component: EventManager,
        beforeEnter: middleware.auth
    },

    {
        path: '/event-ticket-order/:id/:tab?',
        name: 'event.ticket.order',
        component: EventTicketOrder,
        beforeEnter: middleware.auth
    },

    {
        path: '/event/:slug/join',
        name: 'event.join',
        component: EventRegister,
        beforeEnter: middleware.auth
    },

    {
        path: '/events/:category?',
        name: 'events',
        component: EventCategory,
        beforeEnter: middleware.auth
    },


];
