<template>
    <admin>
        <template #header>
            <div class="py-2">
                <h2 class="text-xl">Businesses - {{businesses.totalCount}}</h2>
            </div>
        </template>
        <data-container :loading="loading">
            <app-empty-items v-if="!businesses.totalCount" />
            <div v-else class="overflow-auto">
                <table class="min-w-max w-full table-auto">
                    <thead>
                        <tr class="bg-white text-gray-600 uppercase">
                            <th class="py-3 px-6 text-left">Business</th>
                            <th class="py-3 px-6 text-left">Email</th>
                            <th class="py-3 px-6 text-left">City</th>
                            <th class="py-3 px-6 text-left">Created</th>
                            <th class="py-3 px-6 text-left">Status</th>
                            <th class="py-3 px-6 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody class="">
                        <tr v-for="(business, i) in businesses.items" :key="i"
                        :class="{'bg-gray-100': i%2 === 0, 'bg-white': i%2 === 1 }" 
                        >
                            <td class="py-3 px-6 text-left whitespace-nowrap">
                                <div class="flex items-center">
                                    <business-avatar :business="business" class="w-10 h-10 mr-2 rounded-full" />
                                    <h4>{{business.name}} </h4>
                                </div>
                            </td>
                            <td class="py-3 px-6 text-left">
                                <span>{{ business.businessEmail }}</span>
                            </td>
                            <td class="py-3 px-6 text-left">
                                <span>{{ business.city }}</span>
                            </td>
                            <td class="py-3 px-6 text-left">
                                <span>{{ business.createdAt | datetime }}</span>
                            </td>

                            <td class="py-3 px-6 text-left">
                                <span>{{ business.status }}</span>
                            </td>
                            
                            <td class="py-3 px-6 text-center">
                                <div class="flex item-center space-x-2 justify-center">
                                    <router-link :to="{name: 'business.show', params: {id: business.publicId, _business: business}}" title="View board">
                                        <i class="mdi mdi-eye"></i>
                                    </router-link>
                                     <router-link :to="{name: 'shop.business.show', params: {username: business.username, _business: business}}" title="View store">
                                        <i class="mdi mdi-store"></i>
                                    </router-link>
                                    <a href="#" @click.prevent="editBusiness(business)" title="Edit business">
                                        <i class="mdi mdi-pencil"></i>
                                    </a>
                                    <a href="#" @click.prevent="deleteBusiness(business)" title="Delete business">
                                        <i class="mdi mdi-delete text-red-500"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </data-container>
         <admin-business-edit ref="adminBusinessEdit" @updated="businessUpdated" />
        <app-confirmation :show="confirmation.show" @close="confirmation.show = false" :onConfirm="confirmation.action">
            <template #title>Confirm</template>
            <template #content>{{ confirmation.content }}</template>
        </app-confirmation>
    </admin>
</template>

<script>
import Admin from '../../../layouts/Admin.vue';
import AppEmptyItems from '../../../components/EmptyItems';
import DataContainer from '../../../components/DataContainer';
import BusinessAvatar from '../../Business/components/Avatar';
import AppConfirmation from '../../../components/Confirmation';
import AdminBusinessEdit from '../../Business/components/AdminEdit';

import BusinesseService from '../../Business/services';

export default {
    name: "AdminBusinesses",
    metaInfo() {
        return {
            title: `Businesses (${this.businesses.totalCount})`
        }
    },
    components: {
        Admin,
        AppEmptyItems,
        DataContainer,
        BusinessAvatar,
        AppConfirmation,
        AdminBusinessEdit
    },
    data(){
        return {
            loading: false,
            businesses: {
                items: [],
                totalCount: 0
            },
            confirmation: {
                show: false,
                title: null,
                content: null,
                action: () => Promise.resolve()
            },
            activeBusiness: null
        }
    },
    
    computed: {
        
    },

    methods:{
        getBusinesses(){
            this.loading = true;
            BusinesseService.getBusinesses()
            .then(response => {
                this.businesses = response.data
            })
            .catch(() => {
                // this.$store.commit('APP_SNACKBAR', {
                //     type: 'error',
                //     text: `Couldn't get businesses. ${e.message}`
                // })
            })
            .finally(() => {
                this.loading = false
            })
        },

        editBusiness(business) {
            this.activeBusiness = business;
            this.$refs.adminBusinessEdit.open(business);
        },

        businessUpdated(business) {
            this.activeBusiness = business;
        },

        deleteBusiness(business){
            this.confirmation = {
                show: true,
                title: 'Delete business',
                content: `Are you sure you want to delete ${business.name}?`,
                action: () => BusinesseService.deleteBusiness(business.publicId).then(() =>  {
                    this.businesses.splice(this.businesses.findIndex(p => p.publicId == business.publicId), 1) 
                     this.$store.commit('APP_SNACKBAR', {
                        text: `${business.name} deleted`
                    });
                }),
            }
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(){
                this.getBusinesses();
            }
        }
    }

}
</script>