<template>
    <app-layout bg="gray-100">
        <div class="fixed z-10 w-full align-center pt-6" style="background-color: inherit">
            <div class="sm:mx-10">
                <div class="flex bg-white justify-between items-center">
                    <div class="mx-3">
                        <button class="text-primary-900 text-lg focus:outline-none" @click="expandCategories = !expandCategories"><i class="fe fe-menu"></i></button>
                        <product-categories v-if="expandCategories"  />
                    </div>
                    <div class="flex items-center space-x-5 overflow-auto sm:mr-6" >
                        <nav-link v-if="$store.getters.business" :to="{name: 'shop.business.products'}" :active="$router.currentRoute.name == 'shop.business.products'">
                            Products
                        </nav-link>
                        <nav-link v-if="$store.getters.business" :to="{name: 'shop.business.product.orders'}" :active="$router.currentRoute.name == 'shop.business.product.orders'">
                            Orders
                        </nav-link>
                        <nav-link :to="{name: 'shop.customer.product.orders'}" :active="$router.currentRoute.name == 'shop.customer.product.orders'">
                            My Orders
                        </nav-link>
                        <router-link :to="{name: 'shop.customer.wishlist'}" :active="$router.currentRoute.name == 'shop.customer.wishlist'">
                        <i class="mdi mdi-heart text-primary-900 text-2xl"></i>
                        </router-link>
                        <div>
                            <router-link :to="{name: 'shop.cart'}" class="mr-10 relative ">
                                <span class="absolute text-sm h-5 w-5 rounded-full text-center -right-4 -top-1 bg-primary-900 text-white" >{{ $store.getters.cartItemsTotal }}</span>
                                <i class="fe fe-shopping-cart text-primary-900 text-2xl"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mx-2 sm:mx-10 mt-16">
            <div class="flex flex-wrap">
                <div class="w-full">
                    <div class="mt-6">
                        <slot />
                        <!-- <div class="my-4">
                            <h1 class="text-2xl text-primary-900 font-medium">Sample Products</h1>
                            <recently-viewed-products />
                        </div> -->
                    </div>
                    
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>

import AppLayout from '../../layouts/App.vue';
import NavLink from '../../components/ShopNavLink.vue';
import ProductCategories from './Product/widgets/Categories';
// import RecentlyViewedProducts from './Product/widgets/RecentlyViewed.vue';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import '../../assets/css/shop.css';

export default {
    name: "Shop",
    components: {
        AppLayout, NavLink, ProductCategories, 
        // RecentlyViewedProducts,
    },
    props: {
        
    },
    data(){
        return {
            expandCategories: false
        }
    },
    watch: {
        $route: {
            immediate: true, 
            handler(){
                this.expandCategories = false;
            }
        }
    }
}
</script>
