<template>
    <app-layout>
        <div class="flex justify-center py-5">
            <div class="w-full lg:w-4/5">
                <app-tabs-panel v-model="tab" :items="tabs" :vertical="true">
                    <template v-if="event" #before-items>
                        <div class="space-y-3">
                            <img :src="event.imageUrl" :alt="event.name" class="w-full shadow-lg">
                            <div class="p-2 text-center">
                                <router-link :to="{name: 'event.show', params: {slug: event.slug, _event: event}}">
                                    <h1 class="text-lg font-medium text-primary-900 truncate">{{ event.name }}</h1>
                                </router-link>
                                <p>{{ event.shortDescription }}</p>
                                <p class="text-gray-500">{{ startMoment.format('ddd, MMMM Do YYYY') }}</p>
                            </div>
                        </div>
                    </template>
                    <template #content="attrs">
                        <div class="bg-white sm:mx-2">
                            <data-container :loading="loading">
                                <template v-if="event">
                                    <slot v-bind="{tab: attrs, getEvent}" />
                                </template>
                                <template v-else>
                                    <div class="text-center text-gray-500 py-10">
                                        Event not found
                                    </div>
                                </template>
                            </data-container>
                        </div>
                    </template>
                </app-tabs-panel>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '../../../layouts/App.vue';
import AppTabsPanel from '../../../components/TabsPanel.vue';
import DataContainer from '../../../components/DataContainer.vue';

import eventMixin from '../mixins/event';
import EventService from '../services';

export default {
    name: "EventManager",
    mixins: [eventMixin],
    components: {
        AppLayout, AppTabsPanel,
        DataContainer,
    },
    props: {
        slug: String
    },

    data() {
        return {
            tab: 0,
            tabs: [
                {
                    title: 'Information',
                    id: 'info',
                },
                {
                    title: 'Tickets',
                    id: 'tickets',
                },
                {
                    title: 'Ticket orders',
                    id: 'ticket-orders',
                }
            ],
            loading: false,
            event: null,
        };
    },

    methods: {
        getEvent() {
            this.loading = true;
            EventService.getEventBySlug(this.slug)
            .then(response => {
                this.event = response.data;
                this.$emit('event', response.data);
            })
            .catch(e => {
                this.$store.commit('APP_SNACKBAR', {
                    type: 'error',
                    text: `Couldn't get events. ${e.message}`
                })
            })
            .finally(() => {
                this.loading = false;
            });
        }
    },


    // beforeRouteEnter (to, from, next) {
    //     next(vm => {
    //         if(!vm.isMyEvent) vm.$router.push({
    //             name: 'board'
    //         })
    //     });
    // },

    watch: {
        slug: {
            immediate: true,
            handler() {
                this.getEvent();
            }
        },

        $route: {
            immediate: true,
            handler(route) {
                const target = route.params.tab ?? this.tabs[0].id;
                this.tab =  this.tabs.findIndex(tab => tab.id === target) ?? 0;
            }
        },
        tab: {
            handler(tab) {
                this.$router.push({
                    name: this.$route.name,
                    params: {
                        tab: this.tabs[tab].id
                    }
                })
            }
        }
    }


}
</script>