<template>
    <div v-if="$store.getters.auth">
        <form @submit.prevent="submit"  class="flex items-center space-x-2">
            <user-avatar :user="$store.getters.auth" class="h-10 w-10 rounded-full" />
            <div class="flex-grow">
                <app-input v-model="form.text" placeholder="write your comment here..." />
            </div>
            <div class="flex-shrink -mb-4">
                <app-button type="submit" :disabled="form.text == '' || form.text == null" :loading="loading"
                bg="transparent" color="primary-900" title="send comment">
                    <i class="fe fe-send text-2xl"></i>
                </app-button>
            </div>
        </form>
    </div>
    <div v-else class="text-gray-500 text-center py-5">
        <p>Login to comment on this pin. <router-link :to="{name: 'signin'}" class="text-primary-900"> Login </router-link> or <router-link :to="{name: 'signup'}" class="text-primary-900">Register</router-link></p>
    </div>
</template>

<script>
import AppInput from '../../../components/Input.vue';
import AppButton from '../../../components/Button.vue';
import UserAvatar from '../../User/components/Avatar.vue';
export default {
    name: "CommentForm",
    components: {
        AppInput, AppButton, UserAvatar
    },
    props: {
        pin: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            loading: false,
            form: {
                text: null
            }
        }
    },
    methods: {
        submit(){
            this.loading = true;
            this.$store.dispatch('createComment', {
                pin: this.pin,
                ...this.form
            }).then(response => {
                this.form = {
                    text: null
                }
                this.$emit('created', response.data);
            })
            .catch(e => {
                console.log(e, e.response);
                this.$store.commit('APP_SNACKBAR', {
                    text: 'Your comment was not added',
                    type: 'error'
                })
            })
            .finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>