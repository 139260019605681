import API from '../../../api';
import Auth from '../../../classes/Auth';

export default class extends API {

    /**
     * Register a new user
     * @param {*} data 
     */
  static register(data) {
      return this.post('auth/register', data)
  }

  /**
   * verify code
   */
  static verify(code){
    return new Promise((resolve, reject) => {
      this.get(`auth/verify/${code}`)
      .then(response => {
        resolve(response);
      })
      .catch(e => {
        reject(e)
      })
    })
  }


  /**
   * log user in with email and password
   * @param {*} param0 
   */
  static login({ email, password }) {
    return new Promise((resolve, reject) => {
      this.post('auth/login', { email, password })
        .then(response => {
            Auth.setOAuth(response.data);
            resolve(response);
        })
        .catch(e => {
          Auth.removeOauth();
          reject(e)
        })
      });
  }

  /**
   * refresh token
   */
  static refreshToken(){
    return this.post('auth/token/refresh', {refresh: Auth.get('refresh')})
  }

  /**
   * Send password reset code
   * @param {*} email 
   */
  static SendPasswordReset(email){
    return this.get(`password/reset/${email}`);
  }

  /**
   * Reset user password
   */
  static ResetPassword( { code, email, password }){
    return this.post(`password/change`, { code, email, password });
  }

  /**
   * Get currently authenticated user
   */
  static user() {
    return this.get('user');
  }

   /**
   * Log user out by removing Oauth
   */
  static logout() {
    Auth.removeOauth();
  }
  
}
