import API from '../../../api';

export default class extends API {
    
    static getTypes(){
        return this.get('advert-types');
    }

    static storeType(data){
        return this.post(`advert-types/create`, data)
    }

    static updateType(id, data){
        return this.put(`advert-types/${id}/edit`, data);
    }

    static deleteType(id){
        return this.delete(`advert-types/${id}/delete`);
    }

    /**
     * Store new advert
     * 
     * @param {*} data 
     */
     static store(data){
        return this.post(`adverts/create`, data);
    }

    /**
     * Update an advert
     * @param {*} id 
     * @param {*} data 
     */
    static update(id, data){
        return this.put(`adverts/${id}/edit`, data);
    }

    /**
     * Get adverts
     */
    static getAdverts(query = {}){
       return this.post('adverts/find', query);
    }

    static getActiveAdverts(){
        return this.post('adverts/active/find');
    }
    
    /**
     * Delete an advert
     * 
     * @param {*} id 
     * @returns 
     */
    static deleteAdvert(id){
        return this.delete(`adverts/${id}/delete`)
    }

    /**
     * Get adverts creted by a user
     * @param {*} userId 
     */
    static getUserAdverts(userId){
        return this.post(`adverts/user/${userId}/find`)
    }

}
