<template>
    <div>
        <modal :show="show" max-width="sm" @close="close">
            <form v-if="user" class="divide-y" @submit.prevent="submit">

                <div class="p-5">
                    <div class="flex flex-wrap space-y-3">
                        <div class="w-full flex justify-center">
                            <user-avatar :user="user" class="h-20 w-20 rounded-full cursor-pointer"  />
                        </div>
                        <div class="w-full text-center">
                            <h1 class="text-primary-900 text-lg font-medium">{{ [user.firstName, user.lastName].join(' ') }}</h1>
                        </div>
                    </div>
                </div>

                <div class="mt-3 px-3 sm:px-10 py-5 space-y-5">
                    <app-select label="Role" :options="roles" v-model="form.role" />
                    <app-switch label="Active" v-model="form.isActive" />
                </div>
            
                <div class="border-gray-200 py-5 text-center">
                    <div class="px-5 md:px-28">
                        <app-button type="submit">Save</app-button>
                    </div>
                </div>
            </form>
        </modal>
    </div>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import AppButton from '../../../components/Button.vue';
import AppSelect from '../../../components/Select';
import AppSwitch from '../../../components/Switch';
import UserAvatar from './Avatar';
import UserService from '../services';
import pageMixin from '../../../mixins/page';

export default {
    name: "UserAdminEdit",
    mixins: [pageMixin],
    components: {
        Modal, AppButton, AppSelect, AppSwitch, UserAvatar
    },
    props: {
        user: Object
    },

    data(){
        return {
            show: false,
            form: {},
            roles: [
                {
                    label: 'Customer',
                    value: 'customer'
                },
                {
                    label: 'Admin',
                    value: 'admin'
                },
            ]
        }
    },
  
    methods: {
        open(){
            this.show = true;
        },

        close(){
            this.show = false;
            this.$emit('close')
        },

        submit(){
            this.start()

            delete this.form.bankAccount;
            delete this.form.business;
            
            UserService.update(this.user.publicId, {
                ...this.form,
                isStaff: this.form.role !== 'customer'
            })
            .then(response => {
                this.$emit('user-updated', response.data)
                this.snackbar({
                    text: `User updated`
                });
                this.close()
            })
            .catch(e => {
                this.errors = e.response.data;
                this.snackbar({
                    text: 'Failed to update user.',
                    type: 'error'
                });
            })
            .finally(() => {
                this.complete();
            })
        },

    },

    watch: {
        user: {
            immediate: true,
            handler(user){
                if(user){
                    this.form = {
                        ...user,
                        interests: user.interests.map(i => i.publicId),
                        role: user.isStaff ? 'admin' : 'customer'
                    }
                }
            }
        }
    }

}
</script>