<template>
    <app-layout>
        <data-container :loading="loading">
            <user-events v-if="user" :user="user" :period="$route.params.period" @user-updated="userUpdated" />
            <div v-else class="text-center p-5 text-gray-500">
                <h2>User not found</h2>
            </div>
        </data-container>
    </app-layout>
</template>

<script>

import AppLayout from '../../../layouts/App.vue';
import UserEvents from '../widgets/Events';
import UserService from '../services';
import pageMixin from '../../../mixins/page';
import DataContainer from '../../../components/DataContainer.vue';

export default {
    name: "UserShow",
    metaInfo() {
        return {
            title: this.user ? [this.user.firstName, this.user.lastName].join(' ') : 'User',
        }
    },
    mixins: [pageMixin],
    components: {
        AppLayout, UserEvents,
        DataContainer
    },
    data(){
        return {
            loading: false,
            user: null
        }
    },

    computed:{
        id(){
            return this.$route.params.id
        }
    },

    methods: {
        getUser(){
           
            this.loading = true;

            UserService.getUser(this.id).then(response => {
                this.user = response.data;
            })
            .catch(e => {
                if(e.response.status == 404){
                    this.$store.commit('APP_SNACKBAR', {
                        type:'error',
                        text: 'User not found'
                    });
                }
            })
            .finally(() => {
                this.loading = false
            })
        },

        userUpdated(user){
            this.user = user
        }
    },

    watch:{
        id: {
            immediate: true,
            handler(){
                this.getUser();
            }
        } 
    } 
}
</script>