import multiguard  from 'vue-router-multiguard';
import middleware from '../../../router/middleware';

import ProductCategory from './views/Category.vue';
import ProductShow from './views/Show.vue';
import BusinessProducts from './views/BusinessProducts.vue'

export default [
    {
        path: '/shop/business-products',
        name: 'shop.business.products',
        component: BusinessProducts,
        beforeEnter: multiguard([middleware.auth, middleware.hasBusiness] )
    },

    {
        path: '/shop/products/:category?',
        name: 'shop.products',
        component: ProductCategory,
        beforeEnter: middleware.auth
    },

    {
        path: '/shop/product/:id',
        name: 'shop.product.show',
        component: ProductShow,
        beforeEnter: middleware.auth
    },

];