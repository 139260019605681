<template>
    <app-layout>
        <not-found  v-if="!business" message="Business not found" />
        <template v-else>
            <section class="relative">
                <div v-if="isMyBusiness" class="absolute top-5 right-5 space-y-2">
                    <div class="flex items-center divide-x divide-gray-700">
                        <avatar-input v-model="form.bannerUrl">
                            <template #heading>
                                <h4 class="text-lg">Change cover image</h4>
                            </template>
                            <button class="bg-gray-800 opacity-75 text-white px-3 py-1 rounded-l-md focus:outline-none text-sm">Edit cover</button>
                        </avatar-input>
                        <router-link :to="{name: 'business.edit'}" class="bg-gray-800 opacity-75 text-white px-3 py-1 focus:outline-none text-sm">Edit Business</router-link>
                        <app-drop-down align="right">
                            <template #trigger>
                                <button class="bg-gray-800 opacity-75 text-white px-3 py-1 rounded-r-md focus:outline-none text-sm"><i class="fe fe-more-vertical"></i></button>
                            </template>
                            <template #content>
                                <div class="divide-y">
                                    <app-drop-down-link :to="{name: 'shop.business.products'}">
                                        <span>Manage products</span>
                                    </app-drop-down-link>
                                    <app-drop-down-link :to="{name: 'shop.business.product.orders'}">
                                        <span>Manage orders</span>
                                    </app-drop-down-link>
                                </div>
                            </template>
                        </app-drop-down>
                    </div>
                    <app-button v-if="business.bannerUrl !== form.bannerUrl" 
                    size="sm" :loading="saving"
                    @click="updateBusiness"
                    >Save changes</app-button>
                </div>
                <section class="s1 bg-center bg-cover bg-no-repeat" :class="{'business-default-banner' : !form.bannerUrl}"
                :style="`${ form.bannerUrl ? `background-image: url(${form.bannerUrl})` : ''}`">
                    <div class="flex">
                        <div class="w-full lg:w-3/5 py-32 px-5 sm:px-10">
                            <div class="text-white space-y-2">
                                <div class="flex space-x-2 items-center text-sm md:text-md">
                                    <router-link :to="{name: 'shop'}" class="truncate">Home</router-link>
                                    <i class="mdi mdi-arrow-right"></i>
                                    <router-link :to="{name: 'shop.businesses'}" class="truncate">All categories</router-link>
                                    <i class="mdi mdi-arrow-right"></i>
                                    <router-link :to="{name: 'shop.businesses', query: { category:  business.businessType }}" class="truncate">{{ business.businessType }}</router-link>
                                    <i class="mdi mdi-arrow-right"></i>
                                    <p class="truncate">{{ business.name }}</p>
                                </div>
                                <div class="flex items-center">
                                    <business-avatar :business="business" class="w-16 h-16" />
                                    <h1 class="text-white text-3xl lg:text-5xl">
                                        {{ business.name }}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <section class="s2 my-5 mx-2 sm:mx-5 md:mx-10 lg:mx-20">
                <div class="flex flex-wrap">
                    <div class="w-full md:w-2/3 order-last md:order-first">
                        <div class="my-2 md:mx-2 space-y-3">
                            <div class="bg-white p-5">
                                <div v-if="business.description" v-html="business.description"></div>
                                <div v-else class="text-center text-gray-500">No description</div>
                            </div>
                            <h4 class="text-lg font-medium text-gray-700">Products/Services</h4>
                            <products-widget :src="products" />
                        </div>
                    </div>
                    <div class="w-full md:w-1/3 md:-mt-20 z-10">
                        <div class="md:mx-2">
                            <div class="bg-white rounded-md md:shadow-lg">
                                <div class="border-2 border-gray-500 border-dashed flex items-center justify-center h-36 bg-gray-200">
                                    <h1 class="text-gray-500">Map not available</h1>
                                </div>
                                <div class="p-5 space-y-2">
                                    <div class="flex gap-x-2 text-gray-500">
                                        <i class="mdi mdi-map-marker"></i>
                                        <p>{{ business.address }}</p>
                                    </div>
                                    <div class="flex gap-x-2 text-primary-900">
                                        <i class="mdi mdi-phone"></i>
                                        <p v-if="business.businessPhone"><a :href="`tel: ${business.businessPhone}`">{{ business.businessPhone }}</a> </p>
                                    </div>
                                     <div class="flex gap-x-2 text-primary-900">
                                        <i class="mdi mdi-web"></i>
                                        <p v-if="business.website"><a :href="business.website" target="_blank"> {{ business.website }}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
    </app-layout>
</template>

<script>

import AppLayout from '../../../../layouts/App.vue';
import NotFound from '../../../../components/EmptyItems.vue';
import AvatarInput from '../../../../components/AvatarInput.vue';
import AppButton from '../../../../components/Button.vue';
import AppDropDown from '../../../../components/Dropdown.vue';
import AppDropDownLink from '../../../../components/DropdownLink.vue';
import BusinessAvatar from '../../../Business/components/Avatar.vue';
import ProductsWidget from '../../Product/widgets/Products.vue';

import BussinessService from '../../../Business/services';
import ProductService from '../../Product/services';

import pageMixin from '../../../../mixins/page';

export default {
    name: "ShopBusinessShow",
    mixins: [pageMixin],
    metaInfo() {
        return {
            title: this.business ? this.business.name : 'Business'
        }
    },
    components: {
        AppLayout, NotFound, AvatarInput, AppButton, AppDropDown, AppDropDownLink,
        BusinessAvatar, ProductsWidget
    },

    data(){
        return {
            business: null,
            form: null,
            products: null,
            saving: false
        }
    },

    computed: {
        username() {
            return this.$route.params.username
        },
        isMyBusiness(){
            return this.$store.getters.business && this.$store.getters.business.publicId === this.business.publicId
        },
    },

    methods: {
        getBusiness() {
            this.start({
                opacity: 1
            });

            BussinessService.getBusinessByUsername(this.username)
            .then(response => {
                this.business = response.data;
            })
            .catch(e => {
                this.$store.commit('APP_SNACKBAR', {
                    type: 'error',
                    text: `Couldn't get business. ${e.message}`
                })
            })
            .finally(() => {
                this.complete()
            });

        },

        updateBusiness() {
            this.saving = true;
            this.$store.dispatch('BusinessUpdate', this.form)
           .then(() => {
               
                this.business.bannerUrl = this.form.bannerUrl;

                this.$store.commit('APP_SNACKBAR', {
                    text: 'Changes saved',
                })
           })
           .catch(e => {
                this.errors = e.response.data;
                this.$store.commit('APP_SNACKBAR', {
                    text: 'Changes not saved',
                    type: 'error'
                })
            })
            .finally(() => {
                this.saving = false
            })
        }
    },

    mounted(){
        
    },

    watch: {
        username: {
            immediate: true,
            handler() {
                this.getBusiness();
            }
        },
        business: {
            immediate: true,
            handler(business) {
                if(!business) {
                    this.products = null;
                    return;
                }
                this.form = {
                    ...business, owner: business.owner.publicId
                }
                this.products = ProductService.getBusinessProducts(business.publicId);
            }
        }
    }
}
</script>

<style scoped>
    .business-default-banner{
        background-image: url('../../../../assets/images/image-placeholder.jpg');
    }
    section.s1>div {
        background-color: rgba(0,0,0,.6);
    }
</style>
