<template>
    <shop>
        <div class="flex justify-end mb-2">
            <app-button @click="$refs.productForm.open()" ><i class="fe fe-plus"></i>  Create product</app-button>
            <product-form ref="productForm" @created="productCreated" @updated="productUpdated" />
        </div>
        <data-container :loading="loading">
            <app-empty-items v-if="!products.length" />
            <div v-else class="overflow-auto">
                <table class="min-w-max w-full table-auto mt-3">
                    <thead>
                        <tr class="bg-white text-gray-600 uppercase">
                            <th class="py-3 px-6 text-left">Product</th>
                            <th class="py-3 px-6 text-left">Type</th>
                            <th class="py-3 px-6 text-center">Price</th>
                            <th class="py-3 px-6 text-center">Status</th>
                            <th class="py-3 px-6 text-center">Created</th>
                            <th class="py-3 px-6 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                         <tr v-for="(product, i) in products" :key="product.publicId"
                        :class="{'bg-gray-100': i%2 === 0, 'bg-white': i%2 === 1 }"
                        >
                            <td class="py-3 px-6 text-left whitespace-nowrap">
                                <div class="flex items-center">
                                    <img :src="product.imageUrl" class="w-10 h-10 mr-2">
                                    <h4><router-link :to="{name: 'shop.product.show', params:{id: product.publicId, _product: product}}">{{product.name}}</router-link></h4>
                                </div>
                            </td>
                            <td class="py-3 px-6 text-center">
                                <span>{{ product.isService ? 'Service' : 'Product' }}</span>
                            </td>
                            <td class="py-3 px-6 text-center">
                                <span>{{ product.price }}</span>
                            </td>
                            <td class="py-3 px-6 text-center">
                                <span class="capitalize">{{ product.status}}</span>
                            </td>
                            <td class="py-3 px-6 text-center">
                                <span class="capitalize">{{ product.createdAt | datetime }}</span>
                            </td>
                            <td class="py-3 px-6 text-center">
                                <div class="flex item-center space-x-2 justify-center">
                                    <i class="fe fe-edit cursor-pointer" @click="editProduct(product)"></i>
                                    <i class="fe fe-trash cursor-pointer text-red-500" @click="deleteProduct(product)"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </data-container>
        <app-confirmation :show="confirmation.show" @close="confirmation.show = false" :onConfirm="confirmation.action">
            <template #title>Confirm</template>
            <template #content>{{ confirmation.content }}</template>
        </app-confirmation>
    </shop>
</template>

<script>
import Shop from '../../layout';
import AppButton from '../../../../components/Button.vue';
import pageMixin from '../../../../mixins/page';
import ProductForm from '../components/Form.vue';
import AppEmptyItems from '../../../../components/EmptyItems';
import DataContainer from '../../../../components/DataContainer';
import AppConfirmation from '../../../../components/Confirmation';
import ProductService from '../services';

export default {
    name: "BusinessProducts",
    metaInfo: {
        title: `Products`
    },

    mixins: [pageMixin],
    components: {
        Shop,
        AppButton,
        ProductForm,
        AppEmptyItems,
        DataContainer,
        AppConfirmation
    },
    data(){
        return {
            loading: false,
            products: [],
            confirmation: {
                show: false,
                title: null,
                content: null,
                action: () => Promise.resolve()
            },
        }
    },

    computed: {
    },

    methods: {
        editProduct(product){
            this.$refs.productForm.open(product);
        },

        deleteProduct(product){
            this.confirmation = {
                show: true,
                title: 'Delete product',
                content: `Are you sure you want to delete ${product.name}?`,
                action: () => ProductService.deleteProduct(product.publicId).then(() =>  {
                    this.products.splice(this.products.findIndex(p => p.publicId == product.publicId), 1) 
                     this.$store.commit('APP_SNACKBAR', {
                        text: `${product.name} deleted`
                    });
                }),
            }
        },

        productCreated(product){
            this.products.push(product);
            this.$refs.productForm.close()
        },

        productUpdated(updatedProduct){

            this.products = this.products.map(product => {
                if(product.publicId == updatedProduct.publicId)  return {
                        ...updatedProduct,
                        category: product.category
                    }
                else return product;
            })
            this.$refs.productForm.close();
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(){
                this.loading = true;
                ProductService.getBusinessProducts(this.$store.getters.business.publicId)
                .then(response => {
                    this.products = response.data.items
                })
                .catch(() => {
                    // this.$store.commit('APP_SNACKBAR', {
                    //     type: 'error',
                    //     text: `Couldn't get products. ${e.message}`
                    // })
                })
                .finally(() => {
                    this.loading = false
                })
            }
        },
    }
}
</script>