<template>
    <app-layout>
        <board :user="$store.getters.auth" bg="transparent">
             <template #title>
                <h1 class="text-2xl font-medium mb-2">Ticket Orders - {{ orders.totalCount }}</h1>
            </template>
            <data-container :loading="loading">
                <app-empty-items v-if="!orders.totalCount" />
                <ticket-orders-table v-else :orders="orders" />
            </data-container>
        </board>
    </app-layout>
</template>
<script>
import AppLayout from '../../../layouts/App';
import DataContainer from '../../../components/DataContainer.vue';
import AppEmptyItems from '../../../components/EmptyItems.vue';
import Board from '../widgets/Board.vue';
import TicketOrdersTable from '../../Ticket/widgets/OrdersTable.vue';

import TicketService from '../../Ticket/services';

export default {
    name: "EventTicketOrders",
    metaInfo() {
        return {
            title: `My Ticket Orders (${this.orders.totalCount || 0})`,
        }
    },
    components: {
        AppLayout,
        DataContainer,
        AppEmptyItems,
        Board,
        TicketOrdersTable
    },
    data(){
        return {
            orders: {},
            loading: false
        }
    },

    computed: {
       
    },

    methods: {
        getEventTicketOrders(){

            this.loading = true;

            TicketService.getUserTicketOrders(this.$store.getters.auth.publicId)
            .then(response => {
                this.orders = response.data
            })
            .catch(e => {
                this.$store.commit('APP_SNACKBAR', {
                    type: 'error',
                    text: `Couldn't get orders. ${e.message}`
                })
            })
            .finally(() => {
                this.loading = false;
            });

        },
    },

    watch: {
        $route: {
            immediate: true,
            handler(){
                this.getEventTicketOrders()
            }
        }
    }
}
</script>