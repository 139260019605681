<template>
    <portal to="modal">
        
        <transition leave-active-class="duration-200">
            <div v-show="show">
                <!-- close -->
                <div 
                class="fixed h-8 w-8 top-2 right-2 rounded-full bg-primary-900 flex items-center justify-center shadow-lg cursor-pointer" 
                style="z-index: 99" title="close" 
                @click="close">
                    <i class="fe fe-x  text-white text-lg"></i>
                </div>

                <transition enter-active-class="ease-out duration-300"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="ease-in duration-200"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0">

                    <div v-show="show"
                    class="fixed inset-0 transform transition-all overflow-y-auto"
                    style="background-color: rgba(0,0,0,.5); z-index: 98"
                    >
                        <div class="px-4 flex h-screen flex-wrap h-full items-center justify-center">
                            <div class="py-5 w-full" :class="maxWidthClass">
                                <div 
                                    class="bg-white rounded-sm shadow-xl transform transition-all" 
                                    >
                                    <slot></slot>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </portal>
</template>

<script>
    export default {
        props: {
            show: {
                default: false
            },
            maxWidth: {
                default: '2xl'
            },
            closeable: {
                default: true
            },
        },

        methods: {
            close() {
                if (this.closeable) {
                    this.$emit('close')
                }
            }
        },

        watch: {
            show: {
                immediate: true,
                handler: (show) => {
                    if (show) {
                        document.body.style.overflow = 'hidden'
                    } else {
                        document.body.style.overflow = 'auto'
                    }
                }
            }
        },

        created() {
            const closeOnEscape = (e) => {
                if (e.key === 'Escape' && this.show) {
                    this.close()
                }
            }

            document.addEventListener('keydown', closeOnEscape)

            this.$once('hook:destroyed', () => {
                document.removeEventListener('keydown', closeOnEscape)
            })
        },

        computed: {
            maxWidthClass() {
                return {
                    'sm': 'sm:max-w-sm',
                    'md': 'sm:max-w-md',
                    'lg': 'sm:max-w-lg',
                    'xl': 'sm:max-w-xl',
                    '2xl': 'sm:max-w-2xl',
                    '3xl': 'sm:max-w-3xl',
                    '4xl': 'sm:max-w-4xl',
                    '5xl': 'sm:max-w-5xl',
                }[this.maxWidth]
            }
        }
    }
</script>
