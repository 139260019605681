<template>
    <shop :include-categories="false">
        <div class="text-center">
            <h1>Business order details will be here</h1>
        </div>
    </shop>
</template>

<script>
import Shop from '../../layout.vue';
export default {
    name: "BusinessOrder",
    components: {
        Shop
    }
}
</script>