export default {
    methods: {
        uploadFileToCloudinary(file) {
            return new Promise(function (resolve, reject) {
                const CLOUDINARY_URL = process.env.VUE_APP_CLOUDINARY_URL;
                const CLOUDINARY_UPLOAD_PRESET = process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESEST;

                let formData = new FormData();
                formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
                formData.append('file', file);

                let request = new XMLHttpRequest();
                request.open('POST', CLOUDINARY_URL, true);
                request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

                request.onreadystatechange = () => {
                    // File uploaded successfully
                    if (request.readyState === 4 && request.status === 200) {
                        let response = JSON.parse(request.responseText);
                        resolve(response);
                    }

                    // Not succesfull, let find our what happened
                    if (request.status !== 200) {
                        let response = JSON.parse(request.responseText);
                        let error = response.error.message;
                        reject(error);
                    }

                };

                request.onerror = (err) => {
                    alert('error: ' + err);
                    reject(err);
                };
                request.send(formData);
            });
        }

    }
}