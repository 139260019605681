<template>
    <admin>
        <template #header>
            <div class="py-2">
                <h2 class="text-xl">Adverts</h2>
            </div>
        </template>

        <app-tabs-panel v-model="tab" :items="tabs" >
            <template #content="{ active }">
                <data-container :loading="loading">

                    <!-- Adverts -->
                    <template v-if="active.route === 'admin.adverts'">
                        <app-empty-items v-if="!adverts.totalCount" />
                        <div v-else class="overflow-auto">
                            <table class="min-w-max w-full table-auto">
                                <thead>
                                    <tr class="bg-white text-gray-600 uppercase">
                                        <th class="py-3 px-6 text-left">Title</th>
                                        <th class="py-3 px-6 text-left">Starts</th>
                                        <th class="py-3 px-6 text-left">Ends</th>
                                        <th class="py-3 px-6 text-left">Status</th>
                                        <th class="py-3 px-6 text-left">Active</th>
                                        <th class="py-3 px-6 text-left">Created</th>
                                        <th class="py-3 px-6 text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="">
                                    <tr v-for="(advert, i) in adverts.items" :key="advert.publicId" 
                                    :class="{'bg-gray-100': i%2 === 0, 'bg-white': i%2 === 1 }"
                                    >
                                        <td class="py-3 px-6 text-left whitespace-nowrap">
                                            <div class="flex items-center">
                                                <span class="font-medium capitalize">{{ advert.title }}</span>
                                            </div>
                                        </td>
                                        <td class="py-3 px-6 text-left">
                                            <div class="flex items-center">
                                                <span>{{ advert.startAt | datetime }}</span>
                                            </div>
                                        </td>
                                        <td class="py-3 px-6 text-left">
                                            <div class="flex items-center">
                                                <span>{{ advert.endAt | datetime }}</span>
                                            </div>
                                        </td>
                                        <td class="py-3 px-6 text-left">
                                            {{ advert.status }}
                                        </td>
                                        <td class="py-3 px-6 text-left">
                                            <div class="flex items-center">
                                                <span class="">{{ advert.isActive ? 'ACTIVE' : 'INACTIVE'}}</span>
                                            </div>
                                        </td>
                                        <td class="py-3 px-6 text-left">
                                            <div class="flex items-center">
                                                <span>{{ advert.createdAt | datetime }}</span>
                                            </div>
                                        </td>

                                        <td class="py-3 px-6 text-center">
                                            <div class="flex item-center space-x-2 justify-center">
                                                <a href="#" @click.prevent="$refs.advertForm.open(advert)"><i class="mdi mdi-eye"></i></a>
                                                <a href="#" @click.prevent="deleteAdvert(advert)"><i class="mdi mdi-delete text-red-500"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <advert-form ref="advertForm" @created="advertCreated" @updated="advertUpdated" />
                    </template>

                    <!-- Advert types -->
                    <template v-if="active.route === 'admin.advert.types'" >
                        <div class="flex justify-between items-center py-2">
                            <h4 class="font-bold">Advert Types ({{ types.length }})</h4>
                            <app-button @click="$refs.typeForm.open()" ><i class="fe fe-plus"></i> <span class="hidden sm:inline">Create Type</span> </app-button>
                        </div>
                        <app-empty-items v-if="!types.length" />
                        <div v-else class="overflow-auto">
                            <table class="min-w-max w-full table-auto">
                                <thead>
                                    <tr class="bg-gray-200 text-gray-600 uppercase">
                                        <th class="py-3 px-6 text-left">Name</th>
                                        <th class="py-3 px-6 text-left">Price</th>
                                        <th class="py-3 px-6 text-left">Created</th>
                                        <th class="py-3 px-6 text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="">
                                    <tr v-for="(type, i) in types" :key="type.publicId" 
                                    :class="{'bg-gray-100': i%2 === 0, 'bg-white': i%2 === 1 }"
                                    >
                                        <td class="py-3 px-6 text-left whitespace-nowrap">
                                            <div class="flex items-center">
                                                {{ type.name }}
                                            </div>
                                        </td>
                                        <td class="py-3 px-6 text-left whitespace-nowrap">
                                            {{ type.price }}
                                        </td>
                                        <td class="py-3 px-6 text-left whitespace-nowrap">
                                            {{ type.createdAt | datetime('day/month/year hour:minute') }}
                                        </td>
                                        <td class="py-3 px-6 text-center">
                                            <div class="flex item-center space-x-2 justify-center">
                                                <a href="#" @click.prevent="$refs.typeForm.open(type)"><i class="mdi mdi-pencil"></i></a>
                                                <a href="#" @click.prevent="deleteType(type)"><i class="mdi mdi-delete text-red-500"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <advert-type-form ref="typeForm" @created="typeCreated" @updated="typeUpdated" />
                    </template>

                </data-container>
            </template>
        </app-tabs-panel>
        <app-confirmation :show="confirmation.show" @close="confirmation.show = false" :onConfirm="confirmation.action">
            <template #title>Confirm</template>
            <template #content>{{ confirmation.content }}</template>
        </app-confirmation>
       
    </admin>
</template>

<script>
import Admin from '../../../layouts/Admin.vue';
import AppEmptyItems from '../../../components/EmptyItems';
import DataContainer from '../../../components/DataContainer';
import AdvertForm from '../../Advert/components/AdvertForm';
import AppConfirmation from '../../../components/Confirmation';
import AppTabsPanel from '../../../components/TabsPanel.vue';
import AdvertTypeForm from '../../Advert/components/TypeForm.vue';
import AppButton from '../../../components/Button';

import AdvertService from '../../Advert/services';

export default {
    name: "AdminAdverts",
    metaInfo() {
        let title = 'Adverts';
        if(this.$route.name === 'admin.adverts') {
            title = `Adverts (${this.adverts.totalCount})`;
        }
        if(this.$route.name === 'admin.advert.types') {
            title = `Advert Categories (${this.types.length})`;
        }

        return {
            title
        };
    },
    components: {
        Admin,
        AppEmptyItems,
        DataContainer,
        AdvertForm,
        AppConfirmation,
        AdvertTypeForm,
        AppTabsPanel,
        AppButton
    },
    data(){
        return {
            loading: false,
            adverts: {
                totalCount: 0
            },
            types: [],
            confirmation: {
                show: false,
                title: null,
                content: null,
                action: () => Promise.resolve()
            },
            tab: 0,
            tabs: [
                {
                    title: 'Adverts',
                    route: 'admin.adverts',
                },
                {
                    title: 'Types',
                    route: 'admin.advert.types',
                },
            ]
        }
    },

    methods:{
        getAdverts(){
            this.loading = true;
            AdvertService.getAdverts()
            .then(response => {
                this.adverts = response.data
            })
            .catch(() => {
                // this.$store.commit('APP_SNACKBAR', {
                //     type: 'error',
                //     text: `Couldn't get adverts. ${e.message}`
                // })
            })
            .finally(() => {
                this.loading = false
            })
        },

        advertCreated(advert){
            this.$refs.advertForm.close()
            this.adverts.items.push(advert);
        },

        advertUpdated(updatedAdvert){
            this.$refs.advertForm.close()
            this.adverts.items = this.adverts.items.map(advert => {
                if(advert.publicId == updatedAdvert.publicId)  return updatedAdvert;
                else return advert;
            })
        },

        deleteAdvert(advert){
            this.confirmation = {
                show: true,
                title: 'Delete advert',
                content: `Are you sure you want to delete the advert ?`,
                action: () => AdvertService.deleteAdvert(advert.publicId).then(() => {
                    this.adverts.items.splice(this.adverts.items.findIndex(c => c.publicId == advert.publicId), 1);
                    this.$store.commit('APP_SNACKBAR', {
                        text: `Advert deleted`
                    });
                }),
            }
        },

        getAdvertTypes(){
            this.loading = true;
            this.$store.dispatch('getAdvertTypes').then(types => {
                this.types = types;
            })
            .finally(() => {
                this.loading = false;
            })
        },

        typeCreated(type){
            this.$refs.typeForm.close()
            this.types.push(type);
        },

        typeUpdated(updatedType){
            this.$refs.typeForm.close()
            this.types = this.types.map(type => {
                if(type.publicId == updatedType.publicId)  return updatedType;
                else return type;
            })
        },

        deleteType(type){
            this.confirmation = {
                show: true,
                title: 'Delete type',
                content: `Are you sure you want to delete ${type.name}?`,
                action: () => AdvertService.deleteType(type.publicId).then(() => {
                    this.types.splice(this.types.findIndex(c => c.publicId == type.publicId), 1)
                    this.$store.commit('APP_SNACKBAR', {
                        text: `${type.name} deleted`
                    });
                } ),
            }
        },

    },


    watch: {
        $route: {
            immediate: true,
            handler(route) {
                this.tab =  this.tabs.findIndex(tab => tab.route === route.name) ?? 0;
                if(route.name == 'admin.adverts') this.getAdverts();
                if(route.name == 'admin.advert.types') this.getAdvertTypes();
            }
        },
        tab: {
            handler(tab) {
                this.$router.push({
                    name: this.tabs[tab].route
                })
            }
        }
    },
}
</script>