<template>
    <div>
        
        <div class="text-center">
            <h1 class="text-2xl text-primary-900">Reset Password</h1>
        </div>
        <form v-if="!codeSent" @submit.prevent="sendCode">
            <div class="px-10 py-5 space-y-5">
                <h4 class="text-md text-gray-500 text-center">Kindly insert your email address. You will receive an email with instructions from us.</h4>
                <app-input label="E-mail" v-model="form.email" type="email" :error="error('email')" prepend-icon="mail" required />
                <app-button type="submit" large block>Submit</app-button>
                 <div class="text-center">
                    <a href="#" class="text-primary-900" @click.prevent="$emit('signin')">Back to signin</a>
                </div>
            </div>
        </form>

        <form v-else @submit.prevent="resetPassword">
            <div class="px-10 py-5 space-y-5">
                <h4 class="text-md text-gray-500 text-center">Provide the code that was sent to your email</h4>
                <app-input label="Code" v-model="form.code" type="text" :error="error('code')" prepend-icon="code" required />
                <app-input label="New password" v-model="form.password" type="password" :error="error('password')" prepend-icon="lock" required />
                <app-input label="Repeat new password" v-model="form.password_confirmation" type="password" prepend-icon="lock" required />
                <div class="px-5 md:px-28">
                    <app-button type="submit" large block>Reset Password</app-button>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
import AppInput from '../../../components/Input';
import AppButton from '../../../components/Button';

import pageMixin from '../../../mixins/page';

import Auth from '../services';

export default {
    name: "ForgotPasswordForm",
    mixins: [pageMixin],
    data(){
        return {
            modal: false,
            codeSent: false,
            form: {
                code: null,
                email: null,
                password: null
            }
        }
    },
    props: {
        onHomePage: {
            type: Boolean,
            default: () => false
        }
    },

    components: {
        AppInput,
        AppButton
    },
    methods: {
        sendCode(){
            this.start();
            this.errors = null,

            Auth.SendPasswordReset(this.form.email)
            .then(() => {
                this.snackbar({text: 'A code has been sent to your mail'});
                this.codeSent = true;
            })
            .catch(e => {
                this.errors = e.response.data
            })
            .finally(() => {
                 this.complete();
            })
        },

        resetPassword(){
            this.start();
            this.errors = null,

            Auth.ResetPassword({...this.form})
            .then(() => {
                this.snackbar({text: 'Your password has been reset. You can now sign in with your new password'});
                this.$emit('signin')
            })
            .catch(e => {
                this.errors = e.response.data
            })
            .finally(() => {
                 this.complete();
            })
        }
    }
}
</script>