import middleware from '../../router/middleware';
import Signin from './views/Signin.vue'
import Signup from './views/Signup.vue'

export default [
    {
        path: '/signin',
        name: 'signin',
        component: Signin,
        beforeEnter: middleware.guest
    },

    {
        path: '/signup/:step?',
        name: 'signup',
        component: Signup,
        beforeEnter: middleware.guest
    },

]