<template>
  <div ref="app">  
    
     <!-- Main content -->
    <main class="min-h-screen">
      <div v-if="appReady">      
        <router-view />
      </div>
    </main>
    
    
    <!-- Snackar -->
    <app-snackbar />

    <!-- Service worker update  -->
    <app-service-worker />

    <!-- Modal Portal -->
    <portal-target name="modal" multiple>
    </portal-target>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapGetters, mapState } from 'vuex';
  import AppSnackbar from './components/Snackbar.vue';
  import AppServiceWorker from './components/ServiceWorker.vue';

  import pageMixin from './mixins/page';

  import Auth from './classes/Auth';

  export default {
    name: "App",
    mixins: [pageMixin],
    metaInfo() {
      return {
        titleTemplate: `%s - ${this.appName}`,
        htmlAttrs: {
          lang: 'en',
          amp: true
        }
      }
    },
    components: {
      AppSnackbar, AppServiceWorker
    },

    data(){
      return {
       
      }
    },
    computed: {
      ...mapState([
        'cache'
      ]),
       ...mapGetters([
        'appReady', 'auth'
      ]),
      
      appName(){
        return process.env.VUE_APP_NAME;
      },

      environment() {
        return process.env.VUE_APP_ENV;
      },

      inProduction() {
        return this.environment === 'production'
      }
    },

    created(){
      if(!this.inProduction) {
        console.log('Access token--->', Auth.get('access'))
      } 

      this.start({
        opacity: 1
      });     

      // Intercept axios requests
       axios.interceptors.request.use(request => {
        if(!this.inProduction) {
          console.log('request--->', request);
        }

        if(!request.headers.common['X-Client']) throw ({ message: "Client token is missing" , response: {data: {message: "Client token is missing" }} });
        return request;
        }, error => {

          if(!this.inProduction) {
            console.log('Request error--->', error.message, error.response)
          }
          this.$store.commit('APP_ERROR', error);
          return Promise.reject(error);
        }
      );

      // Intercept axios responses
      axios.interceptors.response.use(response => {
        if(!this.inProduction) {
          console.log('response--->', response);
        }
        return response;
      }, (error)  => {

        if(error.response){
          if(!this.inProduction) {
            console.log('response error---->', error.response );
          }
          // If there is an error message
          if(error.response.data && error.response.data.message){
            this.$store.commit('APP_ERROR', {message: error.response.data.message});
          }
          // If authenticated error is returned
          if (error.response.status === 401 || error.response.status == 423) {
            if((error.response.data && error.response.data.code == 'user_inactive') || error.response.status == 423){
                this.$store.commit('APP_ERROR', {
                  message: 'Your account is currently inactive.'
                })
                if(this.$route.name !== 'signup' && this.$route.params.step !== 'verification'){
                   this.$router.push({
                    name: 'signup',
                    params: {
                      step: 'verification'
                    }
                  })
                }
               
            }else{
                // if you ever get an unauthorized, logout the user
                this.$store.dispatch('UserLogout').then(() => {

                  this.$store.commit('APP_SNACKBAR', {
                    text: 'You need to sign in first'
                  });

                  if(this.$route.name !== 'signin'){
                      this.$router.push({
                      name: 'signin',
                      query: {
                        redirect: this.$route.fullPath
                      }
                    })
                  }
                })
            }
          }
          else if(error.response.status == 403){
            this.$store.commit('APP_ERROR', {message:  error.response.data && error.response.data.detail ? error.response.data.detail : 'You are not allowed to perform the operation'})
          }
          else if(error.response.status == 500){
            this.$store.commit('APP_ERROR', {message:  error.response.data && error.response.data.message ? error.response.data.message : 'Oops!! There was a server error'})
          }
        }
        else{
          this.$store.commit('APP_SNACKBAR', {text: 'Connection problem. Check your internet', type: 'error', persist: true});
        }
          
        return Promise.reject(error);
      });

      // If there is an access 
      if(Auth.isAuthenticated() && !this.auth){
        this.$store.dispatch('getLoggedInUser')
        .then(() => {
          this.$store.dispatch('LoadCache');
        })
        .catch((e) => {
          if(!this.inProduction) {
            console.log('eeeeerrrorrrr---->>>>', e);
          }
        })
        .finally(() => {
            this.complete();
        }) 
      }else{
        this.$store.commit('APP_READY', true);
        this.complete();
      }
    },

    beforeDestroy(){
      this.$eventHub.$off('signed-in')
    }

  }
</script>

<style>
    .bg-themed{
        background-image: url('./assets/svg/background.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
</style>