<template>
  <app-layout>
    <div class="mx-5 md:mx-10 lg:mx-32 py-10 space-y-5">
      <h1 class="text-primary-900 text-2xl font-medium">Terms of Use</h1>
      <div class="space-y-5">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus laoreet mollis pharetra. 
          Suspendisse consequat augue euismod mauris auctor, a gravida tortor accumsan. 
          Maecenas a purus luctus, maximus massa vitae, ultricies erat. Curabitur quis diam varius, 
          vestibulum risus quis, convallis nisl. Aliquam erat volutpat. Duis porta viverra lectus, 
          sed condimentum orci luctus sit amet. Quisque nec lacinia odio, a fermentum felis. 
          Sed mollis sem et ligula volutpat, non pretium dui congue. Integer non tortor felis.
        </p>
        <p>
          Cras venenatis odio non finibus porttitor. Sed dapibus sem aliquet est tincidunt semper. 
          Nunc in condimentum quam, at suscipit lacus. Quisque porttitor ipsum odio, ut malesuada elit auctor ut. 
          Aliquam a ultrices felis, sagittis feugiat leo. Curabitur vulputate dolor neque, nec porta mi auctor non. 
          Cras tempor, lorem nec ultricies laoreet, odio tortor mattis diam, eu euismod augue nisl congue ipsum. 
          Maecenas ultricies lectus in diam posuere, a dictum ligula accumsan. Vivamus aliquam nunc ac venenatis viverra. 
          Donec dictum mollis pulvinar. Phasellus et magna urna. Vestibulum consequat est vel turpis pharetra, nec lacinia lorem commodo. 
          Suspendisse suscipit lectus in ultrices porta. Sed tincidunt magna a mauris eleifend pulvinar.
        </p>
        <p>
          Praesent ex tortor, egestas at porta eu, rhoncus ultricies leo. Quisque maximus mauris tristique odio tempor, 
          eu malesuada velit bibendum. Donec sodales mattis blandit. Vivamus at massa nibh. Donec ac fermentum orci. 
          Praesent a turpis vitae sem cursus vulputate sit amet sit amet ex. Maecenas varius libero ac dolor lobortis bibendum. 
          Vivamus convallis vitae eros nec tempus. Vestibulum hendrerit ullamcorper consectetur. 
          Nulla ultricies vulputate urna id volutpat.
        </p>
        <p>
        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras vitae pellentesque nisi. 
        Fusce aliquam tellus fringilla accumsan laoreet. Phasellus quis semper neque, accumsan porttitor magna. 
        Maecenas quis vehicula nibh, non elementum purus. Vestibulum consequat ultrices libero, cursus malesuada nulla egestas et. 
        Maecenas laoreet elit nunc, in convallis purus fermentum sed. Maecenas lacinia volutpat turpis ut aliquam. 
        </p>
        <p>
          Phasellus non congue magna. Quisque eget augue et metus tempor tincidunt id a est. Pellentesque magna lorem, 
          lacinia vitae rhoncus vitae, tempor a ex. In hac habitasse platea dictumst. Integer cursus placerat tortor non iaculis.
          Vestibulum pulvinar feugiat dolor elementum pulvinar. Praesent semper, felis dapibus pretium hendrerit, 
          leo urna auctor sapien, vitae molestie sem lorem congue nulla. Vestibulum tincidunt nibh ante, non varius sapien interdum in. 
          Vivamus vehicula lectus magna, sit amet venenatis eros ultrices tristique. Pellentesque dapibus dignissim accumsan. 
          Pellentesque blandit, nisi vel dignissim blandit, risus libero luctus erat, eu commodo enim risus quis justo. 
          In fringilla et augue sed congue. Aenean sed felis odio. 
        </p>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from '../layouts/App.vue';

export default {
  name: "Terms",
  metaInfo: {
    title: 'Terms of use'
  },
  components: {
    AppLayout
  }
}
</script>
