<template>
    <manager ref="manager" :slug="slug" #default="{ tab }" @event="eventFetched">
        <div class="p-3">
            <div v-if="tab.active.id === 'info'">
                <h4 class="font-bold text-primary-900 text-xl mb-3">Update Event</h4>
                <event-form :event="event"  @updated="eventUpdated" />
            </div>
            <div v-if="tab.active.id === 'tickets'">
                <h4 class="font-bold text-primary-900  text-xl mb-3">Event Tickets</h4>
                <event-tickets :event="event" />
            </div>
            <div v-if="tab.active.id === 'ticket-orders'">
                <h4 class="font-bold text-primary-900  text-xl mb-3">Event Tickets Orders</h4>
                <event-ticket-orders :event="event" />
            </div>
        </div>
    </manager>
</template>

<script>
import Manager from '../layouts/Manager.vue';
import EventForm from '../components/Form.vue';
import EventTickets from '../Managements/Tickets.vue';
import EventTicketOrders from '../Managements/TicketOrders.vue';

import pageMixin from '../../../mixins/page';
import eventMixin from '../mixins/event';


export default {
    name: "EventManager",
    metaInfo() {
        return {
            title: `Manage Event - ${this.event ? this.event.name : ''}`
        }
    },
    mixins: [pageMixin, eventMixin],
    components: {
        Manager, EventForm,
        EventTickets, EventTicketOrders,
    },
    data() {
        return {
            loading: false,
            event: null,
        }
    },

    computed: {
        slug(){
            return this.$route.params.slug
        },
    },

    methods: {

        eventFetched(event) {
            this.event = event;
        },

        eventUpdated() {
            // reload the event
            this.$refs.manager.getEvent();
        }
    },


}
</script>