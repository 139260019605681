// import multiguard  from 'vue-router-multiguard';
// import middleware from '../../../router/middleware';

import Businesses from './views/List.vue';
import Categories from './views/Categories.vue';
import Business from './views/Show.vue';

export default [
    {
        path: '/shop/businesses',
        name: 'shop.businesses',
        component: Businesses,
        // beforeEnter: middleware.auth
    },

    {
        path: '/shop/business-categories',
        name: 'shop.business.categories',
        component: Categories,
        // beforeEnter: middleware.auth
    },

    {
        path: '/shop/business/:username',
        name: 'shop.business.show',
        component: Business,
        // beforeEnter: middleware.auth
    },
    

];