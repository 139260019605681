<template>
    <data-container :loading="loading">
        <h1 class="hidden sm:block font-bold text-lg">Categories</h1>
        <div class="fixed left-0 right-0 -mt-1 sm:mt-0 w-full z-5 sm:relative">
                <a @click.prevent="expandCategories = !expandCategories" href="#" class="block w-full p-2 bg-primary-900 text-white sm:hidden flex justify-between items-center">
                <span>{{ active ? active.name : 'Categories' }}</span>
                <i :class="`fe fe-chevron-${expandCategories ? 'up' : 'down'}`"></i>
            </a>
            <!-- Categories -->
            <div class="w-full divide-y overflow-y-auto" :class="{'sm:relative shadow-md sm:shadow-none': expandCategories, 'hidden sm:block': !expandCategories  }" style="max-height: 50vh">
                <template v-if="categories && categories.length">
                    <router-link :to="{name: 'events'}" class="block p-2" :class="{'bg-primary-900 text-white': !$route.params.category && $route.name == 'events', 'bg-white text-primary-900': !(!$route.params.category && $route.name == 'events')}">
                        Latest
                    </router-link>
                    <router-link :to="{name: 'events', params: {category: category.slug}}" class="block p-2" :class="{'bg-primary-900 text-white': active && category.publicId == active.publicId, 'bg-white text-primary-900' : !(active && category.publicId == active.publicId)}" v-for="(category, c) in categories" :key="c">
                        {{ category.name }}
                    </router-link>
                </template>
                <template v-else>
                    <div class="p-5-text-center text-gray-500 text-sm">
                        No category
                    </div>
                </template>
            </div>
        </div>
        <div>

        </div>
    </data-container>
</template>

<script>
import DataContainer from '../../../components/DataContainer';
export default {
    name: 'EventCategories',
    components: {
        DataContainer
    },
    props: {
        active: Object,
        collapse: Boolean
    },
    data(){
        return {
            loading: false,
            categories: null,
            expandCategories: false,
        }
    },
    methods: {
        getEventCategories(){
            this.loading = true;
            this.$store.dispatch('getEventCategories').then(categories => {
                this.categories = categories;
                this.$emit('loaded', categories);
            })
            .finally(() => {
                this.loading = false;
            })
        },
    },
    mounted(){
        this.getEventCategories();
    },
    watch: {
        collapse: {
            immediate: true,
            handler(collapse){
                this.expandCategories = collapse
            }
        }
    }
}
</script>