<template>
    <div class="shadow-md rounded-sm absolute w-64 z-2 bg-white">
        <data-container :loading="loading">
            <div class="divide-y">
                <!-- Categories -->
                <h4 class="p-3 text-gray-500 font-bold">Product Categories</h4>
                <div class="w-full divide-y overflow-y-auto" style="height: 50vh">
                    <template v-if="categories && categories.length">
                        <router-link :to="{name: 'shop.products', params: {category: category.publicId }}" class="block p-2 bg-white hover:bg-primary-900 hover:text-white" :class="{'font-bold': active && category.publicId == active.publicId}" v-for="(category, c) in categories" :key="c">
                            {{ category.name }}
                        </router-link>
                    </template>
                    <template v-else>
                        <div class="p-5 text-center text-gray-500 text-sm">
                            No category
                        </div>
                    </template>
                </div>
            </div>
        </data-container>
    </div>
</template>

<script>
import DataContainer from '../../../../components/DataContainer';
export default {
    name: 'ProductCategories',
    components: {
        DataContainer
    },
    props: {
        active: Object,
        collapse: Boolean
    },
    data(){
        return {
            loading: false,
            categories: null,
            expandCategories: false,
        }
    },
    methods: {
        getProductCategories(){
            this.loading = true;
            this.$store.dispatch('getProductCategories').then(categories => {
                this.categories = categories;
                this.$emit('loaded', categories);
            })
            .finally(() => {
                this.loading = false;
            })
        },
    },
    mounted(){
        this.getProductCategories();
    },
    watch: {
        collapse: {
            immediate: true,
            handler(collapse){
                this.expandCategories = collapse
            }
        }
    }
}
</script>