<template>
    <div>
        <component :is="tag" :show="modal" max-width="md" @close="close">
            <div class="p-3" v-if="tag === 'modal'">
                <h4 class="text lg font-medium text-primary-900"><i class="fe fe-credit-card"></i>  Credit/Debit Card</h4>
            </div>
            <div :class="{'p-3': tag === 'modal'}">
                <data-container :loading="initializing">
                    <template #loading>
                        <div class="py-5 text-gray-500 text-center">
                            Hold on while we initialize payment gateway...
                        </div>
                    </template>
                    <app-alert v-if="initError" bg="red-100" color="red-500">Unable to initialize your payment right now</app-alert>
                </data-container>

                <form @submit.prevent="submit" :class="{'hidden': initializing || initError != null }">
                    <div class="my-3">
                        <div  class="my-3" :id="`card-element-${_uid}`">
                        <!-- A Stripe Element will be inserted here. -->
                        </div>
                        <!-- Used to display Element errors. -->
                        <div id="card-errors" class="text-danger" role="alert">{{cardError}}</div>
                    </div>
                    <div class="my-5">
                         <app-alert 
                            v-if="processing"
                            bg="gray-200"
                            color="gray-500"
                        >
                            {{process}}
                        </app-alert>
                    </div>
                   <div class="flex gap-x-3">
                        <app-button type="button" bg="transparent" color="red-500" @click="cancel" invert>Cancel</app-button>
                        <app-button type="submit" :loading="processing" :disabled="initError != null" ><slot v-bind="{ currency, amount }">Pay {{ currency }}{{ amount }} </slot></app-button>
                   </div>
                </form>
            </div>
        </component>
    </div>
</template>

<script>
    import Modal from '../../../components/Modal.vue';
    import AppButton from '../../../components/Button.vue';
    import DataContainer from '../../../components/DataContainer';
    import AppAlert from '../../../components/Alert';

    export default {
        name: "StripePayment",
        components: {
            Modal, AppButton, DataContainer, AppAlert
        },
        data(){
            return {
                modal: false,
                process: '',
                initError: null,
                cardError: null,
                stripe: null,
                card: null,
                initializing: false,
                intent: null,
            }
        },
        props: {
            publishableKey: {
                type: String,
                required: true,
            },
            amount: {
                type: Number,
                required: true,
            },
            currency: {},

            email: {
                type: String,
                required: true,
            },
            tag: {
                type: String,
                default: () => 'modal'
            },
            paymentIntent: {
                type: Object
            },
        },

        computed: {
            processing(){
                return this.process === '' ? false : true
            },
            amountInKobo(){
                return this.amount * 100;
            },
        },

        methods: {
            open(intent){
                if(intent){
                    this.intent = intent;
                    this.modal = true;
                    this.initialize();
                }
            },

            close(){
                this.modal = false;
                this.process = '';
                this.card = null;
                this.intent = null;
            },

            initialize()
            {
                this.initializing = true;
                this.setStripe()
                .then(() => {
                    const elements = this.stripe.elements();
                    const style = {
                        base: {
                            fontSize: '16px',
                            color: '#32325d',
                        },
                    };
                    // Create an instance of the card Element.
                    this.card = elements.create('card', {style: style});
                    // Add an instance of the card Element into the `card-element` <div>.
                    this.card.mount(`#card-element-${this._uid}`);

                })
                .catch(e => {
                    this.$store.commit('APP_ERROR', e);
                    console.log(e, e.message)
                    this.initError = e;
                })
                .finally(() => {
                    this.initializing = false;
                })
            },

            setStripe()
            {
                 /* eslint-disable */
                return new Promise((resolve, reject) => {                    
                    if(document.querySelector("[data-stripe='true']") !== null){
                      
                        this.stripe = Stripe(this.publishableKey);
                        this.$emit('gateway-initailized');
                        resolve();
                        return;
                    }
                    this.process = `Initializing payment gateway...`;
                    let stripeScript = document.createElement('script')
                    stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
                    document.head.appendChild(stripeScript);
                    stripeScript.onload = () => {
                        this.$emit('gateway-initailized');
                        stripeScript.setAttribute('data-stripe', 'true');
                        this.process = '';
                        this.stripe = Stripe(this.publishableKey);
                        resolve();
                    };
                    stripeScript.onerror = (e) => {
                        this.process = '';
                        reject(e);
                    }; 
                })
            },

            submit()
            {
               const vm = this
               this.process = "Processing your payment...";
                // Confirm payment
                vm.stripe.confirmCardPayment(this.intent.clientSecret, {
                    payment_method: {
                        card: this.card,
                        billing_details: {
                            name: [this.$store.getters.auth.firstName, this.$store.getters.auth.lastName].join(' ')
                        }
                    }
                }).then(function(result) {
                    if (result.error) {
                        vm.$emit('error',  result.error);
                    } else {
                    // The payment has been processed!
                    if (result.paymentIntent.status === 'succeeded') {
                            vm.$emit('success');
                        }
                    }
                })
                .finally(() => {
                    this.process = "";
                })
            },

            cancel(){
                this.$emit('aborted');
                this.close();
            }
        },

        watch: {
            paymentIntent: {
                immediate: true,
                handler(intent) {
                    if (intent) this.open(intent);
                    else this.close();
                }
            }
        }
       
    }
</script>

<style scoped>
    /* *
    * The CSS shown here will not be introduced in the Quickstart guide, but shows
    * how you can use CSS to style your Element's container.
    */
    .StripeElement {
    box-sizing: border-box;

    height: 40px;

    padding: 10px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    }

    .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
    border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
    }
</style>