<template>
    <div>
        <modal v-if="event" :show="modal" max-width="md" @close="close">
             
            <form @submit.prevent="pinEvent" class="divide-y">
                <div class="p-3 text-center">
                    <h1 class="text-primary-900 text-md font-medium">{{ pin ? 'Update' : 'Create'}} Pin</h1>
                </div>

                <div class="p-3 overflow-y-auto" style="max-height: 70vh">
                    <h4 class="text-gray-500">Event</h4>
                    <h1 class="text-lg text-primary-900">{{ event.name }}</h1>
                    <div class="space-y-1">
                        <app-input label="Name" v-model="form.name" type="text" :error="error('name')" required />
                        <app-text-area label="Description" v-model="form.description" :error="error('description')" required />                    
                        <app-image-input v-model="form.mediaUrl" :error="error('medialUrl')" required/>
                    </div>
                </div>
                <div class="text-center p-3">
                    <app-button type="submit">{{ pin ? 'Update' : 'Create' }} Pin</app-button>
                </div>
            </form>
        </modal>
    </div>
</template>

<script>
import Modal from '../../../components/Modal.vue';
import AppButton from '../../../components/Button.vue';
import AppInput from '../../../components/Input.vue';
import AppImageInput from '../../../components/ImageInput';
import AppTextArea from '../../../components/TextArea';

import pageMixin from '../../../mixins/page';

export default {
    name: "PinForm",
    mixins: [pageMixin],
    components: {
        Modal, AppButton, AppInput, AppTextArea, AppImageInput,
    },
    data(){
        return {
            modal: false,
            pin: null,
            media: 'upload',
            form: {
                name: null,
                description: null,
                mediaUrl: null
            }
        }
    },
    props: {
        event: Object
    },

    methods: {

        open(pin = null){
            this.pin = pin;
            if(pin){
                this.form = {
                    name: pin.name,
                    description: pin.description,
                    mediaUrl: pin.mediaUrl
                }
            }
            this.modal = true
        },

        close(){
            this.modal = false
        },

        pinEvent(){
           this.start();

            if(this.pin){
                this.$store.dispatch('updatePin', {pin: this.pin, data: {...this.form }}).then(response => {
                    this.$emit('updated', response.data);
                    this.close();
                })
                .catch(e => {
                    this.errors = e.response.data;
                })
                .finally(() => {
                    this.complete();
                })
            }else{
                this.$store.dispatch('createPin', {...this.form, event: this.event}).then(response => {
                    this.$emit('created', response.data);
                    this.close();
                })
                .catch(e => {
                    this.errors = e.response.data;
                })
                .finally(() => {
                    this.complete();
                })
            }
           
        },
    },

    watch: {
        event: {
            immediate: true,
            handler(){
                // this.$store.dispatch('getLoggedInUserPins').then(pins => {
                //    this.pinned = pins.items.find(pin => pin.event.publicId == event.publicId) ? true : false
                // })
            }
        }
    }
}
</script>