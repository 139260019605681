import API from '../../../api';
import { allCities } from '../../../api/cities';

export default class extends API {

    /**
     * Business categories
     */
    static categories(){
        return [
            {
                name: 'Local Place',
                icon: 'mdi mdi-coffee'
            },
            {
                name: 'Organization',
                icon: 'mdi mdi-office-building-marker',
            },

             {
                name: 'Charity',
                icon: 'mdi mdi-heart',
             },
             {
                name: 'Artist',
                icon: 'mdi mdi-account',
            },

            {
                name: 'Brand/Product',
                icon: 'mdi mdi-tag',
            },
        ]
    }

    /**
     * Business cities
     * @returns 
     */

    static cities() {
        return allCities().sort();
    }

   /**
    * Available currencies
    */
    static currencies(){
        return [
            'USD', 'NGN'
        ]
    }

    static getBusinesses(query = {}){
        return this.post(`businesses/find`, query)
    }

    /**
     * Get businesses by category name
     * @param {*} id 
     */
    static getBusinessesByCategory(category, query = {}) {
        return this.post(`businesses/category/${category}/find`, query);
    }

    /**
     * Get businesses by city
     * @param {*} id 
     */
     static getBusinessesByCity(city, query = {}) {
        return this.post(`businesses/city/${city}/find`, query);
    }

    /**
     * Get a single business details
     * @param {*} id 
     */
    static getBusiness(id) {
        return this.get(`businesses/${id}`);
    }

    /**
     * Get a single business details by its ID
     * @param {*} id 
     */
     static getBusinessById(id) {
        return this.get(`businesses/${id}`);
    }
    /**
     * Get a business by username
     * @param {*} username 
     */
    static getBusinessByUsername(username) {
        return this.get(`businesses/username/${username}`);
    }
    /**
     * Create new business
     * @param {*}  
     */
    static store(data) {
        return this.post('businesses/create', data)
    }

    /**
     * update business details
     * @param {*} id 
     * @param {*} data 
     */
    static update(id, data){
        return this.put(`businesses/${id}/edit`, data)
    }

    /**
     * create business bank details
     * @param {*} id 
     * @param {*} data 
     */

    static createBank(data){
        return this.post('businesses/banks/create', data);
    }

    /**
     * update business bank details
     * @param {*} id 
     * @param {*} data 
     */

    static updateBank(bank_id, data){
        return this.put(`businesses/banks/${bank_id}/edit`, data)
    }

    
    /**
     * Get a business followings
     * @param {*} businessId 
     */
    static getBusinessFollowings(businessId)
    {
        return Promise.resolve(businessId);
    }

    /**
     * Get a business followers
     * @param {*} businessId 
    */
    static getBusinessFollowers(businessId)
    {
        return Promise.resolve(businessId);
    }

    /**
     * Delete a business
     * @param {*} id 
     */
    static deleteBuiness(id){
        return this.delete(`businesses/${id}/delete`)
    }

}
