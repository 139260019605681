<template>
    <img v-if="pin.mediaUrl" v-on="$listeners" v-bind="$attrs" :src="pin.mediaUrl" :alt="`Pin by ${pin.user.firstName}`" >
    <img v-else v-on="$listeners" v-bind="$attrs" src="@/assets/images/pin-placeholder.jpg" :alt="`Pin by ${pin.user.firstName}`" >
</template>

<script>
export default {
    name: "PinEvent",
    props: {
        pin: {
            type: Object,
            required: true
        }
    }
}
</script>