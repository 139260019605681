<template>
    <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.7779 28.1426C16.981 28.9922 14.8482 30 12.0709 30C4.99274 30 3.45758 24.7969 3.45758 21.7617V13.3242H0.674377C0.352112 13.3242 0.0884399 13.0605 0.0884399 12.7383V8.75391C0.0884399 8.33203 0.352112 7.95703 0.750549 7.81641C4.38336 6.53906 5.52594 3.36328 5.69 0.955078C5.73688 0.310547 6.07086 0 6.63336 0H10.7877C11.1099 0 11.3736 0.263672 11.3736 0.585938V7.33594H16.2369C16.5591 7.33594 16.8228 7.59375 16.8228 7.91602V12.7031C16.8228 13.0254 16.5591 13.2891 16.2369 13.2891H11.3502V21.0938C11.3502 23.0977 12.7388 24.2344 15.3345 23.1914C15.6158 23.0801 15.8619 23.0039 16.0787 23.0625C16.2838 23.1152 16.4185 23.2617 16.5123 23.5254L17.8013 27.293C17.9068 27.5859 17.9947 27.9141 17.7779 28.1426Z" fill="#1FB2B9"/>
    </svg>
</template>

<script>
export default {
    name: "TwitterSVG"
}
</script>