<template>
    <auth-layout v-if="!profileSet" :order="['side', 'main']">
        <template #main>
            <div class="py-10 lg:px-10">
                <h2 class="text-2xl font-bold text-center text-primary-900">Your account is almost ready ...</h2>
                <profile-form :starting="starting" @updated="profileUpdated" />
            </div>
        </template>
        <template #custom-side>
            <div class="bg-center bg-cover bg-no-repeat h-full shining-event-banner"></div>
        </template>
    </auth-layout>
    <app-layout v-else>
        <div class="flex justify-center py-5">
            <div class="w-full sm:w-2/3">
                <app-tabs-panel v-model="tab" :items="tabs" :vertical="true">
                    <template #content="attrs">
                        <div class="bg-white sm:mx-2">
                            <profile-form  v-if="attrs.active.id === 'info'" @updated="profileUpdated" />
                            <interest-update v-if="attrs.active.id === 'interests'" @updated="interestUpdated" />
                            <user-bank-form v-if="attrs.active.id === 'bank-details'" />
                        </div>
                    </template>
                </app-tabs-panel>
            </div>
        </div>
    </app-layout>
</template>

<script>
import AppLayout from '../../../layouts/App.vue';
import AuthLayout from '../../Auth/views/Layout.vue';
import AppTabsPanel from '../../../components/TabsPanel.vue'
import ProfileForm from '../components/ProfileForm.vue';
import InterestUpdate from '../components/InterestUpdate.vue';
import UserBankForm from '../components/BankForm.vue';

export default {
    name: "UserEdit",
    metaInfo() {
        return {
            title: `Update profile - ${this.tabs[this.tab].title}`,
        }
    },
    data(){
        return {
            profileSet: false, 
            tab: 0,
            tabs: [
                {
                    title: 'Information',
                    id: 'info',
                },
                {
                    title: 'Interests',
                    id: 'interests',
                },
                {
                    title: 'Bank Details',
                    id: 'bank-details',
                }
            ]
        };
    },
    computed: {
        starting() {
            return this.$route.params.getStarted || !this.$store.getters.profileSet;
        }
    },
    components: {
        AppLayout,
        AuthLayout,
        AppTabsPanel,
        ProfileForm,
        InterestUpdate,
        UserBankForm
    },
    methods: {
        profileUpdated(){
            if(this.starting){
                this.profileSet = true;
                this.tab = 1;
                this.$store.commit('APP_SNACKBAR', {
                    text: "Profile updated. Now select some tags you are interested in",
                })
            }else{
                this.$store.commit('APP_SNACKBAR', {
                    text: "Profile updated",
                    persist: true,
                    closeAfterAction: true,
                    actions: [
                        {
                            text: 'Update interest tags',
                            action: () => this.tab = 1
                        }
                    ]
                })
            }
        },

        interestUpdated(){
            if(this.$route.params.getStarted){
                this.$store.commit('APP_SNACKBAR', {
                    text: "You are all set now",
                    persist: true,
                    closeAfterAction: true,
                    actions: [
                        {
                            text: 'Go to your feed',
                            action: () => this.$router.push({name: 'home'})
                        },
                        {
                            text: 'Create your business profile',
                            action: () => this.$router.push({name: 'business.create', params: this.$route.params})
                        },
                    ]
                })
            }else{
                this.$store.commit('APP_SNACKBAR', {
                    text: "Interest updated",
                    persist: true,
                    closeAfterAction: true,
                    actions: [
                        {
                            text: 'Go to your board',
                            action: () => this.$router.push({name: 'board'})
                        },
                    ]
                })
            }
        },
    },

    mounted() {
        if(!this.starting) this.profileSet = true
    },

    watch: {
        $route: {
            immediate: true,
            handler(route) {
                const target = route.params.tab ?? this.tabs[0].id;
                this.tab =  this.tabs.findIndex(tab => tab.id === target) ?? 0;
            }
        },
        tab: {
            handler(tab) {
                this.$router.push({
                    name: this.$route.name,
                    params: {
                        tab: this.tabs[tab].id
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
    .shining-event-banner {
        background-image: url('../../../assets/images/shining-event.jpg');
    }
</style>