<template>
    <div v-if="$store.getters.auth.publicId !== user.publicId">
        <app-button v-if="following" @click="unfollow"> Following</app-button>
        <app-button v-else @click="follow"> Follow</app-button>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import AppButton from '../../../components/Button.vue';

export default {
    name: "UserFollowButton",
    components: {
        AppButton
    },
    data(){
        return {
            following: false
        }
    },
    props: {
        user: {
            type: Object,
            required: true,
        }
    },

    methods: {
        ...mapMutations([
            'APP_SNACKBAR'
        ]),

        follow(){

            this.$store.dispatch('followUser', this.user).then(() => {
                this.$emit('followed', this.user)
                this.following = true;
            })            
        },

        unfollow(){
            this.$store.dispatch('unfollowUser', this.user).then(() => {
                this.$emit('unfollowed', this.user);
                this.following = false;
            })            
        },
    },

    watch: {
        user: {
            immediate: true,
            async handler(user){
                const followings = await this.$store.dispatch('getUserFollowings');
                this.following = followings.items.find(following => following.publicId ==user.publicId);
            }
        }
    }
}
</script>