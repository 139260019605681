<template>
    <user-board :user="user">
        <!-- Event tabs -->
        <template #title>
            <app-tabs-panel v-model="tab" :items="tabs" />
        </template>
        <div ref="eventsContainer">
            <app-data-container :loading="loading">
                <app-empty-items v-if="!events.totalCount" />
                <div v-else class="p-1 md:p-2">
                    <user-board-event v-for="event in events.items" :key="event.publicId" :event="event" :joined="true" bg="gray-100" />
                </div>
            </app-data-container>
        </div>
    </user-board>
</template>

<script>
import UserBoard from '../widgets/Board';
import UserBoardEvent from '../../Event/components/UserBoardEvent.vue';
import AppEmptyItems from '../../../components/EmptyItems.vue';
import AppDataContainer from '../../../components/DataContainer.vue';
import AppTabsPanel from '../../../components/TabsPanel.vue';

import EventService from '../../Event/services';

export default {
    name: "UserEventsWidget",

    components: {
        UserBoard, UserBoardEvent, AppEmptyItems, AppDataContainer,
        AppTabsPanel
    },

    props:{
        user:Object,
        period: {
            type: String,
            default: () => 'all'
        }
    },

    data(){
        return {
            loading: false,
            events: {},
            createEvent: false,
            tab: 0,
            tabs: [
                {
                    title: 'All',
                    id: 'all',
                },
                {
                    title: 'Past',
                    id: 'past',
                },
                {
                    title: 'Upcoming',
                    id: 'upcoming',
                }
            ]
        }
    },

    computed: {
        route(){
            return this.$route.name == 'board' ? 'user.events' : this.$route.name
        }
    },


    methods: {

        getEvents(){
            this.loading = true;

            let eventsSource = null;

            if(this.period == 'past'){
                eventsSource = EventService.getUserPastvents(this.user.publicId)
            }else if(this.period == 'upcoming'){
                eventsSource = EventService.getUserUpcomingEvents(this.user.publicId)
            }else{
                eventsSource = EventService.getUserEvents(this.user.publicId)
            }

            eventsSource.then(response => {
                this.events = response.data
            })
            .catch(() => {
                // this.$store.commit('APP_SNACKBAR', {
                //     type: 'error',
                //     text: `Couldn't get events. ${e.message}`
                // })
            })
            .finally(() => {
                this.loading = false;
            })
        },
    },

    watch:{
        period: {
            immediate: true,
            handler(){
                this.getEvents()
            }
        },
        
        tab: {
            handler(tab) {
                this.$router.push({
                    name: this.route,
                    params: {
                        period: this.tabs[tab].id
                    }
                })
            }
        }
    } 
}
</script>