<template>
    <app-data-container :loading="loading">
        <app-empty-items v-if="!followers.length" />
        <div v-else class="flex flex-wrap">
            <div v-for="user in followers" :key="user.publicId" class="w-1/2 md:w-1/3 lg:w-1/4">
                <user-follow :user="user" />
            </div>
        </div>
    </app-data-container>
</template>

<script>
import AppEmptyItems from '../../../components/EmptyItems.vue';
import AppDataContainer from '../../../components/DataContainer.vue';
import UserFollow from '../components/Tile.vue';

export default {
    name: "PersonalFollowers",
    components: {
        AppEmptyItems, UserFollow, AppDataContainer
    },
    data(){
        return {
            loading: false,
            followers: []
        }
    },
    props: {
        user: Object
    },
    methods: {
        getFollowers(){
            this.loading = true;

            this.$store.dispatch('getUserFollowers', this.user)
            .then(followers => {
                this.followers = followers.items;
                this.$emit('total', followers.totalCount);
            })
            .finally(() => {
                this.loading = false;
            })
        }
    },

    watch: {
        user: {
            immediate: true,
            handler(user){
                if(user){
                    this.getFollowers()
                }
            }
        }
    }
}
</script>