<template>
    <app-layout>
        <not-found v-if="!event.publicId" message="Event not found" />

        <div v-else class="py-5 px-1 sm:px-5 lg:px-20 space-y-5">

            <section class="s1">
                <div class="flex flex-wrap items-center bg-white">
                    <div 
                    class="w-full md:w-8/12 h-72 bg-center bg-cover bg-no-repeat" 
                    :style="`background-image: url(${event.imageUrl})`">
                    </div>
                    <div class="w-full md:w-4/12">
                        <div class="space-y-5 p-5">
                            <p class="text-gray-500">{{ startMoment.format('ddd, MMMM Do YYYY') }}</p>
                            <h1 class="font-bold text-xl md:text-3xl">{{ event.name }}</h1>
                            <p v-if="author">By
                                <router-link :to="author.route" class="text-primary-900">
                                    {{author.name }}
                                </router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="s2">
                <div class="flex flex-wrap">
                    <div class="w-full md:w-8/12 bg-white">
                        <div class="p-3 space-y-3">
                            <h1 class="text-lg font-medium">Details</h1>
                            <p>{{ event.description }}</p>
                            <h1 class="text-lg font-medium">Creator</h1>
                            <div class="flex items-center gap-x-2">
                                <business-avatar :business="author" class="h-10 w-10 rounded-full"  />
                                <div>
                                    <router-link :to="author.route">
                                        <h4 class="font-bold text-primary-900">{{ author.name }}</h4>
                                    </router-link>
                                    <p class="text-gray-500 text-sm">{{ author.businessType }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full md:w-4/12">
                        <div class="ml-0 md:ml-2 h-full bg-primary-900 py-5 md:flex md:items-center md:justify-center">
                            <div class="px-10 mx-auto">
                                <div class="text-white text-center space-y-5">
                                    <div class="">
                                        <i class="fe fe-map-pin text-5xl "></i>
                                    </div>
                                    <p>{{ event.address }}</p>
                                    <p v-if="author.website"><a :href="author.website" class="text-primary-900">{{ author.website }}</a></p>
                                </div>
                                                                    
                                <div class="flex flex-wrap justify-center items-center gap-2">
                                    <event-join-button bg="white" color="primary-900" :event="event" />
                                    <pin-button bg="yellow-500" color="white" :event="event" @created="pinCreated" />
                                    <app-drop-down v-if="isMyEvent" align="right" >
                                        <template #trigger>
                                            <button class="text-white focus:outline-none"><i class="fe fe-more-vertical"></i></button>
                                        </template>
                                        <template #content>
                                            <div class="divide-y">
                                                <app-drop-down-link :to="{name: 'event.manager', params: {slug: event.slug }}">
                                                    <span>Manage event</span>
                                                </app-drop-down-link>
                                            </div>
                                        </template>
                                    </app-drop-down>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="s3">
                <h1 class="text-lg font-medium mb-5">Pins</h1>
                <div class="px-1">
                    <pin-feeds ref="pinFeeds" :src="pins" />
                </div>
            </section>
        </div>

    </app-layout>
</template>

<script>
import AppLayout from '../../../layouts/App.vue';
import EventJoinButton from '../components/JoinButton.vue';
import PinFeeds from '../../Pin/widgets/PinFeeds';
import PinButton from '../../Pin/components/PinButton.vue';
import BusinessAvatar from '../../Business/components/Avatar.vue';
import NotFound from '../../../components/EmptyItems.vue';
import AppDropDown from '../../../components/Dropdown.vue';
import AppDropDownLink from '../../../components/DropdownLink.vue';

import EventService from '../services';
import PinService from '../../Pin/services';

import pageMixin from '../../../mixins/page';
import eventMixin from '../mixins/event';

export default {
    name: "EventShow",
    metaInfo() {
        return {
            title: this.event ? this.event.name : '',
        }
    },
    mixins: [pageMixin, eventMixin],
    components: {
        AppLayout, EventJoinButton, PinButton, PinFeeds,
        BusinessAvatar, NotFound, AppDropDown, AppDropDownLink,
    },
    data(){
        return {
            event: {
                category: {},
                business: {},
                tickets: []
            },
            pins: null,
        }
    },

    computed: {
        slug(){
            return this.$route.params.slug
        },
    },

    methods: {

        getEvent(){

            this.start({
                opacity: 1
            });

            EventService.getEventBySlug(this.slug)
            .then(response => {
                this.event = response.data;
                this.pins = PinService.getEventPins(this.event.publicId);
            })
            .catch(e => {
                this.$store.commit('APP_SNACKBAR', {
                    type: 'error',
                    text: `Couldn't get events. ${e.message}`
                })
            })
            .finally(() => {
                this.complete()
            });

        },

        pinCreated(pin){
            if(this.$refs.pinFeeds.pin.pins.items) this.$refs.pinFeeds.pin.pins.items.unshift({...pin, user: this.$store.getters.auth});
            else this.$refs.pinFeeds.pin.pins.items = [{...pin, user: this.$store.getters.auth}];
        }

    },

    watch: {
        $route: {
            immediate: true,
            handler(route){
                if(route.params._event){
                    this.event = route.params._event;
                    this.pins = PinService.getEventPins(this.event.publicId);
                    return;
                }
                this.getEvent();
            }
        }
    }
    
}
</script>