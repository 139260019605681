<template>
    <div>
        <h4 v-if="heading" class="text-gray-500 font-medium">{{ heading }}</h4>
        <data-container :loading="loading">
            <div v-if="activeAds.length">
                <div class="overflow-x-auto my-1 pb-2 sm:p-0">
                    <div class="inline-flex sm:flex sm:flex-wrap sm:space-y-2">
                        <div v-for="ad in activeAds" :key="ad.publicId" class="w-44 sm:w-full">
                            <div class="px-1 sm:px-0">
                                <app-ad :ad="ad" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="p-5 text-gray-500 text-center text-sm">
                No Ad to display
            </div>
        </data-container>
    </div>
</template>

<script>
import AppAd from '../components/Ad.vue';
import DataContainer from '../../../components/DataContainer.vue';
export default {
    name: "AdsWidget",
    components: {
        AppAd,
        DataContainer
    },

    data(){
        return {
            loading: false,
            ads: null
        }
    },

    props: {
        heading: String
    },

    computed: {
        activeAds(){
            if(!this.ads || !this.ads.items) return []
            let ads = this.ads.items;
            if(ads.length > 4) return ads.slice(0, 3)
            return ads;
        }
    },

    methods: {
        getAds(){
            this.loading = true
            this.$store.dispatch('loadAds').then(ads => {
                this.ads = ads
            })
            .finally(() => {
                this.loading = false
            })
        }
    },

    mounted(){
        this.getAds();
    }
}
</script>