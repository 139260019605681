<template>
    <admin>
        <template #header>
            <div class="py-2">
                <h2 class="text-xl">Products</h2>
            </div>
        </template>

        <app-tabs-panel v-model="tab" :items="tabs" >
            <template #content="{ active }">
                <data-container :loading="loading">
                    <!-- Products -->
                    <template v-if="active.route === 'admin.products'">
                        <app-empty-items v-if="!products.length" />
                        <div v-else class="overflow-auto">
                            <table class="min-w-max w-full table-auto">
                                <thead>
                                    <tr class="bg-white text-gray-600 uppercase">
                                        <th class="py-3 px-6 text-left">Product</th>
                                        <th class="py-3 px-6 text-center">Price</th>
                                        <th class="py-3 px-6 text-center">Status</th>
                                        <th class="py-3 px-6 text-left">Created</th>
                                        <th class="py-3 px-6 text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(product, i) in products" :key="product.publicId"
                                    :class="{'bg-gray-100': i%2 === 0, 'bg-white': i%2 === 1 }"
                                    >
                                        <td class="py-3 px-6 text-left whitespace-nowrap">
                                            <div class="flex items-center">
                                                <img :src="product.imageUrl" class="w-10 h-10 mr-2">
                                                <h4><router-link :to="{name: 'product.show', params:{id: product.publicId, _product: product}}">{{product.name}}</router-link></h4>
                                            </div>
                                        </td>

                                        <td class="py-3 px-6 text-center">
                                            <span>{{ product.price }}</span>
                                        </td>
                                        <td class="py-3 px-6 text-center">
                                            <span class="capitalize">{{ product.status}}</span>
                                        </td>
                                        <td class="py-3 px-6 text-left whitespace-nowrap">
                                            {{ product.createdAt | datetime('day/month/year hour:minute') }}
                                        </td>
                                        <td class="py-3 px-6 text-center">
                                            <div class="flex item-center space-x-2 justify-center">
                                                <a href="#" @click.prevent="$refs.productForm.open(product)"><i class="fe fe-eye"></i></a>
                                                <a href="#" @click.prevent="deleteProduct(product)"><i class="fe fe-trash text-red-500"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <product-form ref="productForm" @updated="productUpdated" />
                    </template>

                    <!-- Categories -->
                    <template v-if="active.route === 'admin.product.categories'" >
                        <div class="flex justify-between items-center py-2">
                            <h4 class="font-bold">Product categories ({{ categories.length }})</h4>
                            <app-button @click="$refs.categoryForm.open()" ><i class="fe fe-plus"></i>  <span class="hidden sm:inline">Add Category</span></app-button>
                        </div>
                        <product-category-form ref="categoryForm" @created="categoryCreated" @updated="categoryUpdated" />
                        <app-empty-items v-if="!categories.length" />
                        <div v-else class="overflow-auto">
                            <table class="min-w-max w-full table-auto">
                                <thead>
                                    <tr class="bg-white text-gray-600 uppercase">
                                        <th class="py-3 px-6 text-left">Name</th>
                                        <th class="py-3 px-6 text-left">Created</th>
                                        <th class="py-3 px-6 text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="">
                                    <tr v-for="(category, i) in categories" :key="category.publicId"
                                    :class="{'bg-gray-100': i%2 === 0, 'bg-white': i%2 === 1 }"
                                    >
                                        <td class="py-3 px-6 text-left whitespace-nowrap">
                                            <div class="flex items-center">
                                                <router-link :to="{name: 'products', params: {category: category.publicId }}">
                                                    {{ category.name }}
                                                </router-link>
                                            </div>
                                        </td>
                                        <td class="py-3 px-6 text-left whitespace-nowrap">
                                            {{ category.createdAt | datetime('day/month/year hour:minute') }}
                                        </td>
                                        <td class="py-3 px-6 text-center">
                                            <div class="flex item-center space-x-2 justify-center">
                                                <a href="#" @click.prevent="$refs.categoryForm.open(category)"><i class="mdi mdi-pencil"></i></a>
                                                <a href="#" @click.prevent="deleteCategory(category)"><i class="mdi mdi-delete text-red-500"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                    </data-container>
            </template>
        </app-tabs-panel>

         <app-confirmation :show="confirmation.show" @close="confirmation.show = false" :onConfirm="confirmation.action">
            <template #title>Confirm</template>
            <template #content>{{ confirmation.content }}</template>
        </app-confirmation>
    </admin>
</template>

<script>
import Admin from '../../../layouts/Admin.vue';
import AppEmptyItems from '../../../components/EmptyItems';
import AppButton from '../../../components/Button';
import DataContainer from '../../../components/DataContainer';
import AppTabsPanel from '../../../components/TabsPanel.vue';
import ProductCategoryForm from '../../Shop/Product/components/CategoryForm';
import AppConfirmation from '../../../components/Confirmation';
import ProductForm from '../../Shop/Product/components/Form';
import ProductService from '../../Shop/Product/services';

export default {
    name: "AdminProducts",
    metaInfo() {
        let title = 'Products';
        if(this.$route.name === 'admin.products') {
            title = `Products (${this.products.length})`;
        }
        if(this.$route.name === 'admin.product.categories') {
            title = `Product Categories (${this.categories.length})`;
        }

        return {
            title
        };
    },
    components: {
        Admin,
        AppEmptyItems,
        DataContainer,
        AppTabsPanel,
        AppButton,
        ProductCategoryForm,
        AppConfirmation,
        ProductForm
    },
    data(){
        return {
            loading: false,
            products: [],
            categories: [],
            confirmation: {
                show: false,
                title: null,
                content: null,
                action: () => Promise.resolve()
            },
            tab: 0,
            tabs: [
                {
                    title: 'Products',
                    route: 'admin.products',
                },
                {
                    title: 'Categories',
                    route: 'admin.product.categories',
                },
            ]
        }
    },

    methods:{
        getProducts(){
            this.loading = true;
            ProductService.getProducts()
            .then(response => {
                this.products = response.data
            })
            .catch(() => {
                // this.$store.commit('APP_SNACKBAR', {
                //     type: 'error',
                //     text: `Couldn't get products. ${e.message}`
                // })
            })
            .finally(() => {
                this.loading = false
            })
        },

        getProductCategories(){
            this.loading = true;
            this.$store.dispatch('getProductCategories').then(categories => {
                this.categories = categories;
            })
            .finally(() => {
                this.loading = false;
            })
        },

        categoryCreated(category){
            this.$refs.categoryForm.close()
            this.categories.push(category);
        },

        categoryUpdated(updatedCategory){
            this.$refs.categoryForm.close()
            this.categories = this.categories.map(category => {
                if(category.publicId == updatedCategory.publicId)  return updatedCategory;
                else return category;
            })
        },

        deleteCategory(category){
            this.confirmation = {
                show: true,
                title: 'Delete category',
                content: `Are you sure you want to delete ${category.name}?`,
                action: () => ProductService.deleteCategory(category.publicId).then(() => {
                    this.categories.splice(this.categories.findIndex(c => c.publicId == category.publicId), 1)
                    this.$store.commit('APP_SNACKBAR', {
                        text: `${category.name} deleted`
                    });
                } ),
            }
        },

        productUpdated(updatedProduct){

            this.products = this.products.map(product => {
                if(product.publicId == updatedProduct.publicId)  return {
                        ...updatedProduct,
                        category: product.category
                    }
                else return product;
            })
            this.$refs.productForm.close();
        },

        deleteProduct(product){
            this.confirmation = {
                show: true,
                title: 'Delete product',
                content: `Are you sure you want to delete ${product.name}?`,
                action: () => ProductService.deleteProduct(product.publicId).then(() =>  {
                    this.products.splice(this.products.findIndex(p => p.publicId == product.publicId), 1) 
                     this.$store.commit('APP_SNACKBAR', {
                        text: `${product.name} deleted`
                    });
                }),
            }
        },

    },

     watch: {
        $route: {
            immediate: true,
            handler(route) {
                this.tab =  this.tabs.findIndex(tab => tab.route === route.name) ?? 0;
                if(route.name == 'admin.products') this.getProducts();
                if(route.name == 'admin.product.categories') this.getProductCategories();
            }
        },
        tab: {
            handler(tab) {
                this.$router.push({
                    name: this.tabs[tab].route
                })
            }
        }
    },
}
</script>