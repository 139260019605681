<template>
    <div>
        <template v-if="loading">
            <div class="text-center py-5">
                <slot name="spinner">
                    <spinner />
                </slot>
                <slot name="loading" />
            </div>
        </template>
        <template v-else>
            <slot />
        </template>
    </div>
</template>

<script>
import Spinner from './Spinner';
export default {
    name: "DataContainer",
    components: {
        Spinner
    },
    props: {
        loading: Boolean
    }
}
</script>