<template>
    <form  @submit.prevent="submit">
        <div class="space-y-5 ">       

            <div class="space-y-3">
                <app-input label="Name" v-model="form.name" type="text" :error="error('name')" prepend-icon="info" required />
                <!-- Image -->
                <div class="my-2">
                    <app-image-input v-model="form.imageUrl" />
                    <app-input-error :message="error('imageUrl')" />
                </div>

                <!-- Privacy -->
                <div>
                    <label class="block font-medium text-sm text-gray-500">
                        Privacy
                    </label>
                    <div>
                        <label for="status-public">
                            <input type="radio" name="status" id="status-public" value="public" v-model="form.privacy" />
                            <span class="ml-1">Public</span>
                        </label>

                        <label for="status-hidden" class="ml-5">
                            <input type="radio" name="status" id="status-hidden" value="private" v-model="form.privacy" />
                            <span class="ml-1">Private</span>
                        </label>
                    </div>
                    <app-input-error :message="error('privacy')" />
                </div>                

                <!-- Address -->
                <div class="flex flex-wrap">
                    <div class="w-1/3 md:w-1/2">
                        <div class="pr-2">
                            <app-select label="Address Type" v-model="form.addressType" :options="addressTypes.map(type=>({label:type, value:type.toLowerCase()}))"  :error="error('addressType')" prepend-icon="map-pin" required />
                        </div>
                    </div>
                    <div class="w-2/3 md:w-1/2">
                        <div>
                            <app-input label="Address" v-model="form.address" type="text" :error="error('address')" prepend-icon="map-pin" required />
                        </div>
                    </div>
                </div>

                <!-- Dates -->
                <div class="flex flex-wrap">
                    <div class="w-1/2">
                        <div class="pr-2">
                            <app-input label="Start date" v-model="form.startAt" type="datetime-local" :error="error('startAt')" prepend-icon="calendar" required />
                        </div>
                    </div>
                    <div class="w-1/2">
                        <div class="pl-2">
                            <app-input label="End date" v-model="form.endAt" type="datetime-local" :error="error('endAt')" prepend-icon="calendar" required />
                        </div>
                    </div>
                </div>

                <event-category-select label="Category" v-model="form.category" :error="error('category')" prepend-icon="grid" required />

                <app-input label="Short description" v-model="form.shortDescription" type="text" :error="error('shortDescription')" prepend-icon="info" required />

                <app-text-area label="Full description" v-model="form.description" :error="error('description')" required />

                <template v-if="$store.getters.isAdmin" >
                    <app-select label="Status" v-model="form.status" :options="statuses.map(status=>({label:status, value:status.toLowerCase()}))"  :error="error('status')" prepend-icon="info" required />
                    <app-switch label="Active" v-model="form.isActive" />
                </template>
            </div>

            
            <h4 class="text-lg text-gray-500">Select interest tags for the event</h4>
            <div class="text-center">
                <app-input-error :message="error('interestTags')" />
            </div>
            <interest-tag-select display="grid" grid-classes="" label="Interest tags" multiple v-model="form.interestTags" :error="error('interestTags')" />

            <div class="md:px-32">
                <app-button type="submit" block>SAVE</app-button>
            </div>
        </div>
    </form> 
</template>

<script>
import AppInput from '../../../components/Input.vue';
import AppInputError from '../../../components/InputError.vue';
import AppButton from '../../../components/Button.vue';
import AppImageInput from '../../../components/ImageInput';
import AppSelect from '../../../components/Select';
import AppSwitch from '../../../components/Switch';
import AppTextArea from '../../../components/TextArea';
import EventCategorySelect from '../components/CategorySelect';
import InterestTagSelect from './InterestTagSelect';

import EventService from '../services';
import pageMixin from '../../../mixins/page';
import eventMixin from '../mixins/event';

export default {
    name: "EventForm",
    mixins: [pageMixin, eventMixin],
    components: {
        AppInput, AppInputError, AppImageInput, AppButton,
        AppSelect, AppTextArea, EventCategorySelect, InterestTagSelect,
        AppSwitch
    },
    data(){
        return {
            show: false,
            tab: null,
            addressTypes: ['Offline', 'Online'],
            statuses: ['Active', 'Inactive'],
            form: {},
        }
    },

    props: {
        event: Object
    },
  
    methods: {

        submit(){

            this.errors = null;

            this.form.interestTags = this.form.interestTags.map(tag => tag instanceof Object ? tag.publicId : tag);

            if(this.event) this.updateEvent();
            else this.createEvent();
        },

        createEvent(){
            this.start()
             EventService.store({
                ...this.form,
                business: this.$store.getters.business.publicId,
                owner: this.$store.getters.auth.publicId,
                tickets: []
            })
            .then(response => {
                this.$emit('created', response.data)
                this.snackbar({
                    text: `New event created, add ticket to event ${this.form.name}`
                });
                this.event = response.data;
                this.$router.push({
                    name: 'event.manager',
                    params: {
                        slug: this.event.slug,
                        tab: 'tickets'
                    }
                })
            })
            .catch(e => {
                this.errors = e.response.data;
                this.snackbar({
                    text: 'Failed to create event. check your input',
                    type: 'error'
                });
            })
            .finally(() => {
                this.complete();
            })
        },

        updateEvent(){
            this.start()
           
            EventService.update(this.event.publicId, this.form)
            .then(response => {
                this.$emit('updated', response.data)
                this.snackbar({
                    text: `Event updated.`
                });
            })
            .catch(e => {
                this.errors = e.response.data;
                this.snackbar({
                    text: 'Failed to update event. check your input',
                    type: 'error'
                });
            })
            .finally(() => {
                this.complete();
            })
        },
    },

    watch: {
        event: {
            immediate: true,
            handler(event) {
                if(!event){
                    this.form = {
                        privacy: 'public',
                        status: 'active',
                    }
                }else{
                    this.event = event;
                    this.form = {
                        name: event.name,
                        imageUrl: event.imageUrl,
                        status: event.status,
                        addressType: event.addressType,
                        address: event.address,
                        privacy: event.privacy,
                        startAt: this.startMoment.format('yyyy-MM-DDThh:mm'),
                        endAt: this.endMoment.format('yyyy-MM-DDThh:mm'),
                        shortDescription: event.shortDescription,
                        description: event.description,
                        category: event.category instanceof Object ? event.category.publicId : event.category,
                        business: event.business instanceof Object ? event.business.publicId : event.business,
                        owner:  event.owner instanceof Object ? event.owner.publicId : event.owner,
                        isActive: event.isActive,
                        interestTags: event.interestTags,            
                    }
                }
            }
        }
    }

}
</script>