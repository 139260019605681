import API from '../../../api';

export default class extends API {
    
    /**
     * Retrieve pins created by a user
     * @param {*} userId 
     */
    static getUserPins(userId, query = {}){
        return this.post(`pins/user/${userId}/find`, query);
    }

    /**
      * Get suggested pins for a user
      * @param {*} userId 
    */
   static getUserSuggestedPins(userId, query = {})
   {
       return this.post(`pins/suggested/${userId}/find`, query);
   }

     /**
      * Create new pin
      * @param {*} data 
      */
     static createPin(data)
     {
         return this.post(`pins/create`, data);
     }

     /**
      * update a pin
      * @param {*} pinId 
      */
      static updatePin(pinId, data){
       return this.put(`pins/${pinId}/edit`, data)
      }

      /**
       * Delete a pin
       * @param {*} pinId 
       */
      static deletePin(pinId){
        return this.delete(`pins/${pinId}`);
      }

    /**
      * Get pins for an event
      * @param {*} eventId 
    */
    static getEventPins(eventId,  query = {})
    {
        return this.post(`pins/event/${eventId}/find`,  query);
    }

    /**
     * create a like for a pin
     * @param {*} pinId 
     */
    static createLike(user, pin)
    {
      return this.post(`pins/like/create`, { user, pin })
    }

    /**
     * Delete a pin like
     * @param {*} likeId 
     */
    static deleteLike(likeId)
    {
      return this.delete(`pins/like/${likeId}`)

    }

}
