<template>
    <div class="space-y-5">
        <div class="flex flex-wrap space-y-1">
            <div class="w-full">
                <app-input label="Name" v-model="form.name" type="text" :error="error('name')" required />
            </div>
            <div class="w-1/3">
                <div class="pr-2 lg:pr-5">
                     <app-select label="Currency" v-model="form.currency" :options="currencies.map(currency => ({label: currency, value: currency}))" :error="error('currency')" />
                </div>
            </div>
            <div class="w-2/3">
                <div class="pl-2 lg:pl-5">
                    <app-input label="Price" v-model="form.price" type="number" :error="error('price')" required />
                </div>
            </div>
            <div class="w-full">
                <app-input label="Max slots" v-model="form.maxSlots" type="number" :error="error('maxSlots')" required />
            </div>
            <div class="w-full">
                <div>
                    <label for="status-public">
                        <input type="radio" name="status" id="active" :value="true" v-model="form.ifActive" />
                        <span class="ml-1">Active</span>
                    </label>

                    <label for="status-hidden" class="ml-5">
                        <input type="radio" name="status" id="inactive" :value="false" v-model="form.ifActive" />
                        <span class="ml-1">Inactive</span>
                    </label>
                </div>
                <app-input-error :message="error('ifActive')" />
            </div>
        </div>
        <app-button type="button" @click.prevent="submit" :loading="loading">Create Ticket</app-button>
    </div>
</template>

<script>
import AppInput from '../../../components/Input.vue';
import AppInputError from '../../../components/InputError.vue';
import AppButton from '../../../components/Button.vue';
import AppSelect from '../../../components/Select.vue';
import TicketService from '../services';
import pageMixin from '../../../mixins/page';

export default {
    name: "EventTicketForm",
    mixins: [pageMixin],
    components: {
        AppInput, AppInputError, AppButton, AppSelect
    },
    data(){
        return {
            loading: false,
            form: {
                ifActive: true
            },
            currencies: [
                'USD', 'NGN'
            ]
        }
    },
    props: {
        event: Object
    },
    methods: {
        submit(){
            this.loading = true;
            this.errors = null;

            TicketService.createEventTicket({...this.form})
            .then(response => {
                this.$emit('created', response.data);
                this.form = {};
            })
            .catch(e => {
                this.errors = e.response.data
            })
            .finally(() => {
                this.loading = false;
            })
        }
    },
    watch: {
        event: {
            immediate: true,
            handler(event){
                if(event){
                    this.form.event = event.publicId;
                }
            }
        }
    }
}
</script>