<template>
    <img v-if="user && user.imageUrl" :src="user.imageUrl" :alt="alt" v-bind="$attrs" v-on="$listeners">
    <img v-else src="@/assets/images/user-avatar.png" :alt="alt" v-bind="$attrs" v-on="$listeners">
</template>

<script>
export default {
    name: "UserAvatar",
    props: {
        user: Object
    },
    computed: {
        alt(){
            return this.user ? [this.user.firstName, this.user.lastname].join(' ') : 'user';
        }
    }
}
</script>