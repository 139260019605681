<template>
    <app-layout>
        <div class="px-1 sm:px-2 lg:px-8 py-5">
            <div class="flex flex-wrap">
                <div class="w-full sm:w-4/12 lg:w-2/12">

                    <div class="">
                        <event-categories @loaded="categoriesLoaded" :active="category ? category : {name: 'Latest Events'}" :collapse="collapseCategories" />
                        <!-- Ads -->
                        <div  class="sm:mt-5">
                            <ads-widget heading="Ads" />
                        </div>
                    </div>
                </div>

                <div class="w-full sm:w-8/12 lg:w-10/12">
                    <div class="sm:px-2 lg:px-4">
                        <!-- Event list -->
                        <div class="hidden sm:block">
                            <h1 v-if="isLatest" class="text-lg">Latest Events</h1>
                            <div v-else>
                                <h4 class="text-gray-500 text">Category</h4>
                                <h1 class="text-lg">{{ category ? category.name : '--' }}</h1>
                            </div>
                        </div>
                        <div ref="eventsContainer">
                            <app-data-container :loading="loading || categoriesLoading">
                                <app-empty-items v-if="!events.totalCount" />
                                <user-board-event v-else v-for="event in events.items" :key="event.publicId" :event="event" />
                            </app-data-container>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </app-layout>
</template>

<script>
import AppLayout from '../../../layouts/App.vue';
import UserBoardEvent from '../components/UserBoardEvent';
import AppEmptyItems from '../../../components/EmptyItems.vue';
import EventCategories from '../widgets/Categories.vue';
import AppDataContainer from '../../../components/DataContainer.vue';
import AdsWidget from '../../Advert/widgets/Ads.vue';

import EventService from '../../Event/services';
import pageMixin from '../../../mixins/page';

export default {
    name: "EventCategory",
    mixins: [pageMixin],
    metaInfo() {
        return {
            title: `Events - ${this.isLatest ? 'Latest' : this.category.name}`
        }
    },
    components: {
        AppLayout, AppDataContainer, AdsWidget, AppEmptyItems, UserBoardEvent, EventCategories
    },
    
    data(){
        return {
            loading: false,
            categoriesLoading: true,
            categories: [],
            events: {},
            collapseCategories: false,
        }
    },

    computed: {
        isLatest(){
            return !this.slug ? true : false
        },

        category(){
            return this.categories.find(c => c.slug == this.slug)
        },

        slug(){
            return this.$route.params.category
        }
    },

    methods: {

        categoriesLoaded(categories){
            this.categoriesLoading = false;
            this.categories = categories;
            this.getEvents()
        },


        getEvents(){
            if(this.slug && !this.category) return;

            this.loading = true;

            let eventSrc = this.isLatest ? EventService.getLatestEvents() :  EventService.getEventsByCategory(this.category.publicId) 

            this.collapseCategories = true;
            eventSrc.then(response => {
                this.events = this.isLatest ? { items: response.data, totalCount: response.data.length } : response.data 
            })
            .catch(() => {
                // this.$store.commit('APP_SNACKBAR', {
                //     type: 'error',
                //     text: `Couldn't get events. ${e.message}`
                // })
            })
            .finally(() => {
                this.loading = false;
                this.collapseCategories = false
            })
        },
    },

    watch:{
        slug: {
            immediate: false,
            handler(){
                this.getEvents();
            }
        }
    } 
}
</script>