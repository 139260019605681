<template>
<div>
    <modal :show="show" max-width="xl" @close="close">
       <form @submit.prevent="submit">
            <div class="p-3 text-center">
                <h1 class="text-primary-900 text-md font-medium">{{product ? `Update Product: ${product.name}` : 'New Product'}}</h1>
            </div>

            <div class="px-3 py-5 sm:px-10 space-y-5 overflow-y-auto" style="height: 65vh">       
                <app-input label="Name" v-model="form.name" type="text" :error="error('name')" required />

               <app-select
                label="Type"
                v-model="type"
                :options="[{ label: 'Product', value: 'product' }, { label: 'Service', value: 'service' }]" 
                :error="error('isService')"
                required />
                
                <div class="flex flex-wrap">
                    <div class="w-1/3">
                        <div class="pr-2 lg:pr-5">
                            <app-select label="Currency" v-model="form.currency" :options="currencies.map(currency => ({label: currency, value: currency}))" :error="error('currency')" />
                        </div>
                    </div>
                    <div class="w-2/3">
                        <div class="pl-2 lg:pl-5">
                            <app-input label="Price" v-model="form.price" type="number" :error="error('price')" required />
                        </div>
                    </div>
                </div>

                <!-- Image -->
                <div>
                    <app-image-input v-model="form.imageUrl" />
                    <app-input-error :message="error('imageUrl')" />
                </div>

                <!-- status -->
                <app-select label="Status" v-model="form.status" :options="statuses.map(status=>({label:status, value:status.toLowerCase()}))"  :error="error('status')" required />

                <product-category-select label="Category" v-model="form.category" :error="error('category')" required />

                <app-text-area label="Full description" placeholder="full description...." v-model="form.description" :error="error('description')" required />
               
               <template v-if="$store.getters.isAdmin">
                    <app-switch  label="Active" v-model="form.isActive" />
                </template>

            </div>

            <div class="border-gray-200 py-5 text-center">
                <div class="px-5 md:px-28">
                    <app-button type="submit">{{product ? 'Update' : 'Create' }} Product</app-button>
                </div>
            </div>

        </form> 
    </modal>
</div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import AppInput from '../../../../components/Input.vue';
import AppInputError from '../../../../components/InputError.vue';
import AppButton from '../../../../components/Button.vue';
import AppImageInput from '../../../../components/ImageInput';
import AppSelect from '../../../../components/Select';
import AppSwitch from '../../../../components/Switch';
import AppTextArea from '../../../../components/TextArea';
import ProductCategorySelect from './CategorySelect';

import pageMixin from '../../../../mixins/page';
import ProductService from '../services';

export default {
    name: "ProductForm",
    mixins: [pageMixin],
    components: {
        Modal, AppInput, AppInputError, AppImageInput, AppButton,
        AppSelect, AppTextArea, ProductCategorySelect, AppSwitch
    },
    data(){
        return {
            show: false,           
            statuses: ['Available', 'Unavailable'],
            form: {},
            product: null,
            currencies: [
                'USD', 'NGN'
            ],
            type: 'product',
        }
    },
  
    computed: {
        
    },
    methods: {
        open(product = null){
            this.product = product;
            this.show = true;
            if(!product){
                this.form = {
                    status: 'available',
                }
            }else{
                this.product = product;
                this.form = {
                    name: product.name,
                    price: product.price,
                    imageUrl: product.imageUrl,
                    status: product.status,
                    description: product.description,
                    category: product.category.publicId,
                    isActive: product.isActive,
                    currency: product.currency,
                };
                this.type = product.isService ? 'service' : 'product';
            }
        },
        close(){
            this.show = false;
            this.$emit('close')
        },
       
        submit(){

            this.form.isService = this.type === 'service';

            this.errors = null
            if(this.product) this.updateProduct();
            else this.createProduct();
        },

        createProduct(){
            this.start()
             ProductService.store({
                ...this.form,
                business: this.$store.getters.business.publicId,
            })
            .then(response => {
                this.$emit('created', response.data)
                this.snackbar({
                    text: `New product created`
                });
                this.product = response.data;
            })
            .catch(e => {
                this.errors = e.response.data;
                this.snackbar({
                    text: 'Failed to create product. check your input',
                    type: 'error'
                });
            })
            .finally(() => {
                this.complete();
            })
        },

        updateProduct(){
            this.start()
             ProductService.update(this.product.publicId, {
                ...this.form,
                business: this.$store.getters.business.publicId,
            })
            .then(response => {
                this.$emit('updated', response.data)
                this.snackbar({
                    text: `Product updated.`
                });
                this.product = response.data;
            })
            .catch(e => {
                this.errors = e.response.data;
                this.snackbar({
                    text: 'Failed to update product. check your input',
                    type: 'error'
                });
            })
            .finally(() => {
                this.complete();
            })
        },
    },

}
</script>