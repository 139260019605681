export const nigeria = [
    'Lagos',
    'Onitsha',
    'Kano',
    'Ibadan',
    'Uyo',
    'Port Harcourt',
    'Nsukka',
    'Abuja',
    'Benin City',
    'Aba',
    'Kaduna',
    'Ilorin',
    'Jos',
    'Maiduguri',
    'Owerri',
    'Ikorodu',
    'Zaria',
    'Enugu',
    'Warri',
    'Osogbo',
    'Akure',
    'Sokoto',
    'Abeokuta',
    'Bauchi',
];

export const england = [
    'Bath',
    'Birmingham',
    'Bradford',
    'Brighton and Hove',
    'Bristol',
    'Cambridge',
    'Canterbury',
    'Carlisle',
    'Chester',
    'Chichester',
    'Coventry',
    'Derby',
    'Durham',
    'Ely',
    'Exeter',
    'Gloucester',
    'Hereford',
    'Kingston upon Hull',
    'Lancaster',
    'Leeds',
    'Leicester',
    'Lichfield',
    'Lincoln',
    'Liverpool',
];

export const london = [
    'Manchester',
    'Newcastle upon Tyne',
    'Norwich',
    'Nottingham',
    'Oxford',
    'Peterborough',
    'Plymouth',
    'Portsmouth',
    'Preston',
    'Ripon',
    'Salford',
    'Salisbury',
    'Sheffield',
    'Southampton',
    'St Albans',
    'Stoke-on-Trent',
    'Sunderland',
    'Truro',
    'Wakefield',
    'Wells',
    'Westminster',
    'Winchester',
    'Wolverhampton',
    'Worcester',
    'York',
];

export const wales = [
    'Bangor',
    'Cardiff',
    'Newport',
    'St Davids',
    'Swansea',
];

export const scotland = [
    'Aberdeen',
    'Dundee',
    'Edinburgh',
    'Glasgow',
    'Inverness',
    'Stirling',
];

export const ireland = [
    'Armagh',
    'Belfast',
    'Londonderry',
    'Lisburn',
    'Newry',
];

export function allCities() {
    return [].concat(nigeria, england, london, wales, scotland, ireland);
}