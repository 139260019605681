import API from '../../../../api';

export default class extends API {

    /**
     * Product categories
     */
    static getCategories(){
        return this.get('product-categories');
    }

    static storeCategory(data){
        return this.post(`product-categories/create`, data)
    }

    static updateCategory(id, data){
        return this.put(`product-categories/${id}/edit`, data);
    }

    static deleteCategory(id){
        return this.delete(`product-categories/${id}/delete`);
    }

    /**
     * Store new product
     * @param {*} data 
     */
     static store(data){
        return this.post(`product/create`, data);
    }

    /**
     * Update a product
     * @param {*} id 
     * @param {*} data 
     */
    static update(id, data){
        return this.put(`product/${id}/edit`, data);
    }

    /**
     * 
     * @returns Get products
     */
    static getProducts(){
        return this.get(`product`);
    }

    static getProductByID(id){
        return this.get(`product/${id}`);
    }

    /**
     * Get products for a particular business
     * @param {*} businessId 
     * @returns 
     */
    static getBusinessProducts(businessId, query = {}){
        return this.post(`product/business/${businessId}/find`, query);
    }

    /**
     * Get product in a particular category
     * @param {*} categoryId 
     * @returns 
     */
    static getProductsInCategory(categoryId, query = {}){
        return this.post(`product/category/${categoryId}/find`, query);
    }

    static deleteProduct(id){
        return this.delete(`product/${id}/delete`);
    }

    /**
   * 
   * @param {*} data 
   */
  static createProductOrder(data){
    return this.post( data instanceof Array ? `product_orders/multiple/create` : `product_orders/create`, data);
  }

}
