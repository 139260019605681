
const getters = {
    auth: state => state.auth,
    oauth: state => state.oauth,
    isAuthenticated: state => state.oauth ? true : false,
    profileSet: state => state.auth && ['firstName', 'lastName', 'email', 'dob'].every(field => state.auth[field] != null),
    isAdmin: state => state.auth && state.auth.isStaff,
    isSuperAdmin: state => state.auth && state.auth.isSuperUser
};

export default getters;