<template>
    <admin>
        <template #header>
            <div class="py-2">
                <h2 class="text-xl">Users - {{users.totalCount}}</h2>
            </div>
        </template>
        <data-container :loading="loading">
            <app-empty-items v-if="!users.totalCount" />
            <div v-else class="overflow-auto">
                <table class="min-w-max w-full table-auto">
                    <thead>
                        <tr class="bg-white text-gray-600 uppercase">
                            <th class="py-3 px-6 text-left">User</th>
                            <th class="py-3 px-6 text-left">Email</th>
                            <th class="py-3 px-6 text-left">Created</th>
                            <th class="py-3 px-6 text-left">Status</th>
                            <th class="py-3 px-6 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user,i) in users.items" :key="i"
                        :class="{'bg-gray-100': i%2 === 0, 'bg-white': i%2 === 1 }" 
                        >
                            <td class="py-3 px-6 text-left whitespace-nowrap">
                                <div class="flex items-center">
                                    <user-avatar :user="user" class="w-10 h-10 mr-2 rounded-full" />
                                    <h4>{{user.firstName}} {{user.lastName}}</h4>
                                </div>
                            </td>
                            <td class="py-3 px-6 text-left">
                                <span>{{ user.email }}</span>
                            </td>

                            <td class="py-3 px-6 text-left">
                                <span>{{ user.createdAt }}</span>
                            </td>

                            <td class="py-3 px-6 text-left">
                                <span>{{ user.status | datetime }}</span>
                            </td>
                            
                            <td class="py-3 px-6 text-center">
                                <div class="flex item-center space-x-2 justify-center">
                                    <router-link :to="{name: 'user.show', params: {id: user.publicId, _user: user}}" class="text-primary-900">
                                        <i class="mdi mdi-eye"></i>
                                    </router-link>
                                    <a href="#" @click.prevent="editUser(user)" class="text-primary-900">
                                        <i class="mdi mdi-pencil"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <user-admin-edit ref="userAdminEdit" :user="activeUser" v-on="$listeners" />
        </data-container>
    </admin>
</template>

<script>
import Admin from '../../../layouts/Admin.vue';
import AppEmptyItems from '../../../components/EmptyItems';
import DataContainer from '../../../components/DataContainer';
import UserAvatar from '../../User/components/Avatar';
import UserAdminEdit from '../../User/components/AdminEdit.vue';

import UserService from '../../User/services';

export default {
    name: "AdminUsers",
    metaInfo() {
        return {
            title: `Users (${this.users.totalCount})`
        }
    },
    components: {
        Admin,
        AppEmptyItems,
        DataContainer,
        UserAvatar,
        UserAdminEdit
    },
    data(){
        return {
            loading: false,
            users: {
                items: [],
                totalCount: 0
            },
            activeUser: null,
        }
    },

    computed: {
        
    },

    methods:{
        getUsers(){
            this.loading = true;
            UserService.getUsers()
            .then(response => {
                this.users = response.data
            })
            .catch(() => {
                // this.$store.commit('APP_SNACKBAR', {
                //     type: 'error',
                //     text: `Couldn't get users. ${e.message}`
                // })
            })
            .finally(() => {
                this.loading = false
            })
        },

        editUser(user) {
            this.activeUser = user;
            this.$refs.userAdminEdit.open();
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(){
                this.getUsers();
            }
        }
    }



}
</script>