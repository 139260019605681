export default {
    install(Vue) {
        Vue.filter('nullable', (value, defaultValue = '--') => {
            return value && value != '' ? value : defaultValue;
        });

        Vue.filter('number', (value, formatFigure = true) => {
            let formatted = value;
            if(!formatFigure){
                formatted = new Intl.NumberFormat().format(value)
            }else{
                formatted = 
                    value >= 1000000000
                    ? (value/1000000000).toFixed(value%1000000000 ? 2 : 0)+'B' 
                    : value >= 1000000
                        ? (value/1000000).toFixed(value%1000000 ? 2 : 0)+'M' 
                        : value >= 1000 
                            ? (value/1000).toFixed(value%1000 ? 2 : 0 )+'K' 
                            : value;

            }
            return formatted;
        });

        Vue.filter('datetime', (value, format = '') => {
            if (!value) return '';

            const dateTime = new Date(Date.parse(value));
            const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
            
            const specific = {
                second: dateTime.getSeconds(),
                minute: dateTime.getMinutes(),
                hour: dateTime.getHours(),
                day: dateTime.getDate(),
                month: dateTime.getMonth()+1,
                mName: monthNames[parseInt(dateTime.getMonth())],
                year: dateTime.getFullYear()
            } 

            const date = `${specific.day}/${specific.month}/${specific.year}`;
            const time = `${specific.hour}:${specific.minute}:${specific.second}`;

            if(!format || format == '') return `${date} ${time}`

             return format.replace(/second|minute|hour|day|month|mName|year/gi, (matched) => specific[matched]);
        });
    }
  };