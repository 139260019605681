<template>
    <admin>
        <template #header>
            <div class="py-2 flex justify-between items-center">
                <h2 class="text-xl">Overview</h2>
                <app-button size="sm" @click="getAnalytics" :loading="loading">
                    <i class="mdi mdi-refresh"></i> Refresh
                </app-button>
            </div>
        </template>

        <data-container :loading="loading">
            <template>
                <div class="flex flex-wrap justify-center my-5">
                    <div v-for="(data, i) in group1" :key="i"
                    class="w-1/2 md:w-1/4">
                        <div class="m-2 bg-white flex flex-wrap items-center justify-center h-32 rounded-lg shadow-lg">
                            <div class="w-full text-center">
                                <h4 class="text-gray-500 text-center">{{ data.label }}</h4>
                                <h1 class="text-2xl text-center">{{ data.value }}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="flex flex-wrap bg-white lg:divide-x">
                    <div class="w-full lg:w-3/4">
                        <div class="p-5">
                            <trend-chart :points="chartPoints">
                                <template #head>
                                    <h4 class="text-lg font-bold">Today's trends</h4>
                                    <p class="text-gray-500 text-xs">as of {{ momentToday.format('D MMM YYYY h:mmA') }}</p>
                                </template>
                            </trend-chart>
                        </div>
                    </div>

                    <div class="w-full lg:w-1/4 flex flex-wrap justify-center lg:divide-y">
                        <div v-for="(data, i) in todayStat.data" :key="i"
                        class="w-1/2 lg:w-full">
                            <div class="m-2 bg-white flex flex-wrap items-center justify-center h-32 rounded-lg shadow-lg">
                                <div class="w-full text-center">
                                    <h4 class="text-gray-500 text-center">{{ data.label }}</h4>
                                    <h1 class="text-2xl text-center">{{ data.value }}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </data-container>

    </admin>
</template>

<script>
import moment from 'moment';
import Admin from '../../../layouts/Admin.vue';
import DataContainer from '../../../components/DataContainer';
import AppButton from '../../../components/Button.vue';
import TrendChart from '../components/TrendChart.vue';
import AdminService from '../services'

export default {
    name: "AdminOverview",
    metaInfo: {
        title: 'Admin Overview'
    },
    components: {
        Admin, DataContainer,
        TrendChart, AppButton,
    },
    data() {
        return {
            loading: false,
            analytics: null,
        }
    },
    computed: {
        momentToday() {
            return moment()
        },

        group1() {
            return [
                {
                    label: 'Total Users',
                    value: 0
                },
                {
                    label: 'Registerered Businesses',
                    value: 0
                },
                {
                    label: 'Total Events',
                    value: 0
                },
                {
                    label: 'Total Products',
                    value: 0
                },
            ]
        },

        todayStat() {
            return {
                date: 'Today',
                data: [
                    {
                        label: 'Registered Users',
                        value: 24
                    },
                    {
                        label: 'Registerered Businesses',
                        value: 3
                    },
                    {
                        label: 'Ongoing Events',
                        value: 10
                    },
                    {
                        label: 'Products Purchased',
                        value: 15
                    },
                    {
                        label: 'Advert Displayed',
                        value: 7
                    },
                ]
            }
        },

        yesterdayStat() {
            return { 
                date: 'Yesterday',
                data: [
                    {
                        label: 'Registered Users',
                        value: 10
                    },
                    {
                        label: 'Registerered Businesses',
                        value: 30
                    },
                    {
                        label: 'Ongoing Events',
                        value: 5
                    },
                    {
                        label: 'Products Purchased',
                        value: 25
                    },
                    {
                        label: 'Advert Displayed',
                        value: 15
                    },
                ]
            }
        },

        chartPoints() {
            return [this.yesterdayStat, this.todayStat];
        }
    },
    methods: {
        getAnalytics() {
            this.loading = true;
            AdminService.analytics()
            .then(response => {
                console.log(response.data);
                this.analytics = response.data
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                this.loading = false
            })
        }
    },

    mounted() {
        this.getAnalytics();
    }
}
</script>