import API from '../../../api';

export default class extends API {

    /**
     * Event categories
     */
    static getCategories(){
        return this.get('event-categories');
    }

    static storeCategory(data){
        return this.post(`event-categories/create`, data)
    }

    static updateCategory(id, data){
        return this.put(`event-categories/${id}/edit`, data);
    }

    static deleteCategory(id){
        return this.delete(`event-categories/${id}/delete`);
    }

    /**
     * Store new event
     * @param {*} data 
     */
    static store(data){
        return this.post(`events/create`, data);
    }

    /**
     * Update an event
     * @param {*} id 
     * @param {*} data 
     */
    static update(id, data){
        return this.put(`events/${id}/edit`, data);
    }

    /**
     * Get events
     */
    static getEvents(query = {}){
        return this.post('events/find', query);
    }
    
    /**
     * Get lates events
     */
    static getLatestEvents(query = {})
    {
        return this.get(`events/latest`, query);
    }

    /**
     * Get event suggesttions for user
     * @param {*} userId 
     */
    static getEventSuggestions(userId, query = {})
    {
        return this.post(`events/suggested/${userId}/find`, query);
    }


    /**
     * Get all events the user has made order on
     * @param {*} userId 
     */
    static getUserEvents(userId, query = {})
    {
        return this.post(`events/all/${userId}/find`, query)
    }


    /**
     * Get upcoming events the user has made order on
     * @param {*} userId 
     */
    static getUserUpcomingEvents(userId, query = {})
    {
        return this.post(`events/upcoming/${userId}/find`, query)
    }

    /**
     * Get past events the user has made order on
     * @param {*} userId 
     */
    static getUserPastvents(userId, query = {})
    {
        return this.post(`events/past/${userId}/find`, query)
    }

    /**
     * Get a single event
     * @param {*} id 
     */
    static getEvent(id){
        return this.get(`events/${id}`)
    }

    /**
     * Get single event by its slug
     * @param {*} slug 
     */
    static getEventBySlug(slug){
        return this.get(`events/slug/${slug}`)
    }

    /**
     * Get single event by its public id
     * @param {*} id 
     */
     static getEventById(id){
        return this.get(`events/${id}`)
    }

    /**
     * Get events in a category
     * @param {*} categoryId 
     */
    static getEventsByCategory(categoryId, query = {})
    {
        return this.post(`events/category/${categoryId}/find`, query)
    }

    /**
     * Get events by a business
     * @param {*} businessId 
     */
     static getBusinessEvents(businessId,  query = {})
     {
        return this.post(`events/business/${businessId}/find`, query)
     }

     /**
      * Get all interest tags
      */
     static getInterestTags()
     {
         return this.get(`interest-tags`);
     }

     /**
      * Delete an event
      * @param {*} id 
      * @returns 
      */
     static deleteEvent(id){
        return this.delete(`events/${id}/delete`)
     }



}
