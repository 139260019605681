<template>
    <app-layout>
        <div class="flex justify-center py-5">
            <div class="w-full lg:w-2/3">
                <div class="bg-white px-2 md:px-10 py-5">
                    <h1 class="text-lg text-primary-900 font-medium">New Event</h1>
                    <event-form @finished="eventFormFinished" />
                </div>
            </div>
        </div>
        
    </app-layout>
</template>

<script>
import AppLayout from '../../../layouts/App.vue';
import EventForm from '../components/Form.vue';

export default {
    name: 'EventCreate',
    metaInfo: {
        title: `Create Event`
    },
    components: {
        AppLayout, EventForm
    }
}
</script>