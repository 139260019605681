import middleware from '../../router/middleware';
import multiguard  from 'vue-router-multiguard';

import AdminOverview from './views/Overview';
import AdminUsers from './views/Users';
import AdminEvents from './views/Events';
import AdminProducts from './views/Products';
import AdminBusinesses from './views/Businesses';
import AdminAdverts from './views/Adverts';


const routes = [
    {
        path: '/admin',
        name: 'admin',
        component: AdminOverview,
        beforeEnter: multiguard([middleware.auth, middleware.admin])
    },

    {
        path: '/admin/users',
        name: 'admin.users',
        component: AdminUsers,
        beforeEnter: multiguard([middleware.auth, middleware.admin])
    },

    {
        path: '/admin/businesses',
        name: 'admin.businesses',
        component: AdminBusinesses,
        beforeEnter: multiguard([middleware.auth, middleware.admin])
    },

    {
        path: '/admin/events',
        name: 'admin.events',
        component: AdminEvents,
        beforeEnter: multiguard([middleware.auth, middleware.admin])
    },

    {
        path: '/admin/event-categories',
        name: 'admin.event.categories',
        component: AdminEvents,
        beforeEnter: multiguard([middleware.auth, middleware.admin])
    },


    {
        path: '/admin/products',
        name: 'admin.products',
        component: AdminProducts,
        beforeEnter: multiguard([middleware.auth, middleware.admin])
    },

    {
        path: '/admin/products-categories',
        name: 'admin.product.categories',
        component: AdminProducts,
        beforeEnter: multiguard([middleware.auth, middleware.admin])
    },

    {
        path: '/admin/adverts',
        name: 'admin.adverts',
        component: AdminAdverts,
        beforeEnter: multiguard([middleware.auth, middleware.admin])
    },

    {
        path: '/admin/advert-types',
        name: 'admin.advert.types',
        component: AdminAdverts,
        beforeEnter: multiguard([middleware.auth, middleware.admin])
    },




];

export default routes;