<template>
    <app-layout>
        <div class="flex justify-center py-5">
            <div class="w-full md:w-2/3">
                <div class="bg-white px-0 md:px-10 lg:px-20">
                    <business-profile-form />
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
    import AppLayout from '../../../layouts/App.vue';
    import BusinessProfileForm from '../components/BusinessProfileForm.vue';
    export default {
        name: "BusinessEdit",
        data(){
            return {
                tab: 'profile'
            }
        },
        components: {
            AppLayout,
            BusinessProfileForm,
        },

    }
</script>