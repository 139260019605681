<template>
    <div class="">
        <div class="flex flex-wrap justify-between items-center">
            <div class="w-full sm:w-auto">
                <slot name="head" v-bind="{ chart }" />
            </div>
            <div class="w-full sm:w-auto">
                <app-select 
                :options="chartTypes"
                :unselectable="false"
                v-model="type"
                class="sm:w-36"
                @input="renderChart()"
                bg="white"
                />
            </div>
        </div>
        <canvas id="chart" width="400" height="400"></canvas>
    </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import AppSelect from '../../../components/Select.vue';

Chart.register(...registerables);
export default {
    name: "TrendChart",

    components: { AppSelect },

    props: {
        points: Array
    },
    data() {
        return {
            chart: null,
            type: 'line',
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            },
            chartData: {
                labels: [],
                dataset:[],
            },
        }
    },

    computed: {
        chartTypes() {
            return [
                {
                    label: 'Line',
                    value: 'line'
                },
                {
                    label: 'Bar',
                    value: 'bar'
                },
                {
                    label: 'Scatter',
                    value: 'scatter'
                },
                {
                    label: 'Bubble',
                    value: 'bubble'
                },
                {
                    label: 'Pie',
                    value: 'pie'
                },
                {
                    label: 'Doughnut',
                    value: 'doughnut'
                },
                {
                    label: 'Polar Area',
                    value: 'polarArea'
                },
                {
                    label: 'Radar',
                    value: 'radar'
                },
            ]
        }
    },
    
    methods: {
        randomizeColor(value) {
            const getRandom = () => Math.floor(Math.random()*100) + value
            return `rgba(${getRandom()%255}, ${getRandom()%255}, ${getRandom()%255}, 0.9)`;
        },


        renderChart() {

            if(this.chart) { // If there is a existing chart, destroy first
                this.chart.destroy()
            }

            let ctx = document.getElementById('chart');

            this.chart = new Chart(ctx, {
                type: this.type,
                data: this.chartData,
                options: this.options
            }); 
        }
    },

    mounted() {
        this.renderChart();
    },

    watch: {
        points: {
            immediate: true,
            handler(points) {
                if(!points || !points.length) {
                    this.chartData = {
                        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                        datasets: [
                            {
                                label: '# of Votes',
                                data: [12, 19, 3, 5, 2, 3],
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(75, 192, 192, 0.2)',
                                    'rgba(153, 102, 255, 0.2)',
                                    'rgba(255, 159, 64, 0.2)'
                                ],
                                borderColor: [
                                    'rgba(255, 99, 132, 1)',
                                    'rgba(54, 162, 235, 1)',
                                    'rgba(255, 206, 86, 1)',
                                    'rgba(75, 192, 192, 1)',
                                    'rgba(153, 102, 255, 1)',
                                    'rgba(255, 159, 64, 1)'
                                ],
                                borderWidth: 1
                            }
                        ]
                    }
                }
                else {
                    // Format the incoming data to the chart dataset                

                    this.chartData = {
                        labels: points[0].data.map((data) => data.label),
                        datasets: points.map((point) => {
                            return {
                                label: point.date,
                                data: point.data.map(data => data.value),
                                backgroundColor: point.data.map(data => this.randomizeColor(data.value)),
                                borderColor: point.data.map(data => this.randomizeColor(data.value)),
                                borderWidth: 1
                            }
                        })
                    }
                }
            }
        },
        
    }
}
</script>