<template>
  <header class="bg-white py-5 fixed z-10 w-full">
      <div class="flex flex-wrap justify-center">
          <div class="w-1/2">
              <div class="text-center">
                  <router-link :to="{name: 'home'}">
                      <img src="@/assets/logo.png" alt="PinEventz" class="h-6 mx-auto">
                  </router-link>
              </div>
          </div>
      </div>
  </header>
</template>

<script>
export default {
    name: "PlainHeader",
}
</script>