<template>
    <div class="flex flex-wrap justify-center">
        <div class="w-full bg-white" :class="{'sm:w-1/4': vertical}">
            <slot name="before-items" v-bind="{ active: activeItem }"  />
            <ul>
                <li v-for="(item, i) in items" :key="i" class="inline-block list-none" :class="{'sm:block': vertical}">
                    <slot name="link" v-bind="{ item, active: current === i  }">
                        <span :class="`block py-2 px-4 cursor-pointer font-semibold ${current === i ? activeClass : inActiveClass}`"
                        @click="$emit('input', i)">
                            {{ item.title }}
                        </span>
                    </slot>
                </li>
            </ul>
        </div>
        <div class="w-full" :class="{'sm:w-3/4': vertical}">
            <slot name="content" v-bind="{ active: activeItem }" />
        </div>
    </div>
</template>

<script>
export default {
    name: "AppTabsPanel",
    data() {
        return {
            current: 0,
        }
    },
    props: {
        value: Number,
        items: Array,
        activeClass: {
            type: String,
            default: () => 'text-white bg-primary-900'
        },
        inActiveClass: {
            type: String,
            default: () => 'bg-white text-primary-900 hover:bg-gray-100'
        },
        vertical: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        activeItem() {
            return this.items[this.current]
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.current = val;
            }
        }
    }
}
</script>