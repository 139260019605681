<template>
    <div>
        <modal :show="show" max-width="xl" @close="close">
            <form class="divide-y" @submit.prevent="submit">

                <div class="px-2">
                    <div class="flex flex-wrap items-center space-x-1">
                        <business-avatar :business="business" class="h-20 w-20 rounded-full cursor-pointer"  />
                        <h1 class="text-primary-900 text-lg font-medium">{{ form.name }}</h1>
                    </div>
                </div>
            
                <div class="mt-3 px-3 sm:px-10 py-5 space-y-5 overflow-auto" style="max-height: 50vh">

                    <app-input label="Name" v-model="form.name" type="text" :error="error('name')" />
                    
                    <div class="flex flex-wrap items-center space-y-5 md:space-y-0">
                        <div class="w-full md:w-1/2">
                            <div class="md:pr-5">
                                <app-input label="Username" v-model="form.username" type="text" :error="error('username')" />
                            </div>
                        </div>
                        <div class="w-full md:w-1/2">
                        <div class="md:pl-5">
                                <app-input label="Website" v-model="form.website" type="url" :error="error('website')" />
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap justify-between items-center space-y-5 sm:space-y-0">
                
                        <div class="w-full">
                            <div class="">
                                <app-select label="Category" v-model="form.businessType" :options="categories" :error="error('businessType')" />
                            </div>
                        </div>

                    </div>

                    <app-input label="Registration Number" v-model="form.registrationNumber" type="text" :error="error('registrationNumber')" />

                    <div class="flex flex-wrap">

                        <div class="w-1/2">
                            <div class="pr-2 md:pr-5">
                                <app-input label="Email" v-model="form.businessEmail" type="text" :error="error('businessEmail')" />
                            </div>
                        </div>

                        <div class="w-1/2">
                            <div class="pl-2 md:pl-5">
                                <app-input label="Phone" v-model="form.businessPhone" type="tel" :error="error('businessPhone')" />
                            </div>
                        </div>                   

                    </div>


                    <div class="flex flex-wrap space-y-4">
                        <div class="w-full">
                            <app-input label="Address" v-model="form.address" type="text" :error="error('address')" />
                        </div>

                        <div class="w-1/2">
                            <div class="pr-2 md:pr-5">
                                <business-city-select label="City" v-model="form.city"  :error="error('city')" />
                            </div>
                        </div>

                        <div class="w-1/2">
                            <div class="pl-2 md:pl-5">
                                <app-input label="Country" v-model="form.country" type="text" :error="error('country')" />
                            </div>
                        </div>                   
                    </div>

                    <app-switch label="Active" v-model="form.isActive" />

                </div>

                <div class="border-gray-200 py-5 text-center">
                    <div class="px-5 md:px-28">
                        <app-button type="submit">Save</app-button>
                    </div>

                </div>
            </form>
        </modal>
    </div>
</template>

<script>

import Modal from '../../../components/Modal.vue';
import AppInput from '../../../components/Input.vue';
import AppButton from '../../../components/Button.vue';
import AppSelect from '../../../components/Select.vue';
import AppSwitch from '../../../components/Switch.vue';
import BusinessAvatar from './Avatar.vue';
import BusinessCitySelect from './BusinessCitySelect.vue';

import BusinessService from '../services';
import pageMixin from '../../../mixins/page';

export default {
    name: "AdminBusinessProfileForm",
    mixins: [pageMixin],
    components: {
        Modal, AppInput, AppButton, AppSelect, BusinessAvatar, AppSwitch,
        BusinessCitySelect
    },
    data(){
        return {
            show: false,
            form: {},
            business: null,
        }
    },
    computed: {
        categories(){
            return  BusinessService.categories().map(category => ({label: category.name, value: category.name}));
        },
    },
    methods: {
         open(business){
            this.business = business;
            this.show = true;
        },

        close(){
            this.show = false;
            this.$emit('close')
        },

       submit(){
            this.start();
            this.errors = null;

           BusinessService.update(this.business.publicId, this.form)
           .then(response => {
                this.snackbar({
                    text: 'Business update successful',
                })
                this.business = response.data;
                this.$emit('updated', response.data);
                this.close();
           })
           .catch(e => {
                this.errors = e.response.data;
            })
            .finally(() => {
                this.complete();
            })
        }
    },

    watch: {
        business: {
            immediate: true,
            handler(business){
                if(business){
                    this.form = {
                        ...business,
                        owner: business.owner.publicId
                    }
                    
                }
            }
        }
    }

}
</script>