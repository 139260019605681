<template>
    <app-layout>
        <section class="s1 bg-center bg-cover bg-no-repeat shop-banner">
            <div class="flex">
                <div class="w-full lg:w-2/3 py-32 px-5 sm:px-10">
                    <h1 class="text-white text-3xl lg:text-5xl">
                        Find the perfect event suppliers and venues for your events
                    </h1>
                    <div class="flex mt-10">
                        <div class="divide-x flex-grow flex">
                            <input type="text" class="p-3 focus:outline-none w-2/3" placeholder="What are you looking for">
                            <input type="text" class="p-3 focus:outline-none w-1/3" placeholder="Location">
                        </div>
                        <button class="bg-primary-900 text-white px-5 sm:px-10 focus:outline-none">
                            <i class="fe fe-search mr-2"></i>SEARCH
                        </button>
                    </div>

                    <div class="mt-5 flex flex-wrap space-y-3 items-center">
                        <p class="text-white mr-5 pt-3">Popular search</p>
                        <div class="flex items-center space-x-3 overflow-x-auto">
                            <button v-for="(search, i) in popularSearches" :key="i"
                            class="border border-white rounded-sm py-1 px-5 text-center text-white uppercase focus:outline-none">
                                {{ search }}
                            </button>
                        </div>
                        
                    </div>

                </div>
            </div>
        </section>
        <section class="s2 flex flex-wrap bg-white py-3 px-10 lg:px-20">
            <div v-for="(feature, f) in features" :key="f"
            class="w-full sm:w-1/3 text-center">
                <div class="p-5">
                    <i class="text-gray-500 text-5xl" :class="feature.icon"></i>
                    <h2 class="text-3xl text-black">{{ feature.heading }}</h2>
                    <p>{{ feature.description }}</p>
                </div>
            </div>
        </section>

        <section class="s3 my-5 px-2 sm:px-5 lg:px-20">
            <div class="flex justify-between">
                <h1 class="font-bold text-lg">Top Businesses</h1>
                <router-link :to="{name: 'shop.businesses'}" class="text-primary-900">More <i class="mdi mdi-arrow-right"></i></router-link>
            </div>

            <top-businesses />
                        
        </section>

        <section class="s4 my-5  px-2 sm:px-5 lg:px-20">
            <div class="flex justify-between">
                <h1 class="font-bold text-lg">Browse by Categories</h1>
                <router-link :to="{name: 'shop.business.categories'}" class="text-primary-900">More <i class="mdi mdi-arrow-right"></i></router-link>
            </div>

            <div class="flex flex-wrap justify-center">
                <div v-for="(category, i) in categories" :key="i"
                class="w-full sm:w-1/3 md:w-1/5">
                    <business-category-card :category="category" />
                </div>
            </div>            
        </section>

        <section class="s5 my-5 px-2 sm:px-5 lg:px-20">
            <div class="flex justify-between">
                <h1 class="font-bold text-lg">Browse by Location</h1>
                <a href="#" class="text-primary-900">More <i class="mdi mdi-arrow-right"></i></a>
            </div>

            <div class="flex flex-wrap justify-center">
                <router-link v-for="(city, i) in cities"  :key="i"
                :to="{name: 'shop.businesses', query: { location: city }}"
                class="w-full sm:w-1/2 md:w-1/6">
                    <div class="m-2 bg-white hover:shadow-lg rounded-md">
                        <img :src="`https://picsum.photos/id/${10+i}/200`" class="w-full rounded-t-md">
                        <div class="p-2 rounded-b-md">
                            <h2 class="font-bold text-primary-900"><i class="mdi mdi-map-marker"></i> {{ city }}</h2>
                        </div>
                    </div>
                </router-link>
            </div>            
        </section>

        <section class="s6 bg-primary-900 py-10 text-center space-y-10">
            <h4 class="text-lg text-white">What are you waiting for, Join Pin Eventz today to find the the best suppliers for your next event.</h4>
            <div>
                <app-button bg="white" color="primary-900" :to="{name: 'signup'}">JOIN PINEVENTZ </app-button>
            </div>
        </section>
    </app-layout>
</template>

<script>

import AppLayout from '../../layouts/App.vue';
import AppButton from '../../components/Button.vue';
import BusinessCategoryCard from './Business/components/CategoryCard.vue';
import TopBusinesses from './Business/widgets/TopBusinesses.vue'
import { nigeria, london, england, wales, ireland, scotland } from '../../api/cities';
import BusinessService from '../Business/services';

export default {
    name: "ShopHome",
    metaInfo: {
        title: `Shop`
    },
    components: {
        AppLayout, AppButton, 
        BusinessCategoryCard, TopBusinesses
    },

    data(){
        return {
            popularSearches: [],
            features: [
                {
                    icon: 'mdi mdi-magnify',
                    heading: 'Search',
                    description: 'Search for the services you require with respect to the location for your event.'
                },
                {
                    icon: 'mdi mdi-phone',
                    heading: 'Contact',
                    description: 'Contact the supplier or venue manager via our platform.'
                },
                {
                    icon: 'mdi mdi-calendar',
                    heading: 'Contact',
                    description: 'Schedule a date, time and location with the supplier and await their delivery.'
                }
            ],
            categories: [],
            cities: [],
        }
    },
    methods: {
        randomImage() {
            return 'https://picsum.photos/200';
        }
    },

    mounted(){
        this.popularSearches = [ 'catering', 'Mascot', 'Games', 'Toys' ];
        this.categories = BusinessService.categories();
        this.cities = [nigeria[0], london[0], england[0], wales[0], ireland[0], scotland[0] ];
    }
}
</script>

<style scoped>
    .shop-banner{
        background-image: url('../../assets/images/shop-banner.jpg');
    }
</style>>
