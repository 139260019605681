<template>
    <div v-if="event" class="space-y-5">
        <div v-if="!tickets.length" class="p-5 text-gray-500 text-center">
            No ticket yet
        </div>
        <div v-else class="space-y-2">
            <ticket v-for="ticket in tickets" :key="ticket.publicId" :ticket="ticket" :show-actions="true" @deleted="ticketDeleted" />
        </div>
        <div class="text-center">
            <app-button type="button" v-if="!createTicket" @click.prevent="createTicket = !createTicket"><i class="fe fe-plus"></i> Add Ticket</app-button>
        </div>
        <div  v-if="createTicket" class="bg-gray-100 p-3 rounded-md" >
            <div class="text-right mb-3">
                <i class="fe fe-x text-lg cursor-pointer" @click="createTicket = !createTicket"></i>
            </div>
            <ticket-form :event="event" @created="ticketCreated" />
        </div>
    </div>
</template>

<script>
import AppButton from '../../../components/Button.vue';
import Ticket from '../../Ticket/components/Ticket.vue';
import TicketForm from '../../Ticket/components/TicketForm.vue';

export default {
    name: "TicketsManagement",
    components: {
        AppButton, Ticket, TicketForm
    },
    props: {
        event: Object,
    },
    data() {
        return {
            tickets: [],
            createTicket: false,
        }
    },
    methods: {
        ticketCreated(ticket) {
            this.tickets.push(ticket);
        },
        ticketDeleted(ticket) {
            this.tickets.splice(this.tickets.findIndex(t => t.publicId === ticket.publicId), 1);
        }
    },
    watch: {
        event: {
            immediate: true,
            handler(event) { 
                this.tickets = event.tickets;
            }
        }
    }
}
</script>