const state = {
    appReady: false,
    mode: 'personal',
    snackbar: {
        show: false,
        text: 'Holla',
        actions: []
    },
    cache: {}
}

export default state;