<template>
    <span 
    :class="`inline-block spinner ease-linear
    rounded-full border-2 border-t-2
    border-${color} h-${size} w-${size}`">
    </span>
</template>

<script>
export default {
    name: "AppSpinner",
    props: {
        color: {
            type: String,
            default: () => 'gray-500'
        },
        size: {
            type: Number,
            default: () => 4
        }
    }
}
</script>

<style>
.spinner {
    border-top-color: transparent;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
