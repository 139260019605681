<template>
    <div v-if="updateExists" class="flex justify-center w-full" >
        <div class="fixed top-28 flex justify-between items-center p-2 px-5  bg-primary-900 rounded-md shadow-lg" style="z-index: 999">
          <div>
              <div class="text-white">There is an update</div>
          </div>
          <div class="flex flex-wrap items-center justify-between ml-2">
              <div class="text-lg text-white ml-2">
                  <button @click="refreshApp" class="px-4 py-1 mx-1 bg-transparent border-gray-300 border rounded-md focus:outline-none disabled:opacity-25 transition ease-in-out duration-150 text-white text-sm">Install</button>
              </div>
              <div class="text-lg text-white ml-2">
                  <i @click="close" class="fe fe-x cursor-pointer"></i>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppServiceWorker",

    data() {
        return {
            // refresh variables
            refreshing: false,
            registration: null,
            updateExists: false,
        }
    },

  created() {
       // Listen for our custom event from the SW registration
      document.addEventListener('swUpdated', this.updateAvailable, { once: true })

      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        // Here the actual reload of the page occurs
        window.location.reload()
      })
   
  },

  methods: {

    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },

    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },

    close(){
        this.updateExists = false
    }
  },

}
</script>