<template>
    <div>
        <app-button v-bind="$attrs" @click="$refs.pinForm.open()"> <i class="fe fe-plus mr-2"></i> Add pin</app-button>
        <pin-form ref="pinForm" :event="event" v-on="$listeners" />
    </div>
</template>

<script>
import AppButton from '../../../components/Button.vue';
import PinForm from './PinForm.vue';
export default {
    name: "PinButton",
    components: {
        AppButton, PinForm
    },
    props: {
        event: {
            type: Object,
            required: true,
        }
    }
}
</script>