import User from '../services';
import Auth from '../../../domain/Auth/services';
import Pin from '../../../domain/Pin/services';
import AuthStorage from '../../../classes/Auth';

const actions = {

    UserLogin: ({ commit, dispatch }, { email, password }) => {

        return new Promise((resolve, reject) => {
            Auth.login({ email, password })
            .then((response) => {
                commit('SET_OAUTH', response.data);
                
                return dispatch('getLoggedInUser');
            })
            .then((response) => {
                resolve(response);
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    RefreshUserToken: ({ commit }) => {
        return new Promise((resolve, reject) => {
            Auth.refreshToken()
            .then((response) => {                
                commit('SET_OAUTH', response.data);
                resolve(response.data);
            })
            
            .catch(err => {
                reject(err)
            })
        })
    },

    UserRegister: ({ commit }, data) => {

        return new Promise((resolve, reject) => {
            Auth.register(data).then(response => {
                commit('APP_SNACKBAR', {
                    text: `A verification code has been sent to ${data.email}`,
                    persist: true,
                })
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    UserVerification: ({ commit }, code) => {

        return new Promise((resolve, reject) => {
            Auth.verify(code)
            .then((response) => {
                commit('APP_SNACKBAR', {
                    text: "Account verification successful. You can now login",
                    persist: true
                })
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },

    getLoggedInUser: ({commit}) => {
        
        return new Promise((resolve, reject) => {

            commit('APP_READY', false);

            Auth.user().then(response => {

                const user = response.data;
                commit('SET_AUTH_USER', user);
                if(user.business){
                    commit('SET_AUTH_BUSINESS', user.business);
                }
                commit('APP_SNACKBAR', {
                    text: `Logged in as ${user.email}`
                })
                commit('APP_READY', true);
                resolve(response);
            })
            .catch(e => {
                if(e.response) commit('APP_READY', true)
                reject(e)
            })
            .finally(() => {
                commit('SET_OAUTH', AuthStorage.getOAuth());
            })
        })
    },

    UserLogout: ({ commit }) => {
        return new Promise((resolve) => {
            commit('SET_AUTH_USER', null)
            commit('SET_AUTH_BUSINESS', null);
            Auth.logout();

            commit('SET_OAUTH', null);

            commit('APP_SNACKBAR', {
                text: `Logged out`
            })

            resolve();
        })
    },

    followUser: ({ getters, commit }, user) => {
        return new Promise((resolve, reject) => {
            User.follow(getters.auth.publicId, user.publicId).then(response => {
                commit('APP_SNACKBAR', {
                    text:  `Followed ${user.firstName}`,
                    // actions: [
                    //     {
                    //         text: 'undo',
                    //         action: () => dispatch('unfollowUser', user)
                    //     }
                    // ]
                });
                
                resolve(response);
            })
            .catch(e => {
                reject(e)
            })
        })
    },

    unfollowUser: ({ getters, commit }, user) => {
        return new Promise((resolve, reject) => {
            User.unfollow(getters.auth.publicId, user.publicId).then(response => {
                commit('APP_SNACKBAR', {
                    text:  `Unfollowed ${user.firstName}`,
                    // actions: [
                    //     {
                    //         text: 'Follow back',
                    //         action: () => dispatch('followUser', user)
                    //     }
                    // ]
                });

                resolve(response);
            })
            .catch(e => {
                reject(e)
            })
        })
    },

    getLoggedInUserPins: ({ getters, commit }) => {
        const cache = '_myPins';
        return getters.cache(cache) 
                ? Promise.resolve(getters.cache(cache)) 
                : new Promise((resolve, reject) => {
                    Pin.getUserPins(getters.auth.publicId)
                    .then(response => {
                        commit('CACHE', {key: cache, data: response.data })
                        resolve(response.data)
                    })
                    .catch(e => {
                        reject(e);
                    })
                })
    },
    
    getUserFollowers: ({ getters, commit }, user = null) => {

        if(user && user.publicId !== getters.auth.publicId) {
            return Promise((resolve, reject) => {
                User.getUserFollowers(user.publicId)
                .then(response => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e);
                })
        })}

        const cache = '_myFollowers';
        return getters.cache(cache) 
                ? Promise.resolve(getters.cache(cache)) 
                : new Promise((resolve, reject) => {
                    User.getUserFollowers(getters.auth.publicId)
                    .then(response => {
                        commit('CACHE', {key: cache, data: response.data })
                        resolve(response.data)
                    })
                    .catch(e => {
                        reject(e);
                    })
                })
    },

    getUserFollowings: ({ getters, commit }, user = null) => {
        if(user && user.publicId !== getters.auth.publicId) {
            return Promise((resolve, reject) => {
                User.getUserFollowings(user.publicId)
                .then(response => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e);
                })
        })}

        const cache = '_myFollowings';
        return getters.cache(cache) 
                ? Promise.resolve(getters.cache(cache)) 
                : new Promise((resolve, reject) => {
                    User.getUserFollowings(getters.auth.publicId)
                    .then(response => {
                        commit('CACHE', {key: cache, data: response.data })
                        resolve(response.data)
                    })
                    .catch(e => {
                        reject(e);
                    })
                })
    },

    UserBankCreate: ({getters, commit }, data) => {
        return new Promise((resolve, reject) => {
            User.createBank(data )
            .then(response => {
                
                commit('SET_AUTH_USER', {
                    ...getters.auth,
                    bankAccount: response.data
                })

                resolve(response);
            })
            .catch(err => {
                commit('APP_ERROR', {
                    text: `Failed to create bank details`
                })
                reject(err)
            })
        })
    },

    UserBankUpdate: ({getters, commit }, data) => {
        return new Promise((resolve, reject) => {
            User.updateBank(getters.auth.bankAccount.publicId, data )
            .then(response => {
                
                commit('SET_AUTH_USER', {
                    ...getters.auth,
                    bankAccount: response.data
                })

                resolve(response);
            })
            .catch(err => {
                commit('APP_ERROR', {
                    text: `Failed to update bank details `
                })
                reject(err)
            })
        })
        
    },
    

}

export default actions;